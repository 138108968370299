import { createAction, props } from "@ngrx/store";
import { EntryCollection } from "contentful";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";

export const fetchContentfulEntries = createAction(
  "[Contentful] Fetch entries",
  props<{ contentType: ContentfulContentType | undefined }>()
);
export const setContentfulEntries = createAction(
  "[Contentful] Set entries",
  props<{ contentType: ContentfulContentType | undefined; result: EntryCollection<any> }>()
);
