import { EventDefinition } from "redux-beacon";
import { AnalyticsEventProps } from "./interfaces";

export const createEventDefinition: EventDefinition = (action, prevState, nextState) =>
  createTrackEvent(action as AnalyticsEventProps);

export const createTrackEvent = ({ category, action, name, value }: AnalyticsEventProps) => [
  "trackEvent",
  category,
  action,
  name,
  value
];

export const trackRouterChange: EventDefinition = ({
  payload: {
    event: { url }
  }
}) => {
  window["trackRouter"](`${location.origin}${url}`);
};
