import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { INotificationSelect } from "../../../../../interfaces/dialogs/notification-settings";
import { NotificationSettingsService } from "../../services/notification-settings.service";

@Component({
  selector: "app-one-region",
  templateUrl: "./one-region.component.html",
  styleUrls: ["./one-region.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OneRegionComponent implements OnInit, OnDestroy {
  @Output() public close = new EventEmitter<void>();
  public selectedConfig: INotificationSelect;
  public initialized = true;
  private destroy$ = new Subject<void>();

  constructor(
    private notificationSettingsService: NotificationSettingsService,
    private readonly dialogsService: DialogsService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {}

  public ngOnInit(): void {
    this.notificationSettingsService
      .getTabName()
      .pipe(
        switchMap(() => this.notificationSettingsService.fetchData()),
        takeUntil(this.destroy$)
      )
      .subscribe(({ data }) => {
        this.selectedConfig = { selected: [data[0].regionId] };
        this.cdr.detectChanges();
      });
  }

  public onClose(): void {
    this.close.emit();
  }

  public closeEdit(): void {
    this.initialized = false;
    this.cdr.detectChanges();
    this.initialized = true;
    this.cdr.detectChanges();
  }

  public openPolicy(): void {
    this.dialogsService.openPolicyDialog();
  }
}
