import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import "@enersis/gp-components/gp-checkbox";
import "@enersis/gp-components/gp-formfield";
import { CheckboxWithStateDirective } from "./checkbox-state.directive";
import { CheckboxComponent } from "./checkbox.component";

@NgModule({
  declarations: [CheckboxComponent, CheckboxWithStateDirective],
  imports: [ReactiveFormsModule],
  exports: [CheckboxComponent, CheckboxWithStateDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CheckboxModule {}
