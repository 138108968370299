<tpm-card class="banner-card" [variant]="'elevation-basic-10'">
  <div class="banner-wrapper">
    <tpm-colored-badge
      *ngIf="showBadge"
      class="tag"
      text="NEWS"
      color="--ene-color-white"
      backgroundColor="--ene-color-primary-600"
    ></tpm-colored-badge>
    <div *ngIf="isMobile === false" class="banner__teaser-picture-wrapper">
      <img *ngIf="props.teaserImage" alt="Teaser Image" [src]="props.teaserImage" />
      <mat-icon *ngIf="props.icon" [fontIcon]="props.icon"></mat-icon>
    </div>
    <div class="banner__description">
      <div class="date">{{ props.date | date : "dd.MM.yyyy" }}</div>
      <div class="banner__teaser-wrapper">
        <div class="headline last-line-2">{{ props.headline }}</div>
        <div *ngIf="isMobile === false && $any(props).teaser" class="teaser last-line-4">{{ props.teaser }}</div>
      </div>
    </div>
  </div>
</tpm-card>
