import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Entry } from "contentful";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import { RootState } from "src/app/ngrx/root-reducers";
import {
  selectAvailableContentfulEntries,
  selectContentfulContentType
} from "src/app/ngrx/selectors/contentful.selectors";
import { selectUsersSettingsUsersStorage } from "src/app/ngrx/selectors/users-settings.selectors";
import { WebinarsEntry } from "./webinars.interfaces";

@Injectable()
export class WebinarsService {
  constructor(private readonly store: Store<RootState>) {}

  public getWebinarsEntries(): Observable<any> {
    return combineLatest([
      this.store.pipe(select(selectContentfulContentType)),
      this.store.pipe(select(selectUsersSettingsUsersStorage)),
      this.store.pipe(select(selectAvailableContentfulEntries))
    ]).pipe(
      map(([contentType, storage, { items = [], includes = {} }]) => {
        switch (contentType) {
          case ContentfulContentType.RegulatedModulesWebinars:
          case ContentfulContentType.ServicesModulesWebinars: {
            const assetById =
              includes?.Asset?.reduce((acc, item) => ({ ...acc, [item.sys.id]: item.fields.file.url }), {}) || {};

            return items
              .map((item) => this.createWebinarsEntry(item as Entry<any>, assetById, storage))
              .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
          }
          default: {
            return [];
          }
        }
      })
    );
  }

  private createWebinarsEntry(
    entry: any,
    assetById: Record<string, string>,
    storage: Record<string, boolean>
  ): WebinarsEntry {
    return {
      ...entry.fields,
      id: entry.sys.id,
      teaserImage: assetById[entry.fields.videoImage.sys.id],
      media: assetById[entry.fields.video.sys.id],
      teaser: entry.fields.videoDescription,
      isNew: !storage[entry.sys.id],
      mediaType: assetById[entry.fields.video.sys.id].toLowerCase().endsWith(".pdf") ? "pdf" : "video"
    };
  }
}
