export interface IGasConnectionGroupsDto {
  consumption: number;
  peakHourlyCapacity: number;
}

export class GasConnectionGroupsDto implements IGasConnectionGroupsDto {
  public consumption: number;
  public peakHourlyCapacity: number;

  constructor(data: IGasConnectionGroupsDto) {
    Object.assign(this, data);
  }
}
