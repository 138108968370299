import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import "@enersis/gp-components/gp-toggle-button";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonToggleComponent } from "./button-toggle.component";

@NgModule({
  declarations: [ButtonToggleComponent],
  imports: [CommonModule, TranslateModule, MatButtonToggleModule],
  exports: [ButtonToggleComponent]
})
export class ButtonToggleModule {}
