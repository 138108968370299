import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import { TextfieldModule } from "src/app/common/components/form/textfield/textfield.module";
import { IconModule } from "src/app/common/components/icon/icon.module";

@Component({
  selector: "tpm-create-user-dialog",
  templateUrl: "create-user-dialog.component.html",
  styleUrls: ["create-user-dialog.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    NgIf,
    ReactiveFormsModule,
    TranslateModule,
    DialogFrameModule,
    TextfieldModule,
    IconModule,
    EneButtonModule
  ]
})
export class CreateUserDialogComponent {
  public readonly formControlNames = ["name", "email", "functionalRole"];
  public readonly usersForm: FormGroup;

  private readonly fb = inject(FormBuilder);
  private readonly dialogRef = inject(MatLegacyDialogRef<CreateUserDialogComponent>);

  constructor() {
    this.usersForm = this.fb.nonNullable.group({
      users: this.fb.nonNullable.array([])
    });
    this.addUser();
  }

  public get users(): FormArray {
    return this.usersForm.controls["users"] as FormArray;
  }

  public addUser(): void {
    this.users.push(
      this.fb.nonNullable.group({
        name: ["", Validators.required],
        email: ["", [Validators.required, Validators.email]],
        functionalRole: ["", [Validators.required]]
      })
    );
  }

  public deleteUser(index: number): void {
    this.users.removeAt(index);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    if (this.usersForm.valid) {
      this.dialogRef.close(this.usersForm.getRawValue().users);
    }
  }
}
