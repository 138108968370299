import { LegacyPageEvent, MatLegacyPaginator } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { BehaviorSubject, Observable, merge } from "rxjs";
import { mapTo, tap } from "rxjs/operators";
import { ITablePagingMetadata } from "../interfaces/table-paging-metadata";

export abstract class TablePagingService<T extends Record<string, any>> {
  protected sort: MatSort | null = null;
  protected paginator: MatLegacyPaginator | null = null;

  private readonly filterSubject = new BehaviorSubject<string | void>(void 0);

  public resetPagination(): void {
    if (this.paginator) {
      this.paginator.firstPage();
      this.paginator.page.emit({
        pageIndex: 0,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length
      });
    }
  }

  public setPaging(paginator: MatLegacyPaginator): void {
    this.paginator = paginator;
  }

  public getPaging(): Observable<LegacyPageEvent | void> {
    return merge(
      this.paginator.initialized.pipe(
        mapTo({
          pageIndex: 0,
          pageSize: this.initialPageSize,
          length: this.paginator.length
        })
      ),
      this.paginator.page
    );
  }

  public setSort(sort: MatSort): void {
    this.sort = sort;
  }

  public getSort(): Observable<Sort | void> {
    return merge(
      this.sort.initialized.pipe(
        mapTo({
          active: this.sort.active,
          direction: this.sort.direction
        })
      ),
      this.sort.sortChange
    ).pipe(
      tap(() => {
        if (this.paginator) {
          this.paginator.firstPage();
        }
      })
    ) as Observable<Sort | void>;
  }

  public setFilter(filter: string): void {
    this.filterSubject.next(filter);
  }

  public getFilter(): Observable<string | void> {
    return this.filterSubject.asObservable();
  }

  public abstract loadData(): Observable<ITablePagingMetadata<T>>;

  private get initialPageSize(): number {
    return (this.paginator.pageSizeOptions && this.paginator.pageSizeOptions[0]) || this.paginator.pageSize || 10;
  }
}
