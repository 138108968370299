import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from "@angular/core";
import { TABLE_COLUMN_CONTEXT, TableColumn } from "../../../classes/table-column";
import { TableService } from "../../../services/table.service";
import { TableColumnContext } from "../../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-multi-row-expand-column",
  templateUrl: "./multi-row-expand-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiRowExpandColumnComponent<T extends Record<string, any> = Record<string, any>> extends TableColumn<T> {
  public expanded: boolean;

  constructor(
    @Inject(TABLE_COLUMN_CONTEXT) public readonly context: TableColumnContext<T>,
    private readonly tableService: TableService<T>
  ) {
    super(context);

    if (this.context.columnConfig.type === "multi-row-expand") {
      this.expanded = this.tableService.expand$.getValue().expanded;
    }
  }

  public onClick(expanded: boolean, row: T): void {
    this.expanded = expanded;
    this.tableService.expand$.next({ expanded, row });
  }
}
