import { SelectionModel } from "@angular/cdk/collections";
import { AfterViewInit, Component, Host, Inject, Input, OnDestroy, OnInit, Optional } from "@angular/core";
import { asyncScheduler, combineLatest, Observable, Subject } from "rxjs";
import { debounceTime, observeOn, switchMap } from "rxjs/operators";
import { ITableEvent } from "src/app/common/components/table/interfaces/table-event";
import { AbstractTableComponent } from "../../../../../abstracts/table-component";
import { TABLE_PAGING_SERVICE } from "../../../../table/tokens/table-paging-service";
import { NotificationSettingsService } from "../../services/notification-settings.service";

@Component({
  selector: "app-notification-settings-table",
  templateUrl: "./notification-settings-table.component.html",
  styleUrls: [
    "../../../../table/components/container/table.component.scss",
    "../../../../table/styles/cells.scss",
    "./notification-settings-table.component.scss"
  ]
})
export class NotificationSettingsTableComponent
  extends AbstractTableComponent<any>
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input()
  public get selectDisabled(): boolean {
    return this._selectDisabled;
  }
  public set selectDisabled(value: boolean) {
    this._selectDisabled = value;

    this.selectionsSubscription?.unsubscribe();

    if (!value) {
      this.selections = new SelectionModel(true, []);
      this.selectionsSubscription = this.selections.changed.pipe(debounceTime(100)).subscribe((event: any) => {
        this.tableEvent.emit({ type: event.type || "select", selected: event.source.selected, id: null });
      });
    }
  }
  public selections: SelectionModel<string>;
  public _selectDisabled: boolean = true;
  public loading$: Observable<boolean>;
  private destroy$ = new Subject<void>();

  constructor(
    @Optional() @Host() @Inject(TABLE_PAGING_SERVICE) protected readonly pagingService: NotificationSettingsService
  ) {
    super(pagingService);
  }

  public ngOnInit(): void {
    this.loading$ = this.pagingService.loading$;
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngAfterViewInit(): void {
    this.raceConditionSubscription = combineLatest([this.getPagining(), this.getSorting()])
      .pipe(
        observeOn(asyncScheduler),
        switchMap(() => this.getPagingData())
      )
      .subscribe((data) => {
        this.dataSource.data = data || [];
      });

    combineLatest([this.getPagining(), this.getSorting(), this.pagingService.getFilter()]).subscribe(() => {
      this.selections.clear();
    });
  }

  public masterToggle(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.isAllSelected()
      ? this.selections.clear()
      : this.dataSource.data.forEach((row) => this.selections.select(row.regionId));
  }

  public isAllSelected(): boolean {
    return this.selections.selected.length === this.dataSource.data.length;
  }

  public cellClicked(event: ITableEvent<any>) {
    if (event.type === "click") {
      this.selections.clear();
    }
    this.selections.select(event.element.regionId);
    this.selections.changed.next({ type: "selectOne", source: { selected: [event.element.regionId] } } as any);
  }
}
