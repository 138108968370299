<div class="mat-dialog-header">
  <div mat-dialog-title class="mat-dialog-title">
    <mat-icon class="circle" fontIcon="ec_option"></mat-icon>
    <h1>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TITLE" | translate }}</h1>
  </div>
  <button class="big" mat-button (click)="closeDialog()">
    <mat-icon fontIcon="ec_building_delete_address"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="settings-tab-group">
    <ene-tabs class="main-tabs">
      <ene-tab
        [tabTitle]="'CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.TITLE' | translate"
        [disabled]="dialogData.multiSelection"
      >
        <app-ca-municipal-settings [form]="form.get('municipality')"></app-ca-municipal-settings>
      </ene-tab>
      <ene-tab
        [tabTitle]="'CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.CONTACT_DATA' | translate"
        [active]="dialogData.multiSelection"
      >
        <ng-container *ngIf="!dialogData.multiSelection; else multiModeInfo"></ng-container>
        <app-ca-contact-settings [form]="form.get('contactPerson')"></app-ca-contact-settings>
      </ene-tab>
      <ene-tab [tabTitle]="'CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.MODULE' | translate">
        <ng-container *ngIf="!dialogData.multiSelection; else multiModeInfo"></ng-container>
        <app-ca-module-settings
          [multiMode]="dialogData.multiSelection"
          [form]="form.get('modules')"
        ></app-ca-module-settings>
      </ene-tab>
    </ene-tabs>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <p class="note">{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.ACTIONS.NOTE" | translate }}</p>
  <button ene-outline-button size="large" (click)="closeDialog()">
    {{ "APP.CANCEL" | translate | uppercase }}
  </button>
  <button ene-filled-button size="large" [disabled]="form.invalid" (click)="closeDialog(true)">
    {{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.ACTIONS.SUBMIT" | translate }}
  </button>
</mat-dialog-actions>

<ng-template #multiModeInfo>
  <div class="info">
    <mat-icon fontIcon="ec_info"></mat-icon>
    <p [innerHTML]="'CONFIGURATION_AREA.DIALOGS.SETTINGS.MULTI_SELECTION_NOTE' | translate | trustHtml"></p>
  </div>
</ng-template>
