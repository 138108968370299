import { HttpClient } from "@angular/common/http";
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";

export class TranslateHttpLoader implements TranslateLoader {
  constructor(private readonly http: HttpClient) {}

  public getTranslation(lang: string): Observable<Record<string, any>> {
    return this.http.get(`/assets/i18n/${lang}.json`);
  }
}
