import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "src/app/common/components/card/card.module";
import { ColoredBadgeModule } from "src/app/common/components/colored-badge/colored-badge.module";
import { ContentfulCardComponent } from "./contentful-card.component";

@NgModule({
  declarations: [ContentfulCardComponent],
  imports: [CommonModule, TranslateModule, MatIconModule, EneButtonModule, ColoredBadgeModule, CardModule],
  exports: [ContentfulCardComponent]
})
export class ContentfulCardModule {}
