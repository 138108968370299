import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { FormFieldControl } from "../form-field-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-dropzone",
  templateUrl: "./dropzone.component.html",
  styleUrls: ["./dropzone.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropzoneComponent extends FormFieldControl {
  @Input() public multiple: boolean = false;
  @Input() public disabled: boolean = false;
  @Input() public maxSizeLimit: number = 50;
  @Input() public maxFilesLimit: number = 5;
  @Input() public maxFilenameLength?: number;
  @Input() public accept: string = "";

  @Output() public readonly dropped = new EventEmitter<FileList>();

  public onChange(event: CustomEvent): void {
    this.writeValue(event.detail.files);
    this.dropped.emit(event.detail.files);
    this.clearInput(event);
  }

  public registerOnChange(fn: any): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      fn(value);
    });
  }

  public clearInput(event: CustomEvent): void {
    try {
      // clear input after selection
      (event.target as HTMLElement).shadowRoot.querySelector("input").value = null;
    } catch (_) {
      console.warn("Not found input");
    }
  }
}
