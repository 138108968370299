<div
  class="colored-badge"
  [style.maxWidth]="maxWidth"
  [style.color]="_color"
  [style.backgroundColor]="_backgroundColor"
  [class.colored-badge--fixed]="maxWidth"
  [class.empty]="!icon && !text"
>
  <i *ngIf="icon" class="colored-badge__icon" [ngClass]="icon" [style.color]="_iconColor"></i>
  {{ text | uppercase }}
</div>
