import { Directive, Input } from "@angular/core";

/* eslint-disable @angular-eslint/directive-class-suffix */
@Directive()
export class MediaPlayer {
  /** Player configs */
  @Input() public autoPlay = false;
  @Input() public muted = false;

  /** Controls configs */
  @Input() public disablePlayControl = false;
  @Input() public displayVolumeControl = true;

  /** Path to file */
  @Input() public source!: string;

  @Input() public startOffset: number;
}
