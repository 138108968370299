<div class="skeleton-loader-template">
  <ng-container *ngFor="let item of [1, 2]">
    <app-skeleton-loader-box
      class="skeleton-loader-template__label"
      height="18px"
      [width]="'50%'"
    ></app-skeleton-loader-box>
    <app-skeleton-loader-box height="60px"></app-skeleton-loader-box>
  </ng-container>
</div>
