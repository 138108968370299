import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CoinComponent } from "./coin.component";

@NgModule({
  declarations: [CoinComponent],
  imports: [CommonModule, TranslateModule],
  exports: [CoinComponent]
})
export class CoinModule {}
