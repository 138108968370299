import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-ca-contact-settings",
  templateUrl: "./contact-settings.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CAContactSettingsTabComponent {
  @Input() public form: FormGroup;

  public readonly roles: ReadonlyArray<string> = ["Konzessionsmanager", "Bürgermeister"];
  public readonly salutationOptions: ReadonlyArray<string> = ["Keine Angabe", "Herr", "Frau"];
}
