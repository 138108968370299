import { Directive, Inject, InjectionToken, Input, Optional } from "@angular/core";
import { TableComponent } from "../container/table.component";
import { TableAction, TableMultiRowContext } from "../table.interfaces";

export const TABLE_MULTI_ROW_CONTEXT = new InjectionToken<TableMultiRowContext>("Context for multi row cell");

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TableMultiRow<T extends Record<string, any> = Record<string, any>> {
  @Input() public data: T;

  constructor(
    @Inject(TABLE_MULTI_ROW_CONTEXT) public readonly context: TableMultiRowContext,
    @Optional() protected readonly table?: TableComponent<T>
  ) {
    this.data = this.context?.data;
  }

  public onTableAction(action: TableAction<T>): void {
    this.table.tableAction.emit(action);
  }
}
