<tpm-card class="banner-card" [variant]="'elevation-basic-10'">
  <div class="banner-wrapper">
    <div *ngIf="isMobile === false" class="logo-wrapper">
      <img [src]="props.logo" alt="Logo" />
    </div>
    <div class="teaser-info-wrapper">
      <p class="teaser-info body-1 last-line-4">{{ props.teaserInfo }}</p>
      <tpm-coin *ngIf="props.price" [text]="props.price + '&nbsp;€'"></tpm-coin>
    </div>
    <ng-container *ngIf="isMedium === false">
      <div *ngIf="props.teaserImage" class="teaser-image-wrapper">
        <img [src]="props.teaserImage" alt="Logo" />
      </div>
      <div *ngIf="!props.teaserImage && props.teaserAddition" class="teaser-info-wrapper">
        <p class="teaser-info body-1 last-line-4">{{ props.teaserAddition }}</p>
      </div>
    </ng-container>
  </div>
</tpm-card>
