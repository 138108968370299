export interface UserInfoDto {
  email: string;
  email_verified: boolean;
  environment: string;
  family_name: string;
  given_name: string;
  name: string;
  preferred_username: string;
  /** tenant-000n */
  tenant: string;
  sub: string;
  userScope: UserScope;
  enableTracking: boolean;
  permissions: {
    /** { region:UUID: ["module.read", "module.write"] } */
    scoped: Record<string, Array<string>>;
  };
  /** deprecated props */
  customerSupportId: string;
  language: string;
  featureFlags?: Array<string>;
}

export enum UserScope {
  END_USER = "endUser", // user from CRM
  INTERNAL_STAKEHOLDER = "internalStakeholder", // user from enersis
  EXTERNAL_STAKEHOLDER = "externalStakeholder", // user from NetzeBW
  UNKNOWN = "unknown"
}
