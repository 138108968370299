<ng-container *ngFor="let item of column.combinedValues; trackBy: trackById">
  <ng-container [ngSwitch]="item.type">
    <ng-container *ngSwitchCase="'icon'">
      <i
        class="ene-icon badge-cell-icon"
        *ngIf="row[item.id]?.icon"
        [ngClass]="[row[item.id]?.icon, item.id]"
        [matTooltipDisabled]="!item.tooltip"
        [matTooltipClass]="item.id + '-tooltip'"
        [matTooltip]="row[item.id]?.tooltip"
        (click)="iconClicked.emit({ id: item.id, event: 'click', element: row })"
      ></i>
    </ng-container>
    <span
      class="state-cell-dot"
      *ngSwitchCase="'state'"
      [matTooltipClass]="'light-tooltip'"
      [matTooltip]="'REVIEW_STATE.' + row[column.id] | translate"
      [ngClass]="row[column.id]"
    ></span>
    <span class="badge-cell" *ngSwitchCase="'badge'" [ngClass]="row[column.id]">
      {{ "REVIEW_STATE." + row[column.id] | translate }}
    </span>
  </ng-container>
</ng-container>
