<div class="column-wrapper">
  <tpm-checkbox
    *ngIf="!(hideCheckbox($any(columnConfig), row) === true)"
    density="small"
    [checked]="checked($any(columnConfig), row)"
    (changed)="onTableAction({ id: columnConfig.id, type: 'click', row: row, index: index, checked: $event })"
  ></tpm-checkbox>
  <div *ngIf="label($any(columnConfig), row) as label">
    {{ label }}
  </div>
</div>
