import { OverlayContainer } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DynamicOverlayContainer extends OverlayContainer {
  /** For main overlay */
  protected _mainContainerElement: HTMLElement;
  /** Flag for switching between main and global overlay */
  protected _isMain = false;

  public setMainOverlay(isMain: boolean): void {
    this._isMain = isMain;
  }

  public getContainerElement(): HTMLElement {
    if (this._isMain) {
      return this.getMainContainerElement();
    } else {
      return this.getCdkContainerElement();
    }
  }

  /**
   * @description Manual clear all children elements from `cdk-overlay-container`
   */
  public clearContainerElement(): void {
    const container = this._containerElement;

    if (container && container.parentNode) {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    }
  }

  private getCdkContainerElement(): HTMLElement {
    if (!this._containerElement) {
      this._createContainer();
    }

    return this._containerElement;
  }

  private getMainContainerElement(): HTMLElement {
    /** Reset main flag to be sure that others components will be render in cdk container */
    this._isMain = false;

    return this._document.getElementById("main-overlay-container");
  }
}
