<tpm-dialog-frame
  [closable]="false"
  [closeButtonLabel]="'Abbrechen' | translate"
  [submitButtonLabel]="'Vertrag kündigen' | translate"
  (submitted)="submit()"
  (closed)="close()"
>
  <p class="body-2">
    {{ "Hiermit kündigen Sie Ihren Vertrag. Dies kann nicht rückgängig gemacht werden." | translate }}
  </p>
</tpm-dialog-frame>
