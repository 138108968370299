import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CardVariant } from "@enersis/gp-components/gp-card";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  @Input() public variant: CardVariant;
}
