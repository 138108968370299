import { Action, createReducer, on } from "@ngrx/store";
import { ElectricityStationDto } from "src/app/common/dto/energy/station/electricity";
import { GasStationDto } from "src/app/common/dto/energy/station/gas";
import { StationWorkloadDto } from "../../../common/dto/energy/station-workload";
import * as StationsActions from "../../actions/energy/stations.actions";

export interface State {
  electricity: ElectricityStationDto;
  gas: GasStationDto;
  workload: Array<StationWorkloadDto>;
}

const initialState: State = {
  electricity: {
    cumulatedInvestment: undefined,
    installedStationCapacity: undefined,
    workload: undefined
  },
  gas: {
    cumulatedInvestment: undefined,
    peakHourlyCapacity: undefined,
    workload: undefined
  },
  workload: undefined
};

const stationsReducer = createReducer(
  initialState,
  on(StationsActions.getElectricityStationData, (state) => ({
    ...state,
    electricity: {
      cumulatedInvestment: undefined,
      installedStationCapacity: undefined,
      workload: undefined
    }
  })),
  on(StationsActions.getElectricityStationDataSuccess, (state, { payload }) => ({ ...state, electricity: payload })),
  on(StationsActions.getGasStationData, (state) => ({
    ...state,
    gas: {
      cumulatedInvestment: undefined,
      peakHourlyCapacity: undefined,
      workload: undefined
    }
  })),
  on(StationsActions.getGasStationDataSuccess, (state, { payload }) => ({ ...state, gas: payload })),
  on(StationsActions.getStationWorkloadData, (state) => ({ ...state, workload: undefined })),
  on(StationsActions.getStationWorkloadDataSuccess, (state, { payload }) => ({ ...state, workload: payload })),
  on(StationsActions.clearStationWorkloadData, (state) => ({ ...state, workload: undefined }))
);

export function reducer(state: State | undefined, action: Action): State {
  return stationsReducer(state, action);
}
