import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Dialog } from "src/app/common/enums/dialogs";
import { IProjectEntity } from "src/app/core/interfaces/project-entity";
import { RegionSwitcherService } from "src/app/core/services/region-switcher-service/region-switcher.service";
import { closeAllDialogs, openDialog } from "src/app/ngrx/actions/dialogs.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectEntities } from "src/app/ngrx/selectors/app.selectors";
import { regionsSwitcherTableColumnsConfig } from "./regions-switcher.constants";

@Component({
  selector: "app-regions-switcher-dialog",
  templateUrl: "./regions-switcher.component.html",
  styleUrls: ["./regions-switcher.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionsSwitcherComponent {
  public readonly columnsConfig = regionsSwitcherTableColumnsConfig;

  public vm$: Observable<
    Array<{
      coatOfArms: string;
      municipality: string;
      municipalityKey: string;
      region: IProjectEntity;
    }>
  > = this.store.pipe(
    select(selectEntities),
    map((entities) =>
      entities?.map((entity) => ({
        coatOfArms: entity.entity.coatOfArms,
        municipality: entity.entity.name,
        municipalityKey: entity.entity.ags,
        region: entity
      }))
    )
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly regionSwitcherService: RegionSwitcherService
  ) {}

  public openContactModal(): void {
    this.store.dispatch(closeAllDialogs());
    this.store.dispatch(openDialog({ dialogType: Dialog.CONTACT }));
  }

  public onTableAction({ region: { entityId } }: { region: IProjectEntity }): void {
    this.regionSwitcherService.switchRegionOntoLanding(entityId);
    this.store.dispatch(closeAllDialogs());
  }
}
