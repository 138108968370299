import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, zip } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { Dialog } from "src/app/common/enums/dialogs";
import { CardNewsDetailsComponent } from "src/app/features/overlays/region/news/components/card-news-details/card-news-details.component";
import { NewsService } from "src/app/features/overlays/region/news/news.service";
import { openDialog } from "src/app/ngrx/actions/dialogs.actions";
import { changeLaunchpadOverlay, openNews } from "src/app/ngrx/actions/overlay.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { ContentfulContentType } from "../interfaces/contentful";
import { LaunchpadOverlay } from "../interfaces/overlay";
import { OverlayService } from "./overlay.service";

@Injectable({ providedIn: "root" })
export class QueryParamsManagerService {
  constructor(
    private readonly store: Store<RootState>,
    private readonly overlayService: OverlayService,
    private readonly newsService: NewsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {}

  public setEntryPoint(): void {
    zip(
      this.getQueryParam<Dialog>("dialog"),
      this.getQueryParam("news_area"),
      this.getQueryParam("news_id"),
      this.getQueryParam("content_type")
    )
      .pipe(
        filter(([dialog, newsArea, newsId, contentType]) => Boolean(dialog || newsArea || newsId || contentType)),
        take(1)
      )
      .subscribe(([dialog, newsArea, newsId, contentType]) => {
        if (dialog) {
          this.openDialog(dialog);
        } else if (newsArea) {
          this.openNews(newsArea as LaunchpadOverlay, contentType as ContentfulContentType, newsId);
        }
      });
  }

  public removeQueryParams(): void {
    this.router.navigate([], { relativeTo: this.route });
  }

  public openNews(newsArea: LaunchpadOverlay, contentType?: ContentfulContentType, newsId?: string): void {
    if (newsArea !== "module" && newsArea !== "plugin") {
      return;
    }

    if (!contentType) {
      contentType =
        newsArea === "module"
          ? ContentfulContentType.CPRegulatedModulesNews
          : ContentfulContentType.CPServicesModulesNews;
    }

    this.store.dispatch(changeLaunchpadOverlay({ overlay: newsArea }));
    this.store.dispatch(
      openNews({
        contentType
      })
    );
    const isMunicipal = [
      ContentfulContentType.CPRegulatedModulesNews,
      ContentfulContentType.CPServicesModulesNews
    ].includes(contentType);

    if (!isMunicipal && newsId) {
      this.openNewsDetails(newsId);
    }
  }

  private openDialog(dialog: Dialog): void {
    if (dialog === Dialog.CONTACT) {
      this.store.dispatch(openDialog({ dialogType: Dialog.CONTACT }));
    }
  }

  private openNewsDetails(newsId: string): void {
    this.newsService
      .getNewsEntries()
      .pipe(
        filter((news) => Boolean(news?.length)),
        take(1)
      )
      .subscribe((news) => {
        const selected = news.find(({ id }) => id === newsId);

        if (selected) {
          this.overlayService.openInGlobal(CardNewsDetailsComponent, { data: { selected } });
        }
      });
  }

  private getQueryParam<T = string>(key: string): Observable<T> {
    return this.route.queryParams.pipe(map((params) => params[key]));
  }
}
