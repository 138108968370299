import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { LoginErrorDialogComponent } from "./login-error-dialog.component";

@NgModule({
  declarations: [LoginErrorDialogComponent],
  imports: [TranslateModule],
  exports: [LoginErrorDialogComponent]
})
export class LoginErrorDialogModule {}
