export interface IRegionsEMobilityDto {
  year: number;
  count: number;
  capacity: number;
  consumption: number;
  co2Saving: number;
}

export class RegionsEMobilityDto implements IRegionsEMobilityDto {
  public year: number;
  public count: number;
  public capacity: number;
  public consumption: number;
  public co2Saving: number;

  constructor(data: IRegionsEMobilityDto) {
    this.year = data.year;
    this.count = data.count;
    this.capacity = data.capacity;
    this.consumption = data.consumption;
    this.co2Saving = data.co2Saving;
  }
}
