import { createAction, props } from "@ngrx/store";
import { BuildingDto } from "../../../common/dto/infrastructure/building";

export const getBuildingByRegionIdData = createAction(
  "[Infrastructure Building / Regions] Get building by region id data",
  props<{
    regionId: string;
    year: number;
  }>()
);

export const getBuildingByRegionIdDataSuccess = createAction(
  "[Infrastructure Building / Regions] Get building by region id data success",
  props<{ payload: BuildingDto }>()
);

export const getBuildingsData = createAction(
  "[Infrastructure Building / Buildings] Get buildings data",
  props<{
    buildingId: string;
    year: number;
  }>()
);

export const getBuildingsDataSuccess = createAction(
  "[Infrastructure Building / Buildings] Get buildings data successfuly",
  props<{ payload: any }>()
);

export const clearBuildingsData = createAction("[Infrastructure Building / Buildings] Clear buildings");
