import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import { MediaPlayerModule } from "src/app/common/components/media-player/media-player.module";
import { LaunchpadTileConfig } from "../../../container/launchpad.interfaces";

@Component({
  selector: "tpm-story-teller-dialog",
  templateUrl: "./story-teller-dialog.component.html",
  styleUrls: ["./story-teller-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, DialogFrameModule, MatLegacyDialogModule, MediaPlayerModule]
})
export class StoryTellerDialogComponent {
  public readonly data: LaunchpadTileConfig["storyTeller"] & { title: string } = inject(MAT_LEGACY_DIALOG_DATA);
}
