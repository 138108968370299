import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { Store, select } from "@ngrx/store";
import { EMPTY, Subject, combineLatest, of } from "rxjs";
import { catchError, filter, map, mapTo, switchMap, takeUntil } from "rxjs/operators";
import { PaginatorDefaultOptions } from "src/app/common/components/paginator/paginator.component";
import { IProjectModuleEntity } from "src/app/core/interfaces/project-entity";
import { DynamicSnackBar } from "src/app/core/material/snack-bar/snack-bar";
import { ProjectConfigurationService } from "src/app/core/services/project-configuration.service";
import { ConfigAreaConfirmDialogComponent } from "src/app/features/configuration-area/common/dialogs/confirm/confirm.component";
import { ConfigAreaSettingsDialogComponent } from "src/app/features/configuration-area/common/dialogs/settings/settings.component";
import { setEntities, setMinMaxYears } from "src/app/ngrx/actions/app.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectEntities } from "src/app/ngrx/selectors/app.selectors";
import { TableAction } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import {
  concessionsModulesCheckboxes,
  concessionsStatesCheckboxes,
  configurationAreaConcessionsColumns
} from "../modules-tab.constants";
import { TableRow } from "../modules-tab.interfaces";

@Component({
  selector: "app-modules-tab",
  templateUrl: "./modules-tab.component.html",
  styleUrls: ["./modules-tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: "ca-modules-tab"
  }
})
export class CAModulesTabComponent implements OnInit, OnDestroy {
  public readonly pagingConfig: PaginatorDefaultOptions = {
    pageSize: 20,
    pageSizeOptions: [20, 50, 100, 1000],
    showFirstLastButtons: true
  };
  public readonly vm$ = this.store.pipe(
    select(selectEntities),
    map((entities = []) =>
      entities.map((entity) => ({
        name: entity.entity.name,
        ags: entity.entity.ags,
        concession: entity.config.concession ? "ja" : "nein",
        ...this.getModules(entity.moduleComposition),
        entity
      }))
    ),
    map((list) => ({
      modulesCheckboxes: concessionsModulesCheckboxes,
      statesCheckboxes: concessionsStatesCheckboxes,
      columns: configurationAreaConcessionsColumns,
      list
    }))
  );
  public selected: Array<any> = [];
  public checkboxesForm: FormGroup;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<RootState>,
    private readonly matDialog: MatLegacyDialog,
    private readonly fb: FormBuilder,
    private readonly projectConfigurationService: ProjectConfigurationService,
    private readonly snackbar: DynamicSnackBar
  ) {}

  public ngOnInit(): void {
    this.checkboxesForm = this.createCheckboxesForm();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public trackById(_: number, { id }: { id: string }): string {
    return id;
  }

  public openDialog(items: Array<TableRow>, multiSelection: boolean = false): void {
    const config = {
      width: "640px",
      panelClass: "no-padding",
      autoFocus: false
    };
    const formData = multiSelection ? items.map(({ entity }) => entity) : [items[0].entity];

    this.matDialog
      .open(ConfigAreaSettingsDialogComponent, {
        ...config,
        data: { multiSelection, formData }
      })
      .afterClosed()
      .pipe(
        switchMap((data) =>
          data
            ? this.matDialog
                .open(ConfigAreaConfirmDialogComponent, config)
                .afterClosed()
                .pipe(filter(Boolean), mapTo(data))
            : EMPTY
        ),
        switchMap((data: any) => {
          if (data.municipality.coatOfArms instanceof FormData) {
            return this.projectConfigurationService
              .updateEntityEmblem(data.municipality.entityId, data.municipality.coatOfArms)
              .pipe(
                map(() => {
                  this.snackbar.open(
                    "CONFIGURATION_AREA.DIALOGS.CONFIRM.SAVE_SUCCESS",
                    undefined,
                    undefined,
                    "success",
                    false
                  );
                  return data;
                }),
                catchError(() => {
                  this.snackbar.open(
                    "CONFIGURATION_AREA.DIALOGS.CONFIRM.SAVE_ERROR",
                    undefined,
                    undefined,
                    "error",
                    false
                  );
                  return of(data);
                })
              );
          } else {
            return of(data);
          }
        }),
        switchMap((data) =>
          items.length > 1
            ? this.projectConfigurationService.updateEntities({
                entities: items.map(({ entity }) => entity.entityId),
                formData: data
              })
            : this.projectConfigurationService.updateEntity(items[0].entity.entityId, data)
        ),
        switchMap(() =>
          combineLatest([
            this.projectConfigurationService.getEntities({ filter: true }),
            this.projectConfigurationService.getYears()
          ])
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(([entities, years]) => {
        this.store.dispatch(setEntities({ payload: entities }));
        this.store.dispatch(setMinMaxYears({ payload: years }));
      });
  }

  public onTableEvent(event: TableAction<TableRow>): void {
    if (event.type === "click") {
      this.openDialog([event.row]);
    } else if (event.type === "select") {
      this.selected = event.selected;
    }
  }

  private createCheckboxesForm(): FormGroup {
    const form = this.fb.group({
      modules: this.fb.group(concessionsModulesCheckboxes.reduce((acc, { id }) => ({ ...acc, [id]: true }), {})),
      states: this.fb.group(concessionsStatesCheckboxes.reduce((acc, { id }) => ({ ...acc, [id]: true }), {}))
    });
    // not available now
    form.disable();

    return form;
  }

  private getModules(moduleComposition: Array<IProjectModuleEntity>): {
    active: boolean;
    divisions: string;
  } {
    return moduleComposition.reduce(
      (acc, { id, active, config }) => {
        const divisions = [];

        if (config.division.electricity) {
          divisions.push("S");
        }

        if (config.division.gas) {
          divisions.push("G");
        }

        return {
          ...acc,
          [id]: { active, divisions: divisions.join(", ") }
        };
      },
      {} as {
        active: boolean;
        divisions: string;
      }
    );
  }
}
