import { Component, Inject, Injector, Input, Optional, SkipSelf } from "@angular/core";
import { AbstractTableComponent } from "src/app/common/abstracts/table-component";
import { TablePagingService } from "src/app/common/components/table/classes/table-paging-service";
import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";
import { TABLE_PAGING_SERVICE } from "src/app/common/components/table/tokens/table-paging-service";
import { Pure } from "src/app/common/decorators/pure";
import { SLOT } from "src/app/common/enums/slot";
import { TABLE_NESTED_CUSTOM_TEMPLATE_CONTEXT } from "../tokens/table-nested-custom-template-context";
import { TABLE_NESTED_TEMPLATE_CONTEXT } from "../tokens/table-nested-template-context";

/**
 * @title Table with expandable rows
 */
@Component({
  selector: "table-expandable-rows",
  styleUrls: ["table-expandable-rows.component.scss"],
  templateUrl: "table-expandable-rows.component.html"
})
export class TableExpandableRowsComponent<T extends object> extends AbstractTableComponent<T> {
  @Input() public module: SLOT;
  @Input() public nestedColumns: Array<ITableColumn>;
  @Input() public set nestedTemplate(_nestedTemplate: Injector) {
    this.expandedElement = null;
    this._nestedTemplate = _nestedTemplate;
  }

  public get nestedTemplate(): Injector {
    return this._nestedTemplate;
  }

  public expandedElement: T;
  private _nestedTemplate: Injector;

  constructor(
    private readonly injector: Injector,
    @Optional() @SkipSelf() @Inject(TABLE_PAGING_SERVICE) protected readonly pagingService: TablePagingService<T>
  ) {
    super(pagingService);
  }

  @Pure
  public getComponentInjector(row: T): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: TABLE_NESTED_TEMPLATE_CONTEXT,
          useValue: { columns: this.nestedColumns, row, module: this.module }
        }
      ]
    });
  }

  @Pure
  public getTemplateInjector(column: ITableColumn, row: T): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: TABLE_NESTED_CUSTOM_TEMPLATE_CONTEXT,
          useValue: { id: column.id, row }
        }
      ]
    });
  }

  public cellClicked(element: T): void {
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
    }

    // eslint-disable-next-line no-self-assign
    this.dataSource.filter = this.dataSource.filter;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public isNested(): Function {
    const self = this;
    return (index: number, item: T) => self.expandedElement === item;
  }
}
