import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "src/app/common/components/card/card.module";
import { ColoredBadgeModule } from "src/app/common/components/colored-badge/colored-badge.module";
import { ContentfulListItemComponent } from "./contentful-list-item.component";

@NgModule({
  declarations: [ContentfulListItemComponent],
  imports: [CommonModule, TranslateModule, MatIconModule, ColoredBadgeModule, EneButtonModule, CardModule],
  exports: [ContentfulListItemComponent]
})
export class ContentfulListItemModule {}
