<tpm-card class="news-card" [variant]="'elevation-basic-10'">
  <div class="news-card-wrapper">
    <div class="info-container">
      <div class="headline-6 last-line-2 teaser">{{ props.teaser }}</div>
      <tpm-colored-badge
        *ngIf="props.sign"
        class="badge"
        [maxWidth]="'80px'"
        [text]="props.sign"
        [backgroundColor]="'--ene-color-emerald-A500'"
      ></tpm-colored-badge>
    </div>
    <div *ngIf="props.actions?.length" class="actions-container">
      <button
        *ngFor="let action of props.actions; trackBy: trackByIndex"
        ene-filled-button
        rightIcon
        icon="ec_controls_collapsed_dropdown"
        (click)="actionClicked.emit({ action: action.action, url: action.url, entry: props }); $event.stopPropagation()"
      >
        {{ action.label }}
      </button>
    </div>
  </div>
</tpm-card>
