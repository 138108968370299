import { Action, createReducer, on } from "@ngrx/store";
import { IGasCockpitKpiDto, IGasNetworkLengthDto } from "../../common/dto/gas-cockpit";
import * as GasCockpitActions from "../actions/gas-cockpit.actions";

export interface State {
  gasCockpitKpi: IGasCockpitKpiDto;
  gasNetworkLength: IGasNetworkLengthDto;
}

const initialState: State = {
  gasCockpitKpi: null,
  gasNetworkLength: null
};

const gasCockpitReducer = createReducer(
  initialState,
  on(GasCockpitActions.getGasCockpitKpi, (state) => ({ ...state, gasCockpitKpi: null })),
  on(GasCockpitActions.getGasCockpitKpiSuccess, (state, { payload }) => ({ ...state, gasCockpitKpi: payload })),
  on(GasCockpitActions.getGasNetworksLength, (state) => ({ ...state, gasNetworkLength: null })),
  on(GasCockpitActions.getGasNetworksLengthSuccess, (state, { payload }) => ({ ...state, gasNetworkLength: payload }))
);

export function reducer(state: State | undefined, action: Action): State {
  return gasCockpitReducer(state, action);
}
