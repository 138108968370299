import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyTableModule } from "@angular/material/legacy-table";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { MatSortModule } from "@angular/material/sort";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { CheckboxModule } from "src/app/common/components/form/checkbox/checkbox.module";
import { SelectModule } from "src/app/common/components/form/select/select.module";
import { PaginatorComponent } from "src/app/common/components/paginator/paginator.component";
import { SkeletonLoaderModule } from "src/app/common/components/skeleton-loader/skeleton-loader.module";
import { CommonDirectivesModule } from "src/app/common/directives/directives.module";
import { ColumnComposerComponent } from "./components/column-composer/column-composer.component";
import { ColumnMultiRowComponent } from "./components/column-multi-row/column-multi-row.component";
import { MultiRowResolverComponent } from "./components/column-multi-row/multi-row-resolver/multi-row-resolver.component";
import { ColumnResolverComponent } from "./components/column-resolver/column-resolver.component";
import { ActionColumnComponent } from "./components/columns/action-column/action-column.component";
import { ActionIconColumnComponent } from "./components/columns/action-icon-column/action-icon-column.component";
import { BadgeColumnComponent } from "./components/columns/badge-column/badge-column.component";
import { CheckboxColumnComponent } from "./components/columns/checkbox-column/checkbox-column.component";
import { CombinedValuesColumnComponent } from "./components/columns/combined-values-column/combined-values-column.component";
import { IconColumnComponent } from "./components/columns/icon-column/icon-column.component";
import { ImageColumnComponent } from "./components/columns/image-column/image-column.component";
import { LinkColumnComponent } from "./components/columns/link-column/link-column.component";
import { MenuColumnComponent } from "./components/columns/menu-column/menu-column.component";
import { MultiRowExpandColumnComponent } from "./components/columns/multi-row-expand-column/multi-row-expand-column.component";
import { MultiSelectionColumnComponent } from "./components/columns/multi-selection-column/multi-selection-column.component";
import { SelectColumnComponent } from "./components/columns/select-column/select-column.component";
import { StateColumnComponent } from "./components/columns/state-column/state-column.component";
import { TextualColumnComponent } from "./components/columns/textual-column/textual-column.component";
import { TableFilterModule } from "./components/table-filter/table-filter.module";
import { TableComponent } from "./container/table.component";
import { TABLE_LOADING_CONFIG, TABLE_VALUE_FORMAT } from "./table.tokens";

export const VALUE_FORMAT_PROVIDER = { provide: TABLE_VALUE_FORMAT, useValue: "1.0-0" };
export const LOADING_CONFIG_PROVIDER = {
  provide: TABLE_LOADING_CONFIG,
  useValue: {
    loading: false,
    size: 5
  }
};
const PUBLIC_COMPONENTS = [
  ColumnComposerComponent,
  ColumnResolverComponent,
  TextualColumnComponent,
  IconColumnComponent,
  ActionIconColumnComponent,
  StateColumnComponent,
  ImageColumnComponent,
  BadgeColumnComponent,
  CombinedValuesColumnComponent,
  ActionColumnComponent,
  LinkColumnComponent,
  CheckboxColumnComponent,
  SelectColumnComponent
];

@NgModule({
  declarations: [
    TableComponent,
    MultiRowResolverComponent,
    ColumnMultiRowComponent,
    MultiRowExpandColumnComponent,
    MultiSelectionColumnComponent,
    MenuColumnComponent,
    ...PUBLIC_COMPONENTS
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatLegacyTableModule,
    MatSortModule,
    MatLegacyTooltipModule,
    MatLegacyCheckboxModule,
    MatExpansionModule,
    EneButtonModule,
    CommonDirectivesModule,
    SkeletonLoaderModule,
    TableFilterModule,
    CheckboxModule,
    SelectModule,
    MatLegacyMenuModule,
    PaginatorComponent
  ],
  providers: [VALUE_FORMAT_PROVIDER, LOADING_CONFIG_PROVIDER],
  exports: [TableComponent, ...PUBLIC_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TableModule {}
