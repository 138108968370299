import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "src/app/common/components/card/card.module";
import { AdminModuleStatus } from "src/app/common/dto/admin-module/module-status";
import { IN_OUT_ANIMATION } from "src/app/core/animations/in-out-animation";
import { TableAction, TableColumnConfig } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { TableModule } from "src/app/slots/three-year-plan/common/components/table/table.module";
import { ProductManagementDialogState } from "../../product-management-dialog.component-store";

@Component({
  selector: "tpm-user-management",
  templateUrl: "user-management.component.html",
  styleUrls: ["user-management.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, TranslateModule, CardModule, EneButtonModule, TableModule],
  animations: [IN_OUT_ANIMATION]
})
export class UserManagementComponent {
  @Output() public readonly addUser = new EventEmitter<void>();
  @Output() public readonly deleteUser = new EventEmitter<void>();
  @Output() public readonly updateInvitation = new EventEmitter<any>();
  @Output() public readonly submit = new EventEmitter<void>();

  @Input({ required: true }) public columnsConfig: Array<TableColumnConfig>;
  @Input({ required: true }) public invitations: ProductManagementDialogState["invitations"];
  @Input() public isUpdated: boolean | undefined;

  public onTableAction(event: TableAction<any> & { selected: AdminModuleStatus }): void {
    if (event.id === "deleteUser") {
      this.deleteUser.emit(event.row);
      return;
    }

    if ("selected" in event) {
      this.updateInvitation.emit({
        ...event.row[event.id],
        invitationId: event.row.id,
        status: event.selected
      });
    }
  }
}
