import { AdminModuleStatus } from "src/app/common/dto/admin-module/module-status";
import { Colors } from "src/styles/themes/colors";

export const STATUS_COLORS: { [key in AdminModuleStatus | "SUBSCRIPTION_NEW"]: string | null } = {
  PENDING: Colors.Error_300,
  ACTIVE: Colors.Success_500,
  PROLONGED: Colors.Success_500,
  CANCELED: Colors.Error_300,
  TERMINATED: Colors.Error_300,
  SUBSCRIPTION_NEW: Colors.Success_500
};

export const SELECT_COLORS = {
  PENDING: Colors.Error_300,
  ACTIVE: Colors.Success_300,
  CANCELED: Colors.Grey_500,
  SUBSCRIPTION_NEW: Colors.Grey_500
};
