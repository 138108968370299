<tpm-carousel class="banners-carousel">
  <tpm-carousel-inner>
    <tpm-carousel-item
      *ngFor="let props of marketplaceList; trackBy: trackByIndex"
      (click)="marketplaceBannerClicked.emit(props)"
      [interval]="7000"
    >
      <tpm-marketplace-banner [props]="props" [isMedium]="isMedium" [isMobile]="isMobile"></tpm-marketplace-banner>
    </tpm-carousel-item>
    <tpm-carousel-item
      *ngFor="let props of newsList; trackBy: trackByIndex"
      [interval]="5000"
      (click)="newsBannerClicked.emit(props)"
    >
      <tpm-news-banner [props]="props" [isMobile]="isMobile" [showBadge]="overlay === 'regulated'"></tpm-news-banner>
    </tpm-carousel-item>
  </tpm-carousel-inner>
  <tpm-carousel-indicators
    *ngIf="(marketplaceList?.length ?? 0) + (newsList?.length ?? 0) > 1"
    class="banners-carousel-indicators"
  ></tpm-carousel-indicators>
</tpm-carousel>
