import { MetaReducer } from "@ngrx/store";
import logger from "@redux-beacon/logger";
import { createMetaReducer } from "redux-beacon";
import { eventsMap } from "./events-map";

// This creates a meta reducer for Redux Beacon that is configured to send events to Matomo Analytics.
// It is also configured with a couple of optional extensions, one to log events to the console,
// and the other to preserve analytics that occur when the user loses their connection.

export const MatomoTagManager = (event) => {
  if (window["_paq"]) {
    window["_paq"].push(event);
  }
};

export const analyticsMetaReducer: MetaReducer<any> = createMetaReducer(eventsMap, MatomoTagManager, { logger });
