import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { SelectOptionItem } from "./select.interface";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: "tpm-select"
  }
})
export class SelectComponent {
  @Input() public placeholder: string;
  @Input() public value: string;
  @Input() public options: Array<SelectOptionItem>;

  @Output() public readonly selectionChange = new EventEmitter<string>();

  public trackByValue(_: number, option: SelectOptionItem): string {
    return option.value;
  }
}
