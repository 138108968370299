import { Component, Input } from "@angular/core";

export interface Square {
  id: string;
  label: string;
  key: string;
  type: string;
  class?: string;
}

@Component({
  selector: "app-square",
  templateUrl: "./square.component.html",
  styleUrls: ["./square.component.scss"]
})
export class SquareComponent {
  @Input() public data: Square;
  @Input() public showTooltip: boolean = false;
  @Input() public showDescription: boolean = false;

  public notification: Notification;
}
