import { createSelector } from "@ngrx/store";
import { createDivisionTemplateString } from "src/app/core/utils/division";
import { ContentfulState } from "../reducers/contentful.reducer";
import { RootState } from "../root-reducers";
import { selectEntity, selectRegionAgs, selectRegionConfig, selectRegionEnabledModules } from "./app.selectors";

const contentfulState = (state: RootState) => state.contentful;

export const selectContentfulContentType = createSelector(contentfulState, (state) => state.contentType);
export const selectContentfulEntries = createSelector(contentfulState, (state) => state.entries);
export const selectAvailableContentfulEntries = createSelector(
  selectContentfulEntries,
  selectRegionEnabledModules,
  selectRegionConfig,
  selectRegionAgs,
  selectEntity,
  (news, modules, divisionConfig, regionAgs, entity) => {
    if ("items" in news) {
      const _modulesMap = modules.reduce((acc, module) => ({ ...acc, [module]: true }), {});
      const _division = createDivisionTemplateString(divisionConfig);

      return {
        ...news,
        items: news.items
          // filter entries by moduleName
          ?.filter(({ fields }: any) => (fields.checkModule ? _modulesMap[fields.moduleName] : true))
          // filter entries by division
          .filter(({ fields }: any) => (fields.division ? _division?.includes(fields.division) : true))
          // filter entries by AGS
          .filter(({ fields }: any) => (fields.checkAgs ? fields.agsList?.includes(regionAgs) : true))
          // filter entries by networkArea
          .filter(({ fields }: any) => compareFilter(fields.networkArea, entity.entity.concession?.networkArea))
          // filter entries by concessionElectricity
          .filter(({ fields }: any) =>
            compareFilter(fields.concessionElectricity, entity.entity.concession?.concessionElectricity)
          )
          // filter entries by concessionGas
          .filter(({ fields }: any) => compareFilter(fields.concessionGas, entity.entity.concession?.concessionGas))
          // filter entries by operationManagementElectricity
          .filter(({ fields }: any) =>
            compareFilter(
              fields.operationManagementElectricity,
              entity.entity.concession?.operationManagementElectricity
            )
          )
          // filter entries by operationManagementGas
          .filter(({ fields }: any) =>
            compareFilter(fields.operationManagementGas, entity.entity.concession?.operationManagementGas)
          )
      };
    } else {
      return {} as ContentfulState["entries"];
    }
  }
);

const compareFilter = (contentfulValue: string | boolean, entityValue: string | boolean) =>
  contentfulValue === undefined ? true : contentfulValue === entityValue;
