import { Component } from "@angular/core";
import { ConfigurationAreaService } from "../../services/configuration-area.service";

@Component({
  selector: "configuration-area-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class ConfigurationAreaHeaderComponent {
  constructor(private configurationArea: ConfigurationAreaService) {}

  public closeConfigurationArea(): void {
    this.configurationArea.stop();
  }
}
