import { GlobalPositionStrategy, Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { ComponentRef, Injectable } from "@angular/core";
import { ConfigurationAreaWrapperComponent } from "../components/wrapper/wrapper.component";

@Injectable({ providedIn: "root" })
export class ConfigurationAreaService {
  public componentRef: ComponentRef<any> | null;
  private overlayRef: OverlayRef;
  private componentPortal: ComponentPortal<any>;

  constructor(private readonly overlay: Overlay) {}

  public start(): void {
    this.createOverlay();
    this.createComponentPortal(ConfigurationAreaWrapperComponent);
    this.overlayRef.attach(this.componentPortal);
  }

  public stop(): void {
    this.overlayRef.detach();
    this.componentRef = null;
  }

  private createOverlay(): void {
    const scrollStrategy = this.overlay.scrollStrategies.reposition({ autoClose: true });
    const positionStrategy = this.getPositionStrategy();

    this.overlayRef = this.overlay.create({
      scrollStrategy,
      positionStrategy,
      hasBackdrop: true,
      backdropClass: "configuration-area-backdrop"
    });
  }

  private createComponentPortal(component: ComponentType<any>): void {
    this.componentPortal = new ComponentPortal(component);
  }

  private getPositionStrategy(): GlobalPositionStrategy {
    return this.overlay.position().global();
  }
}
