<gp-textfield
  #textfield
  cdkFocusInitial
  tabindex="0"
  [outlined]="outlined"
  [small]="small"
  [medium]="medium"
  [large]="large"
  [xlarge]="xlarge"
  [iconTrailing]="iconTrailing"
  [icon]="icon"
  [label]="label"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [required]="required"
  [value]="_value ?? ''"
  [maxLength]="maxLength"
  [validityTransform]="validityTransform"
  (input)="onInputChange($event)"
  (keydown)="onKeyDown($event)"
  (blur)="_onTouched($event)"
></gp-textfield>
