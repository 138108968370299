import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Pure } from "src/app/common/decorators/pure";
import { coordinates } from "../../constants";

@Component({
  selector: "app-ca-municipal-settings",
  templateUrl: "./municipal-settings.component.html",
  styleUrls: ["./municipal-settings.component.scss", "../../styles/tabs.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CAMunicipalSettingsTabComponent implements OnInit {
  @Input() public form: FormGroup;

  public readonly zoomLevelOptions: ReadonlyArray<number> = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 40, 60];

  public coatOfArmsFile: File | undefined;
  public coatOfArms: SafeUrl;
  public coordinatesLongitude = {
    min : coordinates.longitude.min,
    max : coordinates.longitude.max
  };
  public coordinatesLatitude = {
    min : coordinates.latitude.min,
    max : coordinates.latitude.max
  };

  constructor(private readonly cdr: ChangeDetectorRef, private readonly sanitizer: DomSanitizer) {}

  @Pure
  public formatToKb(bytes: number): string {
    return `${(bytes / 1000).toFixed(2)} KB`;
  }

  public ngOnInit(): void {
    this.coatOfArms = this.sanitizer.bypassSecurityTrustUrl(this.form.get("coatOfArms").value);
  }

  public uploadHandler(files: Array<File>): void {
    if (!files || files.length > 1 || !["image/svg+xml", "image/jpeg", "image/png"].includes(files[0].type)) {
      return;
    }

    this.coatOfArmsFile = files[0];
    const reader = new FileReader();
    const formData = new FormData();
    formData.append("image", files[0]);
    this.form.patchValue({ coatOfArms: formData });

    reader.readAsDataURL(this.coatOfArmsFile);
    reader.onload = () => {
      this.coatOfArms = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      this.cdr.detectChanges();
    };
  }

  public deleteFile(): void {
    this.coatOfArmsFile = undefined;
    this.coatOfArms = undefined;
  }
}
