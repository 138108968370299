import { Action, createReducer, on } from "@ngrx/store";
import { IClientConfigDto } from "src/app/common/dto/client-config";
import { IKeycloakUserInfoDto, IUserRegion } from "src/app/common/dto/keycloak/keycloak-user-info";
import { UserInfoDto } from "src/app/common/dto/user-info";
import { PLUGIN } from "src/app/common/enums/plugins";
import { IPolicy } from "src/app/common/interfaces/app/policy";
import { TimelineRangeValue } from "src/app/common/interfaces/range/range";
import { Division } from "src/app/core/interfaces/division";
import { PluginEntity } from "src/app/core/interfaces/pluginEntity";
import { IProjectEntity, MinMaxYears } from "src/app/core/interfaces/project-entity";
import { threeYearPlanTimeRange } from "src/app/slots/three-year-plan/common/constants/three-year-plan-time-range";
import * as AppActions from "../actions/app.actions";

export interface State {
  userInfo: UserInfoDto | null;
  entity: IProjectEntity | null;
  entities: Array<IProjectEntity>;
  keycloakUserInfo: IKeycloakUserInfoDto;
  clientConfig: IClientConfigDto;
  userRegion: IUserRegion;
  year: number;
  timeRange: TimelineRangeValue;
  mapLoaded: boolean;
  useBingProxy?: boolean;
  division: Division | null;
  pluginId: PLUGIN;
  pluginEntities: Array<PluginEntity>;
  minMaxYears: MinMaxYears;
  policy: IPolicy;
  filter: Record<string, any>;
}

const initialState: State = {
  userInfo: null,
  entity: null,
  entities: [],
  keycloakUserInfo: null,
  clientConfig: null,
  userRegion: null,
  year: null,
  timeRange: null,
  mapLoaded: false,
  division: null,
  pluginId: null,
  pluginEntities: null,
  minMaxYears: null,
  policy: null,
  filter: {}
};

const appReducer = createReducer(
  initialState,
  on(AppActions.fetchUserInfoSuccess, (state, { payload }) => ({
    ...state,
    userInfo: payload
  })),
  on(AppActions.fetchUserInfoError, (state) => ({
    ...state,
    userInfo: null
  })),
  on(AppActions.setUserInfo, (state, { userInfo }) => ({ ...state, userInfo })),
  on(AppActions.initEntity, AppActions.setEntity, (state, { entity }) => ({
    ...state,
    entity
  })),
  on(AppActions.fetchKeycloakUserInfoSuccess, (state, { payload }) => ({
    ...state,
    keycloakUserInfo: payload
  })),
  on(AppActions.setEntities, (state, { payload }) => ({
    ...state,
    entities: payload
  })),
  on(AppActions.initUserRegion, AppActions.changeUserRegion, (state, { entityId }) => {
    // TODO(refactor): use new structure from module-config
    // for now we map it from entities to existing structure to prevent breaking changes
    const selectedEntity: IProjectEntity | undefined = state.entities?.find((entity) => entity.entityId === entityId);
    const userRegion = {
      id: selectedEntity.entityId,
      ags: selectedEntity.entity.ags,
      scenarioID: "6ef8552f-2044-5657-a716-2277a4c0a09c",
      name: selectedEntity.entity.name,
      latitude: String(selectedEntity.entity.position.latitude),
      longitude: String(selectedEntity.entity.position.longitude),
      level: String(selectedEntity.entity.level),
      config: {
        hasElectricity: selectedEntity.config.division.electricity,
        hasGas: selectedEntity.config.division.gas
      }
    };

    return {
      ...state,
      entity: selectedEntity,
      userRegion,
      timeRange: threeYearPlanTimeRange
    };
  }),
  on(AppActions.fetchClientConfigSuccess, (state, { payload }) => ({ ...state, clientConfig: payload })),
  on(AppActions.changeYear, (state, { year }) => ({ ...state, year })),
  on(AppActions.changeDivision, (state, { division }) => ({ ...state, division })),
  on(AppActions.changeTimelineRange, (state, { timeRange }) => ({ ...state, timeRange })),
  on(AppActions.setPolicy, (state, { policy }) => ({
    ...state,
    useBingProxy: !policy?.cookiesDetails?.comfort,
    policy
  })),
  on(AppActions.setPluginId, (state, { id }) => ({ ...state, pluginId: id })),
  on(AppActions.setPluginEntities, (state, { pluginEntities }) => ({ ...state, pluginEntities })),
  on(AppActions.setMinMaxYears, (state, { payload }) => ({ ...state, minMaxYears: payload })),
  on(AppActions.setFilterByKey, (state, { key, value }) => ({
    ...state,
    filter: { ...state.filter, [key]: value }
  })),
  on(AppActions.clearFilters, (state) => ({
    ...state,
    filter: {}
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return appReducer(state, action);
}
