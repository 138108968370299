export interface IeMobility {
  loadCapacity: number;
}

export interface IHeating {
  pumpCapacity: number;
}

export interface IPhotovoltaics {
  roofCapacity: number;
}

export interface ICapacityYearDto {
  eMobility?: IeMobility;
  heating?: IHeating;
  photovoltaics?: IPhotovoltaics;
}

export interface ICapacityPerYearDto extends ICapacityYearDto {
  year: number;
}

export class CapacityYearDto implements ICapacityYearDto {
  public eMobility: IeMobility;
  public heating: IHeating;
  public photovoltaics: IPhotovoltaics;

  constructor(data: ICapacityYearDto) {
    this.eMobility = data.eMobility;
    this.heating = data.heating;
    this.photovoltaics = data.photovoltaics;
  }
}

export class CapacityPerYearDto implements ICapacityPerYearDto {
  public eMobility: IeMobility;
  public heating: IHeating;
  public photovoltaics: IPhotovoltaics;
  public year: number;

  constructor(data: ICapacityPerYearDto) {
    this.eMobility = data.eMobility || { loadCapacity: null };
    this.heating = data.heating || { pumpCapacity: null };
    this.photovoltaics = data.photovoltaics || { roofCapacity: null };
    this.year = data.year;
  }
}
