import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { TABLE_NESTED_CUSTOM_TEMPLATE_CONTEXT } from "../tokens/table-nested-custom-template-context";

interface ITableItem {
  regionName: string;
  ags: string;
}

@Component({
  selector: "app-region-cell",
  templateUrl: "./region-cell.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegionCellComponent {
  constructor(@Inject(TABLE_NESTED_CUSTOM_TEMPLATE_CONTEXT) public readonly context: { id: string; row: ITableItem }) {}
}
