import { MatLegacyDateFormats } from "@angular/material/legacy-core";

export const datepickerDefaultFormats: MatLegacyDateFormats = {
  parse: {
    dateInput: "DD.MM.YYYY"
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "DD.MM.YYYY",
    dateA11yLabel: "DD.MM.YYYY",
    monthYearA11yLabel: "DD.MM.YYYY"
  }
};
