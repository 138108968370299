import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatLegacyTableDataSource } from "@angular/material/legacy-table";
import { MatSort } from "@angular/material/sort";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { accountingBadges } from "src/app/common/components/dialogs/notification-settings/constants/accounting-badges";
import { NEWS_BADGES } from "src/app/common/components/dialogs/notification-settings/constants/news-badges";
import { NotificationSquares } from "src/app/common/components/dialogs/notification-settings/constants/notification-type";
import { badges as typBadges } from "src/app/common/components/dialogs/notification-settings/constants/typ-badges";
import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";
import { FEATURES } from "src/app/common/enums/features";
import { SLOT } from "src/app/common/enums/slot";
import { NestedTableComponentStore } from "../component-stores/nested-table-component.store";
import { TABLE_NESTED_TEMPLATE_CONTEXT } from "../tokens/table-nested-template-context";

@Component({
  selector: "nested-table",
  styleUrls: ["nested-table.component.scss"],
  templateUrl: "nested-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NestedTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public userTypeFilter?: "internal" | "external";
  @ViewChild(MatSort) sort: MatSort;
  public module: SLOT | "street-lighting";
  public dataSource = new MatLegacyTableDataSource([]);
  public notificationSquares = NotificationSquares;
  public columnsToDisplay: Array<ITableColumn>;
  public displayedColumns: Array<string>;
  public badges = {
    [SLOT.OUTAGE_MONITORING]: NotificationSquares,
    [SLOT.THREE_YEAR_PLAN]: typBadges,
    [SLOT.ACCOUNTING]: Object.values(accountingBadges),
    "street-lighting": NotificationSquares,
    [FEATURES.NEWS]: Object.values(NEWS_BADGES)
  };

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(TABLE_NESTED_TEMPLATE_CONTEXT)
    public readonly context: { row: { id: string; email?: string }; columns: Array<ITableColumn>; module: SLOT },
    private store: NestedTableComponentStore
  ) {
    this.columnsToDisplay = context.columns;
    this.module = context.module;
    this.displayedColumns = this.columnsToDisplay.map(({ id }) => id);
    this.store.nestedTableData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (this.userTypeFilter) {
        data = data.filter((item) => item.userType === this.userTypeFilter);
      }

      data = data.map(({ gasPhone, electricityPhone, ...config }) => {
        const phone = [gasPhone, electricityPhone].filter((item, idx, arr) => arr.indexOf(item) === idx).join(", ");
        if (this.module === SLOT.OUTAGE_MONITORING || this.module === "street-lighting") {
          const electricityBadges = {
            emailConfig: Object.values(NotificationSquares.electricity).filter(
              (item) => config.electricity.emailConfig[item.id]
            ),
            phoneConfig: Object.values(NotificationSquares.electricity).filter(
              (item) => config.electricity.phoneConfig[item.id]
            )
          };

          const gasBadges = {
            emailConfigGas: Object.values(NotificationSquares.gas).filter((item) => config.gas.emailConfig[item.id]),
            phoneConfigGas: Object.values(NotificationSquares.gas).filter((item) => config.gas.phoneConfig[item.id])
          };

          return { ...config, phone, electricityBadges, gasBadges };
        } else {
          const electricityBadges = {
            emailConfig: this.badges[this.module].filter((item) => config.electricity.emailConfig[item.id]),
            phoneConfig: this.badges[this.module].filter((item) => config.electricity.emailConfig[item.id])
          };

          return { ...config, phone, electricityBadges };
        }
      });
      this.dataSource.data = data;
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public ngOnInit(): void {
    this.store.clearNestedData();
    const id = this.context.row.email ? this.context.row.email : this.context.row.id;
    this.store.fetchNestedTableData({ id, module: this.module });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
