import { environment } from "src/environments/environment";
import { IProjectConnections } from "../interfaces/project-connections";
import { IProjectEnvironments } from "../interfaces/project-environments";

export class AppConfig {
  public static connection: IProjectConnections;
  public static config: IProjectEnvironments;

  private static instance: AppConfig;

  private constructor() {}

  public static getInstance(): AppConfig {
    if (!AppConfig.instance) {
      AppConfig.instance = new AppConfig();
    }

    return AppConfig.instance;
  }

  public async loadConnections(url: string = environment.connectionPath): Promise<IProjectConnections> {
    if (AppConfig.connection) {
      return AppConfig.connection;
    } else {
      const response = await fetch(url);
      const jsonResponse = await response.json();
      AppConfig.connection = jsonResponse;

      return jsonResponse;
    }
  }

  public async loadConfigs(url: string = environment.configPath): Promise<IProjectEnvironments> {
    if (AppConfig.config) {
      return AppConfig.config;
    } else {
      const response = await fetch(url);
      const jsonResponse = await response.json();
      AppConfig.config = jsonResponse;

      return jsonResponse;
    }
  }
}
