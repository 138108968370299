export interface IBuildingsConnectionGroupEntryDto {
  connectionGroupId: string;
  countConnectionObject: number;
  buildingIds: Array<string>;
}

export class BuildingsConnectionGroupEntryDto implements IBuildingsConnectionGroupEntryDto {
  public connectionGroupId: string;
  public countConnectionObject: number;
  public buildingIds: Array<string>;

  constructor(data: IBuildingsConnectionGroupEntryDto) {
    Object.assign(this, data);
  }
}
