import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "configuration-area-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationAreaContentComponent {
  public readonly translationKeys = {
    concessionsArea: "CONFIGURATION_AREA.TABS.CONCESSIONS_AREA",
    downloadsArea: "CONFIGURATION_AREA.TABS.DOWNLOADS_AREA",
    reportingArea: "CONFIGURATION_AREA.TABS.REPORTING_AREA",
  };
}
