import { OverlayConfig, OverlayRef, PositionStrategy } from "@angular/cdk/overlay";
import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { Injectable, InjectionToken, Injector } from "@angular/core";
import { DynamicOverlay } from "src/app/core/material/dynamic-overlay/dynamic-overlay";

export const OVERLAY_DATA = new InjectionToken<any>("OVERLAY_DATA");
export type OverlayConfigWithData = OverlayConfig & { data?: any };

@Injectable({ providedIn: "root" })
export class OverlayService {
  private readonly overlayRefMap: Map<string, OverlayRef> = new Map();

  constructor(private readonly overlay: DynamicOverlay, private readonly injector: Injector) {}

  public openInRegion(component: ComponentType<unknown>, config?: OverlayConfigWithData): void {
    if (!this.overlayRefMap.has(`${component}`)) {
      const positionStrategy = this.overlay.position().global().centerHorizontally();

      this.overlay.setMainOverlay(true);
      this.attachComponent(component, positionStrategy, config);
    }
  }

  public openInGlobal(component: ComponentType<unknown>, config?: OverlayConfigWithData): void {
    if (!this.overlayRefMap.has(`${component}`)) {
      const positionStrategy = this.overlay.position().global().centerHorizontally();

      this.attachComponent(component, positionStrategy, config);
    }
  }

  public close(component: ComponentType<unknown>): void {
    if (this.overlayRefMap.has(`${component}`)) {
      const overlayRef = this.overlayRefMap.get(`${component}`);

      overlayRef.dispose();
      overlayRef.detachBackdrop();

      this.overlayRefMap.delete(`${component}`);
    }
  }

  public closeAll(): void {
    this.overlayRefMap.forEach((overlayRef) => {
      overlayRef.dispose();
      overlayRef.detachBackdrop();
    });
    this.overlayRefMap.clear();
  }

  private attachComponent(
    component: ComponentType<unknown>,
    positionStrategy: PositionStrategy,
    config?: OverlayConfigWithData
  ): unknown {
    const overlayRef = this.createOverlay(positionStrategy, config);
    const componentPortal = new ComponentPortal(component, undefined, this.createInjector(config));
    const componentRef = overlayRef.attach<unknown>(componentPortal);
    const componentInstance = componentRef.instance;

    this.overlayRefMap.set(`${component}`, overlayRef);

    return componentInstance;
  }

  private createOverlay(
    positionStrategy: PositionStrategy,
    config: OverlayConfigWithData = {} as OverlayConfigWithData
  ): OverlayRef {
    const scrollStrategy = this.overlay.scrollStrategies.reposition({ autoClose: true });

    return this.overlay.create({
      scrollStrategy,
      positionStrategy,
      hasBackdrop: true,
      ...config
    });
  }

  private createInjector(config: OverlayConfigWithData = {} as OverlayConfigWithData): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: OVERLAY_DATA,
          useValue: config.data
        }
      ]
    });
  }
}
