import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormFieldControl } from "../form-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-radio",
  templateUrl: "radio.component.html",
  styleUrls: ["radio.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RadioComponent extends FormFieldControl {
  @Input()
  public set checked(_checked: boolean) {
    this._checked = coerceBooleanProperty(_checked);
  }

  public _checked: boolean = false;

  public writeValue(obj: any): void {
    this._checked = this._value === obj;
  }

  public onChange(event: CustomEvent): void {
    event.stopPropagation();

    this.changed.emit(this._value);
    this._onChange(this._value);
  }
}
