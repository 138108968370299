export interface IElectricityConnectionGroupsDto {
  peakLoad: number;
  consumption: number;
  workload: number;
}

export class ElectricityConnectionGroupsDto implements IElectricityConnectionGroupsDto {
  public peakLoad: number;
  public consumption: number;
  public workload: number;

  constructor(data: IElectricityConnectionGroupsDto) {
    Object.assign(this, data);
  }
}
