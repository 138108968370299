import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-glossary-skeleton-loader",
  templateUrl: "./glossary-skeleton-loader.component.html",
  styleUrls: ["./glossary-skeleton-loader.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlossarySkeletonLoaderComponent {}
