import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { classBasedEffects, functionalEffects } from "src/app/ngrx/root-effects";
import { reducers } from "src/app/ngrx/root-reducers";
import { devtoolsModule } from "src/environments/devtools/devtools-environment";
import { analyticsMetaReducer } from "../analytics";
import { ProjectRouterStateSerializer } from "../classes/project-router-state-serializer";

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      },
      metaReducers: [analyticsMetaReducer]
    }),
    EffectsModule.forRoot(...classBasedEffects, functionalEffects),
    devtoolsModule,
    StoreRouterConnectingModule.forRoot({
      serializer: ProjectRouterStateSerializer
    })
  ]
})
export class AppNgRxModule {}
