import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule } from "@angular/material/legacy-list";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule } from "@angular/material/legacy-table";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { MatSortModule } from "@angular/material/sort";
import { EneTabsModule } from "@enersis/ui-tabs";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { TableFilterModule } from "src/app/slots/three-year-plan/common/components/table/components/table-filter/table-filter.module";
import { ButtonToggleModule } from "../../button-toggle/button-toggle.module";
import { PhoneNumberInputModule } from "../../form/phone-number-input/phone-number-input.module";
import { SkeletonLoaderModule } from "../../skeleton-loader/skeleton-loader.module";
import { TableModule } from "../../table/table.module";
import { BadgeComponent } from "./components/badge/badge.component";
import { AccountingNotificationSettingsEditEmailComponent } from "./components/edit/accounting/email/notification-settings-edit-email.component";
import { IMNotificationSettingsEditEmailComponent } from "./components/edit/incident-management/email/notification-settings-edit-email.component";
import { IMNotificationSettingsEditPhoneComponent } from "./components/edit/incident-management/phone/notification-settings-edit-phone.component";
import { MultiSubscriptionNewsWarningComponent } from "./components/edit/multi-subscription-news-waring/multi-subscription-news-waring.component";
import { NewsNotificationSettingsEditEmailComponent } from "./components/edit/news/email/notification-settings-edit-email.component";
import { NotificationSettingsEditComponent } from "./components/edit/notification-settings-edit.component";
import { StreetLightingNotificationSettingsEditEmailComponent } from "./components/edit/street-lighting/email/notification-settings-edit-email.component";
import { TYPNotificationSettingsEditEmailComponent } from "./components/edit/three-year-plan/email/notification-settings-edit-email.component";
import { NotificationSettingsWrapperComponent } from "./components/edit/wrapper/notification-settings-wrapper.component";
import { MultiRegionComponent } from "./components/multi-region/multi-region.component";
import { NotificationSettingsBadgeCellComponent } from "./components/notification-settings-badge-cell/notification-settings-badge-cell.component";
import { NotificationSettingsCellComponent } from "./components/notification-settings-cell/notification-settings-cell.component";
import { NotificationSettingsTYPCellComponent } from "./components/notification-settings-typ-cell/notification-settings-typ-cell.component";
import { NotificationSettingsComponent } from "./components/notification-settings/notification-settings.component";
import { OneRegionComponent } from "./components/one-region/one-region.component";
import { SquareModule } from "./components/square/square.module";
import { NotificationSettingsTableComponent } from "./components/table/notification-settings-table.component";

const materialModules = [
  MatLegacyDialogModule,
  MatButtonToggleModule,
  MatLegacyButtonModule,
  MatLegacyCheckboxModule,
  MatIconModule,
  MatLegacyPaginatorModule,
  MatLegacyTooltipModule,
  MatExpansionModule,
  MatDividerModule,
  MatLegacyListModule,
  MatLegacyInputModule,
  MatLegacyTableModule,
  MatLegacyFormFieldModule,
  MatSortModule
];

@NgModule({
  declarations: [
    NotificationSettingsComponent,
    MultiRegionComponent,
    OneRegionComponent,
    NotificationSettingsCellComponent,
    NotificationSettingsBadgeCellComponent,
    NotificationSettingsTYPCellComponent,
    IMNotificationSettingsEditPhoneComponent,
    IMNotificationSettingsEditEmailComponent,
    TYPNotificationSettingsEditEmailComponent,
    StreetLightingNotificationSettingsEditEmailComponent,
    NewsNotificationSettingsEditEmailComponent,
    AccountingNotificationSettingsEditEmailComponent,
    NotificationSettingsEditComponent,
    NotificationSettingsTableComponent,
    NotificationSettingsWrapperComponent,
    BadgeComponent,
    MultiSubscriptionNewsWarningComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    TableModule,
    EneTabsModule,
    SquareModule,
    PhoneNumberInputModule,
    SkeletonLoaderModule,
    ButtonToggleModule,
    TableFilterModule,
    ...materialModules
  ]
})
export class NotificationSettingsModule {}
