import { createSelector } from "@ngrx/store";
import { HeaderConfig } from "src/app/common/models/header/header-config";
import { numberOrUndefined } from "src/app/common/utils/number-or-undefined";
import { stringToNumberTuple2 } from "src/app/common/utils/string-to-number-tuple";
import { MODULES_CRM_IDS_MAP } from "src/app/core/constants/modules-crm-ids-map";
import { ICompositionItem, IDivision, IProjectModuleEntity } from "src/app/core/interfaces/project-entity";
import { RootState } from "../root-reducers";
import { selectCurrentSlot } from "./router.selectors";

const app = (state: RootState) => state.app;

/** app selectors */
export const selectUserInfo = createSelector(app, (state) => state?.userInfo);
export const selectKeycloakUserInfo = createSelector(app, (state) => state?.keycloakUserInfo);
export const selectUserRegion = createSelector(app, (state) => state?.userRegion);
export const selectClientConfig = createSelector(app, (state) => state?.clientConfig);
export const selectYear = createSelector(app, (state) => state?.year);
export const selectDivision = createSelector(app, (state) => state.division);
export const selectTimeRange = createSelector(app, (state) => state?.timeRange);
export const selectMapLoaded = createSelector(app, (state) => state?.mapLoaded);
export const selectUseBingProxy = createSelector(app, (state) => state?.useBingProxy);
export const selectPluginId = createSelector(app, (state) => state.pluginId);
export const selectPluginEntities = createSelector(app, (state) =>
  state.pluginEntities?.map((entity) => ({ ...entity, crmId: MODULES_CRM_IDS_MAP[entity.id] as string }))
);
export const selectPolicy = createSelector(app, (state) => state?.policy);

/** entity selectors */
export const selectEntities = createSelector(app, (state) => state?.entities);
export const selectEntity = createSelector(app, (state) => state?.entity);
export const selectEntityId = createSelector(selectEntity, (entity) => entity?.entityId || null);
export const selectEntityFeaturesFlags = createSelector(selectEntity, (state) => state.features || {});
export const selectRegionId = createSelector(selectEntity, (entity) => entity?.id);
export const selectModuleComposition = createSelector(selectEntity, (entity) => entity?.moduleComposition || []);
export const selectModule = createSelector(
  selectModuleComposition,
  (moduleComposition: Array<IProjectModuleEntity>, props: { moduleId: string }) =>
    moduleComposition.find(({ id }) => id === props.moduleId) || null
);
export const selectCurrentModule = createSelector(
  selectModuleComposition,
  selectCurrentSlot,
  (moduleComposition, slot) => moduleComposition.find(({ id }) => id === slot) || null
);
export const selectEntityTimelineRange = createSelector(
  selectModuleComposition,
  (moduleComposition: Array<IProjectModuleEntity>, props: { moduleId: string }) => {
    const module = moduleComposition.find(({ id }) => id === props.moduleId);

    if (module?.config?.timeline) {
      const { from, to } = module.config.timeline;
      return [from, to];
    } else {
      return null;
    }
  }
);
export const selectModuleConfig = createSelector(
  selectCurrentModule,
  (module) => module?.config || ({} as ICompositionItem["config"])
);
export const selectH2Ready = createSelector(selectModuleConfig, (config) => config.h2Ready);
export const selectModuleDivisionConfig = createSelector(
  selectModuleConfig,
  (config) => config.division || ({} as IDivision)
);

/** keycloak selectors */
export const selectUserRegions = createSelector(selectEntities, (state) => state);

/** region selectors */
export const selectRegionAgs = createSelector(selectEntity, (state) => state?.entity?.ags);
export const selectRegionName = createSelector(selectEntity, (state) => state?.entity?.name);
export const selectRegionTimelineRange = createSelector(selectCurrentModule, (state) =>
  stringToNumberTuple2(String(state?.config?.timeline?.from), String(state?.config?.timeline?.to))
);
export const selectRegionSelectedYear = createSelector(selectModuleComposition, (moduleComposition) => {
  const module = moduleComposition.find(({ id }) => id === "grid-development");
  return numberOrUndefined(String(module?.config?.timeline?.default));
});
export const selectRegionEnabledModules = createSelector(selectModuleComposition, (state) =>
  state.filter((module) => module.active).map((module) => module.id)
);
export const selectRegionContactPerson = createSelector(selectEntity, (state) => state?.contactPerson);
export const selectRegionServiceContactPerson = createSelector(selectEntity, (state) => state?.serviceContactPerson);
export const selectRegionConfig = createSelector(selectUserRegion, (state) => state?.config);
export const selectRegionConfigElectricity = createSelector(selectRegionConfig, (state) => state?.hasElectricity);
export const selectRegionConfigGas = createSelector(selectRegionConfig, (state) => state?.hasGas);
export const selectUserLocation = createSelector(selectEntity, (state) => {
  const position = state?.entity?.position;
  return [position?.longitude, position?.latitude] as [number, number];
});

/** client config selectors */
export const selectScenarioId = createSelector(selectClientConfig, (state) => state?.environments?.scenarioId);
export const selectGlossaryConfig = createSelector(selectClientConfig, (state) => state?.help?.glossary);
export const selectFooterConfig = createSelector(selectClientConfig, (state) => state?.footer);
export const selectDetailInformationClasses = createSelector(
  selectClientConfig,
  (state) => state?.environments?.detailInformationClasses
);

/** combined selectors */
export const selectRegionIdRange = createSelector(
  [selectRegionId, selectTimeRange],
  (regionId, timeRange) => regionId && timeRange && { regionId, timeRange }
);
export const selectHeaderConfig = createSelector(
  selectClientConfig,
  selectUserInfo,
  selectRegionName,
  (clientConfig, userInfo, regionName) => new HeaderConfig({ clientConfig, userInfo, regionName })
);

export const selectCoatOfArms = createSelector(selectEntity, (entity) => entity?.entity?.coatOfArms);
export const selectMinMaxYears = createSelector(app, (state) => state?.minMaxYears);
export const selectConcessionDistricts = createSelector(
  selectEntity,
  (entity) => entity?.entity?.concession?.districts || []
);

export const selectFilterByKey = createSelector(app, (state, props: { key: string }) => state?.filter[props.key]);
