<div class="mat-dialog-header">
  <div mat-dialog-title>
    <mat-icon class="circle" fontIcon="ec_video"></mat-icon>
    <span class="headline-3">{{ data?.headline | translate }}</span>
  </div>
  <button class="big" mat-button mat-dialog-close>
    <mat-icon fontIcon="ec_building_delete_address"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <video-player [source]="data?.media" [title]="data?.headline | translate"></video-player>
</mat-dialog-content>
