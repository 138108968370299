import { createAction, props } from "@ngrx/store";
import { ElectricityStationDto } from "src/app/common/dto/energy/station/electricity";
import { GasStationDto } from "src/app/common/dto/energy/station/gas";
import { StationWorkloadDto } from "../../../common/dto/energy/station-workload";

export interface IConnectionGroupsProps {
  stationId: string;
  year: number;
  scenarioId: string;
}

export const getElectricityStationData = createAction(
  "[Energy / Stations] Get electricity station data",
  props<IConnectionGroupsProps>()
);

export const getElectricityStationDataSuccess = createAction(
  "[Energy / Stations] Get electricity station data successfully",
  props<{ payload: ElectricityStationDto }>()
);

export const clearElectricityStationData = createAction("[Energy / Stations] Clear electricity station data");

export const getGasStationData = createAction(
  "[Energy / Stations] Get gas station data",
  props<IConnectionGroupsProps>()
);

export const getGasStationDataSuccess = createAction(
  "[Energy / Stations] Get gas station data successfully",
  props<{ payload: GasStationDto }>()
);

export const clearGasStationData = createAction("[Energy / Stations] Clear gas station data");

export const getStationWorkloadData = createAction(
  "[Energy / Stations] Get station workload data",
  props<Pick<IConnectionGroupsProps, "stationId" | "scenarioId"> & { timelineRange: [number, number] }>()
);

export const getStationWorkloadDataSuccess = createAction(
  "[Energy / Stations] Get station workload data successfully",
  props<{ payload: Array<StationWorkloadDto> }>()
);

export const clearStationWorkloadData = createAction("[Energy / Stations] Clear station workload data");
