export const accountingBadges = {
  start: {
    id: "start",
    type: "primary",
    label: "APP.NETZEBW",
    key: "NOTIFICATION_SERVICE.EDIT.ACCOUNTING.CHANGES_NETZEBW",
    class: "primary"
  },
  intermediate: {
    id: "intermediate",
    type: "documents",
    label: "NOTIFICATION_SERVICE.DOCUMENTS",
    key: "NOTIFICATION_SERVICE.EDIT.ACCOUNTING.NEW_DOCUMENTS",
    class: "documents"
  }
};
