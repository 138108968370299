import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import "@enersis/gp-components/gp-formfield";
import "@enersis/gp-components/gp-radio";
import { RadioComponent } from "./radio.component";

@NgModule({
  declarations: [RadioComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [RadioComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RadioModule {}
