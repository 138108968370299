import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TableColumn } from "src/app/slots/three-year-plan/common/components/table/classes/table-column";

@Component({
  selector: "app-module-info-column",
  templateUrl: "./module-info-column.component.html",
  styleUrls: ["./module-info-column.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleInfoColumnComponent extends TableColumn {}
