<tpm-dialog-frame
  [closable]="false"
  [icon]="'ec_maptools_zoomin'"
  [header]="'Nutzer hinzufügen'"
  [closeButtonLabel]="'Abbrechen' | translate"
  [submitButtonLabel]="'speichern' | translate"
  [submitDisabled]="usersForm.invalid"
  (closed)="close()"
  (submitted)="submit()"
>
  <div class="user-create-dialog-wrapper">
    <p class="body-2 description">
      {{ "Bitte geben Sie den Namen, Email und Funktion des neuen Nutzers ein." | translate }}
    </p>
    <table class="table-form" [formGroup]="usersForm">
      <thead>
        <th *ngFor="let header of ['Name', 'E-Mail', 'Funktion']" class="header-cell">
          <div class="headline-5 header">{{ header | translate }}</div>
        </th>
        <th class="header-cell"></th>
      </thead>
      <tbody formArrayName="users">
        <tr *ngFor="let userForm of users.controls; let index = index; let first = first" [formGroup]="userForm">
          <td *ngFor="let formControlName of formControlNames" class="cell">
            <tpm-textfield
              outlined
              [formControlName]="formControlName"
              [iconTrailing]="'gpi-filled-pen'"
            ></tpm-textfield>
          </td>
          <td class="cell">
            <tpm-icon
              *ngIf="!first"
              class="delete-button"
              [icon]="'gpi-filled-cross'"
              (click)="deleteUser(index)"
            ></tpm-icon>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="add-user-button" ene-button [icon]="'ec_maptools_zoomin'" (click)="addUser()">
      {{ "nutzer hinzfügen" | translate }}
    </button>
  </div>
</tpm-dialog-frame>
