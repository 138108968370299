import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { filter, map, take, takeUntil } from "rxjs/operators";
import { timeline } from "src/app/common/constants/module-settings";
import { Pure } from "src/app/common/decorators/pure";
import { SLOT } from "src/app/common/enums/slot";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectMinMaxYears } from "src/app/ngrx/selectors/app.selectors";

@Component({
  selector: "app-ca-module-settings",
  templateUrl: "./module-settings.component.html",
  styleUrls: ["./module-settings.component.scss", "../../styles/tabs.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CAModuleSettingsTabComponent implements OnInit, OnDestroy {
  @Input() public form: FormGroup;
  @Input() public multiMode: boolean;
  public slot = SLOT;
  public modules: { [key: string]: { label: string; disabled: boolean } };
  public minMaxYears$: Observable<Array<number>>;

  private readonly destroy$ = new Subject<void>();

  constructor(private store: Store<RootState>) {}

  public ngOnInit(): void {
    this.minMaxYears$ = this.store.pipe(
      select(selectMinMaxYears),
      map(({ minYear, maxYear }) => Array.from({ length: maxYear - minYear + 1 }, (_, i) => minYear + i))
    );

    this.modules = {
      "grid-development": {
        label: "NEP",
        disabled: this.multiMode
      },
      "three-year-plan": {
        label: "3JP",
        disabled: this.multiMode
      },
      accounting: {
        label: "AuV",
        disabled: this.multiMode
      },
      "incident-management": {
        label: "SM",
        disabled: this.multiMode
      },
      "grid-connections": {
        label: "EuN",
        disabled: this.multiMode
      }
    };
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  @Pure
  public getOtions(from: number = timeline.from, to: number = timeline.to): Array<number> {
    return Array.from({ length: to - from + 1 }, (_, i) => from + i);
  }

  @Pure
  public showLabel(key: string): string {
    return this.modules[key]?.label;
  }

  public onConcessionChange(form: FormGroup): void {
    form.valueChanges
      .pipe(
        filter((values) => !values.concession),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        form.get("electricity").setValue(false);
        form.get("gas").setValue(false);
        form.get("benchmarking").setValue(false);
      });
  }

  public onActiveChange(form: FormGroup): void {
    form.valueChanges
      .pipe(
        filter((values) => !values.active),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        form.get("electricity").setValue(false);
        form.get("gas").setValue(false);
        form.get("benchmarking").setValue(false);
      });
  }
}
