import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyRadioModule } from "@angular/material/legacy-radio";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { ButtonToggleModule } from "../../button-toggle/button-toggle.module";
import { ContentfulMediaComponent } from "../../contentful-media/contentful-media.component";
import { DialogFrameModule } from "../../dialog-frame/dialog-frame.module";
import { CheckboxFieldModule } from "../../form/checkbox-field/checkbox-field.module";
import { InputFieldModule } from "../../form/input-field/input-field.module";
import { TextareaFieldModule } from "../../form/textarea-field/textarea-field.module";
import { MediaPlayerModule } from "../../media-player/media-player.module";
import { LeadComponent } from "./lead.component";
import { LeadService } from "./lead.service";
const materialModules = [
  MatLegacyDialogModule,
  MatLegacyCheckboxModule,
  MatIconModule,
  MatLegacyRadioModule,
  MatLegacyInputModule,
  MatLegacyFormFieldModule
];

@NgModule({
  declarations: [LeadComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    ButtonToggleModule,
    InputFieldModule,
    TextareaFieldModule,
    CheckboxFieldModule,
    MediaPlayerModule,
    DialogFrameModule,
    ContentfulMediaComponent,
    ...materialModules
  ],
  providers: [LeadService]
})
export class LeadModule {}
