import { Action, createReducer, on } from "@ngrx/store";
import * as SummaryActions from "../../../actions/energy/self-sufficiency/summary.actions";

export interface State {
  production: number;
  consumption: number;
  gridFeed: number;
  ownUse: number;
  restNeed: number;
  selfSufficiencyDegree: number;
  selfSupplyDegree: number;
}

const initialState: State = {
  production: undefined,
  consumption: undefined,
  gridFeed: undefined,
  ownUse: undefined,
  restNeed: undefined,
  selfSufficiencyDegree: undefined,
  selfSupplyDegree: undefined
};

const summaryReducer = createReducer(
  initialState,
  on(SummaryActions.getSelfSufficiencySummaryData, (state) => ({
    ...state,
    production: undefined,
    consumption: undefined,
    gridFeed: undefined,
    ownUse: undefined,
    restNeed: undefined,
    selfSufficiencyDegree: undefined,
    selfSupplyDegree: undefined
  })),
  on(SummaryActions.getSelfSufficiencySummaryDataSuccess, (state, { payload }) => ({ ...state, ...payload }))
);

export function reducer(state: State | undefined, action: Action): State {
  return summaryReducer(state, action);
}
