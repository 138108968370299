<form name="notificationSettingsForm" class="settings-form" *ngIf="form" [formGroup]="form">
  <mat-accordion>
    <tpm-multi-subscription-news-warning
      *ngIf="(tabName$ | async) === 'news' && !oneRegionSelected"
    ></tpm-multi-subscription-news-warning>
    <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-checkbox
            *ngIf="showEmailToggle"
            [disableRipple]="true"
            formControlName="allowNotifyByEmail"
            (click)="$event.stopPropagation()"
          ></mat-checkbox>
          <h3 [class.multi-region-selected]="!oneRegionSelected && !allowNotifyByEmail.value">
            {{
              oneRegionSelected
                ? ("NOTIFICATION_SERVICE.EDIT.EMAIL" | translate)
                : ("NOTIFICATION_SERVICE.EDIT.EMAIL_MULTI" | translate)
            }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider big></mat-divider>
      <div class="settings-form__content">
        <ng-container [ngSwitch]="tabName$ | async">
          <app-im-notification-settings-edit-email
            *ngSwitchCase="'incident-management'"
            [division]="division"
            [formGroup]="emailNotificationFormControl"
            [indeterminate]="indeterminateEmail$ | async"
            [notificationSquares]="notificationSquares[division]"
          ></app-im-notification-settings-edit-email>
          <app-typ-notification-settings-edit-email
            *ngSwitchCase="'three-year-plan'"
            [formGroup]="emailNotificationFormControl"
            [indeterminate]="indeterminateEmail$ | async"
          ></app-typ-notification-settings-edit-email>
          <app-accounting-notification-settings-edit-email
            *ngSwitchCase="'accounting'"
            [formGroup]="emailNotificationFormControl"
            [indeterminate]="indeterminateEmail$ | async"
          ></app-accounting-notification-settings-edit-email>
          <app-street-lighting-notification-settings-edit-email
            *ngSwitchCase="'street-lighting'"
            [formGroup]="emailNotificationFormControl"
            [indeterminate]="indeterminateEmail$ | async"
          ></app-street-lighting-notification-settings-edit-email>
          <app-news-notification-settings-edit-email
            *ngSwitchCase="'news'"
            [formGroup]="emailNotificationFormControl"
            [indeterminate]="indeterminateEmail$ | async"
          ></app-news-notification-settings-edit-email>
        </ng-container>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-elevation-z0" *ngIf="(tabName$ | async) === 'incident-management'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-checkbox
            *ngIf="!oneRegionSelected"
            [disableRipple]="true"
            formControlName="allowNotifyBySMS"
            (click)="$event.stopPropagation()"
          ></mat-checkbox>
          <h3 [class.multi-region-selected]="!oneRegionSelected && !allowNotifyBySMS.value">
            {{
              oneRegionSelected
                ? ("NOTIFICATION_SERVICE.EDIT.SMS" | translate)
                : ("NOTIFICATION_SERVICE.EDIT.SMS_MULTI" | translate)
            }}
          </h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider big></mat-divider>
      <div class="settings-form__content">
        <app-im-notification-settings-edit-phone
          [formGroup]="phoneNotificationFormControl"
          [division]="division"
          [indeterminate]="indeterminateSms$ | async"
          [notificationSquares]="notificationSquares[division]"
        ></app-im-notification-settings-edit-phone>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</form>
