export interface IBuildingDto {
  countBuildings: number;
  sumPVPotential: number;
  sumPVSuitability: number;
  countBuildingCompaction: number;
}

export class BuildingDto implements IBuildingDto {
  public countBuildings: number;
  public sumPVPotential: number;
  public sumPVSuitability: number;
  public countBuildingCompaction: number;

  constructor(data: IBuildingDto) {
    Object.assign(this, data);
  }
}

export interface Timerange {
  value: number;
  inclusive: boolean;
}

export interface InfrastructureBuildingDto {
  buildingId: string;
  externalId: string;
  regionId: string;
  rasterId: string;
  geometry: Record<string, any>;
  timerange: [Timerange, Timerange];
  attributes: Array<{
    buildingId: string;
    attributeTypeId: string;
    value: any;
    quality: number;
    timerange?: [Timerange, Timerange];
  }>;
}
