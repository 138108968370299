import { inject } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs";
import { AdminDataService } from "src/app/core/services/admin-data.service";
import * as AdminDataActions from "../actions/admin-data.actions";

export const loadLaunchpadEffect = createEffect(
  (actions$ = inject(Actions), adminDataService = inject(AdminDataService)) => {
    return actions$.pipe(
      ofType(AdminDataActions.loadLaunchpad),
      switchMap(({ listType, ags }) =>
        adminDataService
          .getLaunchpadList(listType, ags)
          .pipe(map((response) => AdminDataActions.launchpadLoadedSuccess({ response })))
      )
    );
  },
  { functional: true }
);
