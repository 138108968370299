import { NgModule } from "@angular/core";
import { MatLegacyProgressBarModule } from "@angular/material/legacy-progress-bar";
import { ProgressBarComponent } from "./progress-bar.component";

@NgModule({
  declarations: [ProgressBarComponent],
  imports: [MatLegacyProgressBarModule],
  exports: [ProgressBarComponent]
})
export class ProgressBarModule {}
