import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EneIconModule } from "@enersis/ui-icon";
import { EneTabsModule } from "@enersis/ui-tabs";
import { TranslateModule } from "@ngx-translate/core";
import { CommonDirectivesModule } from "src/app/common/directives/directives.module";
import { ConfigAreaSettingsDialogModule } from "./common/dialogs/settings/settings.module";
import { ConfigurationAreaContentComponent } from "./components/content/content.component";
import { ConfigurationAreaContentModule } from "./components/content/content.module";
import { ConfigurationAreaHeaderComponent } from "./components/header/header.component";
import { ConfigurationAreaWrapperComponent } from "./components/wrapper/wrapper.component";

const components = [
  ConfigurationAreaWrapperComponent,
  ConfigurationAreaHeaderComponent,
  ConfigurationAreaContentComponent
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    TranslateModule,
    CommonDirectivesModule,
    ConfigurationAreaContentModule,
    EneIconModule,
    EneTabsModule,
    ConfigAreaSettingsDialogModule
  ],
  exports: [...components]
})
export class ConfigurationAreaModule {}
