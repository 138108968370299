<!--
  This (ugly) template includes only the base templates (examples). Additional logic such as e.g. iterating
  over all configs and cleaning up may have to be considered in future task
-->

<mat-accordion class="custom spaced" [multi]="multiMode" [formGroup]="form">
  <!-- NBW -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <img src="/assets/images/logos/netzebw_logosignet.svg" />
        Vertragsverhältnis
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider big></mat-divider>
    <div class="content">
      <mat-list>
        <mat-list-item>
          <mat-slide-toggle formControlName="concession" (click)="onConcessionChange(form)">
            <b>Konzession vorhanden</b>
          </mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox formControlName="electricity" [disabled]="!form.get('concession').value">
            <b>Strom</b>
          </mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox formControlName="gas" [disabled]="!form.get('concession').value">
            <b>Gas</b>
          </mat-checkbox>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-expansion-panel>
  <!-- MODULES -->
  <ng-container formArrayName="items">
    <mat-expansion-panel
      *ngFor="let item of form.get('items')['controls']; let i = index"
      [formGroupName]="i"
      [disabled]="multiMode && item.get('disabled').value"
      [class.disabled]="multiMode && item.get('disabled').value"
      [expanded]="multiMode && !item.get('disabled').value"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <ng-container *ngIf="multiMode; else single">
            <mat-checkbox
              (click)="$event.stopPropagation(); item.get('disabled').setValue(!item.get('disabled').value)"
            ></mat-checkbox>
            <div class="checkbox-wrapper">{{ showLabel(item.get("id").value) }}</div>
          </ng-container>
          <ng-template #single>{{ showLabel(item.get("id").value) }}</ng-template>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider big></mat-divider>
      <div class="content">
        <mat-list>
          <mat-list-item>
            <mat-slide-toggle formControlName="active" (click)="onActiveChange(item)">
              <b>Modul aktiv</b>
            </mat-slide-toggle>
          </mat-list-item>
          <mat-divider></mat-divider>
          <mat-list-item>
            <mat-checkbox formControlName="electricity" [disabled]="!item.get('active').value">
              <b>Strom</b>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item>
            <mat-checkbox formControlName="gas" [disabled]="!item.get('active').value">
              <b>Gas</b>
            </mat-checkbox>
          </mat-list-item>
          <mat-list-item *ngIf="item.get('benchmarking')">
            <mat-checkbox formControlName="benchmarking" [disabled]="!item.get('active').value">
              <b>Kommunenbenchmarking aktiv</b>
            </mat-checkbox>
          </mat-list-item>
          <ng-container *ngIf="item.get('h2Ready')">
            <mat-list-item>
              <mat-checkbox
                formControlName="h2Ready"
                [disabled]="!item.get('gas').value || !item.get('active').value"
              >
                Wasserstoffeignung aktiv
              </mat-checkbox>
            </mat-list-item>
            <mat-divider></mat-divider>
            <mat-list-item>
              <h3>Timeline</h3>
              <section class="timeline-datepicker">
                <mat-form-field class="input">
                  <mat-label>Anfangsjahr</mat-label>
                  <mat-select formControlName="from" [disabled]="!item.get('active').value">
                    <mat-option *ngFor="let year of minMaxYears$ | async" [value]="year">
                      {{ year }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>event</mat-icon>
                </mat-form-field>

                <mat-form-field class="input">
                  <mat-label>Endjahr</mat-label>
                  <mat-select formControlName="to" [disabled]="!item.get('active').value">
                    <mat-option *ngFor="let year of minMaxYears$ | async" [value]="year">
                      {{ year }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>event</mat-icon>
                </mat-form-field>

                <mat-form-field class="input">
                  <mat-label>Anfangsjahr gesetzt</mat-label>
                  <mat-select formControlName="default" [disabled]="!item.get('active').value">
                    <mat-option
                      *ngFor="let year of getOtions(item.get('from').value, item.get('to').value)"
                      [value]="year"
                    >
                      {{ year }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>event</mat-icon>
                </mat-form-field>
              </section>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
