<div class="configuration-area-downloads">
  <ene-tabs class="nested-tabs centered" (currentTabChange)="onModuleChange($event)">
    <ene-tab
      *ngFor="let item of modulesList"
      [tabName]="item.value"
      [tabTitle]="item.label | translate"
    ></ene-tab>
  </ene-tabs>
  <div *ngIf="vm$ | async as vm" class="configuration-area-downloads__card">
    <p class="headline-3">
      {{ "CONFIGURATION_AREA.DOWNLOADS.HEADLINE" | translate }} {{ currentTab$ | async | translate }}
    </p>
    <button
      ene-filled-button
      size="xlarge"
      icon="ec_check"
      [disabled]="!vm.hasMulti"
      (click)="downloadArchive(vm.module)"
    >
      {{ "CONFIGURATION_AREA.DOWNLOADS.DOWNLOAD_ARCHIVE" | translate }}
    </button>
    <ngmy-table
      class="configuration-area-downloads__table"
      selections
      [hideFilter]="true"
      [disableSort]="false"
      [sortActive]="'timestamp'"
      [sortDirection]="'desc'"
      [pagingConfig]="pagingConfig"
      [columnsConfig]="columnsConfig"
      [loading]="vm.loading"
      [data]="vm.result"
      (tableAction)="onTableAction(vm.module, $event)"
    ></ngmy-table>
  </div>
</div>
