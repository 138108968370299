import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { TablePagingService } from "src/app/common/components/table/classes/table-paging-service";
import { ITablePagingMetadata } from "src/app/common/components/table/interfaces/table-paging-metadata";
import { SLOT } from "src/app/common/enums/slot";
import { AppConfig } from "src/app/core/helpers/app.config";
import { Tabs } from "../constants/tabs";

@Injectable()
export class PaginationService extends TablePagingService<Record<string, any>> {
  private module: SLOT | "street-lighting";
  private tabName: Tabs;
  private urls = {
    [Tabs.USERS]: `${AppConfig.connection.infrastructure.userSettings}/users/by-user`,
    [Tabs.MUNICIPALITIES]: `${AppConfig.connection.infrastructure.userSettings}/users/by-municipality`
  };

  constructor(private http: HttpClient) {
    super();
  }

  public setTabName(tabName: Tabs): void {
    this.sort?.sortChange.emit(null);
    this.tabName = tabName;
  }

  public getTabName(): Tabs {
    return this.tabName;
  }

  public setModule(module: SLOT | "street-lighting") {
    this.module = module;
  }

  public loadData(): Observable<ITablePagingMetadata<Record<string, any>>> {
    return combineLatest([this.getPaging(), this.getSort(), this.getFilter()]).pipe(
      switchMap(([paging, sorting, search]) => {
        let paramsObject: any = {
          module: this.module
        };

        if (paging) {
          paramsObject = { ...paramsObject, limit: paging.pageSize, offset: paging.pageIndex * paging.pageSize };
        }

        if (sorting) {
          paramsObject = { ...paramsObject, sortColumn: sorting.active, sortDirection: sorting.direction };
        }

        if (search) {
          paramsObject = { ...paramsObject, filter: search };
        }

        return this.http.get<ITablePagingMetadata<Record<string, any>>>(this.urls[this.tabName], {
          params: new HttpParams({
            fromObject: paramsObject
          })
        });
      })
    );
  }
}
