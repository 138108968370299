import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { distinctUntilChanged, filter, tap, withLatestFrom } from "rxjs/operators";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { OverlayService } from "src/app/core/services/overlay.service";
import { QueryParamsManagerService } from "src/app/core/services/query-params-manager.service";
import { counter } from "src/app/core/utils/ngrx";
import { LaunchpadComponent } from "src/app/features/overlays/region/launchpad/container/launchpad.component";
import { NewsComponent } from "src/app/features/overlays/region/news/container/news.component";
import { WebinarsComponent } from "src/app/features/overlays/region/webinars/container/webinars.component";
import { fetchContentfulEntries } from "../actions/contentful.actions";
import {
  changeLaunchpadOverlay,
  closeLaunchpad,
  closeNews,
  closeWebinars,
  openLaunchpad,
  openNews,
  openWebinars
} from "../actions/overlay.actions";
import { fetchUsersSettingsUsersStorage } from "../actions/users-settings.actions";
import { RootState } from "../root-reducers";
import { selectLaunchpadOverlay } from "../selectors/overlay.selectors";

@Injectable()
export class RegionOverlayEffects {
  openLaunchpad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openLaunchpad),
        tap(() => {
          this.updateNewNewsInLaunchpad();
          this.overlayService.openInRegion(LaunchpadComponent, {
            backdropClass: "launchpad-backdrop"
          });
        })
      ),
    { dispatch: false }
  );

  closeLaunchpad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeLaunchpad),
        tap(() => {
          this.overlayService.close(LaunchpadComponent);
        })
      ),
    { dispatch: false }
  );

  changeLaunchpadOverlay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(changeLaunchpadOverlay),
        filter(({ overlay }) => Boolean(overlay)),
        distinctUntilChanged((prev, curr) => prev.overlay === curr.overlay),
        counter(),
        tap(({ value, index }) =>
          this.analyticsService.trackKpAreaOpen(index === 0 ? "Direktlink" : "Launchpad", value.overlay)
        )
      ),
    { dispatch: false }
  );

  openNews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openNews),
        withLatestFrom(this.store.pipe(select(selectLaunchpadOverlay))),
        tap(([{ contentType }, overlay]) => {
          this.overlayService.openInRegion(NewsComponent, {
            data: {
              contentType,
              contentTypes:
                overlay === "module"
                  ? [ContentfulContentType.CPRegulatedModulesNews, ContentfulContentType.NBRegulatedModulesNews]
                  : [ContentfulContentType.CPServicesModulesNews, ContentfulContentType.NBServicesModulesNews]
            }
          });
        })
      ),
    { dispatch: false }
  );

  closeNews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeNews),
        tap(() => {
          this.updateNewNewsInLaunchpad();
          this.overlayService.close(NewsComponent);
          this.queryParamsManagerService.removeQueryParams();
        })
      ),
    { dispatch: false }
  );

  openWebinars$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openWebinars),
        withLatestFrom(this.store.pipe(select(selectLaunchpadOverlay))),
        tap(([_, overlay]) => {
          this.overlayService.openInRegion(WebinarsComponent, {
            data: {
              contentType:
                overlay === "module"
                  ? ContentfulContentType.RegulatedModulesWebinars
                  : ContentfulContentType.ServicesModulesWebinars
            }
          });
        })
      ),
    { dispatch: false }
  );

  closeWebinars$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeWebinars),
        tap(() => {
          this.updateNewNewsInLaunchpad();
          this.overlayService.close(WebinarsComponent);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly overlayService: OverlayService,
    private readonly queryParamsManagerService: QueryParamsManagerService,
    private readonly analyticsService: AnalyticsService
  ) {}

  private updateNewNewsInLaunchpad(): void {
    this.store.dispatch(fetchContentfulEntries({ contentType: undefined }));
    this.store.dispatch(fetchUsersSettingsUsersStorage());
  }
}
