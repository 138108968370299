import { Action, combineReducers } from "@ngrx/store";
import * as capacity from "./capacity.reducer";
import * as co2Saving from "./co2-saving.reducer";
import * as connectionGroups from "./connection-groups.reducer";
import * as consumption from "./consumption.reducer";
import * as cumulatedInvest from "./cumulated-invest.reducer";
import * as regionsEMobility from "./regions-e-mobility.reducer";
import * as selfSufficiency from "./self-sufficiency/self-sufficiency.reducer";
import * as stations from "./stations.reducer";

export interface State {
  publicSector: {
    consumption: consumption.State;
    co2Saving: co2Saving.State;
    selfSufficiency: selfSufficiency.State;
    capacity: capacity.State;
    regions: regionsEMobility.State;
    cumulatedInvest: cumulatedInvest.State;
    connectionGroups: connectionGroups.State;
    stations: stations.State;
  };
}

const energyReducer = combineReducers({
  publicSector: combineReducers({
    consumption: consumption.reducer,
    co2Saving: co2Saving.reducer,
    selfSufficiency: selfSufficiency.reducer,
    capacity: capacity.reducer,
    regions: regionsEMobility.reducer,
    cumulatedInvest: cumulatedInvest.reducer,
    connectionGroups: connectionGroups.reducer,
    stations: stations.reducer
  })
});

export function reducer(state: State | undefined, action: Action): State {
  return energyReducer(state, action);
}
