import { Action, createReducer, on } from "@ngrx/store";
import * as TimeRangeActions from "../../../actions/energy/self-sufficiency/time-range.actions";

export interface State {
  timeRange: [Date, Date];
}

const initialState: State = {
  timeRange: null
};

const timeRangeReducer = createReducer(
  initialState,
  on(TimeRangeActions.changeSelfSufficiencyTimeRange, (state, { timeRange }) => ({ ...state, timeRange }))
);

export function reducer(state: State | undefined, action: Action): State {
  return timeRangeReducer(state, action);
}
