<gp-formfield [alignEnd]="alignEnd" [label]="label" [class.disabled]="_disabled">
  <gp-checkbox
    [disabled]="disabled"
    [required]="required"
    [value]="_value"
    [checked]="_checked"
    [indeterminate]="indeterminate"
    (change)="onChange($event)"
  ></gp-checkbox>
</gp-formfield>
