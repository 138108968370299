import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { merge } from "lodash";
import { units } from "../constants/units";
import { FormatOptions } from "../interfaces/unit-format/format-options";
import { Unit, UnitConfig } from "../interfaces/unit-format/unit";

const SPACE = "\xA0";
const DEFAULT_OPTIONS: FormatOptions = {
  maximumSignificantDigits: 4,
  minimumSignificantDigits: 4
};

@Pipe({ name: "eneUnit" })
export class EneUnitPipe implements PipeTransform {
  private locale: string = "de-DE";

  constructor(private currency: CurrencyPipe) {}

  public transform(value: number, unitConfig: UnitConfig): string {
    if (!unitConfig) {
      // if no config provided, return as a raw
      return value?.toLocaleString(this.locale);
    }

    return this.formatUnit(value, unitConfig);
  }

  /**
   * Formats a value of the specified unit-category into a string
   *
   * @param value
   * @param unitConfig specify category, input-unit and more
   */
  public formatUnit(value: number, unitConfig: UnitConfig) {
    const mergedOptions: FormatOptions = merge({}, DEFAULT_OPTIONS, unitConfig.numberFormat);

    if (unitConfig.category === "percentage") {
      // we always only want the "unit" percentage. Always omit showing decimals.
      unitConfig.onlyUnits = ["%"];
    }
    if (unitConfig.category === "currency") {
      return this.currency.transform(value, "EUR", "symbol", "1.0-2", this.locale);
    }

    const { unit, formattedValue } = this.findBestRepresentation(value, unitConfig, mergedOptions);

    return `${unitConfig.valuePrefix ?? ""}${formattedValue}${SPACE}${unitConfig.unitPrefix ?? ""}${unit.symbol}${
      unitConfig.unitSuffix ?? ""
    }`;
  }

  private findBestRepresentation(
    value: number,
    unitConfig: UnitConfig,
    options: FormatOptions
  ): {
    unit: Unit;
    formattedValue: string;
  } {
    const categoryUnits = units[unitConfig.category];
    const baseUnit = categoryUnits.find((unit) => unit.base === unitConfig.base);
    if (baseUnit === undefined) {
      throw new Error(
        `Cannot use the base "${unitConfig.base}" in conjunction with the category "${unitConfig.category}"`
      );
    }
    const valueIsZero = value === 0;
    const availableUnits = this.getAllowedUnits(categoryUnits, unitConfig, baseUnit, valueIsZero);

    // find unit with smallest scale, where the resulting value is still >= 1
    const selectedUnit: Unit = availableUnits.find((unit, index) => {
      const scaledValue = (value * baseUnit.scale) / unit.scale;
      // return true if the scaled value is the first one that is >= 1 (OR if it is the last available unit)
      return Math.abs(scaledValue) >= 1 || index === availableUnits.length - 1;
    });

    // the parenthesis are important to avoid floating point errors
    const scaledValue = (value * baseUnit.scale) / selectedUnit.scale;

    return { unit: selectedUnit, formattedValue: this.format(scaledValue, options) };
  }

  private getAllowedUnits(
    units: Array<Unit>,
    unitConfig: UnitConfig,
    baseUnit: Unit,
    valueIsZero: boolean
  ): Array<Unit> {
    const omitUnits = () => units.filter((unit) => !unitConfig.omitUnits?.includes(unit.base));
    const onlyUnits = () => units.filter((unit) => unitConfig.onlyUnits?.includes(unit.base));
    const allowedUnits = (unitConfig.onlyUnits ? onlyUnits() : omitUnits()).reverse(); // reverse order, so that we can find the unit with the smallest scale first (e.g. km instead of m)

    if (valueIsZero) {
      // if value is 0, we only want to display the base unit.
      if (!allowedUnits.includes(baseUnit)) {
        // ...but if the base unit is not within the allowedUnits, we'll try to choose the closest allowed unit.
        const nextBestUnit =
          allowedUnits.find(
            (unit) => unit.scale < baseUnit.scale // try to find a unit with a smaller scale than baseUnit (e.g. "k", if base is "M")
          ) ?? allowedUnits[allowedUnits.length - 1]; // if no unit with a smaller scale was found, we'll use the last unit in the list

        if (nextBestUnit) {
          return [nextBestUnit];
        }
        // there are no units allowed. All hell breaks loose.
      }
      return [baseUnit];
    }

    return allowedUnits;
  }

  /**
   * Formats a number to a localized string, using the given options.
   * @param value the input number to format
   * @param options (optional) specify how you want your number formatted
   * @returns the formatted number as a string
   */
  private format(value: number, options: FormatOptions = {}): string {
    const { smallerThanOneRules, ...args } = options;
    const ruleSet = !!smallerThanOneRules && value < 1.0 ? smallerThanOneRules : args;

    // allow setting maximumFractionDigits in combination with maximum/minimumSignificantDigits
    const pointValue =
      options.maximumFractionDigits >= 0 ? Number(value.toFixed(options.maximumFractionDigits)) : value;
    return pointValue.toLocaleString(this.locale, ruleSet);
  }
}
