<div *ngFor="let item of itemAccessor($any(columnConfig), row)">
  <div
    *ngIf="item[columnConfig.id]"
    class="badge-element"
    tooltipIfTruncated
    [matTooltip]="translateAccessor(item) | translate | uppercase"
    [matTooltipClass]="'table-tooltip'"
    [ngClass]="item[columnConfig.id]"
    [style.backgroundColor]="getColor($any(columnConfig), item)"
  >
    {{ translateAccessor(item) | translate | uppercase }}
  </div>
</div>
