import {createAction, props} from "@ngrx/store";
import {GasCockpitValuesAllYearsDto, NetworkDto} from "../../common/dto/network";

export const getNetworkIds = createAction("[Network] Get network ids");

export const getNetworkIdsSuccess = createAction(
  "[Network] Get network ids success",
  props<{
    payload: Array<NetworkDto>;
  }>()
);

export const getGasCockpitValuesForAllYears = createAction(
  "[Network] Get gas cockpit values for all years",
  props<{
    gasNetworkId: string;
    timelineRange: Array<number>;
  }>()
);

export const getGasCockpitValuesForAllYearsSuccess = createAction(
  "[Network] Get gas cockpit values for all years success",
  props<{
    payload: GasCockpitValuesAllYearsDto;
  }>()
);
