import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { EneLayoutService } from "@enersis/ui-helper";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, merge, Observable, of } from "rxjs";
import { map, scan, switchMap } from "rxjs/operators";
import { IButtonToggleItem } from "src/app/common/components/button-toggle/button-toggle.component";
import { SelectOptionItem } from "src/app/common/components/select/select.interface";
import { PLUGIN } from "src/app/common/enums/plugins";
import { SLOT } from "src/app/common/enums/slot";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectRegionEnabledModules } from "src/app/ngrx/selectors/app.selectors";
import { selectLaunchpadOverlay } from "src/app/ngrx/selectors/overlay.selectors";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-contentful-wrapper",
  templateUrl: "./contentful-wrapper.component.html",
  styleUrls: ["./contentful-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentfulWrapperComponent {
  @Input() public headline: string;
  @Input() public contentType: ContentfulContentType;
  @Input() public contentTypeToggles: Array<IButtonToggleItem>;
  @Input()
  public set hideModuleSelectOptions(_hideModuleSelectOptions: boolean) {
    this.hideModuleSelectOptionsChange.next(_hideModuleSelectOptions);
  }

  @Output() public readonly searchChange = new EventEmitter<string>();
  @Output() public readonly moduleChange = new EventEmitter<SLOT | undefined>();
  @Output() public readonly contentTypeChange = new EventEmitter<ContentfulContentType>();
  @Output() public readonly markAllRead = new EventEmitter<string>();

  public readonly hideModuleSelectOptionsChange = new BehaviorSubject<boolean>(false);
  public readonly vm$ = merge(
    this.layoutService.isMobileScreen$.pipe(map((isMobileView) => ({ isMobileView }))),
    this.getModuleSelectOptions().pipe(map((moduleSelectOptions) => ({ moduleSelectOptions })))
  ).pipe(scan((vm, values) => ({ ...vm, ...values }), {}));

  constructor(private readonly store: Store<RootState>, private readonly layoutService: EneLayoutService) {}

  private getModuleSelectOptions(): Observable<Array<SelectOptionItem>> {
    return combineLatest([
      this.hideModuleSelectOptionsChange.asObservable(),
      this.store.pipe(select(selectLaunchpadOverlay))
    ]).pipe(
      switchMap(([hideModuleSelectOptions, overlay]) => {
        if (hideModuleSelectOptions) {
          return of([]);
        }

        if (overlay === "module") {
          return this.store.pipe(
            select(selectRegionEnabledModules),
            map((modules) =>
              [undefined, ...modules].map((value) => ({
                label: value === undefined ? "CONTENTFUL.none" : `LAUNCHPAD.MODULES.${value}.name`,
                value
              }))
            )
          );
        } else {
          return of([
            {
              label: "LAUNCHPAD.DIALOGS.ALL_MODULES",
              value: undefined
            },
            {
              label: `LAUNCHPAD.PLUGINS.${PLUGIN.STREET_LIGHTING}.name`,
              value: PLUGIN.STREET_LIGHTING
            }
          ]);
        }
      })
    );
  }
}
