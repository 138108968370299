import { Injectable, inject } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { switchMap, tap, withLatestFrom } from "rxjs/operators";
import { AdminModuleStatus } from "src/app/common/dto/admin-module/module-status";
import { AdminModuleSubscriptionDto } from "src/app/common/dto/admin-module/subscription";
import { LoadingResponse } from "src/app/common/interfaces/loading-response";
import { DynamicSnackBar } from "src/app/core/material/snack-bar/snack-bar";
import { AdminDataService } from "src/app/core/services/admin-data.service";
import { formatDateDE } from "src/app/core/utils/number/date";
import { CampusOneAdminViewComponent } from "./campus-one-admin-view.component";

export interface CampusOneAdminViewState {
  subscriptions: LoadingResponse<Array<AdminModuleSubscriptionDto>>;
  updatedSubscriptions: Array<{
    subscriptionName: string;
    status: AdminModuleStatus;
  }>;
}

@Injectable()
export class CampusOneAdminViewComponentStore extends ComponentStore<CampusOneAdminViewState> {
  private readonly adminService = inject(AdminDataService);
  private readonly snackbar = inject(DynamicSnackBar);

  constructor() {
    super({
      subscriptions: {
        result: [],
        loading: false
      },
      updatedSubscriptions: []
    });
  }

  public readonly isUpdated$ = this.select((state) => state.updatedSubscriptions.length > 0);
  public readonly updatedSubscriptions$ = this.select((state) => state.updatedSubscriptions);
  public readonly subscriptions$ = this.select((state) => ({
    result: state.subscriptions.result.map((item) => this.formatTableItem(item)),
    loading: state.subscriptions.loading
  }));
  public readonly mergedSubscriptions$ = this.select(
    this.subscriptions$,
    this.updatedSubscriptions$,
    (subscriptions, updatedSubscriptions) => ({
      result: subscriptions.result.map((subscription) => ({
        ...subscription,
        status:
          updatedSubscriptions.find(({ subscriptionName }) => subscriptionName === subscription.subscriptionName)
            ?.status ?? (subscription.statusMessage === "SUBSCRIPTION_NEW" ? "SUBSCRIPTION_NEW" : subscription.status)
      })),
      loading: subscriptions.loading
    }),
    { debounce: true }
  );

  public readonly vm$ = this.select(
    {
      isUpdated: this.isUpdated$,
      subscriptions: this.mergedSubscriptions$,
      updatedSubscriptions: this.updatedSubscriptions$
    },
    { debounce: true }
  );

  public readonly updateSubscription = this.updater(
    (
      state,
      props: {
        subscriptionName: string;
        status: AdminModuleStatus;
      }
    ) => {
      const invitationIndex = state.updatedSubscriptions.findIndex(
        ({ subscriptionName }) => subscriptionName === props.subscriptionName
      );

      if (invitationIndex !== -1) {
        state.updatedSubscriptions.splice(invitationIndex, 1);
      }

      return { ...state, updatedSubscriptions: [...state.updatedSubscriptions, props] };
    }
  );

  public readonly addInvoice = this.effect<{ file: File; moduleSubscriptionName: string }>((params$) =>
    params$.pipe(
      switchMap(({ file, moduleSubscriptionName }) =>
        this.adminService.postInvoiceUpload({ file, moduleSubscriptionName }).pipe(
          tapResponse(
            () => this.fetchSubscriptions(false),
            () => void 0
          )
        )
      )
    )
  );

  public readonly downloadInvoice = (fileName: string) => this.adminService.postInvoiceDownload({ fileName });

  public readonly deleteInvoice = this.effect<string>((params$) =>
    params$.pipe(
      switchMap((fileName) =>
        this.adminService.deleteInvoice({ fileName }).pipe(
          tapResponse(
            () => this.fetchSubscriptions(false),
            () => void 0
          )
        )
      )
    )
  );

  public readonly fetchSubscriptions = this.effect<boolean | void>((trigger$) =>
    trigger$.pipe(
      tap((isLoading) => {
        if (isLoading !== false) {
          this.patchState({ subscriptions: { result: [], loading: true }, updatedSubscriptions: [] });
        }
      }),
      switchMap(() =>
        this.adminService.getSubscriptionList({ pageSize: 9999 }).pipe(
          tapResponse(
            (result) => this.patchState({ subscriptions: { result, loading: false }, updatedSubscriptions: [] }),
            () => this.patchState({ subscriptions: { result: [], loading: false } })
          )
        )
      )
    )
  );

  public readonly updateSubscriptionsStatuses = this.effect<
    MatLegacyDialogRef<CampusOneAdminViewComponent> | undefined
  >((params$) =>
    params$.pipe(
      withLatestFrom(this.updatedSubscriptions$),
      switchMap(([dialogRef, updatedSubscriptions]) =>
        this.adminService.patchSubscriptionStatus(updatedSubscriptions).pipe(
          tapResponse(
            () => {
              this.snackbar.success("Status erfolgreich geändert", undefined, undefined, false);
              dialogRef?.close();
              this.fetchSubscriptions(false);
            },
            () => {
              this.snackbar.error(
                "Status konnte aufgrund eines internen Fehlers nicht geändert werden",
                undefined,
                undefined,
                false
              );
              dialogRef?.close();
              this.fetchSubscriptions(false);
            }
          )
        )
      )
    )
  );

  private formatTableItem(item: AdminModuleSubscriptionDto): any {
    return {
      displayName: item.displayName,
      contractTerm: `${formatDateDE(item.clientSubscriptionContract.details.contractStartDate)} - ${formatDateDE(
        item.clientSubscriptionContract.details.contractEndDate
      )}`,
      addressCity: item.clientSubscriptionContract.offeree.addressCity,
      maxUsers: item.plan?.options?.maxUsers,
      customer: item.customer,
      status: item.status?.code,
      fullName: `${item.clientSubscriptionContract.offeree.givenName} ${item.clientSubscriptionContract.offeree.familyName}`,
      addressStreet: item.clientSubscriptionContract.offeree.addressStreet,
      addressPostalCode: item.clientSubscriptionContract.offeree.addressPostalCode,
      email: item.clientSubscriptionContract.offeree.email,
      document: null,
      originalStatus: item.status?.code,
      statusMessage: item.status?.message,
      subscriptionName: item.name,
      files: item.files
    };
  }
}
