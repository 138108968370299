import { Injectable, OnDestroy } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakEventType, KeycloakService } from "keycloak-angular";
import { Subject } from "rxjs";
import { filter, switchMap, take, takeUntil } from "rxjs/operators";
import {
  ERROR_MESSAGE,
  LoginErrorDialogComponent
} from "src/app/common/components/login-error-dialog/login-error-dialog.component";
import { UserInfoDto } from "src/app/common/dto/user-info";
import { LocaleCode } from "src/app/common/enums/locale-code";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectKeycloakUserInfo, selectUserInfo } from "src/app/ngrx/selectors/app.selectors";
import { DialogsService } from "./dialogs.service";

@Injectable({ providedIn: "root" })
export class AuthErrorService implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();

  constructor(
    private readonly keycloak: KeycloakService,
    private readonly store: Store<RootState>,
    private readonly dialogsService: DialogsService,
    private readonly translate: TranslateService
  ) {}

  public init() {
    this.store
      .pipe(
        select(selectKeycloakUserInfo),
        filter(Boolean),
        take(1),
        switchMap(() => this.keycloak.keycloakEvents$),
        filter(({ type }) => [KeycloakEventType.OnAuthRefreshError, KeycloakEventType.OnAuthError].includes(type)),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.keycloak.logout();
      });

    // check for errors at keycloak init
    this.keycloak.keycloakEvents$
      .pipe(
        filter(({ type }) => [KeycloakEventType.OnAuthRefreshError, KeycloakEventType.OnAuthError].includes(type)),
        switchMap(() => this.translate.use(LocaleCode.de_DE)),
        // only for init
        takeUntil(this.store.pipe(select(selectKeycloakUserInfo), filter(Boolean))),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.dialogsService.open(LoginErrorDialogComponent, {
          data: ERROR_MESSAGE.BROWSER_STRICTION,
          maxWidth: "700px",
          disableClose: true,
          autoFocus: false
        });
      });

    // check if user hasn't any permissions
    this.store
      .pipe(select(selectUserInfo), filter(Boolean), takeUntil(this.destroy$))
      .subscribe((userInfo: UserInfoDto) => {
        const hasPermissions = Object.values(userInfo.permissions?.scoped || {}).some(
          (permissions) => permissions.length
        );
        if (!hasPermissions) {
          this.dialogsService.open(LoginErrorDialogComponent, {
            data: ERROR_MESSAGE.MISSING_PERMISSON,
            maxWidth: "700px",
            disableClose: true,
            autoFocus: false
          });
        }
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
