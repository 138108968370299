<div
  class="kpi-badge"
  data-cy="kpiBadge"
  [ngSwitch]="valueType"
  [ngStyle]="{
    'background-color': badge.style?.backgroundColor,
    color: badge.style?.color,
    'font-weight': badge.style?.fontWeight
  }"
  [ene-tooltip]="badge?.tooltip?.key | translate: badge?.tooltip?.params"
  [placement]="'right'"
  [display]="!!badge.tooltip"
>
  <ng-container *ngSwitchCase="'number'"> {{ badge.value | numberSign }}% </ng-container>
  <ng-container *ngSwitchCase="'string'">
    {{ badge.value }}
  </ng-container>
  <ng-container *ngSwitchDefault>-</ng-container>
</div>
