import { animate, state, style, transition, trigger } from "@angular/animations";

/**
 * <div [@expand]="'expanded'/'collapsed'"></div>
 */
export const EXPAND_ANIMATION = trigger("expand", [
  state("collapsed", style({ height: "0px", minHeight: "0" })),
  state("expanded", style({ height: "*" })),
  transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
]);
