import { NgFor, NgIf, NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Inject,
  Input,
  Optional
} from "@angular/core";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import {
  MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS,
  MatLegacyPaginatorDefaultOptions,
  MatLegacyPaginatorIntl,
  _MatLegacyPaginatorBase
} from "@angular/material/legacy-paginator";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { TableComponent } from "src/app/slots/three-year-plan/common/components/table/container/table.component";
import { PaginatorIntl } from "./paginator-intl";

export interface PaginatorDefaultOptions {
  pageSize: number;
  pageIndex?: number;
  length?: number;
  pageSizeOptions?: Array<number | "all">;
  hidePageSize?: boolean;
  showFirstLastButtons?: boolean;
  showPaginationAbove?: boolean;
  showPaginationBelow?: boolean;
}

@Component({
  selector: "tpm-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: ["disabled"],
  host: {
    class: "tpm-paginator",
    role: "group"
  },
  imports: [
    NgIf,
    NgFor,
    NgTemplateOutlet,
    TranslateModule,
    MatLegacySelectModule,
    MatLegacyTooltipModule,
    MatLegacyButtonModule
  ],
  providers: [
    {
      provide: MatLegacyPaginatorIntl,
      useClass: PaginatorIntl,
      deps: [TranslateService]
    }
  ]
})
export class PaginatorComponent
  extends _MatLegacyPaginatorBase<MatLegacyPaginatorDefaultOptions>
  implements AfterViewInit
{
  @ContentChild(TableComponent) readonly tableRef?: TableComponent<unknown>;

  @Input() showPaginationAbove: boolean = false;
  @Input() showPaginationBelow: boolean = true;

  @Input()
  set options(_options: PaginatorDefaultOptions) {
    if (_options) {
      this.pageSize = _options.pageSize;

      if ("pageIndex" in _options) {
        this.pageIndex = _options.pageIndex;
      }

      if ("length" in _options) {
        this.length = _options.length;
      }

      if ("pageSizeOptions" in _options) {
        this.pageSizeOptions = _options.pageSizeOptions.map((size) => (size === "all" ? 999999999 : size));
      }

      if ("hidePageSize" in _options) {
        this.hidePageSize = _options.hidePageSize;
      }

      if ("hidePageSize" in _options) {
        this.pageIndex = _options.pageIndex;
      }

      if ("showFirstLastButtons" in _options) {
        this.showFirstLastButtons = _options.showFirstLastButtons;
      }

      if ("showPaginationAbove" in _options) {
        this.showPaginationAbove = _options.showPaginationAbove;
      }

      if ("showPaginationBelow" in _options) {
        this.showPaginationBelow = _options.showPaginationBelow;
      }
    }
  }

  constructor(
    readonly intl: MatLegacyPaginatorIntl,
    readonly changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS)
    readonly defaults?: MatLegacyPaginatorDefaultOptions
  ) {
    super(intl, changeDetectorRef, defaults);
  }

  ngAfterViewInit(): void {
    if (this.tableRef) {
      this.tableRef.paginator = this;
    }
  }
}
