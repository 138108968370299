import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { CumulatedInvestDto } from "src/app/common/dto/energy/cumulated-invest";
import { EnergyService } from "../../../core/services/energy.service";
import {
  getCumulatedInvestAllYearsData,
  getCumulatedInvestAllYearsDataSuccess
} from "../../actions/energy/cumulated-invest.actions";

@Injectable()
export class CumulatedInvestEffects {
  getCumulatedInvestAllYearsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCumulatedInvestAllYearsData),
      switchMap((action) =>
        this.energyService.getCumulatedInvestAllYearsData(action).pipe(
          map((payload: Array<CumulatedInvestDto>) => getCumulatedInvestAllYearsDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private energyService: EnergyService) {}
}
