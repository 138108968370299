export interface ICumulatedInvestResponse {
  year: number;
  ons: number;
  ms: number;
  ns: number;
}

export interface ICumulatedInvestDto {
  year: number;
  localNetworkStations: number;
  lowVoltage: number;
  mediumVoltage: number;
}

export class CumulatedInvestDto implements ICumulatedInvestDto {
  public year: number;
  public localNetworkStations: number;
  public lowVoltage: number;
  public mediumVoltage: number;

  constructor(data: ICumulatedInvestResponse) {
    this.year = data.year;
    this.localNetworkStations = data.ons;
    this.lowVoltage = data.ns;
    this.mediumVoltage = data.ms;
  }
}
