import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GpCheckboxElement } from "@enersis/gp-components/gp-checkbox";
import { FormFieldControl } from "../form-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { "[attr.checked]": "_checked" }
})
export class CheckboxComponent extends FormFieldControl {
  @Input() public alignEnd: boolean = false;
  @Input() public indeterminate: boolean = false;
  @Input()
  public set checked(_checked: boolean) {
    this._checked = coerceBooleanProperty(_checked);
  }

  public _checked: boolean = false;

  public writeValue(obj: any): void {
    this._checked = typeof obj === "boolean" ? obj : this._value === obj;
    this.cdr.detectChanges();
  }

  public onChange(event: Event): void {
    event.stopPropagation();
    this.changed.emit((event.target as GpCheckboxElement).checked);
    this._onChange((event.target as GpCheckboxElement).checked);
    this._onTouched();
    this._checked = (event.target as GpCheckboxElement).checked;
  }
}
