import { createAction, props } from "@ngrx/store";
import { CapacityPerYearDto, CapacityYearDto } from "../../../common/dto/energy/capacity";

export const getCapacityData = createAction(
  "[Capacity] Get capacity data",
  props<{
    regionId: string;
    scenarioId: string;
    year: number;
  }>()
);

export const getCapacityDataSuccess = createAction(
  "[Capacity] Get capacity data success",
  props<{
    payload: CapacityYearDto;
  }>()
);

export const getCapacityAllYearsData = createAction(
  "[Capacity] Get capacity all years data",
  props<{
    regionId: string;
    scenarioId: string;
    timelineRange: [number, number];
  }>()
);

export const getCapacityDataAllYearsSuccess = createAction(
  "[Capacity] Get capacity all years data success",
  props<{
    payload: Array<CapacityPerYearDto>;
  }>()
);
