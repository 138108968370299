import { select, Selector, SelectorWithProps, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { RootState } from "src/app/ngrx/root-reducers";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-undef
export const takeLatestFrom = (
  store: Store<RootState>,
  selector: Selector<RootState, any> | SelectorWithProps<RootState, Record<string, any>, any>,
  props?: Record<string, any>
): any => {
  let state: any;
  store.pipe(select(selector, props), take(1)).subscribe((value) => (state = value));
  return state;
};

/**
 * Returns the event count index and the incomming value
 */
export const counter =
  () =>
  <T>(source: Observable<T>): Observable<{ index: number; value: T }> => {
    let index = 0;
    return new Observable((subscriber) => {
      source.subscribe({
        next(value: T) {
          subscriber.next({ index: index++, value });
        },
        error(error: any) {
          subscriber.error(error);
        },
        complete() {
          subscriber.complete();
        }
      });
    });
  };
