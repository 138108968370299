import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";
import { NotificationSettingsCellComponent } from "../components/notification-settings-cell/notification-settings-cell.component";

const propertyAccessorFactory = (column) => (data: any, id: string) => {
  return data[column];
};

export const notificationSettingsColumns: Array<ITableColumn> = [
  {
    id: "municipality",
    key: "NOTIFICATION_SERVICE.TABLE_COLUMNS.ALL_MUNICIPALITIES",
    type: "text"
  },
  {
    id: "email",
    key: "NOTIFICATION_SERVICE.TABLE_COLUMNS.EMAIL",
    type: "custom",
    customTemplate: NotificationSettingsCellComponent,
    propertyAccessor: (data, id) => data[id]
  },
  {
    id: "phone",
    key: "NOTIFICATION_SERVICE.TABLE_COLUMNS.SMS",
    type: "custom",
    customTemplate: NotificationSettingsCellComponent,
    propertyAccessor: (data, id) => data[id]
  },
  {
    id: "edit",
    key: "",
    type: "action",
    actionIcon: "ec_edit",
    columnStyles: "grey",
    style: {
      minWidth: "3rem",
      maxWidth: "3rem"
    },
    stickyEnd: true
  }
];
