import { ChangeDetectionStrategy, Component, Inject, Optional } from "@angular/core";
import { TABLE_CUSTOM_TEMPLATE_CONTEXT } from "src/app/common/components/table/tokens/table-custom-template-context";

@Component({
  selector: "app-notification-settings-typ-cell",
  styleUrls: ["./notification-settings-typ-cell.component.scss"],
  templateUrl: "./notification-settings-typ-cell.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsTYPCellComponent {
  public badges = [
    { class: "primary", type: "intermediate", label: "APP.NETZEBW", key: "NOTIFICATION_SERVICE.NETZEBW_DESCRIPTION" },
    {
      class: "secondary",
      type: "start",
      label: "APP.MUNICIPALITY",
      key: "NOTIFICATION_SERVICE.MUNICIPALITY_DESCRIPTION"
    },
    {
      class: "documents",
      type: "dataStorage",
      label: "NOTIFICATION_SERVICE.DOCUMENTS"
    }
  ];

  constructor(@Optional() @Inject(TABLE_CUSTOM_TEMPLATE_CONTEXT) public readonly context: { id: string; row: any }) {
    this.badges = this.badges.filter(({ type }) => context.row.notifyByEmail?.[type]);
  }
}
