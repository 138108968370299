import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";
import { RegionCellComponent } from "../../cells/region-cell.component";

export const municipalitiesTableConfig: { columns: Array<ITableColumn>; nestedColumns: Array<ITableColumn> } = {
  columns: [
    {
      id: "regionName",
      type: "custom",
      key: "USER_SETTINGS.MUNICIPALITY",
      style: {
        width: "14%"
      },
      customTemplate: RegionCellComponent
    },
    {
      id: "internal",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.INTERNAL",
      style: {
        width: "10%"
      }
    },
    {
      id: "activeIntEmailCount",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_EMAIL_CNT_ELECTRICITY",
      style: {
        width: "8%"
      }
    },
    {
      id: "activeIntEmailCountGas",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_EMAIL_CNT_GAS",
      style: {
        width: "8%"
      }
    },
    {
      id: "activeIntSmsCount",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_SMS_CNT_ELECTRICITY",
      style: {
        width: "8%"
      }
    },
    {
      id: "activeIntSmsCountGas",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_SMS_CNT_GAS",
      style: {
        width: "8%",
        "border-right-width": "1px",
        "border-right-style": "solid"
      }
    },
    {
      id: "external",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.EXTERNAL",
      style: {
        width: "10%"
      }
    },
    {
      id: "activeExtEmailCount",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_EMAIL_CNT_ELECTRICITY",
      style: {
        width: "8%"
      }
    },
    {
      id: "activeExtEmailCountGas",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_EMAIL_CNT_GAS",
      style: {
        width: "8%"
      }
    },
    {
      id: "activeExtSmsCount",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_SMS_CNT_ELECTRICITY",
      style: {
        width: "8%"
      }
    },
    {
      id: "activeExtSmsCountGas",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.ACTIVE_SMS_CNT_GAS",
      style: {
        width: "8%"
      }
    },
    {
      id: "expand",
      type: "action",
      actionIcon: "ec_networks_mix_addrow",
      selectedIcon: "ec_networks_mix_deleterow",
      key: "",
      style: {
        "text-align": "center"
      }
    }
  ],
  nestedColumns: [
    {
      id: "name",
      type: "text",
      key: "USER_SETTINGS.NAME",
      style: {
        width: "18%"
      }
    },
    {
      id: "role",
      type: "text",
      key: "USER_SETTINGS.ROLE",
      style: {
        width: "6%"
      }
    },
    {
      id: "email",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.EMAIL",
      style: {
        width: "18%"
      }
    },
    {
      id: "emailConfig",
      type: "notification-settings",
      key: "USER_SETTINGS.LIST_USERS.EMAIL_CONFIG_ELECTRICITY",
      style: {
        width: "10%"
      }
    },
    {
      id: "emailConfigGas",
      type: "notification-settings",
      key: "USER_SETTINGS.LIST_USERS.EMAIL_CONFIG_GAS",
      style: {
        width: "10%"
      }
    },
    {
      id: "phone",
      type: "text",
      key: "USER_SETTINGS.LIST_USERS.PHONE",
      style: {
        width: "18%"
      }
    },
    {
      id: "phoneConfig",
      type: "notification-settings",
      key: "USER_SETTINGS.LIST_USERS.SMS_CONFIG_ELECTRICITY",
      style: {
        width: "10%"
      }
    },
    {
      id: "phoneConfigGas",
      type: "notification-settings",
      key: "USER_SETTINGS.LIST_USERS.SMS_CONFIG_GAS",
      style: {
        width: "10%"
      }
    }
  ]
};
