import { ChangeDetectionStrategy, Component, Inject, Optional } from "@angular/core";
import { TABLE_CUSTOM_TEMPLATE_CONTEXT } from "src/app/common/components/table/tokens/table-custom-template-context";
import { accountingBadges } from "../../constants/accounting-badges";
import { NEWS_BADGES } from "../../constants/news-badges";

@Component({
  selector: "app-notification-settings-badge-cell",
  templateUrl: "./notification-settings-badge-cell.component.html",
  styleUrls: ["./notification-settings-badge-cell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsBadgeCellComponent {
  public badges = [
    ...Object.values(accountingBadges),
    ...Object.values(NEWS_BADGES)
  ];

  constructor(@Optional() @Inject(TABLE_CUSTOM_TEMPLATE_CONTEXT) public readonly context: { id: string; row: any }) {
    this.badges = this.badges.filter(({ id }) => context.row.notifyByEmail?.[id]);
  }
}
