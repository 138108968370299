import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import "@enersis/gp-components/gp-dropzone";
import { DropzoneComponent } from "./dropzone.component";

@NgModule({
  declarations: [DropzoneComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [DropzoneComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DropzoneModule {}
