import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { ConsumptionDto } from "../../../common/dto/energy/consumption";
import { EnergyService } from "../../../core/services/energy.service";
import { getConsumptionData, getConsumptionDataSuccess } from "../../actions/energy/consumption.actions";

@Injectable()
export class ConsumptionEffects {
  getConsumptionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConsumptionData),
      switchMap((action) =>
        this.energyService.getConsumptionData(action).pipe(
          map((payload: ConsumptionDto) => getConsumptionDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private energyService: EnergyService) {}
}
