import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppConfig } from "src/app/core/helpers/app.config";
import { RootState } from "src/app/ngrx/root-reducers";
import { LeadInterface } from "./lead.interface";

@Injectable()
export class LeadService {
  constructor(private readonly http: HttpClient, private readonly store: Store<RootState>) {}

  public submit(data: LeadInterface) {
    return this.http.post(`${AppConfig.connection.infrastructure.userSettings}/module/lead`, data);
  }
}
