<div
  class="campus-one-module-order-container__form-card__internal-container"
  *ngIf="orderFormContent$ | async as orderFormContent"
>
  <p class="headline-1">{{ orderFormContent.headline }}</p>
  <div class="campus-one-module-order-container__form-card__coin-container">
    <tpm-coin [text]="orderFormContent.price + '&nbsp;€'"></tpm-coin>
    <div class="html-translate" [innerHTML]="orderFormContent.condition | trustHtml"></div>
  </div>
  <div class="html-translate" [innerHTML]="orderFormContent.offer | trustHtml"></div>
  <form class="campus-one-module-order-container__form-card__form-container" [formGroup]="formGroup">
    <tpm-select
      class="pronouns-select"
      appearance="outline"
      [label]="'Anrede' | translate"
      formControlName="pronouns"
      [options]="options"
    ></tpm-select>
    <tpm-textfield
      *ngFor="let textfield of textFieldList; trackBy: trackByName"
      outlined
      [label]="textfield.label | translate"
      [formControlName]="textfield.formControlName"
    ></tpm-textfield>
    <p class="body-3">{{ "ORDER_MODULE.FIELDS_REQUIRED_EXPLANATION" | translate }}</p>
    <div *ngIf="orderFormContent.isCampusOne">
      <p class="headline-5">{{ "ORDER_MODULE.PLAN_TYPE_HEADLINE" | translate }}</p>
      <div class="campus-one-module-order-container__form-card__radios-container">
        <tpm-radio
          *ngFor="let plan of planList$ | async"
          [label]="plan.label"
          [value]="plan.id"
          formControlName="planId"
        ></tpm-radio>
      </div>
    </div>
    <div *ngIf="orderFormContent.agb" class="agreement-checkbox-wrapper">
      <tpm-checkbox density="small" formControlName="agreement"></tpm-checkbox>
      <p
        class="body-3"
        [innerHTML]="
          'ORDER_MODULE.CHECKBOX_AGREEMENT_LABEL'
            | translate : { url: orderFormContent.agb, ModuleName: orderFormContent.moduleName }
        "
      ></p>
    </div>
    <p
      *ngIf="orderFormContent.privacyPolicy"
      class="body-3"
      [innerHTML]="
        'ORDER_MODULE.READ_AGREEMENT'
          | translate : { url: orderFormContent.privacyPolicy, ModuleName: orderFormContent.moduleName }
      "
    ></p>
    <button
      ene-filled-button
      class="submit-button"
      color="primary"
      size="large"
      [disabled]="formGroup.invalid"
      (click)="submit()"
    >
      {{ submitButtonLabel$ | async | translate }}
    </button>
  </form>
</div>
