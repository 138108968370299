import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule } from "@angular/material/legacy-table";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { MatSortModule } from "@angular/material/sort";
import { EneIconModule } from "@enersis/ui-icon";
import { TranslateModule } from "@ngx-translate/core";
import { CommonDirectivesModule } from "../../directives/directives.module";
import { PipesModule } from "../../pipes/pipes.module";
import { SkeletonLoaderModule } from "../skeleton-loader/skeleton-loader.module";
import { TableComponent } from "./components/container/table.component";
import { StatusDescriptionComponent } from "./components/status-description/status-description.component";
import { TableCellComponent } from "./components/table-cells/table-cell/table-cell.component";
import { TableCombinedValuesCellComponent } from "./components/table-cells/table-combined-value-cell/table-combined-values-cell.component";
import { TableFilterComponent } from "./components/table-filter/table-filter.component";
import { TablePaginatorComponent } from "./components/table-paginator/table-paginator.component";
import { TableTemplateTransformPipe } from "./pipes/table-template-transform.pipe";

const materialModules = [
  MatLegacyTableModule,
  MatLegacyFormFieldModule,
  MatLegacyInputModule,
  MatLegacyPaginatorModule,
  MatSortModule,
  MatLegacyTooltipModule,
  MatLegacyButtonModule,
  MatLegacyCheckboxModule
];

const pipes = [TableTemplateTransformPipe];

const components = [
  TableComponent,
  TableCellComponent,
  TableCombinedValuesCellComponent,
  TableFilterComponent,
  TablePaginatorComponent,
  StatusDescriptionComponent
];

@NgModule({
  declarations: [...pipes, ...components],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    EneIconModule,
    CommonDirectivesModule,
    PipesModule,
    SkeletonLoaderModule,
    ...materialModules
  ],
  exports: [...pipes, ...components]
})
export class TableModule {}
