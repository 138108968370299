import { DASH } from "src/app/slots/outage-monitoring/constants/utils";
import { TableColumnConfig } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { SELECT_COLORS, STATUS_COLORS } from "../constants/colors-maps.constants";
import { CampusOneAdminSubscription } from "./campus-one-admin-view.interfaces";

export const isNewSubscription = (row: CampusOneAdminSubscription) =>
  !row.maxUsers && row.status === "SUBSCRIPTION_NEW";

export const CAMPUS_ONE_ADMIN_VIEW_COLUMN_CONFIGS: ReadonlyArray<TableColumnConfig> = [
  {
    id: "displayName",
    key: "Modul",
    type: "text"
  },
  {
    id: "contractTerm",
    key: "Vertragslaufzeit",
    type: "text",
    style: {
      width: "170px"
    }
  },
  {
    id: "addressCity",
    key: "Kommune",
    type: "text"
  },
  {
    id: "customer",
    key: "Gemeindeschlüssel",
    type: "text"
  },
  {
    id: "maxUsers",
    key: "Mitarbeiter",
    type: "text",
    translateAccessor: (row: CampusOneAdminSubscription) =>
      row.maxUsers ? `Bis ${row.maxUsers}` : `Über ${row.maxUsers ?? 30}`
  },
  {
    id: "status",
    key: "Modulstatus",
    type: "select",
    sortDisabled: true,
    options: [
      {
        value: "ACTIVE",
        label: "aktiv"
      },
      {
        value: "CANCELED",
        label: "inaktiv"
      }
    ],
    style: {
      width: "220px"
    },
    styleAccessor: (row: CampusOneAdminSubscription) => ({
      color: SELECT_COLORS[row.status],
      "--ene-color-grey-700": SELECT_COLORS[row.status]
    }),
    valueAccessor: (row: CampusOneAdminSubscription) => (isNewSubscription(row) ? "CANCELED" : row.status)
  },
  {
    id: "fullName",
    key: "Vertragspartner",
    type: "text"
  },
  {
    id: "addressStreet",
    key: "Straße, Hausnummer",
    type: "text"
  },
  {
    id: "addressPostalCode",
    key: "PLZ, Ort",
    type: "text"
  },
  {
    id: "email",
    key: "E-Mail",
    type: "text"
  },
  {
    id: "document",
    key: "Rechnung vorhanden",
    type: "text",
    style: {
      width: "80px"
    },
    translateAccessor: (row: any) => (row.document ? "ja" : DASH)
  },
  {
    id: "originalStatus",
    key: "Vertragsstatus",
    type: "text",
    style: {
      width: "140px"
    },
    translateAccessor: (row: CampusOneAdminSubscription) => `CAMPUS_ONE_ADMIN_VIEW.STATUS.${row.status}`,
    styleAccessor: (row: CampusOneAdminSubscription) => ({
      color: STATUS_COLORS[row.status]
    })
  },
  {
    id: "menuAction",
    key: "",
    type: "menu",
    sortDisabled: true,
    style: {
      width: "40px"
    },
    optionsAccessor: (row: CampusOneAdminSubscription) => [
      {
        value: "upload",
        label: "Upload Rechnung"
      },
      {
        value: "download",
        label: "Download Rechnung",
        disabled: !row.files?.length
      },
      {
        value: "delete",
        label: "Rechnung löschen",
        disabled: !row.files?.length
      }
    ]
  }
];
