export interface IConsumptionDto {
  electricity: number;
  gas: number;
}

export class ConsumptionDto implements IConsumptionDto {
  public electricity: number;
  public gas: number;

  constructor(data: IConsumptionDto) {
    this.electricity = data.electricity;
    this.gas = data.gas;
  }
}
