import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { CheckboxFieldComponent } from "./checkbox-field.component";

@NgModule({
  declarations: [CheckboxFieldComponent],
  imports: [ReactiveFormsModule, MatLegacyCheckboxModule],
  exports: [CheckboxFieldComponent]
})
export class CheckboxFieldModule {}
