import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { FormFieldControl } from "../form-field-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-checkbox-field",
  templateUrl: "./checkbox-field.component.html",
  styleUrls: ["./checkbox-field.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxFieldComponent extends FormFieldControl {
  @Input() public name: string | null = null;

  public registerOnChange(fn: any): void {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      fn(value);
    });
  }
}
