import { Action, createReducer, on } from "@ngrx/store";
import { ICapacityPerYearDto, ICapacityYearDto } from "../../../common/dto/energy/capacity";
import * as CapacityActions from "../../actions/energy/capacity.actions";

export interface State {
  capacityYear: ICapacityYearDto;
  capacityAllYears: Array<ICapacityPerYearDto>;
}

const initialState: State = {
  capacityYear: {
    eMobility: undefined,
    heating: undefined,
    photovoltaics: undefined
  },
  capacityAllYears: undefined
};

const capacityReducer = createReducer(
  initialState,
  on(CapacityActions.getCapacityData, (state) => ({
    ...state,
    capacityYear: {
      eMobility: undefined,
      heating: undefined,
      photovoltaics: undefined
    }
  })),
  on(CapacityActions.getCapacityDataSuccess, (state, { payload }) => ({ ...state, capacityYear: payload })),
  on(CapacityActions.getCapacityAllYearsData, (state) => ({ ...state, capacityAllYears: undefined })),
  on(CapacityActions.getCapacityDataAllYearsSuccess, (state, { payload }) => ({ ...state, capacityAllYears: payload }))
);

export function reducer(state: State | undefined, action: Action): State {
  return capacityReducer(state, action);
}
