<form name="contactSettingsForm" [formGroup]="form">
  <mat-form-field class="input">
    <mat-label>Rolle</mat-label>
    <mat-select formControlName="role">
      <mat-option *ngFor="let option of roles" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>Anrede</mat-label>
    <mat-select formControlName="salutation">
      <mat-option *ngFor="let option of salutationOptions" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>Vorname</mat-label>
    <input matInput formControlName="givenName" />
    <mat-icon matSuffix>mode_edit</mat-icon>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>Nachname</mat-label>
    <input matInput formControlName="familyName" />
    <mat-icon matSuffix>mode_edit</mat-icon>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>E-Mail</mat-label>
    <input matInput formControlName="email" />
    <mat-icon matSuffix>mode_edit</mat-icon>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>Adresse</mat-label>
    <input matInput formControlName="address" />
    <mat-icon matSuffix>mode_edit</mat-icon>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>Mobilfunknummer</mat-label>
    <input matInput formControlName="phoneNumber" />
    <mat-icon matSuffix>mode_edit</mat-icon>
  </mat-form-field>
</form>
