import { TableTemplate } from "../types/table-template";

export const TABLE_TEMPLATES = {
  Text: "text",
  Value: "value",
  List: "list",
  Date: "date",
  Action: "action",
  Icon: "icon",
  State: "state",
  Image: "image",
  Badge: "badge",
  Button: "button",
  NotificationSettings: "notification-settings",
  CombinedValues: "combined-values",
  Custom: "custom"
} as const;

export const TABLE_COMPONENT_TAMPLATES: ReadonlyArray<TableTemplate> = [
  TABLE_TEMPLATES.Icon,
  TABLE_TEMPLATES.State,
  TABLE_TEMPLATES.Image,
  TABLE_TEMPLATES.Action,
  TABLE_TEMPLATES.CombinedValues,
  TABLE_TEMPLATES.Custom
];
