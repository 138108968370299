import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { DualRolesService } from "../services/dual-roles.service";

export const canActivateDualRolesPortalGuard: CanActivateFn = async () => {
  const dualRolesService = inject(DualRolesService);
  const router = inject(Router);
  const params = await firstValueFrom(dualRolesService.getParams());
  const hasDualRole = params.hasKommunalplattformAccess && params.hasPngAccess;
  if (hasDualRole) {
    return true;
  }

  // no dual roles, redirect to root
  router.navigate(["/"]);
  return false;
};
