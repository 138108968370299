import { Injectable } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Asset } from "contentful";
import { filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { AdminModulePlanDto } from "src/app/common/dto/admin-module/plan";
import { AdminModulePronouns } from "src/app/common/dto/admin-module/subscription";
import { OrderFormEntry } from "src/app/core/interfaces/contentful";
import { DynamicSnackBar } from "src/app/core/material/snack-bar/snack-bar";
import { AdminDataService } from "src/app/core/services/admin-data.service";
import { CampusOneDataService } from "src/app/core/services/marketplace/campus-one-data.service";
import { takeLatestFrom } from "src/app/core/utils/ngrx";
import { formatCurrencyDE } from "src/app/core/utils/number/currency";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectRegionAgs, selectRegionName, selectUserInfo } from "src/app/ngrx/selectors/app.selectors";
import { OrderModuleDialogComponent } from "./order-module-dialog.component";

export interface CampusOneOrderModuleState {
  formData: {
    givenName: string;
    familyName: string;
    name: string;
    email: string;
    addressCity: string;
    customer: string;
    planId?: string;
  };
  planList: {
    result: Array<AdminModulePlanDto>;
    loading: boolean;
  };
  orderFormContent: {
    result: OrderFormEntry;
    loading: boolean;
  };
}

export interface FormSubmitBody {
  moduleId: string;
  pronouns: AdminModulePronouns;
  address: string;
  addressPostalCode: string;
  planId: string;
}

const RADIO_BUTTONS_MAP = {
  // eslint-disable-next-line quote-props
  "30": (pricePerUnit: number, priceCurrencyCode: string) =>
    `bis zu 30 Mitarbeiter*innen (${formatCurrencyDE(pricePerUnit, priceCurrencyCode)} pro Jahr)`,
  default: () => "mehr als 30 Mitarbeiter*innen (individuelles Angebot)"
};

@Injectable()
export class CampusOneOrderModuleComponentStore extends ComponentStore<CampusOneOrderModuleState> {
  public readonly formData$ = this.select((state) => state.formData);
  public readonly planList$ = this.select((state) => state.planList.result).pipe(
    filter((planList) => planList !== undefined),
    map((planList) =>
      planList.map((plan) => ({
        ...plan,
        label: RADIO_BUTTONS_MAP[plan.options.maxUsers || "default"](plan.pricePerUnit, plan.priceCurrencyCode)
      }))
    )
  );
  public readonly coinPrice$ = this.planList$.pipe(
    map((planList) => {
      const _plan = planList.find((plan) => plan.options.maxUsers);
      return _plan?.options.maxUsers ? formatCurrencyDE(_plan.pricePerUnit, _plan.priceCurrencyCode) : "";
    })
  );
  public readonly orderFormContent$ = this.select((state) => state.orderFormContent.result).pipe(
    map((orderForm) =>
      orderForm
        ? {
            moduleId: orderForm.fields.moduleId,
            moduleName: this.translate.instant(`ORDER_MODULE.MODULE_NAME.${orderForm.fields.moduleId}`),
            headline: orderForm.fields.headline,
            price: orderForm.fields.price,
            condition: documentToHtmlString(orderForm.fields.condition as any),
            offer: documentToHtmlString(orderForm.fields.offer as any),
            logo: orderForm.fields.logo
              ? {
                  fileName: (orderForm.fields.logo.fields as any).file.fileName,
                  url: (orderForm.fields.logo.fields as any).file.url,
                  contentType: (orderForm.fields.logo.fields as any).file.contentType
                }
              : null,
            productHeadline: orderForm.fields.productHeadline,
            productDescription: documentToHtmlString(orderForm.fields.productDescription as any),
            productMedia:
              (orderForm.fields.productMedia as any as Array<Asset>)?.map((media) => ({
                fileName: media.fields.file.fileName,
                url: media.fields.file.url,
                contentType: media.fields.file.contentType
              })) ?? [],
            agb: orderForm.fields.agb,
            privacyPolicy: orderForm.fields.privacyPolicy
          }
        : null
    )
  );

  public readonly updatePlanId = this.updater((state, plans: Array<AdminModulePlanDto>) => ({
    ...state,
    formData: {
      ...state.formData,
      planId: plans.find(({ options }) => Number(options?.maxUsers) > 0)?.id || null
    }
  }));

  public readonly fetchPlanList = this.effect<void>((trigger$) =>
    trigger$.pipe(
      tap(() => this.patchState({ planList: { result: [], loading: true } })),
      switchMap(() =>
        this.adminDataService.getPlanList(this.adminDataService.getApplicationModule("module-00015")).pipe(
          tapResponse(
            (result) => {
              this.patchState({ planList: { result, loading: false } });
              this.updatePlanId(result);
            },
            () => this.patchState({ planList: { result: [], loading: false } })
          )
        )
      )
    )
  );

  public readonly setOrderForm = this.effect<{ orderForm: OrderFormEntry }>((params$) =>
    params$.pipe(
      tap(({orderForm}) => this.patchState({ orderFormContent: { result: orderForm, loading: false } }))
    )
  );

  public readonly submit = this.effect<{
    body: FormSubmitBody;
    dialogRef: MatLegacyDialogRef<OrderModuleDialogComponent>;
  }>((params$) =>
    params$.pipe(
      withLatestFrom(this.formData$),
      switchMap(([{ body, dialogRef }, formData]) =>
        this.adminDataService
          .postSubscription({
            parent: this.adminDataService.parent,
            applicationModule: body.moduleId,
            customer: formData.customer,
            vendor: "netzebw",
            planId: body.planId,
            offeree: {
              pronouns: body.pronouns,
              givenName: formData.givenName,
              familyName: formData.familyName,
              email: formData.email,
              addressStreet: body.address.split(",")[0]?.trim(),
              addressHouseNumber: body.address.split(",")[1]?.trim(),
              addressPostalCode: body.addressPostalCode,
              addressCity: formData.addressCity
            }
          })
          .pipe(
            tapResponse(
              () => {
                dialogRef.close(true);
                this.snackbar.success("Ihre Produktanfrage wurde an uns übermittelt.");
              },
              (error: string) => {
                this.snackbar.error(error, undefined, undefined, false);
              }
            )
          )
      )
    )
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly snackbar: DynamicSnackBar,
    private readonly adminDataService: AdminDataService,
    private readonly campusOneDataService: CampusOneDataService,
    private readonly translate: TranslateService
  ) {
    super({
      formData: {
        givenName: null,
        familyName: null,
        name: null,
        email: null,
        addressCity: null,
        customer: null,
        planId: null
      },
      planList: {
        result: [],
        loading: false
      },
      orderFormContent: {
        result: null,
        loading: false
      }
    });

    const userInfo = takeLatestFrom(this.store, selectUserInfo);
    const addressCity = takeLatestFrom(this.store, selectRegionName);
    const customer = takeLatestFrom(this.store, selectRegionAgs);

    this.setState((state) => ({
      ...state,
      formData: {
        givenName: userInfo.given_name,
        familyName: userInfo.family_name,
        name: userInfo.name,
        email: userInfo.email,
        addressCity,
        customer
      }
    }));
  }
}
