import { Action, createReducer, on } from "@ngrx/store";
import { EMobilityConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/e-mobility";
import { ElectricityConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/electricity";
import { GasConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/gas";
import { HeatpumpConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/heatpump";
import { PhotovoltaicsConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/photovoltaics";
import * as ConnectionGroupsActions from "../../actions/energy/connection-groups.actions";

export interface State {
  electricity: Array<ElectricityConnectionGroupsDto>;
  gas: Array<GasConnectionGroupsDto>;
  eMobility: Array<EMobilityConnectionGroupsDto>;
  photovoltaics: Array<PhotovoltaicsConnectionGroupsDto>;
  heatpump: Array<HeatpumpConnectionGroupsDto>;
}

const initialState: State = {
  electricity: null,
  gas: null,
  eMobility: null,
  photovoltaics: null,
  heatpump: null
};

const connectionGroupsReducer = createReducer(
  initialState,
  on(ConnectionGroupsActions.getElectricityConnectionGroupsDataSuccess, (state, { payload }) => ({
    ...state,
    electricity: payload
  })),
  on(ConnectionGroupsActions.clearElectricityConnectionGroupsData, (state) => ({ ...state, electricity: null })),
  on(ConnectionGroupsActions.getGasConnectionGroupsDataSuccess, (state, { payload }) => ({ ...state, gas: payload })),
  on(ConnectionGroupsActions.clearGasConnectionGroupsData, (state) => ({ ...state, gas: null })),
  on(ConnectionGroupsActions.getEMobilityConnectionGroupsDataSuccess, (state, { payload }) => ({
    ...state,
    eMobility: payload
  })),
  on(ConnectionGroupsActions.clearEMobilityConnectionGroupsData, (state) => ({ ...state, eMobility: null })),
  on(ConnectionGroupsActions.getPhotovoltaicsConnectionGroupsDataSuccess, (state, { payload }) => ({
    ...state,
    photovoltaics: payload
  })),
  on(ConnectionGroupsActions.clearPhotovoltaicsConnectionGroupsData, (state) => ({ ...state, photovoltaics: null })),
  on(ConnectionGroupsActions.getHeatpumpConnectionGroupsDataSuccess, (state, { payload }) => ({
    ...state,
    heatpump: payload
  })),
  on(ConnectionGroupsActions.clearHeatpumpConnectionGroupsData, (state) => ({ ...state, heatpump: null }))
);

export function reducer(state: State | undefined, action: Action): State {
  return connectionGroupsReducer(state, action);
}
