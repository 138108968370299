import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { InfrastructureService } from "src/app/core/services/infrastructure.service";
import {
  clearBuildingsConnectionGroup,
  getBuildingsConnectionGroup,
  getBuildingsConnectionGroupSuccess
} from "../actions/map.actions";
import { RootState } from "../root-reducers";
import { selectYear } from "./../selectors/app.selectors";

@Injectable()
export class MapEffects {
  getBuildingsConnectionGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuildingsConnectionGroup),
      withLatestFrom(this.store.pipe(select(selectYear))),
      switchMap(([{ id }, year]) =>
        this.infrastructureService.getBuildingsConnectionGroup(id, year).pipe(
          map((payload) => getBuildingsConnectionGroupSuccess({ payload })),
          catchError(() => of(clearBuildingsConnectionGroup()))
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly infrastructureService: InfrastructureService,
    private readonly store: Store<RootState>
  ) {}
}
