import { animate, style, transition, trigger } from "@angular/animations";

/**
 * <div @inOutAnimation *ngIf="true/false"></div>
 */
export const IN_OUT_ANIMATION = trigger("inOutAnimation", [
  transition(":enter", [
    style({ height: 0, opacity: 0 }),
    animate("300ms ease-out", style({ height: "*", opacity: 1 }))
  ]),
  transition(":leave", [style({ height: "*", opacity: 1 }), animate("300ms ease-in", style({ height: 0, opacity: 0 }))])
]);

/**
 * <div @inAnimation *ngIf="true/false"></div>
 */
export const IN_ANIMATION = trigger("inAnimation", [
  transition(":enter", [
    style({ height: 0, opacity: 0 }),
    animate("300ms ease-out", style({ height: "*", opacity: 1 }))
  ])
]);
