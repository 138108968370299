import { createAction, props } from "@ngrx/store";
import { EMobilityConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/e-mobility";
import { ElectricityConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/electricity";
import { GasConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/gas";
import { HeatpumpConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/heatpump";
import { PhotovoltaicsConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/photovoltaics";

export interface IConnectionGroupsProps {
  connectionGroupIds: Array<string>;
  year: number;
  scenarioId: string;
}

export const getElectricityConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Get electricity connection groups data",
  props<IConnectionGroupsProps>()
);

export const getElectricityConnectionGroupsDataSuccess = createAction(
  "[Energy / Connection Groups] Get electricity connection groups data successfully",
  props<{ payload: Array<ElectricityConnectionGroupsDto> }>()
);

export const clearElectricityConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Clear electricity connection groups"
);

export const getGasConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Get gas connection groups data",
  props<IConnectionGroupsProps>()
);

export const getGasConnectionGroupsDataSuccess = createAction(
  "[Energy / Connection Groups] Get gas connection groups data successfully",
  props<{ payload: Array<GasConnectionGroupsDto> }>()
);

export const clearGasConnectionGroupsData = createAction("[Energy / Connection Groups] Clear gas connection groups");

export const getEMobilityConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Get e-mobility connection groups data",
  props<IConnectionGroupsProps>()
);

export const getEMobilityConnectionGroupsDataSuccess = createAction(
  "[Energy / Connection Groups] Get e-mobility connection groups data successfully",
  props<{ payload: Array<EMobilityConnectionGroupsDto> }>()
);

export const clearEMobilityConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Clear e-mobility connection groups"
);

export const getPhotovoltaicsConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Get photovoltaics connection groups data",
  props<IConnectionGroupsProps>()
);

export const getPhotovoltaicsConnectionGroupsDataSuccess = createAction(
  "[Energy / Connection Groups] Get photovoltaics connection groups data successfully",
  props<{ payload: Array<PhotovoltaicsConnectionGroupsDto> }>()
);

export const clearPhotovoltaicsConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Clear photovoltaics connection groups"
);

export const getHeatpumpConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Get heatpump connection groups data",
  props<IConnectionGroupsProps>()
);

export const getHeatpumpConnectionGroupsDataSuccess = createAction(
  "[Energy / Connection Groups] Get heatpump connection groups data successfully",
  props<{ payload: Array<HeatpumpConnectionGroupsDto> }>()
);

export const clearHeatpumpConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Clear heatpump connection groups"
);

export const clearConnectionGroupsData = createAction(
  "[Energy / Connection Groups] Clear connection groups stored data"
);
