import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatIconRegistry } from "@angular/material/icon";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, iif } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { contactFormLabels, contactFormOptions, occuredErrorsOptions } from "src/app/common/constants/contact-form";
import { ContactTemplate } from "src/app/common/enums/dialogs/contact-templates";
import { IContactPerson } from "src/app/core/interfaces/project-entity";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { postContactData } from "src/app/ngrx/actions/app.actions";
import { closeAllDialogs } from "src/app/ngrx/actions/dialogs.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectRegionContactPerson, selectRegionServiceContactPerson } from "src/app/ngrx/selectors/app.selectors";
import { selectLaunchpadOverlay } from "src/app/ngrx/selectors/overlay.selectors";
import { IButtonToggleItem } from "../../button-toggle/button-toggle.component";

interface IIconConfig {
  name: string;
  path: string;
  url: string;
}

@Component({
  selector: "app-contact-dialog",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"]
})
export class ContactDialogComponent implements OnInit, OnDestroy {
  public id: ContactTemplate;
  public contactForm: FormGroup;
  public person$: Observable<IContactPerson>;
  public labels = contactFormLabels;
  public options = contactFormOptions;
  public templates = ContactTemplate;
  public occuredErrorsSelect = occuredErrorsOptions;

  public hasSubmitMessage: boolean;

  public tabs: ReadonlyArray<IButtonToggleItem> = [
    {
      value: ContactTemplate.CONTACTS,
      label: "CONTACT_DIALOG.BUTTON_TOGGLE.contacts"
    },
    {
      value: ContactTemplate.PLATFORM,
      label: "CONTACT_DIALOG.BUTTON_TOGGLE.platform"
    },
    {
      value: ContactTemplate.NETZEBW,
      label: "CONTACT_DIALOG.BUTTON_TOGGLE.netzeBw"
    }
  ];

  public socialMediaIcons: ReadonlyArray<IIconConfig> = [
    { name: "twitter", path: "/assets/images/social-media/twitter.svg", url: "https://twitter.com/netzebw?lang=de" },
    {
      name: "youtube",
      path: "/assets/images/social-media/youtube.svg",
      url: "https://www.youtube.com/channel/UC1IZ9Ybcn0qAhg2HOIYqWMQ/featured"
    },
    {
      name: "xing",
      path: "/assets/images/social-media/xing.svg",
      url: "https://www.xing.com/companies/netzebwgmbh-einunternehmenderenbwag"
    },
    {
      name: "linkedin",
      path: "/assets/images/social-media/linkedin.svg",
      url: "https://www.linkedin.com/company/netze-bw-gmbh/"
    },
    {
      name: "instagram",
      path: "/assets/images/social-media/instagram.svg",
      url: "https://www.instagram.com/netze_bw/?hl=de"
    }
  ];
  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public data: { id: ContactTemplate },
    private store: Store<RootState>,
    private fb: FormBuilder,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private readonly analytics: AnalyticsService,
    private readonly dialogsService: DialogsService
  ) {}

  public ngOnInit(): void {
    this.id = this.data.id || this.templates.CONTACTS;
    this.contactForm = this.getFormGroup();
    const contactPerson$ = this.store.pipe(select(selectRegionContactPerson));
    const serviceContactPerson$ = this.store.pipe(select(selectRegionServiceContactPerson));
    this.person$ = this.store.pipe(
      select(selectLaunchpadOverlay),
      switchMap((overlay) => iif(() => overlay === "plugin", serviceContactPerson$, contactPerson$)),
      map((person) => {
        const idx = person.address.search(/[\d]{5}/g);
        const postCode = idx > 0 ? person.address.slice(idx) : "";
        const address = idx > 0 ? person.address.slice(0, idx) : person.address;
        return { ...person, postCode, address };
      })
    );

    this.registerIcons();
    this.resetValues(this.id);
    this.analytics.trackContactTabClick(this.id);
  }

  public ngOnDestroy(): void {
    this.hasSubmitMessage = false;

    this.destroy$.next();
    this.destroy$.complete();
  }

  public onSubmit(): void {
    if (this.contactForm.invalid) {
      return;
    }

    this.store.dispatch(
      postContactData({
        form: this.contactForm.getRawValue(),
        requestType: this.id
      })
    );

    this.resetValues(this.id);
    this.hasSubmitMessage = true;
  }

  public get topic(): AbstractControl {
    return this.contactForm.get("topic");
  }

  public get text(): AbstractControl {
    return this.contactForm.get("text");
  }

  public openProtectionDialog(): void {
    this.store.dispatch(closeAllDialogs());
    this.dialogsService.openPolicyDialog("dataProtection");
  }

  public resetValues(id: ContactTemplate): void {
    this.id = id;
    this.hasSubmitMessage = false;

    this.contactForm.reset();
    this.contactForm.patchValue({ topic: null, text: null });
    this.analytics.trackContactTabClick(id);
  }

  private getFormGroup(): FormGroup {
    return this.fb.group({
      topic: [null, Validators.required],
      occurred: [null],
      text: ["", Validators.required]
    });
  }

  private registerIcons(): void {
    this.socialMediaIcons.forEach((item: IIconConfig) => {
      this.matIconRegistry.addSvgIcon(item.name, this.domSanitizer.bypassSecurityTrustResourceUrl(item.path));
    });
  }
}
