import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { Pure } from "src/app/common/decorators/pure";
import { TableActionColumn } from "../../../classes/table-action-column";
import { TableColumnContext } from "../../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-action-column",
  templateUrl: "./action-column.component.html",
  styleUrls: ["./action-column.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionColumnComponent extends TableActionColumn {
  @Pure
  public buttonLabel(columnConfig: TableColumnContext["columnConfig"], row: TableColumnContext["row"]): string {
    return columnConfig.actionLabelAccessor?.(row) ?? columnConfig.actionLabel;
  }

  @Pure
  public isButtonDisabled(columnConfig: TableColumnContext["columnConfig"], row: TableColumnContext["row"]): boolean {
    return "buttonDisabledAccessor" in columnConfig && columnConfig.buttonDisabledAccessor(row);
  }

  @Pure
  public tooltipText(columnConfig: TableColumnContext["columnConfig"], row: TableColumnContext["row"]): string {
    return columnConfig.tooltipTextAccessor?.(row) ?? columnConfig.tooltipText;
  }

  @Pure
  public isTooltipDisabled(columnConfig: TableColumnContext["columnConfig"], row: TableColumnContext["row"]): boolean {
    return columnConfig.tooltipDisabledAccessor?.(row) ?? !columnConfig.tooltip;
  }

  public onClick() {
    return this.isButtonDisabled(this.columnConfig, this.row)
      ? null
      : this.onTableAction({ id: this.columnConfig.id, type: "click", row: this.row, index: this.index });
  }
}
