import { IKpiItemMap } from "../components/kpi/interfaces/kpi-item";

export const kpiItemMap: IKpiItemMap = {
  population: {
    id: "population",
    icon: "ec_cockpit_population",
    title: "KPI.POPULATION"
  },
  buildingsCount: {
    id: "buildingsCount",
    icon: "ec_cockpit_buildings_count",
    title: "KPI.BUILDINGS_COUNT"
  },
  area: {
    id: "area",
    icon: "ec_cockpit_territory_area",
    title: "KPI.AREA",
    unitConfig: {
      category: "area",
      base: "m2"
    }
  },
  // Grid-connection
  electricityConsumption: {
    id: "electricityConsumption",
    icon: "ec_electr_cons",
    title: "COCKPIT.ELECTRICITY_CONSUMPTION",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  countInstallations: {
    id: "countInstallations",
    icon: "ec_currency-euro"
  },
  countConsumer: {
    id: "countConsumer",
    icon: "ec_currency-euro"
  },
  electricityFeedin: {
    id: "electricityFeedin",
    icon: "ec_stromeinspeisung",
    title: "KPI.ELECTRICITY_FEED",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  feedinConsumptionRatio: {
    id: "feedinConsumptionRatio",
    icon: "ec_selfsuff",
    title: "KPI.FEED_IN_CONSUMPTION",
    unitConfig: {
      category: "percentage",
      base: "decimal"
    }
  },
  installedCapacity: {
    id: "installedCapacity",
    icon: "ec_renewables",
    title: "KPI.RENEWABLE_SERVICE",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  installedCapacityLatestYear: {
    id: "installedCapacityLatestYear",
    icon: "ec_renewables",
    title: "KPI.INSTALLED_CAPACITY",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  achievedCo2Savings: {
    id: "achievedCo2Savings",
    icon: "ec_co2-emission",
    title: "KPI.ACHIEVED_CO2_SAVINGS",
    unitConfig: {
      category: "weight",
      base: "t"
    }
  },
  co2Savings: {
    id: "co2Savings",
    icon: "ec_co2-emission",
    title: "KPI.CO2_SAVINGS",
    unitConfig: {
      category: "weight",
      base: "t"
    }
  },
  monetaryCompensation: {
    id: "monetaryCompensation",
    icon: "ec_currency-euro",
    title: "KPI.MONETARY_COMPENSATION",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  currentIncidentMediumVoltage: {
    id: "currentIncidentMediumVoltage",
    title: "BUILDING.TABS.KPI.OUTAGES_CURRENT_MV",
    icon: "ec_outage_big"
  },
  currentSupplyLevel: {
    id: "currentSupplyLevel",
    title: "BUILDING.TABS.KPI.OUTAGES_CURRENT_SUPPLY",
    icon: "ec_supply"
  },
  resolvedIncidentMediumVoltage: {
    id: "resolvedIncidentMediumVoltage",
    title: "BUILDING.TABS.KPI.OUTAGES_RESOLVED_MV",
    icon: "ec_outage_big"
  },
  gasTotalOutages: {
    id: "gasTotalOutages",
    title: "INCIDENT.KPIS.gasTotalOutages",
    icon: "ec_gas_outage"
  },
  gasSeriousOutages: {
    id: "gasSeriousOutages",
    title: "INCIDENT.KPIS.gasSeriousOutages",
    icon: "ec_schwerwiegende-störung"
  },
  networkCountMeasuresElectricity: {
    id: "networkCountMeasuresElectricity",
    title: "TYP_COCKPIT.KPIS.MEASURES_ELECTRICITY_AMOUNT",
    icon: "ec_measure"
  },
  networkCountMeasuresGas: {
    id: "networkCountMeasuresGas",
    title: "TYP_COCKPIT.KPIS.MEASURES_GAS_AMOUNT",
    icon: "ec_measure"
  },
  electricityStationsCount: {
    id: "electricityStationsCount",
    title: "TYP_COCKPIT.KPIS.ELECTRICITY_STATIONS_AMOUNT",
    icon: "ec_Transformator"
  },
  gasStationsCount: {
    id: "gasStationsCount",
    title: "TYP_COCKPIT.KPIS.GAS_STATIONS_AMOUNT",
    icon: "ec_gas_ventile"
  },
  lowVoltageLength: {
    id: "lowVoltageLength",
    title: "TYP_COCKPIT.KPIS.LOW_VOLTAGE_LINES_TOTAL",
    icon: "ec_length",
    unitConfig: {
      category: "length",
      base: "m",
      omitUnits: ["km"],
      numberFormat: {
        minimumSignificantDigits: 1,
        maximumFractionDigits: 0
      }
    }
  },
  mediumVoltageLength: {
    id: "mediumVoltageLength",
    title: "TYP_COCKPIT.KPIS.MEDIUM_VOLTAGE_LINES_TOTAL",
    icon: "ec_length",
    unitConfig: {
      category: "length",
      base: "m",
      omitUnits: ["km"],
      numberFormat: {
        minimumSignificantDigits: 1,
        maximumFractionDigits: 0
      }
    }
  },
  lowPressureLength: {
    id: "lowPressureLength",
    title: "TYP_COCKPIT.KPIS.LOW_PRESSURE_LINES_TOTAL",
    icon: "ec_length",
    unitConfig: {
      category: "length",
      base: "m",
      omitUnits: ["km"],
      numberFormat: {
        minimumSignificantDigits: 1,
        maximumFractionDigits: 0
      }
    }
  },
  mediumPressureLength: {
    id: "mediumPressureLength",
    title: "TYP_COCKPIT.KPIS.MEDIUM_PRESSURE_LINES_TOTAL",
    icon: "ec_length",
    unitConfig: {
      category: "length",
      base: "m",
      omitUnits: ["km"],
      numberFormat: {
        minimumSignificantDigits: 1,
        maximumFractionDigits: 0
      }
    }
  },
  combinedCountMeasures: {
    id: "combinedCountMeasures",
    title: "TYP_COCKPIT.KPIS.MEASURES_MUNICIPALITY_AMOUNT",
    icon: "ec_measure"
  },
  countChargingPoints: {
    id: "countChargingPoints",
    icon: "ec_mobility-1",
    title: "GRID_CONNECTIONS.REPORT_PANEL.SUB_TABS.CHARGING_POINTS_COUNT"
  },
  production: {
    id: "production",
    icon: "ec_stromerzeugung",
    title: "KPI.ELECTRICITY_PRODUCTION",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  selfConsumption: {
    id: "selfConsumption",
    icon: "ec_stromerzeugung",
    title: "KPI.ELECTRICITY_PRODUCTION",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  /** Electricity selection */
  elStationWorkload: {
    id: "elStationWorkload",
    title: "NETWORK.STATION.POWER_LOAD",
    icon: "ec_ons-leistung",
    unitConfig: {
      category: "percentage",
      base: "decimal"
    }
  },
  elStationInstalledPower: {
    id: "elStationInstalledPower",
    title: "NETWORK.STATION.POWER_INSTALLED",
    icon: "ec_ONS-leistung",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "VA"
    }
  },
  elStationInvest: {
    id: "elStationInvest",
    title: "NETWORK.STATION.INVEST",
    icon: "ec_currency-euro-up",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  /** Gas selection */
  gasStationWorkload: {
    id: "gasSelectionStationWorkload",
    title: "NETWORK.STATION.GAS_LOAD",
    icon: "ec_gas-leistung",
    unitConfig: {
      category: "percentage",
      base: "decimal",
      numberFormat: {
        minimumSignificantDigits: 1
      }
    }
  },
  /** Building selection (electricity) */
  elHouseConnections: {
    id: "elHouseConnections",
    title: "BUILDING.TABS.KPI.ELECTRICITY_HOUSE_CONNECTIONS",
    icon: "ec_cockpit_buildings_count"
  },
  elConsumption: {
    id: "elConsumption",
    title: "BUILDING.TABS.KPI.ELECTRICITY_CONSUMPTION",
    icon: "ec_electr_cons",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh/a"
    }
  },
  elPeakLoad: {
    id: "elPeakLoad",
    title: "BUILDING.TABS.KPI.ELECTRICITY_PEAK_LOAD",
    icon: "ec_Transformator",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  /** Building selection (gas) */
  gasHouseConnections: {
    id: "buildingGasHouseConnections",
    title: "BUILDING.TABS.KPI.GAS_HOUSE_CONNECTIONS",
    icon: "ec_cockpit_buildings_count"
  },
  gasCountConsumer: {
    id: "buildingGasHouseConnections",
    title: "BUILDING.TABS.KPI.GAS_HOUSE_CONNECTIONS",
    icon: "ec_feed-in"
  },
  gasConsumption: {
    id: "buildingGasConsumption",
    title: "BUILDING.TABS.KPI.GAS_CONSUMPTION",
    icon: "ec_gas_cons",
    oneLine: false,
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh/a"
    }
  },
  gasCapacity: {
    id: "buildingGasCapacity",
    title: "BUILDING.TABS.KPI.GAS_CAPACITY",
    icon: "ec_gas-max",
    unitConfig: {
      category: "volume",
      base: "m3",
      unitSuffix: "/h"
    }
  },
  /** Building selection (e-mobility) */
  evHouseConnectionsCount: {
    id: "evHouseConnectionsCount",
    title: "BUILDING.TABS.PLANT_ENGINEERING.EV_AMOUNT",
    icon: "ec_emob"
  },
  evPower: {
    id: "evPower",
    title: "BUILDING.TABS.PLANT_ENGINEERING.POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  evConsumption: {
    id: "evConsumption",
    title: "BUILDING.TABS.PLANT_ENGINEERING.CONSUMPTION",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  evCo2Saving: {
    id: "evCo2Saving",
    title: "BUILDING.TABS.PLANT_ENGINEERING.CO2_SAVINGS",
    icon: "ec_co2-emission",
    unitConfig: {
      category: "weight",
      base: "t"
    }
  },
  /** Building selection (photovoltaic) */
  pvPower: {
    id: "pvPower",
    title: "BUILDING.TABS.PLANT_ENGINEERING.PV_POWER",
    icon: "ec_pv-power",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wp"
    }
  },
  pvProduction: {
    id: "pvProduction",
    title: "BUILDING.TABS.PLANT_ENGINEERING.PRODUCTION",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  pvCo2Saving: {
    id: "pvCo2Saving",
    title: "BUILDING.TABS.PLANT_ENGINEERING.CO2_SAVINGS",
    icon: "ec_co2-emission",
    unitConfig: {
      category: "weight",
      base: "t"
    }
  },
  /** Building selection (heat pump) */
  hpHouseConnectionsCount: {
    id: "hpHouseConnectionsCount",
    title: "BUILDING.TABS.PLANT_ENGINEERING.AMOUNT"
  },
  hpPower: {
    id: "hpPower",
    title: "BUILDING.TABS.PLANT_ENGINEERING.HP_POWER",
    label: "kW",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  hpConsumption: {
    id: "hpConsumption",
    title: "BUILDING.TABS.PLANT_ENGINEERING.CONSUMPTION",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  hpCo2Saving: {
    id: "hpCo2Saving",
    title: "BUILDING.TABS.PLANT_ENGINEERING.CO2_SAVINGS",
    icon: "ec_co2-emission",
    unitConfig: {
      category: "weight",
      base: "t"
    }
  },
  evPowerDistrict: {
    id: "evPowerDistrict",
    icon: "ec_emob",
    title: "COCKPIT.EV_POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  pvPowerDistrict: {
    id: "pv_power_district",
    icon: "ec_pv-power",
    title: "COCKPIT.PV_POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  hpPowerDistrict: {
    id: "hp_power_district",
    icon: "ec_wp",
    title: "COCKPIT.WP_POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  pvPeakPower: {
    id: "pvPeakPower",
    icon: "ec_pv",
    title: "KPI.PV_PEAK_POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W",
      omitUnits: ["M"],
      numberFormat: {
        maximumSignificantDigits: 5,
        minimumSignificantDigits: 5
      }
    }
  },
  gasHouseConnectionsQuota: {
    id: "gasHouseConnectionsQuota",
    icon: "ec_buildingstate_existing",
    title: "Anschlussquote",
    unitConfig: {
      category: "percentage",
      base: "decimal"
    }
  },
  gasCompactionPotential: {
    id: "gasCompactionPotential",
    icon: "ec_verdichtungspotential",
    title: "Verdichtungspotential"
  },
  gasConnectionsObjectCount: {
    id: "gasConnectionsObjectCount",
    title: "BUILDING.TABS.KPI.GAS_HOUSE_CONNECTIONS",
    icon: "ec_cockpit_buildings_count"
  },
  gasNetworkLength: {
    id: "gasNetworkLength",
    icon: "ec_massnahme",
    title: "Netzlänge",
    unitConfig: {
      category: "length",
      base: "m"
    }
  },
  h2Suitable: {
    id: "h2Suitable",
    icon: "ec_tubes",
    title: "Geeignete Rohrleitungsmaterialien",
    iconColor: "--ene-color-success-400"
  },
  h2Unsuitable: {
    id: "h2Unsuitable",
    icon: "ec_tubes",
    title: "Ungeeignete Rohrleitungsmaterialien",
    iconColor: "--ene-color-error-50"
  },
  electricityRequirement: {
    id: "electricityRequirement",
    icon: "ec_electr_demand",
    title: "KPI.ELECTRICITY_REQUIREMENT",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  electricityProduction: {
    id: "electricityProduction",
    icon: "ec_pv",
    title: "KPI.ELECTRICITY_PRODUCTION",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  networkFeedIn: {
    id: "networkFeedIn",
    icon: "ec_feed-in",
    title: "KPI.NETWORK_FEED_IN",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  electricityOnSitePower: {
    id: "electricityOnSitePower",
    icon: "ec_own_demand",
    title: "KPI.ELECTRICITY_ON_SITE_POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  remainPower: {
    id: "remainPower",
    icon: "ec_rest_demand",
    title: "KPI.REMAIN_POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  /** grid-connection / object-selection: commercial / private */
  installedCapacityForGC: {
    id: "installedCapacityForGC",
    icon: "ec_electr_capacity",
    title: "GRID_CONNECTIONS.OBJECT_SELECTION.KPI.INSTALLED_FEED_IN_POWER",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "W"
    }
  },
  ownerType: {
    id: "ownerType",
    icon: "ec_buildingtype_1family",
    title: "GRID_CONNECTIONS.OBJECT_SELECTION.KPI.OWNER"
  },
  commissioningDate: {
    id: "commissioningDate",
    icon: "ec_networks_mix_timeperiod",
    title: "GRID_CONNECTIONS.OBJECT_SELECTION.KPI.COMMISSIONING_YEAR"
  },
  selfSufficiency: {
    id: "selfSufficiency",
    icon: "ec_selfsuff",
    title: "COCKPIT.SELF_SUFFICIENCY",
    unitConfig: {
      category: "percentage",
      base: "decimal"
    }
  },
  emobilityCountChargingPoints: {
    id: "emobilityCountChargingPoints",
    icon: "ec_subtract",
    title: "GRID_CONNECTIONS.OBJECT.KPI.COUNT_CHARGING_POTINS"
  },
  emobilityInstalledCapacity: {
    id: "emobilityInstalledCapacity",
    icon: "ec_electr_capacity",
    title: "GRID_CONNECTIONS.OBJECT.KPI.INSTALLED_CAPACITY"
  },
  emobilityCommissioningDate: {
    id: "emobilityCommissioningDate",
    icon: "ec_networks_mix_timeperiod",
    title: "GRID_CONNECTIONS.OBJECT_SELECTION.KPI.COMMISSIONING_YEAR"
  },
  // Accounting module
  quarterlyAdvancePayment: {
    id: "quarterlyAdvancePayment",
    icon: "ec_building_construction_period",
    title: "ACCOUNTING.MONITOR.KPI.QUARTERLY_ADVANCE_PAYMENT",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  quarterlyAdvancePaymentElectricity: {
    id: "quarterlyAdvancePaymentElectricity",
    icon: "ec_Transformator",
    title: "CHARTS.ELECTRICITY",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  quarterlyAdvancePaymentGas: {
    id: "quarterlyAdvancePaymentGas",
    icon: "ec_gas_ventile",
    title: "CHARTS.GAS",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  concessionFee: {
    id: "concessionFee",
    icon: "ec_currency-euro",
    title: "ACCOUNTING.MONITOR.KPI.CONCESSION_FEE",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  concessionFeeElectricity: {
    id: "concessionFeeElectricity",
    icon: "ec_Transformator",
    title: "CHARTS.ELECTRICITY",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  concessionFeeGas: {
    id: "concessionFeeGas",
    icon: "ec_gas_ventile",
    title: "CHARTS.GAS",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  municipalSystemsAmount: {
    id: "municipalSystemsAmount",
    icon: "ec_building_di_generalfeatures",
    title: "ACCOUNTING.MONITOR.KPI.MUNICIPAL_SYSTEMS"
  },
  /** Accounting / Cockpit */
  qualifiedForDiscount: {
    id: "qualifiedForDiscount",
    title: "ACCOUNTING.COCKPIT.KPI.qualifiedForDiscount",
    icon: "ec_clipboard-check",
    iconColor: "--ene-color-success-300"
  },
  possibleForDiscount: {
    id: "possibleForDiscount",
    title: "ACCOUNTING.COCKPIT.KPI.possibleForDiscount",
    icon: "ec_clipboard",
    iconColor: "--ene-color-blue-300"
  },
  inReview: {
    id: "inReview",
    title: "ACCOUNTING.COCKPIT.KPI.inReview",
    icon: "ec_clipboard-outline",
    iconColor: "--ene-color-sand-400"
  },
  notPossibleForDiscount: {
    id: "notPossibleForDiscount",
    title: "ACCOUNTING.COCKPIT.KPI.notPossibleForDiscount",
    icon: "ec_clipboard_exclamation",
    iconColor: "--ene-color-error-100"
  },
  registrationDeadline: {
    id: "registrationDeadline",
    title: "ACCOUNTING.COCKPIT.KPI.registrationDeadline",
    icon: "ec_networks_mix_timeperiod"
  },
  summaryDiscount: {
    id: "summaryDiscount",
    icon: "ec_currency-euro",
    title: "ACCOUNTING.COCKPIT.KPI.summaryDiscount",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  summaryElectricityConsumption: {
    id: "summaryElectricityConsumption",
    icon: "ec_electr_cons",
    title: "ACCOUNTING.COCKPIT.KPI.summaryElectricityConsumption",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  summaryGasConsumption: {
    id: "summaryGasConsumption",
    icon: "ec_gas_cons",
    title: "ACCOUNTING.COCKPIT.KPI.summaryGasConsumption",
    unitConfig: {
      category: "decimal",
      base: "k",
      unitSuffix: "Wh"
    }
  },
  summaryFee: {
    id: "summaryFee",
    icon: "ec_currency-euro",
    title: "ACCOUNTING.COCKPIT.KPI.summaryFee",
    unitConfig: {
      category: "currency",
      base: "EUR"
    }
  },
  selectionBuildingStreet: {
    id: "selectionBuildingStreet",
    icon: "ec_side_building",
    title: "Straße"
  },
  selectionBuildingHeight: {
    id: "selectionBuildingHeight",
    icon: "ec_filled-building-floors",
    title: "Gebäudehöhe",
    unitConfig: {
      category: "length",
      base: "m"
    }
  },
  selectionBuildingUtilisation: {
    id: "selectionBuildingUtilisation",
    icon: "ec_filled-building-type",
    title: "Nutzungsart"
  },
  selectionBuildingStoreys: {
    id: "selectionBuildingStoreys",
    icon: "ec_filled-building-floors",
    title: "Etagenanzahl"
  },
  selectionCrisisUtilisation: {
    id: "selectionCrisisUtilisation",
    icon: "ec_house_crisis",
    title: "Nutzungsart im Krisenfall"
  },
  selectionCrisisGeneratorAvailable: {
    id: "selectionCrisisGeneratorAvailable",
    icon: "ec_cockpit_total_energy_consumption",
    title: "Notstromversorgung vorhanden"
  },
  selectionCrisisMedicalCare: {
    id: "selectionCrisisMedicalCare",
    icon: "ec_cross",
    title: "Ambulante medizinische Versorgungseinrichtung vorhanden"
  },
  buildingRoofType: {
    id: "buildingRoofType",
    title: "Dachform",
    icon: "ec_filled-roof"
  },
  buildingRoofOrientation: {
    id: "buildingRoofOrientation",
    title: "Dachausrichtung",
    icon: "ec_filled-roof"
  },
  heatedSurfaceArea: {
    id: "heatedSurfaceArea",
    title: "Etagenanzahl x Grundfläche",
    icon: "ec_filled-building-radiator",
    unitConfig: {
      category: "area",
      base: "m2"
    }
  }
};
