import { Directive, Inject, InjectionToken, Input, Optional } from "@angular/core";
import { Pure } from "src/app/common/decorators/pure";
import { TableColumnConfig, TableColumnContext } from "../table.interfaces";

export const TABLE_COLUMN_CONTEXT = new InjectionToken<TableColumnContext>("Context for column");

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TableColumn<T extends Record<string, any> = Record<string, any>> {
  @Input() public index: number;
  @Input() public columnConfig: TableColumnConfig;
  @Input() public row: T;

  constructor(@Optional() @Inject(TABLE_COLUMN_CONTEXT) public readonly context?: TableColumnContext) {
    this.index = this.context?.index;
    this.columnConfig = this.context?.columnConfig;
    this.row = this.context?.row;
  }

  @Pure
  public translateAccessor(row: Record<string, any>): string {
    return this.columnConfig.translateAccessor
      ? this.columnConfig.translateAccessor(row)
      : (row[this.columnConfig.id] as string);
  }
}
