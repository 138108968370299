import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacySliderModule } from "@angular/material/legacy-slider";

import { AudioPlayerComponent } from "./components/audio-player/audio-player.component";
import { MediaControlsComponent } from "./components/controls/media-controls.component";
import { VideoPlayerComponent } from "./components/video-player/video-player.component";
import { FormatTimePipe } from "./pipes/format-time.pipe";
import { MediaAnalyticsDirective } from "../../directives/media-analytics/media-analytics.directive";

const materialModules = [MatIconModule, MatLegacyButtonModule, MatLegacySliderModule];

@NgModule({
  declarations: [AudioPlayerComponent, VideoPlayerComponent, MediaControlsComponent, FormatTimePipe],
  imports: [CommonModule, MediaAnalyticsDirective, ...materialModules],
  exports: [AudioPlayerComponent, VideoPlayerComponent]
})
export class MediaPlayerModule {}
