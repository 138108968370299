import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { Pure } from "src/app/common/decorators/pure";
import { TableActionColumn } from "../../../classes/table-action-column";
import { CheckboxTableColumnConfig } from "../../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-checkbox-column",
  templateUrl: "./checkbox-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxColumnComponent<T> extends TableActionColumn {
  @Pure
  public hideCheckbox(columnConfig: CheckboxTableColumnConfig, row: T): boolean {
    return "hideCheckbox" in columnConfig
      ? columnConfig.hideCheckbox
      : "hideCheckboxAccessor" in columnConfig
      ? columnConfig.hideCheckboxAccessor(row)
      : false;
  }

  @Pure
  public checked(columnConfig: CheckboxTableColumnConfig, row: T): boolean {
    return "checked" in columnConfig
      ? columnConfig.checked
      : "checkedAccessor" in columnConfig
      ? columnConfig.checkedAccessor(row)
      : false;
  }

  @Pure
  public label(columnConfig: CheckboxTableColumnConfig, row: T): string | null {
    return "checkboxLabel" in columnConfig
      ? columnConfig.checkboxLabel
      : "checkboxLabelAccessor" in columnConfig
      ? columnConfig.checkboxLabelAccessor(row)
      : null;
  }
}
