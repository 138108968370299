import { createAction, props } from "@ngrx/store";
import {
  GasCockpitKpiDto,
  GasNetworkLengthDto
} from "../../common/dto/gas-cockpit";

export const getGasCockpitData = createAction(
  "[Gas Cockpit] Get gas cockpit data",
  props<{
    gasNetworkId: string;
    year: number;
  }>()
);

export const getGasCockpitKpi = createAction(
  "[Gas Cockpit] Get gas cockpit kpi data",
  props<{
    gasNetworkId: string;
    year: number;
  }>()
);

export const getGasNetworksLength = createAction(
  "[Gas Cockpit] Get gas networks length data",
  props<{
    gasNetworkId: string;
  }>()
);

export const getGasCockpitKpiSuccess = createAction(
  "[Gas Cockpit] Get gas cockpit kpi data success",
  props<{
    payload: GasCockpitKpiDto;
  }>()
);

export const getGasNetworksLengthSuccess = createAction(
  "[Gas Cockpit] Get gas network length data success",
  props<{
    payload: GasNetworkLengthDto;
  }>()
);
