import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ContentfulActionField } from "src/app/core/interfaces/contentful";
import { CommunalPlatformNewsEntry } from "../../news/news.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-contentful-list-item",
  templateUrl: "./contentful-list-item.component.html",
  styleUrls: ["./contentful-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentfulListItemComponent {
  @Input() public isMobileView: boolean;
  @Input() public data: CommunalPlatformNewsEntry;

  @Output() public readonly listItemClick = new EventEmitter<ContentfulActionField>();

  public readonly trackByIndex = (index: number): number => index;
}
