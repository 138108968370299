<table
  mat-table
  [dataSource]="dataSource"
  multiTemplateDataRows
  matSort
  [matSortDisabled]="_sortDisabled"
  [matSortDirection]="sortDirection"
  [matSortActive]="sortActive"
>
  <ng-container matColumnDef="{{ column.id }}" *ngFor="let column of _columnsConfigs; trackBy: trackById">
    <th mat-header-cell *matHeaderCellDef [ngStyle]="column.style" mat-sort-header>{{ column.key | translate }}</th>
    <td mat-cell *matCellDef="let row; let i = dataIndex" [ngStyle]="column.style">
      <ng-container [ngSwitch]="column.type">
        <ng-container *ngSwitchCase="'action'">
          <i
            class="action-cell-icon ene-icon"
            [ngClass]="expandedElement === row ? column.selectedIcon : column.actionIcon"
            [matTooltip]="column.key | translate"
            [matTooltipDisabled]="!column.key"
            [matTooltipClass]="'light-tooltip'"
            (click)="cellClicked(row)"
          ></i>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          {{ row[column.id] }}
        </ng-container>
        <ng-container *ngSwitchCase="'custom'">
          <ng-container
            [ngComponentOutlet]="column.customTemplate"
            [ngComponentOutletInjector]="getTemplateInjector(column, row)"
          ></ng-container>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>
  <ng-container matColumnDef="nested">
    <td
      mat-cell
      *matCellDef="let row; let i = dataIndex"
      [attr.colspan]="displayedColumns.length"
      [attr.dc]="displayedColumns.length"
    >
      <ng-container
        [ngComponentOutlet]="nestedTemplate"
        [ngComponentOutletInjector]="getComponentInjector(row)"
      ></ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns; when: !isNested()"></tr>
  <tr mat-row *matRowDef="let row; columns: ['nested']; when: isNested()"></tr>
</table>
<app-table-paginator *ngIf="_pagingConfig" [config]="_pagingConfig"></app-table-paginator>
