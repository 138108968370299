<media-controls
  [autoPlay]="autoPlay"
  [muted]="muted"
  [disablePlayControl]="disablePlayControl"
  [displayVolumeControl]="displayVolumeControl"
  [source]="source"
  [startOffset]="startOffset"
>
  <audio #player [src]="source" preload="metadata" poster="null" scanMediaAnalytics [attr.data-matomo-title]="title"></audio>
</media-controls>
