import { Type } from "@angular/core";
import { TableColumnType } from "../../../table.interfaces";
import { ActionIconColumnComponent } from "../action-icon-column/action-icon-column.component";
import { BadgeColumnComponent } from "../badge-column/badge-column.component";
import { IconColumnComponent } from "../icon-column/icon-column.component";
import { StateColumnComponent } from "../state-column/state-column.component";

export const TABLE_COMBINED_VALUES_COLUMNS_COMPONENTS = new Map<TableColumnType, Type<any>>([
  ["icon", IconColumnComponent],
  ["state", StateColumnComponent],
  ["action-icon", ActionIconColumnComponent],
  ["badge", BadgeColumnComponent]
]);
