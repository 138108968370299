import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";

export const usersTableConfig: { columns: Array<ITableColumn>; nestedColumns: Array<ITableColumn> } = {
  columns: [
    {
      id: "name",
      type: "text",
      key: "USER_SETTINGS.NAME",
      style: {
        width: "20%"
      }
    },
    {
      id: "email",
      type: "text",
      key: "USER_SETTINGS.EMAIL",
      style: {
        width: "20%"
      }
    },
    {
      id: "municipalitiesCount",
      type: "text",
      key: "USER_SETTINGS.MUNICIPALITIES_COUNT",
      style: {
        width: "19%"
      }
    },
    {
      id: "activeEmailCount",
      type: "text",
      key: "USER_SETTINGS.EMAIL_COUNT_ELECTRICITY",
      style: {
        width: "9%"
      }
    },
    {
      id: "activeEmailCountGas",
      type: "text",
      key: "USER_SETTINGS.EMAIL_COUNT_GAS",
      style: {
        width: "9%"
      }
    },
    {
      id: "activeSmsCount",
      type: "text",
      key: "USER_SETTINGS.SMS_COUNT_ELECTRICITY",
      style: {
        width: "9%"
      }
    },
    {
      id: "activeSmsCountGas",
      type: "text",
      key: "USER_SETTINGS.SMS_COUNT_GAS",
      style: {
        width: "9%"
      }
    },
    {
      id: "expand",
      type: "action",
      actionIcon: "ec_networks_mix_addrow",
      selectedIcon: "ec_networks_mix_deleterow",
      key: "",
      style: {
        "text-align": "center"
      }
    }
  ],
  nestedColumns: [
    {
      id: "region",
      type: "text",
      key: "USER_SETTINGS.MUNICIPALITY",
      style: {
        width: "22%"
      }
    },
    {
      id: "role",
      type: "text",
      key: "USER_SETTINGS.REGION_ROLE",
      style: {
        width: "22%"
      }
    },
    {
      id: "emailConfig",
      type: "notification-settings",
      key: "USER_SETTINGS.EMAIL_SETTINGS_ELECTRICITY"
    },
    {
      id: "emailConfigGas",
      type: "notification-settings",
      key: "USER_SETTINGS.EMAIL_SETTINGS_GAS"
    },
    {
      id: "phone",
      type: "text",
      key: "USER_SETTINGS.PHONE"
    },
    {
      id: "phoneConfig",
      type: "notification-settings",
      key: "USER_SETTINGS.PHONE_SETTINGS_ELECTRICITY"
    },
    {
      id: "phoneConfigGas",
      type: "notification-settings",
      key: "USER_SETTINGS.PHONE_SETTINGS_GAS"
    }
  ]
};
