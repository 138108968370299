import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeDeCH from "@angular/common/locales/de-CH";
import localeFr from "@angular/common/locales/fr";
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EneUiService } from "@enersis/ui-helper";
import { Store } from "@ngrx/store";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { createErrorHandler, TraceService } from "@sentry/angular-ivy";
import * as Highcharts from "highcharts";
import { KeycloakAngularModule } from "keycloak-angular";
import { combineLatest, EMPTY, Observable, of, Subject } from "rxjs";
import { catchError, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { KpiModule } from "./common/components/kpi/kpi.module";
import { TABLE_VALUE_FORMAT } from "./common/components/table/tokens/table-value-format";
import { kpiItemMap } from "./common/constants/kpi-item-map";
import { months, shortMonths } from "./common/constants/months";
import { LocaleCode } from "./common/enums/locale-code";
import { PipesModule } from "./common/pipes/pipes.module";
import { AppComponent } from "./components/app/app.component";
import { TranslateHttpLoader } from "./core/classes/translate-http-loader";
import { CoreModule } from "./core/core.module";
import { PluginEntity } from "./core/interfaces/pluginEntity";
import { AuthErrorService } from "./core/services/auth-error.service";
import { AUTH_PROVIDER, AuthService, LOCATION_TOKEN } from "./core/services/auth.service";
import { DualRolesService } from "./core/services/dual-roles.service";
import { JsonsService } from "./core/services/jsons.service";
import { ProjectConfigurationService } from "./core/services/project-configuration.service";
import { initApp, setEntities, setMinMaxYears, setPluginEntities, setUserInfo } from "./ngrx/actions/app.actions";
import { RootState } from "./ngrx/root-reducers";

registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeDeCH);

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    KeycloakAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateHttpLoader,
        deps: [HttpClient]
      }
    }),
    PipesModule.forRoot(),
    KpiModule.forRoot(kpiItemMap),
    CoreModule
  ],
  providers: [
    AUTH_PROVIDER,
    { provide: LOCATION_TOKEN, useValue: window.location },
    { provide: TABLE_VALUE_FORMAT, useValue: "1.0-0" },
    AuthErrorService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({})
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    }
  ]
})
export class AppModule implements DoBootstrap {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly store: Store<RootState>,
    private readonly translate: TranslateService,
    private readonly uiService: EneUiService,
    private readonly authService: AuthService,
    private readonly projectConfigurationService: ProjectConfigurationService,
    private readonly authErrorService: AuthErrorService,
    private readonly dualRolesService: DualRolesService,
    private readonly jsonsService: JsonsService
  ) {}

  public ngDoBootstrap(appRef: ApplicationRef): void {
    this.authErrorService.init();
    this.authService
      .init()
      .pipe(
        switchMap((authenticated) => {
          if (authenticated) {
            return of(void 0);
          } else {
            return this.authService.login();
          }
        }),
        take(1),
        switchMap(() =>
          combineLatest([
            this.authService.getUserInfo(),
            this.projectConfigurationService.getEntities({ filter: true }),
            this.jsonsService.getPlugins() as Observable<Array<PluginEntity>>,
            this.projectConfigurationService.getYears()
          ])
        ),
        tap(([userInfo, entities, pluginEntities, years]) => {
          this.dualRolesService.init();
          this.store.dispatch(setPluginEntities({ pluginEntities }));
          this.store.dispatch(setUserInfo({ userInfo }));
          this.store.dispatch(setEntities({ payload: entities }));
          this.store.dispatch(setMinMaxYears({ payload: years }));
        }),
        // Lang initialization
        switchMap(() => this.translate.use(LocaleCode.de_DE)),
        tap(() => {
          // Lang for ui-pipe
          this.uiService.setLanguage(LocaleCode.de_DE);
          // Global highcharts options
          Highcharts.setOptions({ lang: { shortMonths, months, decimalPoint: "," } });
        }),
        catchError(() => EMPTY),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.store.dispatch(initApp());
        appRef.bootstrap(AppComponent);
      });
  }
}
