import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ConfigurationAreaModule } from "../features/configuration-area/configuration-area.module";
import { LaunchpadComponent } from "../features/overlays/region/launchpad/container/launchpad.component";
import { NewsModule } from "../features/overlays/region/news/news.module";
import { WebinarsModule } from "../features/overlays/region/webinars/webinars.module";
import { AppMaterialModule } from "./material/app-material.module";
import { AppDialogsModule } from "./modules/app-dialogs.module";
import { AppNgRxModule } from "./modules/app-ngrx.module";
import { AppRoutingModule } from "./modules/app-routing.module";

const featureModules = [ConfigurationAreaModule, NewsModule, WebinarsModule];

@NgModule({
  imports: [CommonModule, AppDialogsModule, AppRoutingModule, LaunchpadComponent, ...featureModules],
  exports: [AppDialogsModule, AppRoutingModule, AppNgRxModule, AppMaterialModule, ...featureModules]
})
export class CoreModule {}
