import { RouterStateSnapshot } from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";
import { SLOT } from "src/app/common/enums/slot";
import { RouterStateUrl } from "src/app/common/interfaces/router-state-url";
import { isModalPlugin } from "../guards/is-modal-plugin.matcher";

/**
 * @description https://ngrx.io/guide/router-store/configuration
 */
export class ProjectRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
  public serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;
    const lastParam = url.split(/\//).pop();
    const slotParam = lastParam.split("?")[0];

    const slot = isModalPlugin(slotParam) ? SLOT.LANDING : Object.values(SLOT).find((value) => value === slotParam);

    return { url, slot, params, queryParams };
  }
}
