import { createAction, props } from "@ngrx/store";
import { CumulatedInvestDto } from "src/app/common/dto/energy/cumulated-invest";

export const getCumulatedInvestAllYearsData = createAction(
  "[Cumulated Invest] Get cumulated invest data",
  props<{
    regionId: string;
    scenarioId: string;
  }>()
);

export const getCumulatedInvestAllYearsDataSuccess = createAction(
  "[Cumulated Invest] Get cumulated invest data success",
  props<{
    payload: Array<CumulatedInvestDto>;
  }>()
);
