<div class="mat-dialog-header">
  <div mat-dialog-title>
    <mat-icon class="circle" fontIcon="ec_option"></mat-icon>
    <h1>{{ "NOTIFICATION_SERVICE.TITLE" | translate }}</h1>
  </div>
  <button class="big" mat-button mat-dialog-close>
    <mat-icon fontIcon="ec_building_delete_address"></mat-icon>
  </button>
</div>
<div class="notification-settings">
  <ene-tabs data-cy="notificationSettingsTab" class="main-tabs" (currentTabChange)="onPanelTabChange($event)">
    <ene-tab
      *ngFor="let tab of tabs; trackBy: trackByName"
      [tabName]="tab.name"
      [tabTitle]="tab.label | translate"
      [disabled]="tab.disabled"
      [active]="tab.name === activeTabName"
    ></ene-tab>
  </ene-tabs>
  <app-multi-region
    *ngIf="multiRegion === true"
    class="multi-region"
    [columns]="columnsConfigs[activeTabName]"
    [sortActive]="sort.active"
    [sortDirection]="sort.direction"
  ></app-multi-region>
  <app-one-region *ngIf="multiRegion === false" (close)="close()"></app-one-region>
</div>
