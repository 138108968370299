<div class="top-wrapper">
  <p class="description body-2">
    {{
      "Fügen Sie neue Nutzer über den Button rechts hinzu. Der Status kann direkt in der Tabelle bearbeitet und anschließend gespeichert werden. Die Änderungen werden nach spätestens 48 Stunden wirksam."
        | translate
    }}
  </p>
  <button
    class="download-button"
    ene-filled-button
    [icon]="'ec_maptools_zoomin'"
    [size]="'large'"
    (click)="addUser.emit()"
  >
    {{ "Nutzer hinzufügen" | translate }}
  </button>
</div>
<tpm-card @inOutAnimation *ngIf="isUpdated === true">
  <div class="user-management-actions-wrapper">
    <button class="delete-button" ene-filled-button (click)="submit.emit()">Speichern</button>
  </div>
</tpm-card>
<ngmy-table
  class="user-management-table"
  [scrollableX]="true"
  [disableSort]="false"
  [hideFilter]="true"
  [columnsConfig]="columnsConfig"
  [loading]="invitations.loading"
  [data]="invitations.result"
  (tableAction)="onTableAction($event)"
></ngmy-table>
