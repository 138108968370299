import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from "@angular/core";
import { EneButtonModule } from "@enersis/ui-button";
import { CardModule } from "src/app/common/components/card/card.module";
import { ColoredBadgeModule } from "src/app/common/components/colored-badge/colored-badge.module";
import { LaunchpadNewsCardProps } from "../../container/launchpad.interfaces";

@Component({
  selector: "tpm-news-card",
  templateUrl: "news-card.component.html",
  styleUrls: ["news-card.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, CardModule, ColoredBadgeModule, EneButtonModule]
})
export class NewsCardComponent {
  @Output() public readonly actionClicked = new EventEmitter<LaunchpadNewsCardProps>();

  @Input() public props: LaunchpadNewsCardProps | undefined;

  public readonly trackByIndex: TrackByFunction<LaunchpadNewsCardProps> = (index) => index;
}
