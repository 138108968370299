export const coordinates = {
    longitude : {
      min : 7,
      max : 12
    },
    latitude : {
      min : 47,
      max : 50
    }
  };
