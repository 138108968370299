import { Injectable } from "@angular/core";
import {
  MatLegacyCellDef,
  MatLegacyColumnDef,
  MatLegacyFooterCellDef,
  MatLegacyHeaderCellDef
} from "@angular/material/legacy-table";
import { BehaviorSubject } from "rxjs";
import { TableComponent } from "../container/table.component";

@Injectable({ providedIn: "root" })
export class TableService<T extends Record<string, any>> {
  public readonly expand$ = new BehaviorSubject<{ expanded: boolean; row: T }>({ expanded: false, row: null });

  constructor() {}

  public addColumnDef(
    table: TableComponent<T>,
    columnDef: MatLegacyColumnDef,
    cellDef: MatLegacyCellDef,
    headerCellDef?: MatLegacyHeaderCellDef,
    footerCellDef?: MatLegacyFooterCellDef
  ): void {
    if (table) {
      columnDef.headerCell = headerCellDef;
      columnDef.footerCell = footerCellDef;
      columnDef.cell = cellDef;
      table.matTable.addColumnDef(columnDef);
    }
  }

  public removeColumnDef(table: TableComponent<T>, columnDef: MatLegacyColumnDef): void {
    if (table) {
      table.matTable.removeColumnDef(columnDef);
    }
  }

  public syncColumnDef(columnDef: MatLegacyColumnDef, name: string): void {
    if (columnDef) {
      columnDef.name = name;
    }
  }
}
