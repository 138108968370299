import { Component, Input } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Division } from "src/app/core/interfaces/division";
import { INotificationSettings } from "../../../../../../../interfaces/dialogs/notification-settings";
import { Square } from "../../../square/square.component";

@Component({
  selector: "app-im-notification-settings-edit-email",
  templateUrl: "./notification-settings-edit-email.component.html",
  styleUrls: ["../../style.scss"]
})
export class IMNotificationSettingsEditEmailComponent {
  @Input() public division: Division = "electricity";
  @Input() public formGroup: FormGroup;
  @Input() public indeterminate: INotificationSettings;
  @Input() public notificationSquares: { [key: string]: Square };
  public checkboxWarning: AbstractControl;
  public get startEndControl(): AbstractControl | null {
    return this.formGroup.get("startEnd");
  }

  public validateCheckboxes(control?: AbstractControl): void {
    if (!control || control.value) {
      this.checkboxWarning = undefined;
      return;
    }

    if (control.disabled) {
      return;
    }

    this.checkboxWarning = control;
  }
}
