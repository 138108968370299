import { Action, createReducer, on } from "@ngrx/store";
import { CumulatedInvestDto } from "src/app/common/dto/energy/cumulated-invest";
import * as CumulatedInvestActions from "../../actions/energy/cumulated-invest.actions";

export interface State {
  allYearsData: Array<CumulatedInvestDto>;
}

const initialState: State = {
  allYearsData: undefined
};

const cumulatedInvestReducer = createReducer(
  initialState,
  on(CumulatedInvestActions.getCumulatedInvestAllYearsData, (state) => ({
    ...state,
    allYearsData: undefined
  })),
  on(CumulatedInvestActions.getCumulatedInvestAllYearsDataSuccess, (state, { payload }) => ({
    ...state,
    allYearsData: payload
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return cumulatedInvestReducer(state, action);
}
