import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { LegacySimpleSnackBar } from "@angular/material/legacy-snack-bar";

/* eslint-disable @angular-eslint/no-host-metadata-property */
@Component({
  selector: "snack-bar",
  template: `
    <span>{{ data.message | translate }}</span>
    <div class="mat-simple-snackbar-action">
      <button class="small dark" *ngIf="hasAction" mat-button (click)="action()">
        {{ data.action | translate }}
      </button>
      <button class="small" *ngIf="!hasAction" mat-icon-button (click)="action()">
        <ene-icon icon="ec_building_delete_address"></ene-icon>
      </button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "mat-simple-snackbar"
  }
})
export class SnackBarComponent extends LegacySimpleSnackBar {}
