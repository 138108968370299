<ng-container [formGroup]="formGroup" *ngIf="formGroup">
  <app-phone-number-input
    label="NOTIFICATION_SERVICE.EDIT.FILL_PHONE"
    formControlName="phone"
    #phoneInput
  ></app-phone-number-input>
  <mat-checkbox
    [disableRipple]="true"
    formControlName="verifyPhone"
    [(indeterminate)]="indeterminate.verifyPhone"
    class="settings-form__checkbox-verify-phone"
    [class.warn]="
      !verifyPhoneControl?.value && (checkboxWarning === verifyPhoneControl || checkboxWarning === startEndControl)
    "
    (click)="validateCheckboxes(phoneControl)"
  >
    <b>{{ "NOTIFICATION_SERVICE.EDIT.PHONE_CONFIRMATION" | translate }}</b>
  </mat-checkbox>
  <mat-divider big></mat-divider>
  <div class="settings-form__email-checkboxes">
    <div class="settings-form__checkbox-wrapper">
      <mat-checkbox
        [disableRipple]="true"
        formControlName="startEnd"
        [class.warn]="checkboxWarning === startEndControl"
        [(indeterminate)]="indeterminate.startEnd"
        (click)="validateCheckboxes(verifyPhoneControl)"
        >{{ "NOTIFICATION_SERVICE.EDIT." + division + ".START_END" | translate }}</mat-checkbox
      >
      <div class="squares">
        <app-square *ngIf="notificationSquares.start" [data]="notificationSquares.start"></app-square>
        <app-square *ngIf="notificationSquares.end" [data]="notificationSquares.end"></app-square>
      </div>
    </div>
    <mat-list>
      <mat-list-item>
        <div class="settings-form__checkbox-wrapper">
          <mat-checkbox
            [disableRipple]="true"
            formControlName="update"
            [(indeterminate)]="indeterminate.update"
            (click)="validateCheckboxes(startEndControl)"
            >{{ "NOTIFICATION_SERVICE.EDIT." + division + ".UPDATE_DESCRIPTION" | translate }}</mat-checkbox
          >
          <div class="squares">
            <app-square [data]="notificationSquares.update"></app-square>
          </div>
        </div>
      </mat-list-item>
      <mat-list-item *ngIf="division === 'gas'">
        <div class="settings-form__checkbox-wrapper">
          <mat-checkbox
            [disableRipple]="true"
            formControlName="intermediate"
            [(indeterminate)]="indeterminate.intermediate"
            (click)="validateCheckboxes(startEndControl)"
          >
            {{ "NOTIFICATION_SERVICE.EDIT." + division + ".INTERMEDIATE_DESCRIPTION" | translate }}
          </mat-checkbox>
          <div class="squares">
            <app-square [data]="notificationSquares.intermediate"></app-square>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</ng-container>
