import { inject } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlMatchResult,
  UrlMatcher,
  UrlSegment,
  createUrlTreeFromSnapshot
} from "@angular/router";
import { CrmModule } from "../enums/permissions";

/** (almost) single source of truth to determine whether a CRM Module (plugin)
 * should be opened in a modal dialog instead of iframe or link-out */
const modalPlugins: Array<CrmModule> = [CrmModule.QuickCheck];

export function isModalPlugin(plugin: CrmModule | string): boolean {
  return modalPlugins.includes(plugin as CrmModule);
}

/**
 * Matcher for the angular router to match a route with a modal plugin
 */
export const matchModalPluginRoute: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  const moduleId = segments[0].path;

  if (isModalPlugin(moduleId)) {
    return { consumed: [segments[0]], posParams: { moduleId: segments[0] } };
  } else {
    return null;
  }
};

/**
 * Matcher for the angular router to match any plugin that is _not_ a modal plugin
 */
export const matchNonModalPluginRoute: UrlMatcher = (segments: UrlSegment[]): UrlMatchResult | null => {
  const moduleId = segments[0].path;

  if (!isModalPlugin(moduleId)) {
    return { consumed: [segments[0]], posParams: { moduleId: segments[0] } };
  } else {
    return null;
  }
};

/**
 * this function will add the query param `overlay=plugin` to the url. This is
 * used to keep the launchpad on the "marketplace" tab when opening a plugin
 * in a modal.
 */
export const addModalPluginQueryParamResolver: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  if (route.queryParams["overlay"] === "plugin") {
    return true;
  }
  const urlTree = createUrlTreeFromSnapshot(route, [], {
    overlay: "plugin"
  });
  return inject(Router).navigateByUrl(urlTree.toString());
};
