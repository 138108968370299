<ng-container [ngSwitch]="valueType">
  <span *ngSwitchCase="'number'" [innerHTML]="$any(_value) | eneUnit: config?.unitConfig"></span>
  <span
    *ngSwitchCase="'string'"
    tooltipIfTruncated
    [innerHTML]="$any(_value) | translate"
    [matTooltip]="$any(_value) | translate"
  ></span>
  <span *ngSwitchCase="'undefined'">
    {{ "TDI.NOT_AVAILABLE.LONG" | translate }}
  </span>
  <span *ngSwitchDefault>-</span>
</ng-container>
