<ng-content #player></ng-content>
<div class="controls">
  <div class="controls__navigations">
    <button mat-button (click)="togglePlay()">
      <ng-container *ngIf="_paused; else playing">
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none">
          <path d="M6.85714 12H10.2857V0H6.85714V12ZM0 12H3.42857V0H0V12Z" fill="white" />
        </svg>
      </ng-container>
      <ng-template #playing>
        <svg width="11" height="12" viewBox="0 0 11 12" fill="none">
          <path d="M0 0V12L11 6L0 0Z" fill="white" />
        </svg>
      </ng-template>
    </button>
    <div class="progess-timer">{{ currentTime | formatTime : durationTime }}</div>
    <button mat-button (click)="toggleVolume()">
      <svg *ngIf="_muted; else volumeOn" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path
          d="M7 0.777778L5.37444 2.40333L7 4.02889V0.777778ZM0.987778 0L0 0.987778L3.67889 4.66667H0V9.33333H3.11111L7 13.2222V7.98778L10.3056 11.3011C9.78444 11.6978 9.20111 12.0244 8.55556 12.2111V13.8211C9.62889 13.5722 10.6011 13.0822 11.4178 12.4133L13.0122 14L14 13.0122L7 6.01222L0.987778 0ZM12.4444 7C12.4444 7.73111 12.2889 8.41556 12.0244 9.05333L13.1989 10.2278C13.7044 9.26333 14 8.16667 14 7C14 3.67111 11.6667 0.886667 8.55556 0.178889V1.78111C10.8033 2.45 12.4444 4.53444 12.4444 7ZM10.5 7C10.5 5.62333 9.72222 4.44111 8.55556 3.86556V5.58444L10.4611 7.49C10.5 7.33444 10.5 7.16333 10.5 7Z"
          fill="white"
        />
      </svg>
      <ng-template #volumeOn>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
          <path
            d="M8.55556 1.48349e-08V1.60222C10.8033 2.27111 12.4444 4.35556 12.4444 6.82111C12.4444 9.28667 10.8033 11.3633 8.55556 12.0322V13.6422C11.6667 12.9344 14 10.15 14 6.82111C14 3.49222 11.6667 0.707778 8.55556 1.48349e-08ZM10.5 6.82111C10.5 5.44444 9.72222 4.26222 8.55556 3.68667V9.93222C9.72222 9.38 10.5 8.19 10.5 6.82111ZM0 4.48778V9.15444H3.11111L7 13.0433V0.598889L3.11111 4.48778H0Z"
            fill="white"
          />
        </svg>
      </ng-template>
    </button>
    <button *ngIf="displayFullscreenControl" mat-button (click)="toggleFullscreen()">
      <svg *ngIf="isFulscreen$ | async; else fullscreenOff" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3 0H5V5H0V3H3V0ZM9 0H7V5H12V3H9V0ZM0 7V9H3V12H5V7H0ZM12 9V7H7V12H9V9H12Z"
          fill="white"
        />
      </svg>
      <ng-template #fullscreenOff>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M12 4.5H10V2H7.5V0H12V4.5ZM7.5 12V10H10V7.5H12V12H7.5ZM0 7.5H2V10H4.5V12H0V7.5ZM4.5 0V2H2V4.5H0V0H4.5Z"
            fill="white"
          />
        </svg>
      </ng-template>
    </button>
  </div>
  <mat-slider class="controls__slider" min="0" [max]="durationTime" (change)="onCurrentTimeChange($event)">
    <input matSliderThumb [value]="currentTime"
  /></mat-slider>
</div>
