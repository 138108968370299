import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { IKpiBadge } from "../../interfaces/kpi-badge";
import { ValueType } from "../kpi-box/kpi-box.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "kpi-badge",
  templateUrl: "./kpi-badge.component.html",
  styleUrls: ["./kpi-badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class KpiBadgeComponent {
  @Input()
  public badge: IKpiBadge;

  public get valueType(): ValueType {
    return typeof this.badge.value as ValueType;
  }
}
