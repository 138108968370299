import { TableColumnConfig } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";

export const configurationAreaDownloadsColumnConfig: Array<TableColumnConfig> = [
  {
    id: "timestamp",
    key: "Zeitstempel Erzeugung",
    type: "date",
    dateFormat: "EEEE, dd.MM.yyyy, HH:mm",
    style: {
      width: "30%"
    }
  },
  {
    id: "fileName",
    key: "Dateiname",
    type: "text"
  },
  {
    id: "size",
    key: "Dateigröße",
    type: "text",
    style: {
      width: "100px"
    }
  },
  {
    id: "download",
    key: "",
    type: "action",
    actionIcon: "ec_download",
    style: {
      width: "50px"
    }
  }
];
