import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";

export enum ERROR_MESSAGE {
  BROWSER_STRICTION,
  MISSING_PERMISSON
}

const TRANSLATION_TEMPLATES = {
  [ERROR_MESSAGE.BROWSER_STRICTION]: "APP.ERROR_AUTH.BROWSER_STRICTION_TEMPLATE",
  [ERROR_MESSAGE.MISSING_PERMISSON]: "APP.ERROR_AUTH.MISSING_PERMISSON_TEMPLATE"
};

@Component({
  selector: "app-login-error-dialog",
  templateUrl: "./login-error-dialog.component.html",
  styleUrls: ["./login-error-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginErrorDialogComponent {
  public translationTemplates = TRANSLATION_TEMPLATES;

  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public errorMessageType: ERROR_MESSAGE) {}
}
