import { NgModule } from "@angular/core";
import { ContactDialogModule } from "src/app/common/components/dialogs/contact/contact.module";
import { GlossaryDialogModule } from "src/app/common/components/dialogs/glossary/glossary.module";
import { LeadModule } from "src/app/common/components/dialogs/lead/lead.module";
import { NotificationSettingsModule } from "src/app/common/components/dialogs/notification-settings/notification-settings.module";
import { RegionsSwitcherDialogModule } from "src/app/common/components/dialogs/regions-switcher/regions-switcher.module";
import { TextDialogModule } from "src/app/common/components/dialogs/text/text.module";
import { LoginErrorDialogModule } from "src/app/common/components/login-error-dialog/login-error-dialog.module";

@NgModule({
  imports: [
    TextDialogModule,
    GlossaryDialogModule,
    ContactDialogModule,
    RegionsSwitcherDialogModule,
    NotificationSettingsModule,
    LoginErrorDialogModule,
    LeadModule
  ]
})
export class AppDialogsModule {}
