import { loadLaunchpadEffect } from "./effects/admin-data.effects";
import { AppEffects } from "./effects/app.effects";
import { ContentfulEffects } from "./effects/contentful.effects";
import { DialogsEffects } from "./effects/dialogs.effects";
import { CapacityEffects } from "./effects/energy/capacity.effects";
import { Co2SavingEffects } from "./effects/energy/co2-saving.effects";
import { ConnectionGroupsEffects } from "./effects/energy/connection-groups.effects";
import { ConsumptionEffects } from "./effects/energy/consumption.effects";
import { CumulatedInvestEffects } from "./effects/energy/cumulated-invest.effects";
import { RegionEMobilityEffects } from "./effects/energy/region-e-mobility.effects";
import { SelfSufficiencyConsumptionEffects } from "./effects/energy/self-sufficiency/consumption.effects";
import { DegreeEffects } from "./effects/energy/self-sufficiency/degree.effects";
import { SelfSufficiencyProductionEffects } from "./effects/energy/self-sufficiency/production.effects";
import { SummaryEffects } from "./effects/energy/self-sufficiency/summary.effects";
import { StationsEffects } from "./effects/energy/stations.effects";
import { GasCockpitEffects } from "./effects/gas-cockpit.effects";
import { BuildingEffects } from "./effects/infrastructure/building.effects";
import { RegionsEffects } from "./effects/infrastructure/regions.effects";
import { MapEffects } from "./effects/map.effects";
import { NetworkEffects } from "./effects/network.effects";
import { RegionOverlayEffects } from "./effects/overlay.effects";
import { RouterEffects } from "./effects/router.effects";
import { UsersSettingsEffects } from "./effects/users-settings.effects";

export const classBasedEffects = [
  AppEffects,
  ContentfulEffects,
  UsersSettingsEffects,
  DialogsEffects,
  RegionOverlayEffects,
  MapEffects,
  ConsumptionEffects,
  GasCockpitEffects,
  Co2SavingEffects,
  DegreeEffects,
  BuildingEffects,
  NetworkEffects,
  CapacityEffects,
  RegionsEffects,
  SelfSufficiencyConsumptionEffects,
  SelfSufficiencyProductionEffects,
  SummaryEffects,
  RegionEMobilityEffects,
  CumulatedInvestEffects,
  ConnectionGroupsEffects,
  StationsEffects,
  RouterEffects
];

export const functionalEffects = {
  loadLaunchpadEffect
};
