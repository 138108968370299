<mat-accordion [multi]="true">
  <mat-expansion-panel [expanded]="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ "USER_SETTINGS.LIST_USERS.EXTERNAL" | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <nested-table [userTypeFilter]="'external'"></nested-table>
  </mat-expansion-panel>
  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>{{ "USER_SETTINGS.LIST_USERS.INTERNAL" | translate }}</mat-panel-title>
    </mat-expansion-panel-header>
    <nested-table [userTypeFilter]="'internal'"></nested-table>
  </mat-expansion-panel>
</mat-accordion>
