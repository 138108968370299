import { InjectionToken } from "@angular/core";
import { TablePagingService } from "./classes/table-paging-service";
import { TableLoadingConfig } from "./table.interfaces";

export const TABLE_VALUE_FORMAT = new InjectionToken<string>("Digits format for values");

export const TABLE_PAGING_SERVICE = new InjectionToken<TablePagingService<Record<string, any>>>(
  "Default TablePagingService"
);

export const TABLE_LOADING_CONFIG = new InjectionToken<TableLoadingConfig>("Table default loading config");
