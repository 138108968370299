import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  InfrastructureBuildingUpdatesDto,
  InfrastructureCrisisBuildingItemDto
} from "src/app/common/dto/infrastructure/crisis-building-item";
import { BuildingsConnectionGroupDto } from "src/app/common/dto/map/buildings-connection-group";
import {
  BuildingsConnectionGroupEntryDto,
  IBuildingsConnectionGroupEntryDto
} from "src/app/common/dto/map/buildings-connection-group-entry";
import { BuildingUtilization } from "src/app/slots/outage-monitoring/interfaces/bulding-utilization";
import { acceptJsonWithoutCache } from "../../common/constants/common";
import { BuildingDto, IBuildingDto, InfrastructureBuildingDto } from "../../common/dto/infrastructure/building";
import {
  IRegionsPerYearDto,
  IRegionsYearDto,
  RegionsPerYearDto,
  RegionsYearDto
} from "../../common/dto/infrastructure/regions";
import { AppConfig } from "../helpers/app.config";

interface IBuildingsConnectionGroupResponse {
  result: {
    result: {
      electricity?: Array<IBuildingsConnectionGroupEntryDto>;
      gas?: Array<IBuildingsConnectionGroupEntryDto>;
    };
  };
}

interface RequestParams {
  buildingId?: string;
  regionId?: string;
  year?: number;
  years?: Array<number>;
  timelineRange?: [number, number];
  includeResolved?: boolean;
  updates?: Partial<InfrastructureBuildingUpdatesDto["updates"]>;
}

@Injectable({ providedIn: "root" })
export class InfrastructureService {
  private readonly api = AppConfig.connection.infrastructure;

  constructor(private readonly http: HttpClient) {}

  public getBuildingByRegionIdData({ regionId, year }: RequestParams): Observable<BuildingDto> {
    return this.http
      .get<{ result: IBuildingDto }>(`${AppConfig.connection.infrastructure.buildingApi}/regions/${regionId}`, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: new HttpParams().set("year", String(year))
      })
      .pipe(
        map(({ result }) => new BuildingDto(result)),
        catchError(() => of({} as BuildingDto))
      );
  }

  public getBuildingsData({ buildingId, year }: RequestParams): Observable<InfrastructureBuildingDto> {
    return this.http
      .get<{ result: InfrastructureBuildingDto }>(`${this.api.buildingApi}/buildings/${buildingId}`, {
        params: new HttpParams().set("year", String(year))
      })
      .pipe(
        map(({ result }) => result),
        catchError(() => of({ attributes: [] } as InfrastructureBuildingDto))
      );
  }

  public getRegionsData({ regionId, year }: RequestParams): Observable<RegionsYearDto> {
    const uri = `${AppConfig.connection.infrastructure.regionApi}/regions/${regionId}`;

    return this.http
      .get<{ result: IRegionsYearDto }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: new HttpParams().set("year", String(year))
      })
      .pipe(map((response) => new RegionsYearDto(response.result)));
  }

  public getRegionsAllYearsData({
    regionId,
    timelineRange: [from, to]
  }: RequestParams): Observable<Array<IRegionsPerYearDto>> {
    const uri = `${AppConfig.connection.infrastructure.buildingApi}/regions/${regionId}/summary`;

    return this.http
      .get<{ result: Array<IRegionsPerYearDto> }>(uri, {
        params: new HttpParams().set("timeRange", `${from},${to}`)
      })
      .pipe(
        map(({ result }) => result.map((item) => new RegionsPerYearDto(item))),
        catchError(() => EMPTY)
      );
  }

  public getBuildingsConnectionGroup(buildingId: string, year: number): Observable<BuildingsConnectionGroupDto> {
    const url = `${AppConfig.connection.infrastructure.buildingApi}/buildings/${buildingId}/connection-group`;

    return this.http
      .get<IBuildingsConnectionGroupResponse>(url, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: new HttpParams({ fromObject: { year: year.toString() } })
      })
      .pipe(
        map(({ result: { result } }) => result),
        map(({ electricity, gas }) => ({
          electricity: electricity?.map((entry) => new BuildingsConnectionGroupEntryDto(entry)),
          gas: gas?.map((entry) => new BuildingsConnectionGroupEntryDto(entry))
        })),
        map(({ electricity, gas }) => new BuildingsConnectionGroupDto(electricity, gas))
      );
  }

  public updateBuildingAttribute({
    buildingId,
    attribute,
    value
  }: {
    buildingId: string;
    attribute: string;
    value: string;
  }): Observable<void> {
    return this.http.patch<void>(`${AppConfig.connection.infrastructure.buildingApi}/buildings`, {
      buildingId,
      updates: { [attribute]: value }
    });
  }

  public getCrisisBuildings({
    regionId
  }: Pick<RequestParams, "regionId">): Observable<Array<InfrastructureCrisisBuildingItemDto>> {
    return this.http
      .get<{ result: Array<InfrastructureCrisisBuildingItemDto> }>(
        `${this.api.buildingApi}/regions/${regionId}/crisisBuildings`
      )
      .pipe(
        map(({ result }) => result),
        catchError(() => of([]))
      );
  }

  public updateCrisisBuilding({ buildingId, updates }: Pick<RequestParams, "buildingId" | "updates">): Observable<any> {
    return this.http.patch<any>(`${this.api.buildingApi}/buildings`, { buildingId, updates }).pipe();
  }

  public deleteCrisisBuilding({ buildingId }: Pick<RequestParams, "buildingId">): Observable<void> {
    return this.http.delete<void>(`${this.api.buildingApi}/buildings/crisis/${buildingId}`);
  }

  public getBuildingUtilizationMapping(): Observable<Record<string, BuildingUtilization>> {
    return this.http
      .get<{ result: Record<string, BuildingUtilization> }>(`${this.api.buildingApi}/buildings/schema/utilisation`)
      .pipe(
        map(({ result }) => result),
        catchError(() => of({}))
      );
  }
}
