<tpm-dialog-frame [header]="'Produktverwaltung'" [closable]="false" (closed)="onClose()">
  <div *ngrxLet="vm$ as vm" class="product-management-dialog-wrapper">
    <tpm-tab-nav-bar
      class="tab-nav-bar"
      [items]="tabNavItems"
      [active]="vm.tab"
      (tabChange)="onTabChange($event)"
    ></tpm-tab-nav-bar>
    <div [ngSwitch]="vm.tab">
      <ng-container *ngSwitchCase="'userManagement'">
        <tpm-user-management
          [columnsConfig]="vm.columnsConfig"
          [invitations]="vm.invitations"
          [isUpdated]="vm.isUpdated"
          (addUser)="onAddUser()"
          (deleteUser)="onDeleteUser($event)"
          (updateInvitation)="onUpdateInvitation($event)"
          (submit)="onSubmit()"
        ></tpm-user-management>
      </ng-container>
      <ng-container *ngSwitchCase="'contractManagement'">
        <tpm-contract-management
          [contracts]="vm.contracts"
          (terminateContract)="onTerminateContract($event)"
          (downloadInvoice)="onDownloadInvoice($event)"
        ></tpm-contract-management>
      </ng-container>
    </div>
  </div>
</tpm-dialog-frame>
