import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ROUTER_NAVIGATED, RouterNavigatedPayload } from "@ngrx/router-store";
import { Store, select } from "@ngrx/store";
import { map, tap, withLatestFrom } from "rxjs/operators";
import { RouterStateUrl } from "src/app/common/interfaces/router-state-url";
import { MODULES_CRM_IDS_MAP } from "src/app/core/constants/modules-crm-ids-map";
import { isModalPlugin } from "src/app/core/guards/is-modal-plugin.matcher";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { counter } from "src/app/core/utils/ngrx";
import { clearFilters, setPluginId } from "../actions/app.actions";
import { navigateToPlugin, navigateToSlot } from "../actions/router.actions";
import { RootState } from "../root-reducers";
import { selectRegionId } from "../selectors/app.selectors";

@Injectable()
export class RouterEffects {
  navigateToSlot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigateToSlot),
      withLatestFrom(this.store.pipe(select(selectRegionId))),
      tap(([{ slot }, regionId]) => {
        this.router.navigate(["regions", regionId, slot], { replaceUrl: true });
      }),
      tap(() => {
        this.store.dispatch(clearFilters());
      }),
      map(() => setPluginId({ id: null }))
    )
  );

  navigateToPlugin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigateToPlugin),
      withLatestFrom(this.store.pipe(select(selectRegionId))),
      tap(([{ id }, regionId]) => {
        const crmModule = MODULES_CRM_IDS_MAP[id];
        this.router.navigate(["regions", regionId, crmModule], {
          replaceUrl: true,
          queryParams: isModalPlugin(crmModule) ? { overlay: "plugin" } : {}
        });
      }),
      tap(() => {
        this.store.dispatch(clearFilters());
      }),
      map(([{ id }]) => setPluginId({ id }))
    )
  );

  routerNavigated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATED),
        map(({ payload }: { payload: RouterNavigatedPayload<RouterStateUrl> }) => payload.routerState),
        counter(),
        tap(({ index, value }) => {
          // only on init
          if (index === 0) {
            // modal plugins have moduleId in params _and_ slot is set to "launchpad"
            this.analyticsService.trackDirectLink(value.params.moduleId || value.slot);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private readonly router: Router,
    private readonly analyticsService: AnalyticsService
  ) {}
}
