import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Dialog } from "src/app/common/enums/dialogs";
import { openDialog } from "src/app/ngrx/actions/dialogs.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { ITableColumn } from "../../../interfaces/table-column";
import { ITableCombinedValue } from "../../../interfaces/table-combined-value";

@Component({
  selector: "app-table-combined-values-cell",
  templateUrl: "./table-combined-values-cell.component.html",
  styleUrls: ["./table-combined-values-cell.component.scss", "../../../styles/cells.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCombinedValuesCellComponent<T extends object> {
  @Input() public column: ITableColumn;
  @Input() public row: T;

  @Output() public readonly iconClicked = new EventEmitter<T>();

  public trackById(_: number, { id }: ITableCombinedValue): string {
    return id;
  }
}
