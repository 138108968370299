<div *ngIf="!hideHeader" mat-dialog-title [class.no-title-icon]="!titleIcon">
  <div *ngIf="titleIcon" class="title-icon-wrapper">
    <mat-icon class="title-icon" [fontIcon]="titleIcon"></mat-icon>
  </div>
  <h1 class="headline-3">{{ title }}</h1>
  <button mat-dialog-close ene-button size="large" icon="ec_building_delete_address" (click)="closed.emit()"></button>
</div>
<div class="sticked-content">
  <ng-content select="[stickedContent]"></ng-content>
</div>
<mat-dialog-content>
  <ng-content></ng-content>
</mat-dialog-content>
<mat-dialog-actions *ngIf="hasActions">
  <ng-content select="[actions]"></ng-content>
  <div *ngIf="closeButtonLabel || submitButtonLabel" class="dialog-default-actions">
    <button
      *ngIf="closeButtonLabel"
      class="close-button"
      mat-dialog-close
      ene-outline-button
      size="large"
      (click)="closed.emit()"
    >
      {{ closeButtonLabel }}
    </button>
    <button
      *ngIf="submitButtonLabel"
      class="submit-button"
      ene-filled-button
      size="large"
      [disabled]="submitDisabled"
      (click)="submitted.emit()"
    >
      {{ submitButtonLabel }}
    </button>
  </div>
</mat-dialog-actions>
