<div class="link-column link-column-{{ columnConfig.type }}">
  <ng-container *ngIf="columnConfig.type === 'email'; else link">
    <a
      href="mailto:{{ row[columnConfig.id] }}"
      tooltipIfTruncated
      [matTooltip]="row[columnConfig.id]"
      [matTooltipClass]="'table-tooltip'"
      >{{ row[columnConfig.id] }}</a
    >
  </ng-container>
  <ng-template #link>
    <a [attr.href]="row[columnConfig.id]" target="_blank">{{ row[columnConfig.id] }}</a>
  </ng-template>
</div>
