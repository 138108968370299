import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
  selector: "[dragAndDrop]"
})
export class DragAndDropDirective {
  @Output() public dropFile = new EventEmitter<any>();

  @HostListener("drop", ["$event"])
  public onDrop(event: any): void {
    event.preventDefault();

    this.dropFile.emit(event.dataTransfer.files);
  }
}
