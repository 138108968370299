<tpm-card [variant]="'elevation-basic-20'">
  <div class="contentful-list-item" [class.mobile]="isMobileView" (click)="data.actions ? null : listItemClick.emit()">
    <mat-icon *ngIf="!data.image; else image" class="contentful-list-item__icon" [fontIcon]="data.icon"></mat-icon>
    <ng-template #image>
      <img class="contentful-list-item__icon image" [src]="data.image" />
    </ng-template>
    <div class="contentful-list-item__content">
      <div class="contentful-list-item__content__overline body-1">
        <span>{{ data.overline }}</span>
        <span *ngIf="data.date">&nbsp;&ndash;&nbsp;{{ data.date | date : "dd.MM.yyyy" }}</span>
        <tpm-colored-badge
          *ngIf="data.isNew"
          class="new-badge"
          text="NEU"
          [backgroundColor]="'--ene-color-emerald-A500'"
        ></tpm-colored-badge>
        <mat-icon *ngIf="data.checkCritical" class="critical-badge" fontIcon="ec_warning"></mat-icon>
      </div>
      <div class="contentful-list-item__content__headline headline-2">
        {{ data.headline }}
      </div>
      <div class="cms__infotext contentful-list-item__content__infotext body-1" [innerHTML]="data.infotext"></div>
      <div *ngIf="data.actions" class="contentful-list-item__content__actions">
        <button
          *ngFor="let action of data.actions; trackBy: trackByIndex"
          ene-filled-button
          rightIcon
          color="primary"
          icon="ec_pfeil-re"
          [size]="isMobileView ? 'medium' : 'large'"
          (click)="listItemClick.emit(action)"
        >
          {{ action.label }}
        </button>
      </div>
    </div>
  </div>
</tpm-card>
