<div class="carousel-indicators">
  <ng-container *ngFor="let item of items; let index = index">
    <button
      type="button"
      [attr.data-button-target]="index"
      [attr.aria-current]="active === index"
      [class.active]="active === index"
      (click)="onClick(index)"
    ></button>
  </ng-container>
</div>
