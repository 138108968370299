<tpm-dialog-frame
  [closeButtonLabel]="'APP.CANCEL' | translate"
  [submitButtonLabel]="'Ich möchte Wechseln' | translate"
  (closed)="dialogRef.close()"
  (submitted)="dialogRef.close(true)"
>
  <p class="body-1">
    {{
      "Sie wechseln in den Marketplace, in dem Ihnen Dienstleistungen und Produkte außerhalb des Bereichs Netzbetrieb der Netze BW angeboten werden."
        | translate
    }}
  </p>
</tpm-dialog-frame>
