import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { EnergyService } from "../../../core/services/energy.service";
import {
  getCapacityAllYearsData,
  getCapacityData,
  getCapacityDataAllYearsSuccess,
  getCapacityDataSuccess
} from "../../actions/energy/capacity.actions";
import { CapacityPerYearDto, CapacityYearDto } from "../../../common/dto/energy/capacity";

@Injectable()
export class CapacityEffects {
  getCapacityData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCapacityData),
      switchMap(action =>
        this.energyService.getCapacityData(action)
          .pipe(
            map((payload: CapacityYearDto) => getCapacityDataSuccess({ payload })),
            catchError(() => EMPTY)
          )
      )
    )
  );

  getCapacityAllYearsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCapacityAllYearsData),
      switchMap(action =>
        this.energyService.getCapacityAllYearsData(action)
          .pipe(
            map((payload: Array<CapacityPerYearDto>) => getCapacityDataAllYearsSuccess({ payload })),
            catchError(() => EMPTY)
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private energyService: EnergyService
  ) { }
}
