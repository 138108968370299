<app-dialog-wrapper
  titleIcon="ec_help_feedback"
  [title]="'CONTACT_DIALOG.TITLE' | translate"
  [closeButtonLabel]="'APP.CLOSE' | translate"
  [submitButtonLabel]="'FOOTER.KONTAKT.SEND' | translate"
  [submitDisabled]="contactForm.invalid"
  (submitted)="onSubmit()"
>
  <app-button-toggle
    class="contact-button-toggle-group"
    fullWidth
    mobileView
    size="small"
    [active]="id"
    [items]="tabs"
    (buttonChange)="resetValues($event)"
  ></app-button-toggle>
  <ng-container [ngSwitch]="id">
    <ng-container *ngSwitchCase="templates.CONTACTS">
      <ng-container *ngTemplateOutlet="contactsInfoTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="templates.PLATFORM">
      <ng-container *ngTemplateOutlet="contactFormTemplate; context: { $implicit: templates.PLATFORM }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="templates.NETZEBW">
      <ng-container *ngTemplateOutlet="contactFormTemplate; context: { $implicit: templates.NETZEBW }"></ng-container>
    </ng-container>
  </ng-container>
  <div actions>
    <p class="html-translate">
      {{ "CONTACT_DIALOG.CONTACTS_TEMPLATE.ACTIONS_INFO" | translate }}
      <a (click)="openProtectionDialog()">{{ "CONTACT_DIALOG.CONTACTS_TEMPLATE.OPEN_DATA_PROTECTION" | translate }}</a>
    </p>
  </div>
</app-dialog-wrapper>

<!-- Contact info template -->
<ng-template #contactsInfoTemplate>
  <div
    class="html-translate"
    [innerHTML]="'CONTACT_DIALOG.CONTACTS_TEMPLATE.CONTENT_INFO' | translate : (person$ | async) | trustHtml"
  ></div>
  <div class="social-media-icons">
    <a *ngFor="let icon of socialMediaIcons" target="_blank" [attr.href]="icon.url">
      <mat-icon [svgIcon]="icon.name"></mat-icon>
    </a>
  </div>
</ng-template>

<!-- Contact form template -->
<ng-template #contactFormTemplate let-type>
  <form name="contactForm" *ngIf="!hasSubmitMessage; else submitMessage" class="contact-form" [formGroup]="contactForm">
    <div class="input-field">
      <label class="input-field__label">{{ labels[type] | translate }}</label>
      <mat-select
        data-cy="feedbackTopic"
        class="input-field__input"
        formControlName="topic"
        [placeholder]="'Bitte wählen'"
      >
        <mat-option *ngFor="let option of options[type]" [value]="option.value">
          {{ option.viewValue | translate }}
        </mat-option>
      </mat-select>
    </div>
    <div class="input-field" *ngIf="topic.value === 'errors'">
      <label class="input-field__label">{{ "APP.ERROR_FORM_SELECT_ISSUE_LABEL" | translate }}</label>
      <mat-select
        data-cy="feedbackErrorType"
        class="input-field__input"
        formControlName="occurred"
        [placeholder]="'APP.PLACEHOLDER' | translate"
      >
        <mat-option *ngFor="let option of occuredErrorsSelect" [value]="option.value">
          {{ option.viewValue | translate }}
        </mat-option>
      </mat-select>
    </div>
    <div class="input-field">
      <label class="input-field__label">{{ "APP.MESSAGE_REQUIRED" | translate }}</label>
      <p class="input-field__note" *ngIf="topic.value === 'errors'">{{ "APP.MESSAGE_INFO" | translate }}</p>
      <textarea
        class="input-field__input"
        [class.required]="text.dirty && text.invalid"
        placeholder="Bitte eingeben..."
        formControlName="text"
      ></textarea>
    </div>
  </form>
  <ng-template #submitMessage>
    <div class="submit-message-container">
      <p class="headline-1">{{ "Vielen Dank für Ihre Mitteilung!" | translate }}</p>
    </div>
  </ng-template>
</ng-template>
