import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { Co2SavingPerYearDto, Co2SavingYearDto } from "../../../common/dto/energy/co2-saving";
import { EnergyService } from "../../../core/services/energy.service";
import {
  getCo2SavingAllYearsData,
  getCo2SavingAllYearsDataSuccess,
  getCo2SavingData,
  getCo2SavingDataSuccess
} from "../../actions/energy/co2-saving.actions";

@Injectable()
export class Co2SavingEffects {
  getCo2SavingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCo2SavingData),
      switchMap((action) =>
        this.energyService.getCo2SavingData(action).pipe(
          map((payload: Co2SavingYearDto) => getCo2SavingDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getCo2SavingAllYearsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCo2SavingAllYearsData),
      switchMap((action) =>
        this.energyService.getCo2SavingAllYearsData(action).pipe(
          map((payload: Array<Co2SavingPerYearDto>) => getCo2SavingAllYearsDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private energyService: EnergyService) {}
}
