<tpm-dialog-frame [closable]="false">
  <p class="body-2 description">
    {{ "Es gibt nicht gespeicherte Änderungen. Möchten Sie diese vor dem Schließen speichern?" | translate }}
  </p>
  <div class="actions-wrapper">
    <button ene-outline-button [size]="'large'" (click)="dialogRef.close()">{{ "abbrechen" | translate }}</button>
    <button ene-outline-button [size]="'large'" (click)="dialogRef.close(false)">
      {{ "nicht speichern" | translate }}
    </button>
    <button ene-filled-button [size]="'large'" (click)="dialogRef.close(true)">{{ "speichern" | translate }}</button>
  </div>
</tpm-dialog-frame>
