import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import "@enersis/gp-components/gp-list";
import "@enersis/gp-components/gp-select";
import { SelectComponent } from "./select.component";

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [SelectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SelectModule {}
