<ng-container matColumnDef>
  <td mat-cell *matCellDef="let row" [attr.colspan]="colspan">
    <ng-container *ngIf="hasPlaceholder(_config)">
      <div *ngIf="_config.rowAccessor && !_config.rowComponent" class="column-multi-row__placeholder">
        {{ rowAccessor(row) }}
      </div>
      <div *ngIf="_config.rowComponent">
        <ngmy-multi-row-resolver
          [component]="_config.rowComponent"
          [dataAccessor]="_config.rowComponentDataAccessor"
          [row]="row"
        ></ngmy-multi-row-resolver>
      </div>
    </ng-container>
    <div
      *ngIf="_config.expansionComponent"
      class="column-multi-row__expansion-content"
      [@expand]="(expandedRows.has(row) ? expandedRows.get(row) : _config.expanded) ? 'expanded' : 'collapsed'"
    >
      <ngmy-multi-row-resolver
        [component]="_config.expansionComponent"
        [dataAccessor]="_config.expansionComponentDataAccessor"
        [row]="row"
      ></ngmy-multi-row-resolver>
    </div>
  </td>
</ng-container>
