import { NgModule } from "@angular/core";
import { CustomTooltipDirective } from "./custom-tooltip/custom-tooltip.directive";
import { NoWhitespaceDirective } from "./no-whitespace/no-whitespace.directive";
import { TooltipIfTruncatedDirective } from "./tooltip-if-truncated/tooltip-if-truncated.directive";

@NgModule({
  declarations: [NoWhitespaceDirective, TooltipIfTruncatedDirective, CustomTooltipDirective],
  exports: [NoWhitespaceDirective, TooltipIfTruncatedDirective, CustomTooltipDirective]
})
export class CommonDirectivesModule {}
