import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { INotificationSettings } from "../../../../../../../interfaces/dialogs/notification-settings";
import { accountingBadges } from "../../../../constants/accounting-badges";

@Component({
  selector: "app-accounting-notification-settings-edit-email",
  templateUrl: "./notification-settings-edit-email.component.html",
  styleUrls: ["../../style.scss"]
})
export class AccountingNotificationSettingsEditEmailComponent {
  @Input() public formGroup: FormGroup;
  @Input() public indeterminate: INotificationSettings;
  public badges = accountingBadges;
}
