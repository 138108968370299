import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "abs"
})
export class AbsPipe implements PipeTransform {
  public transform(value: number | string): number {
    return Math.abs(Number(value));
  }
}
