<gp-toggle-button-group
  class="secondary"
  data-cy="appButtonToggle"
  [ngClass]="_size"
  [class.full-width]="_fullWidth"
  [class.dark]="dark"
  [attr.size]="_size"
  [attr.minWidth]="!_fullWidth ? '' : null"
>
  <gp-toggle-button
    *ngFor="let item of items; trackBy: trackByValue"
    [disableRipple]="true"
    [disabled]="item.disabled || item.preventedClick"
    [active]="item.value === active"
    (click)="onClick(item)"
  >
    {{ item.label | translate }}
  </gp-toggle-button>
</gp-toggle-button-group>
