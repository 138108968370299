import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LegacyProgressBarMode } from "@angular/material/legacy-progress-bar";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-progress-bar",
  templateUrl: "progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressBarComponent {
  @Input() public mode: LegacyProgressBarMode = "determinate";
  @Input() public value = null;
}
