import { createAction, props } from "@ngrx/store";
import { RegionsEMobilityDto } from "../../../common/dto/energy/regions-e-mobility";

export const getRegionsEMobilityAllYearsData = createAction(
  "[Regions E-Mobility] Get regions e-mobility data",
  props<{
    regionId: string;
    scenarioId: string;
    timelineRange: [number, number];
  }>()
);

export const getRegionsEMobilityAllYearsDataSuccess = createAction(
  "[Regions E-Mobility] Get regions e-mobility data success",
  props<{
    payload: Array<RegionsEMobilityDto>;
  }>()
);
