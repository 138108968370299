<ng-container [formGroup]="formGroup">
  <mat-form-field class="settings-form__email-input">
    <mat-label>{{ "NOTIFICATION_SERVICE.EDIT.EMAIL_ADDRESS" | translate }}</mat-label>
    <input matInput formControlName="email" />
    <mat-icon matSuffix fontIcon="ec_edit"></mat-icon>
  </mat-form-field>
  <mat-divider big></mat-divider>
  <div class="settings-form__email-checkboxes">
    <div class="settings-form__checkbox-wrapper">
      <mat-checkbox
        formControlName="startEnd"
        [disableRipple]="true"
        [class.warn]="checkboxWarning === startEndControl"
        [(indeterminate)]="indeterminate.startEnd"
        (click)="validateCheckboxes()"
        >{{ "NOTIFICATION_SERVICE.EDIT." + division + ".START_END" | translate }}</mat-checkbox
      >
      <div class="squares">
        <app-square [data]="notificationSquares.start"></app-square>
        <app-square [data]="notificationSquares.end"></app-square>
      </div>
    </div>
    <mat-list>
      <mat-list-item>
        <div class="settings-form__checkbox-wrapper">
          <mat-checkbox
            [disableRipple]="true"
            formControlName="update"
            [(indeterminate)]="indeterminate.update"
            (click)="validateCheckboxes(startEndControl)"
            >{{ "NOTIFICATION_SERVICE.EDIT." + division + ".UPDATE_DESCRIPTION" | translate }}</mat-checkbox
          >
          <div class="squares">
            <app-square [data]="notificationSquares.update"></app-square>
          </div>
        </div>
      </mat-list-item>
      <mat-list-item>
        <div class="settings-form__checkbox-wrapper">
          <mat-checkbox
            [disableRipple]="true"
            formControlName="intermediate"
            [(indeterminate)]="indeterminate.intermediate"
            (click)="validateCheckboxes(startEndControl)"
            >{{ "NOTIFICATION_SERVICE.EDIT." + division + ".INTERMEDIATE_DESCRIPTION" | translate }}</mat-checkbox
          >
          <div class="squares">
            <app-square [data]="notificationSquares.intermediate"></app-square>
          </div>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</ng-container>
