import { DOCUMENT } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from "@angular/core";
import { MatLegacySliderChange } from "@angular/material/legacy-slider";
import { Observable } from "rxjs";
import { FullscreenService } from "../../services/fullscreen.service";

enum Volume {
  OFF = 0,
  ON
}

/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
@Component({
  selector: "media-controls",
  templateUrl: "./media-controls.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "media-controls"
  }
})
export class MediaControlsComponent implements OnInit, OnDestroy {
  @ContentChild("player", { read: ElementRef, static: true })
  public player!: ElementRef;

  /** Player configs */
  @Input() public autoPlay = false;
  @Input() public muted = false;

  /** Controls configs */
  @Input() public disablePlayControl = true;
  @Input() public displayVolumeControl = true;
  @Input() public displayFullscreenControl = false;

  /** Path to file */
  @Input() public source!: string;

  @Input()
  public set startOffset(value: number) {
    this.currentTime = value;
  }

  public renderedTime: string | null = null;
  public currentTime = 0;
  public durationTime: number;
  public isFulscreen$: Observable<boolean>;

  public _paused: boolean;
  public _muted: boolean;

  private listenersMap = new Map<string, () => void>();

  constructor(
    private fsService: FullscreenService,
    @Inject(DOCUMENT) private document: Document,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._paused = this.autoPlay;
    this._muted = this.muted;
    this.isFulscreen$ = this.fsService.isFullscreen$;

    this.listenersMap.set("timeupdate", () => {
      this.currentTime = this.playerEl.currentTime;
      this.cdr.detectChanges();
    });

    this.listenersMap.set("loadedmetadata", () => {
      this.durationTime = this.playerEl.duration;
      this.cdr.detectChanges();

      if (this.source && this.autoPlay) {
        this.playerEl.play();
      }
    });

    this.listenersMap.set("ended", () => {
      this.currentTime = 0;
      this.togglePlay();
      this.cdr.detectChanges();
    });

    this.ngZone.runOutsideAngular(() =>
      this.listenersMap.forEach((callback, eventName) => this.playerEl.addEventListener(eventName, callback))
    );
  }

  public ngOnDestroy(): void {
    this.playerEl.pause();
    this.listenersMap.forEach((callback, eventName) => this.playerEl.removeEventListener(eventName, callback));
    this.listenersMap.clear();
  }

  public togglePlay(): void {
    this._paused = !this._paused;

    if (this._paused) {
      this.playerEl.play();
    } else {
      this.playerEl.pause();
    }
  }

  public toggleVolume(): void {
    this._muted = !this._muted;
    this.renderer.setProperty(this.playerEl, "volume", this._muted ? Volume.OFF : Volume.ON);
  }

  public toggleFullscreen(): void {
    this.fsService.toggleFullscreen(this.elementRef.nativeElement);
  }

  public onCurrentTimeChange({ value }: MatLegacySliderChange): void {
    this.playerEl.currentTime = value;
  }

  private get playerEl(): HTMLMediaElement {
    return this.player.nativeElement;
  }
}
