<div class="mat-dialog-header">
  <div mat-dialog-title class="mat-dialog-title">
    <mat-icon class="circle" fontIcon="ec_help_problem"></mat-icon>
    <h1>{{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.TITLE" | translate }}</h1>
  </div>
  <button class="big" mat-button (click)="closeDialog(false)">
    <mat-icon fontIcon="ec_building_delete_address"></mat-icon>
  </button>
</div>

<mat-dialog-content>
  <section class="warning-area">
    <p>
      <b>{{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.TEXT_1" | translate }}</b>
    </p>
    <p>{{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.TEXT_2" | translate }}</p>
    <p class="warn">
      <b>{{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.WARN_NOTE" | translate }}</b>
    </p>
  </section>
</mat-dialog-content>

<mat-dialog-actions>
  <section class="validation-checkboxes">
    <h3 class="validation-checkboxes__title">
      {{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.ACTIONS.CHECKBOXES.TITLE" | translate }}
    </h3>
    <form [formGroup]="confirmForm" class="validation-checkboxes__form">
      <mat-checkbox formControlName="moduleConfigurationCorrect">
        {{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.ACTIONS.CHECKBOXES.MODULE_CONFIGURATION" | translate }}
      </mat-checkbox>
      <mat-checkbox formControlName="coaCorrect">
        {{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.ACTIONS.CHECKBOXES.COA" | translate }}
      </mat-checkbox>
      <mat-checkbox formControlName="municipalityNamesCorrect">
        {{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.ACTIONS.CHECKBOXES.MUNICIPALITY_NAMES" | translate }}
      </mat-checkbox>
    </form>
  </section>
  <button color="accent" mat-stroked-button (click)="closeDialog(false)">
    {{ "APP.CANCEL" | translate | uppercase }}
  </button>
  <button color="accent" class="big" mat-flat-button [disabled]="confirmForm.invalid" (click)="closeDialog(true)">
    {{ "CONFIGURATION_AREA.DIALOGS.CONFIRM.ACTIONS.SUBMIT" | translate }}
  </button>
</mat-dialog-actions>
