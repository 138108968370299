import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule } from "@angular/material/legacy-list";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacySlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { EneButtonModule } from "@enersis/ui-button";
import { EneTabsModule } from "@enersis/ui-tabs";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { DragAndDropDirective } from "../../../directives/drag-and-drop.directive";
import { ConfigAreaConfirmDialogModule } from "../confirm/confirm.module";
import { CAContactSettingsTabComponent } from "./components/contact-settings/contact-settings.component";
import { CAModuleSettingsTabComponent } from "./components/module-settings/module-settings.component";
import { CAMunicipalSettingsTabComponent } from "./components/municipal-settings/municipal-settings.component";
import { ConfigAreaSettingsDialogComponent } from "./settings.component";

const materialModules = [
  MatLegacyDialogModule,
  MatLegacyButtonModule,
  MatIconModule,
  MatLegacyCheckboxModule,
  MatLegacyInputModule,
  MatLegacyFormFieldModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatDividerModule,
  MatExpansionModule,
  MatLegacySelectModule,
  MatLegacyListModule,
  MatLegacySlideToggleModule
];

@NgModule({
  declarations: [
    ConfigAreaSettingsDialogComponent,
    CAMunicipalSettingsTabComponent,
    CAContactSettingsTabComponent,
    CAModuleSettingsTabComponent,
    DragAndDropDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    EneTabsModule,
    ConfigAreaConfirmDialogModule,
    PipesModule,
    EneButtonModule,
    ...materialModules
  ]
})
export class ConfigAreaSettingsDialogModule {}
