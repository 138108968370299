import { Directive, Inject, Optional } from "@angular/core";
import { TableComponent } from "../container/table.component";
import { TableAction, TableColumnContext } from "../table.interfaces";
import { TABLE_COLUMN_CONTEXT, TableColumn } from "./table-column";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class TableActionColumn<T extends Record<string, any> = Record<string, any>> extends TableColumn<T> {
  constructor(
    @Inject(TABLE_COLUMN_CONTEXT) public readonly context: TableColumnContext<T>,
    @Optional() protected readonly table: TableComponent<T>
  ) {
    super(context);
  }

  public onTableAction(action: TableAction<T>): void {
    if (this.table.selections) {
      this.table.dataSource.setSelected(action.index);
    }

    this.table.tableAction.emit(action);
  }
}
