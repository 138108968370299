import { createAction, props } from "@ngrx/store";
import { ConsumptionDto } from "../../../common/dto/energy/consumption";

export const getConsumptionData = createAction(
  "[Consumption] Get consumption data",
  props<{
    regionId: string;
    scenarioId: string;
    year: number;
  }>()
);

export const getConsumptionDataSuccess = createAction(
  "[Consumption] Get consumption data success",
  props<{
    payload: ConsumptionDto;
  }>()
);
