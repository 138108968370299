import { AsyncPipe, DatePipe, NgClass, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, Optional, TrackByFunction } from "@angular/core";
import { EneButtonModule } from "@enersis/ui-button";
import { EneLayoutService } from "@enersis/ui-helper";
import { Store } from "@ngrx/store";
import { ColoredBadgeModule } from "src/app/common/components/colored-badge/colored-badge.module";
import { IconModule } from "src/app/common/components/icon/icon.module";
import { MediaPlayerModule } from "src/app/common/components/media-player/media-player.module";
import { ContentfulActionField } from "src/app/core/interfaces/contentful";
import { OVERLAY_DATA, OverlayService } from "src/app/core/services/overlay.service";
import { QueryParamsManagerService } from "src/app/core/services/query-params-manager.service";
import { updateUsersSettingsUsersStorage } from "src/app/ngrx/actions/users-settings.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { NewsActionsManagerService } from "../../news-actions-manager.service";
import { NetzeBwNewsEntry } from "../../news.interfaces";
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-card-news-details",
  templateUrl: "./card-news-details.component.html",
  styleUrls: ["./card-news-details.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    AsyncPipe,
    DatePipe,
    IconModule,
    EneButtonModule,
    ColoredBadgeModule,
    MediaPlayerModule
  ]
})
export class CardNewsDetailsComponent {
  readonly isMobileView$ = this.layoutService.isMobileScreen$;

  constructor(
    @Optional()
    @Inject(OVERLAY_DATA)
    readonly data: { selected: NetzeBwNewsEntry },
    private readonly store: Store<RootState>,
    private readonly layoutService: EneLayoutService,
    private readonly overlayService: OverlayService,
    private readonly newsActionsManagerService: NewsActionsManagerService,
    private readonly queryParamsManagerService: QueryParamsManagerService
  ) {
    if (data?.selected?.isNew) {
      this.store.dispatch(updateUsersSettingsUsersStorage({ items: [{ key: data?.selected.id, value: true }] }));
    }
  }

  readonly trackByLabel: TrackByFunction<{ label: string }> = (_, item): string => item.label;

  onActionClick(actionField: ContentfulActionField): void {
    this.newsActionsManagerService.dispatch(actionField.action, actionField.url);
  }

  onClose(): void {
    this.overlayService.close(CardNewsDetailsComponent);
    this.queryParamsManagerService.removeQueryParams();
  }
}
