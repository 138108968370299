export interface IElectricityStationDto {
  workload: number;
  installedStationCapacity: number;
  cumulatedInvestment: number;
}

export class ElectricityStationDto implements IElectricityStationDto {
  public workload: number;
  public installedStationCapacity: number;
  public cumulatedInvestment: number;

  constructor(data: IElectricityStationDto) {
    Object.assign(this, data);
  }
}
