<tpm-dialog-frame icon="ec_mplayer_vol-high" [header]="data.title | translate">
  <div class="story-teller-dialog-content">
    <p class="headline-3">{{ "LAUNCHPAD.STORYTELLER.CONTENT_TITLE" | translate }}</p>
    <audio-player *ngIf="data.media?.type === 'audio'" [source]="data.media?.url" [title]="data.title | translate"></audio-player>
    <ng-container *ngIf="data.description">
      <p class="body-3">{{ "LAUNCHPAD.STORYTELLER.TRANSCRIPTION_LABEL" | translate }}</p>
      <p class="body-3 transcription" [innerHTML]="data.description | translate"></p>
    </ng-container>
  </div>
</tpm-dialog-frame>
