import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { acceptJsonWithoutCache } from "../../common/constants/common";
import { map } from "rxjs/operators";
import {
  GasCockpitValuesAllYearsDto,
  IGasCockpitValuesAllYearsDto,
  INetworkDto,
  NetworkDto
} from "../../common/dto/network";
import { AppConfig } from "../helpers/app.config";

@Injectable({
  providedIn: "root"
})
export class NetworkService {
  constructor(private http: HttpClient) {}

  public getNetworkIds(regionId: string): Observable<Array<NetworkDto>> {
    const uri = `${AppConfig.connection.infrastructure.gridApi}/networks`;

    return this.http
      .get<{ result: Array<INetworkDto> }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: new HttpParams().set("regionId", regionId)
      })
      .pipe(map((response) => response.result.map((item) => new NetworkDto(item))));
  }

  public getGasCockpitValuesAllYears({ gasNetworkId, timelineRange }): Observable<GasCockpitValuesAllYearsDto> {
    const uri = `${AppConfig.connection.infrastructure.gridApi}/networks/${gasNetworkId}?year=${
      timelineRange[0] - 1
    }&year=${timelineRange[1]}`;

    return this.http
      .get<{ result: IGasCockpitValuesAllYearsDto }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache)
      })
      .pipe(map((response) => new GasCockpitValuesAllYearsDto(response.result)));
  }
}
