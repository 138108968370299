<div class="column-wrapper">
  <tpm-select
    *ngIf="!(hideSelect($any(columnConfig), row) === true)"
    [style.width]="columnConfig.style?.width ?? null"
    [fixedMenuPosition]="true"
    [appearance]="'outline'"
    [options]="options($any(columnConfig), row)"
    [value]="value($any(columnConfig), row)"
    (closed)="onTableAction({ id: columnConfig.id, type: 'click', row: row, index: index, selected: $event })"
  ></tpm-select>
  <div *ngIf="label($any(columnConfig), row) as label">
    {{ label }}
  </div>
</div>
