import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppComponent } from "src/app/components/app/app.component";
import { DualRolesPortalComponent } from "src/app/components/dual-roles-portal/dual-roles-portal.component";
import { CanActivateAccountingChangelogGuard } from "../guards/can-activate-accounting-changelog.guard";
import { CanActivateAppGuard } from "../guards/can-activate-app.guard";
import { canActivateDualRolesPortalGuard } from "../guards/can-activate-dual-roles-portal.guard";

const routes: Routes = [
  /** Empty path redirection */
  {
    path: "",
    pathMatch: "full",
    canActivate: [CanActivateAppGuard],
    component: AppComponent
  },
  /** Keycloak extra login path redirection */
  {
    path: "login",
    redirectTo: "",
    pathMatch: "full"
  },
  {
    path: "portal",
    pathMatch: "full",
    canActivate: [canActivateDualRolesPortalGuard],
    component: DualRolesPortalComponent
  },
  {
    path: "regions",
    loadChildren: () => import("../../features/regions/regions.module").then((m) => m.RegionsFeatureModule)
  },
  {
    path: "admin-portal",
    canActivate: [CanActivateAccountingChangelogGuard],
    loadChildren: () =>
      import("../../features/accounting-changelog/accounting-changelog.module").then((m) => m.AccountingChangelogModule)
  },
  /** Wrong path redirection */
  {
    path: "**",
    redirectTo: ""
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
