import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { init, browserTracingIntegration, replayIntegration } from "@sentry/angular-ivy";
import { AppModule } from "./app/app.module";
import { EnvironmentType } from "./app/common/enums/environmentType";
import { AppConfig } from "./app/core/helpers/app.config";
import { environment } from "./environments/environment";

const enableSentry = () => {
  const environmentNameMap = {
    [EnvironmentType.PROD]: "production",
    [EnvironmentType.RC]: "staging",
    [EnvironmentType.DEV]: "development"
  };

  init({
    dsn: "https://46415e6b4671d3e079cb88af537e40b9@o4505917412343808.ingest.sentry.io/4506157169704960",
    environment: environmentNameMap[environment.type],
    release: environment.buildId,
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
};

// For better debugging
console.log("BuildId", environment.buildId);

if (environment.type === EnvironmentType.PROD) {
  enableProdMode();
}

if (environment.type !== EnvironmentType.DEV) {
  enableSentry();
}

AppConfig.getInstance()
  .loadConnections()
  .then(() => AppConfig.getInstance().loadConfigs())
  .then(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
