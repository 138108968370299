import { createAction, props } from "@ngrx/store";
import {
  SelfSufficiencyDegreePerYearDto,
  SelfSufficiencyDegreeYearDto
} from "../../../../common/dto/energy/self-sufficiency/degree";

export const getSelfSufficiencyDegreeData = createAction(
  "[SelfSufficiency Degree] Get degree data",
  props<{
    regionId: string;
    scenarioId: string;
    year: number;
  }>()
);

export const getSelfSufficiencyDegreeDataSuccess = createAction(
  "[SelfSufficiency Degree] Get degree data success",
  props<{
    payload: SelfSufficiencyDegreeYearDto;
  }>()
);

export const getSelfSufficiencyDegreeAllYearsData = createAction(
  "[SelfSufficiency Degree] Get degree all years data",
  props<{
    regionId: string;
    scenarioId: string;
    timelineRange: [number, number];
  }>()
);

export const getSelfSufficiencyDegreeAllYearsDataSuccess = createAction(
  "[SelfSufficiency Degree] Get degree all years data success",
  props<{
    payload: Array<SelfSufficiencyDegreePerYearDto>;
  }>()
);
