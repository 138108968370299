<ng-container [formGroup]="formGroup">
  <mat-form-field class="settings-form__email-input">
    <mat-label>{{ "NOTIFICATION_SERVICE.EDIT.EMAIL_ADDRESS" | translate }}</mat-label>
    <input matInput formControlName="email" />
    <mat-icon matSuffix fontIcon="ec_edit"></mat-icon>
  </mat-form-field>
  <mat-divider big></mat-divider>
  <div class="settings-form__email-checkboxes">
    <div class="settings-form__checkbox-wrapper">
      <mat-checkbox
        formControlName="operationNews"
        [disableRipple]="true"
        [(indeterminate)]="indeterminate.operationNews"
      >
        {{ "NOTIFICATION_SERVICE.EDIT.NEWS.OPERATION_NEWS" | translate }}
      </mat-checkbox>
      <app-badge [badge]="operationNewsBadge"></app-badge>
    </div>
    <div class="settings-form__checkbox-wrapper">
      <mat-checkbox [disableRipple]="true" formControlName="serviceNews" [(indeterminate)]="indeterminate.serviceNews">
        {{ "NOTIFICATION_SERVICE.EDIT.NEWS.SERVICE_NEWS" | translate }}
      </mat-checkbox>
      <app-badge [badge]="serviceNewsBadge"></app-badge>
    </div>
    <div class="settings-form__checkbox-wrapper">
      <mat-checkbox
        [disableRipple]="true"
        formControlName="criticalNews"
        [(indeterminate)]="indeterminate.criticalNews"
      >
        {{ "NOTIFICATION_SERVICE.EDIT.NEWS.CRITICAL_NEWS" | translate }}
      </mat-checkbox>
      <app-badge [badge]="criticalNewsBadge"></app-badge>
    </div>
  </div>
</ng-container>
