import { ContactTemplate } from "../enums/dialogs/contact-templates";
import { IContactFormOption } from "../interfaces/dialogs/contact";

export const contactFormLabels: { [key: string]: string } = {
  [ContactTemplate.PLATFORM]: "APP.FEEDBACK_FORM_SELECT_LABEL",
  [ContactTemplate.NETZEBW]: "APP.REPORT_ERROR_SELECT_LABEL"
};

export const contactFormOptions: { [key: string]: Array<IContactFormOption> } = {
  [ContactTemplate.PLATFORM]: [
    {
      value: "improvmentIdeas",
      viewValue: "Verbesserungsvorschläge"
    },
    {
      value: "errors",
      viewValue: "Fehler"
    },
    {
      value: "other",
      viewValue: "Sonstiges"
    }
  ],
  [ContactTemplate.NETZEBW]: [
    {
      value: "request",
      viewValue: "Anfrage"
    },
    {
      value: "complaint",
      viewValue: "Beschwerde"
    },
    {
      value: "feedback",
      viewValue: "Feedback"
    },
    {
      value: "other",
      viewValue: "Sonstiges"
    }
  ]
};

export const occuredErrorsOptions = [
  {
    value: "cockpit",
    viewValue: "APP.ERROR_FORM_SELECT_ISSUE.COCKPIT"
  },
  {
    value: "maptopics",
    viewValue: "APP.ERROR_FORM_SELECT_ISSUE.MAPTOPICS"
  },
  {
    value: "selection",
    viewValue: "APP.ERROR_FORM_SELECT_ISSUE.SELECTION"
  },
  {
    value: "miscellaneous",
    viewValue: "APP.ERROR_FORM_SELECT_ISSUE.MISCELLANEOUS"
  },
  {
    value: "search",
    viewValue: "APP.ERROR_FORM_SELECT_ISSUE.SEARCH"
  }
];
