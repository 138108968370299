<ng-container *ngIf="!loading; else loadingTpl">
  <ng-container *ngIf="column.customTemplate; else defaultTemplates">
    <ng-container
      [ngComponentOutlet]="column.customTemplate"
      [ngComponentOutletInjector]="componentInjector"
    ></ng-container>
  </ng-container>
  <ng-template #defaultTemplates>
    <ng-container [ngSwitch]="column.type">
      <ng-container *ngSwitchCase="'icon'">
        <i class="ene-icon" *ngIf="row[column.id]" [ngClass]="row[column.id]"></i>
      </ng-container>
      <span
        class="state-cell-dot"
        *ngSwitchCase="'state'"
        [matTooltipClass]="'light-tooltip'"
        [matTooltip]="'REVIEW_STATE.' + row[column.id] | translate"
        [matTooltipDisabled]="!row[column.id]"
        [ngClass]="row[column.id]"
      ></span>
      <ng-container *ngSwitchCase="'image'">
        <div class="image-cell-img" *ngIf="row[column.id]; else imageAlt">
          <img [src]="row[column.id]" />
        </div>
        <ng-template #imageAlt>{{ row.alt }}</ng-template>
      </ng-container>
      <!-- experimental type of cell -->
      <span class="badge-cell" *ngSwitchCase="'badge'" [ngClass]="row[column.id]">
        {{ column.key + "_" + row[column.id] | uppercase | translate }}
      </span>
      <ng-container *ngSwitchCase="'combined-values'">
        <app-table-combined-values-cell
          [column]="column"
          [row]="row"
          (iconClicked)="cellClicked.emit($event)"
        ></app-table-combined-values-cell>
      </ng-container>
      <ng-container *ngSwitchCase="'action'">
        <i
          class="action-cell-icon ene-icon"
          *ngIf="column.actionIcon; else textualButton"
          [ngClass]="column.actionIcon"
          [matTooltip]="column.key | translate"
          [matTooltipDisabled]="!column.key"
          [matTooltipClass]="'light-tooltip'"
          (click)="cellClicked.emit({ id: column.id, type: 'click', element: row })"
        ></i>
        <ng-template #textualButton>
          <button
            class="action-cell-button small"
            mat-stroked-button
            color="accent"
            [disabled]="isButtonDisabled(column, row)"
            (click)="cellClicked.emit({ id: column.id, type: 'click', element: row })"
          >
            {{ column.actionLabel }}
          </button>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
<ng-template #loadingTpl>
  <app-skeleton-loader-box height="25px"></app-skeleton-loader-box>
</ng-template>
