import { inject, Injectable } from "@angular/core";
import { CookieService as NgxCookieService } from "ngx-cookie-service";
import { Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class CookieService {
  private readonly ngxCookie = inject(NgxCookieService);

  readonly onChanged = new Subject<string | undefined | void>();

  check(name: string): boolean {
    return this.ngxCookie.check(name);
  }

  get(name: string): string {
    return this.ngxCookie.get(name);
  }

  getAll(): { [key: string]: string } {
    return this.ngxCookie.getAll();
  }

  set(name: string, value: string, expiresOrOptions?: number | Date): void {
    this.ngxCookie.set(name, value, expiresOrOptions, "/");
    this.onChanged.next(name);
  }

  delete(name: string): void {
    this.ngxCookie.delete(name, "/");
    this.onChanged.next(name);
  }

  deleteAll(): void {
    this.ngxCookie.deleteAll("/");
    this.onChanged.next();
  }
}
