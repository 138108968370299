<ng-container matColumnDef="select" [sticky]="sticky">
  <th *matHeaderCellDef mat-header-cell>
    <mat-checkbox
      [disableRipple]="true"
      [disabled]="isMasterToggleDisabled()"
      [checked]="selectionsCollection.hasValue() && isAllSelected()"
      [indeterminate]="selectionsCollection.hasValue() && !isAllSelected()"
      (change)="masterToggle()"
    ></mat-checkbox>
  </th>
  <td *matCellDef="let row" mat-cell>
    <mat-checkbox
      [disableRipple]="true"
      [disabled]="disableSelectionsAccessor(row)"
      [checked]="selectionsCollection.isSelected(row)"
      (click)="$event.stopPropagation()"
      (change)="selectionsCollection.toggle(row)"
    ></mat-checkbox>
  </td>
  <td *matFooterCellDef mat-footer-cell></td>
</ng-container>
