import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  Self
} from "@angular/core";
import { KpiItemComponent } from "../components/kpi-item/kpi-item.component";

/* eslint-disable @angular-eslint/no-host-metadata-property */
@Directive({
  selector: "kpi-item[kpiAction]",
  host: {
    class: "kpi-item-action",
    "[class.kpi-item-action--active]": "kpiActionActive",
    "[class.kpi-item-action--hide]": "kpiActionHide"
  }
})
export class KpiActionDirective implements AfterViewInit {
  @Input()
  public kpiActionActive: boolean;

  @Input()
  public kpiActionHide: boolean;

  @Output()
  public kpiActionClick = new EventEmitter<string | number>();

  private iconEl: HTMLElement;

  constructor(
    @Self() private readonly kpiItem: KpiItemComponent,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  @HostListener("click")
  public onClick(): void {
    if (this.kpiItem) {
      this.kpiActionClick.emit(this.kpiItem.id);
    }
  }

  public ngAfterViewInit(): void {
    this.iconEl = this.renderer.createElement("i");

    this.renderer.addClass(this.iconEl, "ec_controls_collapsed_dropdown");
    this.renderer.addClass(this.iconEl, "kpi-item-action-icon");
    this.renderer.appendChild(this.elementRef.nativeElement, this.iconEl);
  }
}
