import { Square } from "../components/square/square.component";

export const NotificationSquares: { [key: string]: { [key: string]: Square } } = {
  electricity: {
    start: {
      id: "start",
      type: "start",
      label: "S",
      key: "NOTIFICATION_SERVICE.ON_START"
    },
    intermediate: {
      id: "intermediate",
      type: "intermediate",
      label: "Z",
      key: "NOTIFICATION_SERVICE.INTERMEDIATE"
    },
    update: {
      id: "update",
      type: "update",
      label: "S",
      key: "NOTIFICATION_SERVICE.ONCE_UPDATE"
    },
    end: {
      id: "end",
      type: "end",
      label: "E",
      key: "NOTIFICATION_SERVICE.ON_END"
    }
  },
  gas: {
    start: {
      id: "start",
      type: "start-gas",
      label: "G",
      key: "NOTIFICATION_SERVICE.GAS.ON_START"
    },
    intermediate: {
      id: "intermediate",
      type: "intermediate-gas",
      label: "SG",
      key: "NOTIFICATION_SERVICE.GAS.INTERMEDIATE"
    },
    update: {
      id: "update",
      type: "update-gas",
      label: "VG",
      key: "NOTIFICATION_SERVICE.GAS.UPDATE"
    }
  }
};
