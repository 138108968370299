import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CardModule } from "src/app/common/components/card/card.module";
import { CoinModule } from "src/app/components/dialogs/order-module-dialog/components/coin/coin.module";
import { LaunchpadMarketplaceBannerProps } from "../../../container/launchpad.interfaces";

@Component({
  selector: "tpm-marketplace-banner",
  templateUrl: "marketplace-banner.component.html",
  styleUrls: ["marketplace-banner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CoinModule, CardModule]
})
export class MarketplaceBannerComponent {
  @Input() public props!: LaunchpadMarketplaceBannerProps;
  @Input() public isMedium!: boolean;
  @Input() public isMobile!: boolean;
}
