import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild
} from "@angular/core";
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors, Validators } from "@angular/forms";
import { PhoneNumberUtil } from "google-libphonenumber";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

const phoneUtil = PhoneNumberUtil.getInstance();

const PHONE_NUMBER_PATTERN =
  /^(015|016([ ]?0|[ ]?2|[ ]?3)|017|0049[ ]?1(5|6[ ]?0|6[ ]?2|6[ ]?3|7)|[+]49[ ]?1(5|6[ ]?0|6[ ]?2|6[ ]?3|7))[\ 0-9\-]+$/;

@Component({
  selector: "app-phone-number-input",
  templateUrl: "./phone-number-input.component.html",
  styleUrls: ["./phone-number-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneNumberInputComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
  @ViewChild("phone") public readonly phoneInput: ElementRef;

  @Input() public label: string = "";
  @Input() public hintMessage: string = "FORM.PHONE_NUMBER.HINT_MESSAGE";
  @Input() public errorMessage: string = "FORM.PHONE_NUMBER.ERROR_MESSAGE";
  @Input() public locale: string = "DE";

  public control: FormControl;
  public showHint: boolean;

  private readonly destroy$ = new Subject<void>();

  constructor(@Optional() @Self() private readonly ngControl: NgControl, private readonly cdr: ChangeDetectorRef) {
    this.control = new FormControl("");

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public onChange: (value: any) => void = () => {};
  public onTouch: () => void = () => {};

  public ngOnInit(): void {
    this.control.setValidators([Validators.pattern(PHONE_NUMBER_PATTERN), this.validate.bind(this)]);
    this.control.updateValueAndValidity();

    this.control.valueChanges
      .pipe(
        map((value) => this.formatTransmission(value)),
        takeUntil(this.destroy$)
      )
      .subscribe((value) => {
        this.onChange(value);
      });

    this.control.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.ngControl?.control.setErrors(this.control.validator(this.control));
    });
  }

  public ngAfterViewInit(): void {
    this.phoneInput.nativeElement.addEventListener("focus", () => this.focusChanged(true));
    this.phoneInput.nativeElement.addEventListener("blur", () => this.focusChanged(false));
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public writeValue(value: string | number): void {
    this.showHint = !value;
    this.control.setValue(value);
  }

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public validate(control: FormControl): ValidationErrors | null {
    return !this.isPhoneNumberValid(control.value, this.locale)
      ? { invalid: true, message: "invalid phone number" }
      : null;
  }

  public detectChanges(): void {
    this.cdr.detectChanges();
  }

  public setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  private focusChanged(isFocused: boolean): void {
    this.showHint = !isFocused && !this.control.value && this.control.valid;
    this.detectChanges();
  }

  private isPhoneNumberValid(value: string, region: string): boolean {
    try {
      return value ? phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, region), region) : true;
    } catch {
      return false;
    }
  }

  private formatTransmission(value: string | null): string {
    let result = "";

    if (value?.startsWith("01")) {
      result = value.replace("01", "+491");
    } else if (value?.startsWith("00491")) {
      result = value.replace("00", "+");
    } else {
      result = value;
    }

    return result?.replace(/[ \-]/g, "");
  }
}
