import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { TextDialogComponent } from "./text.component";

const materailModules = [MatLegacyDialogModule, MatIconModule, MatLegacyButtonModule];

@NgModule({
  declarations: [TextDialogComponent],
  imports: [CommonModule, TranslateModule, PipesModule, ...materailModules]
})
export class TextDialogModule {}
