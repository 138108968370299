import { createReducer, on } from "@ngrx/store";
import { LaunchpadResponse } from "src/app/common/dto/admin-module/launchpad";
import { LoadingResponse } from "src/app/common/interfaces/loading-response";
import * as AdminDataActions from "../actions/admin-data.actions";

export interface AdminDataState {
  launchpadModules: LoadingResponse<LaunchpadResponse["modules"]>;
  outageDashboard: boolean | null;
}

export const initialState: AdminDataState = {
  launchpadModules: {
    result: [],
    loading: false
  },
  outageDashboard: null
};

export const adminDataReducer = createReducer(
  initialState,
  on(AdminDataActions.loadLaunchpad, (state) => ({
    ...state,
    launchpadModules: { result: [], loading: true }
  })),
  on(AdminDataActions.launchpadLoadedSuccess, (state, { response }) => ({
    ...state,
    launchpadModules: { result: response.modules, loading: false },
    outageDashboard: response.outageDashboard
  }))
);
