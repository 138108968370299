import { Action, createReducer, on } from "@ngrx/store";
import { IRegionsPerYearDto, IRegionsYearDto } from "../../../common/dto/infrastructure/regions";
import * as RegionsActions from "../../actions/infrastructure/regions.actions";

export interface State {
  regionsYear: IRegionsYearDto;
  regionsAllYears: Array<IRegionsPerYearDto>;
}

const initialState: State = {
  regionsYear: {
    ags: null,
    area: null,
    name: null,
    population: null,
    rs: null,
    bbox: null,
    loading: true
  },
  regionsAllYears: null
};

const regionsReducer = createReducer(
  initialState,
  on(RegionsActions.getRegionsData, (state) => ({
    ...state,
    regionsYear: {
      ags: null,
      area: null,
      name: null,
      population: null,
      rs: null,
      bbox: null,
      loading: true
    }
  })),
  on(RegionsActions.getRegionsDataSuccess, (state, { payload }) => ({
    ...state,
    regionsYear: {
      ...payload,
      loading: false
    }
  })),
  on(RegionsActions.getRegionsAllYearsData, (state) => ({ ...state, regionsAllYears: null })),
  on(RegionsActions.getRegionsAllYearsDataSuccess, (state, { payload }) => ({ ...state, regionsAllYears: payload }))
);

export function reducer(state: State | undefined, action: Action): State {
  return regionsReducer(state, action);
}
