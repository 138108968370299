import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFilterComponent implements OnInit, OnDestroy {
  @Output() public readonly changed = new EventEmitter<string>();

  @Input() public filterLabel: string = "TOOLTIP.table.filterPlaceholder";

  @Input()
  public set value(_value: string) {
    this.control.setValue(_value);
  }

  public readonly control = new FormControl("");

  private controlSubscription!: Subscription;

  public ngOnInit(): void {
    this.controlSubscription = this.control.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged(), map(String))
      .subscribe((value) => this.changed.emit(value));
  }

  public ngOnDestroy(): void {
    this.controlSubscription?.unsubscribe();
  }

  public reset(): void {
    this.control.setValue("");
  }
}
