import { CommonModule, CurrencyPipe, DecimalPipe } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { EneIconModule } from "@enersis/ui-icon";
import { EneTooltipModule } from "@enersis/ui-tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { CommonDirectivesModule } from "../../directives/directives.module";
import { PipesModule } from "../../pipes/pipes.module";
import { SkeletonLoaderModule } from "../skeleton-loader/skeleton-loader.module";
import { KpiBadgeComponent } from "./components/kpi-badge/kpi-badge.component";
import { KpiBoxComponent } from "./components/kpi-box/kpi-box.component";
import { KpiGroupComponent } from "./components/kpi-group/kpi-group.component";
import { KpiItemComponent } from "./components/kpi-item/kpi-item.component";
import { KPI_ITEM_MAP, kpiItemMap } from "./constants/kpi-map";
import { KpiActionDirective } from "./directives/kpi-action.directive";
import { IKpiItemMap } from "./interfaces/kpi-item";

const componentsAndDirectives = [
  KpiBadgeComponent,
  KpiBoxComponent,
  KpiItemComponent,
  KpiGroupComponent,
  KpiActionDirective
];

@NgModule({
  declarations: [...componentsAndDirectives],
  imports: [
    CommonModule,
    TranslateModule,
    EneIconModule,
    EneTooltipModule,
    PipesModule,
    SkeletonLoaderModule,
    MatLegacyTooltipModule,
    CommonDirectivesModule
  ],
  providers: [DecimalPipe, CurrencyPipe],
  exports: [...componentsAndDirectives]
})
export class KpiModule {
  public static forRoot(projectItemMap: IKpiItemMap): ModuleWithProviders<KpiModule> {
    return {
      ngModule: KpiModule,
      providers: [
        {
          provide: KPI_ITEM_MAP,
          useValue: {
            ...kpiItemMap,
            ...projectItemMap
          }
        }
      ]
    };
  }
}
