import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { EMPTY } from "rxjs";

import { SelfSufficiencyService } from "../../../../core/services/self-sufficiency.service";
import {
  getSelfSufficiencyConsumptionData,
  getSelfSufficiencyConsumptionDataSuccess
} from "../../../actions/energy/self-sufficiency/consumption.actions";
import { SelfSufficiencyConsumptionDto } from "../../../../common/dto/energy/self-sufficiency/consumption";

@Injectable()
export class SelfSufficiencyConsumptionEffects {
  getConsumptionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelfSufficiencyConsumptionData),
      switchMap(action =>
        this.selfSufficiencyService.getConsumptionData(action)
          .pipe(
            map((payload: SelfSufficiencyConsumptionDto) => getSelfSufficiencyConsumptionDataSuccess({ payload })),
            catchError(() => EMPTY)
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private selfSufficiencyService: SelfSufficiencyService
  ) { }
}
