import { LiveAnnouncer } from "@angular/cdk/a11y";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Inject, Injectable, Injector, Optional, SkipSelf } from "@angular/core";
import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBar,
  MatLegacySnackBarConfig,
  MatLegacySnackBarRef
} from "@angular/material/legacy-snack-bar";
import { DynamicOverlay } from "../dynamic-overlay/dynamic-overlay";
import { SnackBarComponent } from "./snack-bar.component";

type SnackBarType = "info" | "success" | "error";

/* eslint-disable jsdoc/no-types */
@Injectable({ providedIn: "root" })
export class DynamicSnackBar extends MatLegacySnackBar {
  private readonly dynamicOverlay: DynamicOverlay;
  private readonly defaultConfig: MatLegacySnackBarConfig;

  constructor(
    overlay: DynamicOverlay,
    live: LiveAnnouncer,
    injector: Injector,
    breakpointObserver: BreakpointObserver,
    @Optional() @SkipSelf() parentSnackBar: MatLegacySnackBar,
    @Inject(MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS) defaultConfig: MatLegacySnackBarConfig
  ) {
    super(overlay, live, injector, breakpointObserver, parentSnackBar, defaultConfig);

    this.dynamicOverlay = overlay;
    this.defaultConfig = defaultConfig;
  }

  /** @override Default materail open method */
  public open(
    message: string,
    action: string = "",
    config: MatLegacySnackBarConfig = {},
    type: SnackBarType = "info",
    isMain: boolean = true
  ): MatLegacySnackBarRef<SnackBarComponent> {
    /** Render snack bar in main overlay container */
    this.dynamicOverlay.setMainOverlay(isMain);

    let _panelClass: Array<string> = [];

    if (config.panelClass) {
      if (Array.isArray(config.panelClass)) {
        _panelClass = config.panelClass;
      } else {
        _panelClass = [config.panelClass];
      }
    }

    const _config: MatLegacySnackBarConfig = {
      ...this.defaultConfig,
      ...config,
      panelClass: ["ene-snack-bar", type, ..._panelClass]
    };

    /**
     * Since the user doesn't have access to the component, we can
     * override the data to pass in our own message and action.
     */
    _config.data = { message, action };

    if (!_config.announcementMessage) {
      _config.announcementMessage = message;
    }

    return this.openFromComponent(SnackBarComponent, _config);
  }

  public info(
    message: string,
    config?: MatLegacySnackBarConfig,
    action?: string,
    isMain?: boolean
  ): MatLegacySnackBarRef<SnackBarComponent> {
    return this.open(message, action, config, "info", isMain);
  }

  public error(
    message: string,
    config?: MatLegacySnackBarConfig,
    action?: string,
    isMain?: boolean
  ): MatLegacySnackBarRef<SnackBarComponent> {
    return this.open(message, action, config, "error", isMain);
  }

  public success(
    message: string,
    config?: MatLegacySnackBarConfig,
    action?: string,
    isMain?: boolean
  ): MatLegacySnackBarRef<SnackBarComponent> {
    return this.open(message, action, config, "success", isMain);
  }
}
