<media-controls
  [autoPlay]="autoPlay"
  [muted]="muted"
  [disablePlayControl]="disablePlayControl"
  [displayVolumeControl]="displayVolumeControl"
  [displayFullscreenControl]="displayFullscreenControl"
  [source]="source"
  [startOffset]="startOffset"
>
  <video #player [attr.data-matomo-title]="title" [src]="source" scanMediaAnalytics preload="metadata" poster=""></video>
</media-controls>
