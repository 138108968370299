import { A11yModule } from "@angular/cdk/a11y";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import "@enersis/gp-components/gp-textfield";
import { TextfieldComponent } from "./textfield.component";

@NgModule({
  declarations: [TextfieldComponent],
  imports: [CommonModule, ReactiveFormsModule, A11yModule],
  exports: [TextfieldComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TextfieldModule {}
