import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { EnergyService } from "../../../core/services/energy.service";
import {
  clearElectricityConnectionGroupsData,
  clearEMobilityConnectionGroupsData,
  clearGasConnectionGroupsData,
  clearHeatpumpConnectionGroupsData,
  clearPhotovoltaicsConnectionGroupsData,
  getElectricityConnectionGroupsData,
  getElectricityConnectionGroupsDataSuccess,
  getEMobilityConnectionGroupsData,
  getEMobilityConnectionGroupsDataSuccess,
  getGasConnectionGroupsData,
  getGasConnectionGroupsDataSuccess,
  getHeatpumpConnectionGroupsData,
  getHeatpumpConnectionGroupsDataSuccess,
  getPhotovoltaicsConnectionGroupsData,
  getPhotovoltaicsConnectionGroupsDataSuccess
} from "../../actions/energy/connection-groups.actions";

@Injectable()
export class ConnectionGroupsEffects {
  getElectricityConnectionGroupsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getElectricityConnectionGroupsData),
      switchMap((action) =>
        this.energyService.getElectricityConnectionGroupsData(action).pipe(
          map((payload) => getElectricityConnectionGroupsDataSuccess({ payload })),
          catchError(() => of(clearElectricityConnectionGroupsData()))
        )
      )
    )
  );

  getGasConnectionGroupsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGasConnectionGroupsData),
      switchMap((action) =>
        this.energyService.getGasConnectionGroupsData(action).pipe(
          map((payload) => getGasConnectionGroupsDataSuccess({ payload })),
          catchError(() => of(clearGasConnectionGroupsData()))
        )
      )
    )
  );

  getEMobilityConnectionGroupsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getEMobilityConnectionGroupsData),
      switchMap((action) =>
        this.energyService.getEMobilityConnectionGroupsData(action).pipe(
          map((payload) => getEMobilityConnectionGroupsDataSuccess({ payload })),
          catchError(() => of(clearEMobilityConnectionGroupsData()))
        )
      )
    )
  );

  getPhotovoltaicsConnectionGroupsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPhotovoltaicsConnectionGroupsData),
      switchMap((action) =>
        this.energyService.getPhotovoltaicsConnectionGroupsData(action).pipe(
          map((payload) => getPhotovoltaicsConnectionGroupsDataSuccess({ payload })),
          catchError(() => of(clearPhotovoltaicsConnectionGroupsData()))
        )
      )
    )
  );

  getHeatpumpConnectionGroupsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHeatpumpConnectionGroupsData),
      switchMap((action) =>
        this.energyService.getHeatpumpConnectionGroupsData(action).pipe(
          map((payload) => getHeatpumpConnectionGroupsDataSuccess({ payload })),
          catchError(() => of(clearHeatpumpConnectionGroupsData()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private energyService: EnergyService) {}
}
