import { MatLegacyTableDataSource } from "@angular/material/legacy-table";
import { TranslateService } from "@ngx-translate/core";
import { TableColumnConfig } from "../table.interfaces";

export class TableDataSource<T extends Record<string, any>> extends MatLegacyTableDataSource<T> {
  public selected: T | undefined;

  private columnsAccessorsMap!: ReadonlyMap<string, TableColumnConfig["propertyAccessor"]>;
  private columnsFormatsMap!: ReadonlyMap<string, TableColumnConfig["translateAccessor"]>;
  private _columnsConfig: Array<TableColumnConfig> = [];

  constructor(initialData: Array<T> = [], protected readonly translate: TranslateService) {
    super(initialData);
  }

  public setSelected(value: number | string | undefined) {
    const data = this.sortData(this.data.slice(), this.sort);
    let foundItem: T | undefined;

    if (typeof value === "number") {
      foundItem = data.find((_, index) => index === value);
    } else if (typeof value === "string") {
      foundItem = data.find((item) => item.id === value);
    } else {
      foundItem = undefined;
    }

    this.selected = foundItem;
  }

  public get columnsConfig(): Array<TableColumnConfig> {
    return this._columnsConfig;
  }

  public set columnsConfig(columns: Array<TableColumnConfig>) {
    this.columnsAccessorsMap = new Map(
      columns
        .filter(({ propertyAccessor }) => propertyAccessor)
        .map(({ id, propertyAccessor }) => [id, propertyAccessor])
    );
    this.columnsFormatsMap = new Map(
      columns
        .filter(({ translateAccessor }) => translateAccessor)
        .map(({ id, translateAccessor }) => [id, translateAccessor])
    );
    this._columnsConfig = columns;
  }

  /**
   * Data accessor function that is used for accessing data properties for sorting through
   * the default sortData function.
   * This default function assumes that the sort header IDs (which defaults to the column name)
   * matches the data's properties (e.g. column Xyz represents data['Xyz']).
   * Custom extension also check if 'propAccessor' was exist in column config.
   * Will prevent case sensitive
   *
   * @param data Data object that is being accessed.
   * @param sortHeaderId The name of the column that represents the data.
   */
  public sortingDataAccessor = (data: T, sortHeaderId: string): string | number => {
    const value = this.propertyAccessor(data, sortHeaderId);
    return typeof value === "string" ? value.toLocaleLowerCase() : value;
  };

  /**
   * Checks if a data object matches the data source's filter string. By default, each data object
   * is converted to a string of its properties and returns true if the filter has
   * at least one occurrence in that string. By default, the filter string has its whitespace
   * trimmed and the match is case-insensitive.
   * Custom extension also check if 'propAccessor' was exist in column config.
   *
   * @param data Data object used to check against the filter.
   * @param filter Filter string that has been set on the data source.
   * @returns Whether the filter matches against the data
   */
  public filterPredicate = (row: T, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const dataStr = this.columnsConfig
      .map(({ id }) => id)
      .reduce((currentTerm: string, key: string) => {
        let value = this.propertyAccessor(row, key);

        if (this.translate && typeof value === "string" && value) {
          value = this.translate.instant(this.translateAccessor(value, row, key));
        }

        // Use an obscure Unicode character to delimit the words in the concatenated string.
        // This avoids matches where the values of two columns combined will match the user's query
        // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
        // that has a very low chance of being typed in by somebody in a text field. This one in
        // particular is "White up-pointing triangle with dot" from
        // https://en.wikipedia.org/wiki/List_of_Unicode_characters
        return `${currentTerm}${value}◬`;
      }, "")
      .toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.includes(transformedFilter);
  };

  /** override base order functionality by reset pageIndex */
  public _orderData(data: T[]): T[] {
    if (this.paginator) {
      this.paginator.firstPage();
    }

    return super._orderData(data);
  }

  private propertyAccessor(row: T, name: string): string | number {
    return (this.columnsAccessorsMap.has(name) ? this.columnsAccessorsMap.get(name)(row, name) : row[name]) as
      | string
      | number;
  }

  private translateAccessor(value: string, row: T, name: string): string {
    return (this.columnsFormatsMap.has(name) ? this.columnsFormatsMap.get(name)(row) : value) as string;
  }
}
