import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Output, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, take } from "rxjs";
import { filter, map } from "rxjs/operators";
import { CheckboxModule } from "src/app/common/components/form/checkbox/checkbox.module";
import { RadioModule } from "src/app/common/components/form/radio/radio.module";
import { SelectModule } from "src/app/common/components/form/select/select.module";
import { TextfieldModule } from "src/app/common/components/form/textfield/textfield.module";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import {
  CampusOneOrderModuleComponentStore,
  FormSubmitBody
} from "../../container/order-module-dialog.component-store";
import { SELECT_OPTIONS, TEXT_FIELD_LIST } from "../../container/order-module-dialog.constants";
import { CoinModule } from "../coin/coin.module";

@Component({
  selector: "app-order-form",
  templateUrl: "./order-form.component.html",
  styleUrls: ["./order-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    EneButtonModule,
    TextfieldModule,
    RadioModule,
    CheckboxModule,
    SelectModule,
    CoinModule,
    PipesModule
  ]
})
export class OrderFormComponent {
  @Output() public readonly submit$ = new EventEmitter<Omit<FormSubmitBody, 'moduleId'>>();
  public readonly formGroup: FormGroup;
  public readonly options = SELECT_OPTIONS;
  public readonly textFieldList = TEXT_FIELD_LIST;
  public readonly planList$ = this.componentStore.planList$;
  public readonly coinPrice$ = this.componentStore.coinPrice$;
  public readonly submitButtonLabel$: BehaviorSubject<string> = new BehaviorSubject("");
  public readonly orderFormContent$ = this.componentStore.orderFormContent$.pipe(
    filter(Boolean),
    map((orderFormContent) => ({
      ...orderFormContent,
      isCampusOne: (orderFormContent.moduleId as any) === "module-00015"
    })),
    take(1)
  );

  private readonly destroyRef = inject(DestroyRef);

  constructor(private readonly fb: FormBuilder, private readonly componentStore: CampusOneOrderModuleComponentStore) {
    this.formGroup = this.fb.group({
      pronouns: [null, Validators.required],
      name: [{ value: null, disabled: true }, Validators.required],
      addressCity: [{ value: null, disabled: true }, Validators.required],
      address: [null, [Validators.required, Validators.minLength(4)]],
      addressPostalCode: [null, [Validators.required, Validators.pattern(/^(\d{4,5})[\s,]+(\w.*)$/)]],
      email: [{ value: null, disabled: true }, [Validators.required, Validators.email]],
      planId: [null, Validators.required],
      agreement: [null, Validators.requiredTrue]
    });

    combineLatest([this.formGroup.get("planId").valueChanges, this.componentStore.formData$, this.orderFormContent$])
      .pipe(
        map(([planIdControlValue, { planId }, { isCampusOne }]) =>
          isCampusOne
            ? planIdControlValue === planId
              ? "ORDER_MODULE.SUBMIT_BUTTON_LABEL_LIMITED_USER"
              : "ORDER_MODULE.SUBMIT_BUTTON_LABEL_UNLIMITED_USER"
            : "ORDER_MODULE.SUBMIT_BUTTON_LABEL_LIMITED_USER"
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((result) => this.submitButtonLabel$.next(result));

    this.componentStore.formData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((result) => {
      this.formGroup.patchValue(result);
    });

    this.orderFormContent$.subscribe((formContent) => {
      formContent?.isCampusOne ? this.formGroup.get("planId").enable() : this.formGroup.get("planId").disable();
      formContent?.agb ? this.formGroup.get("agreement").enable() : this.formGroup.get("agreement").disable();
    });

    this.componentStore.fetchPlanList();
  }

  public readonly trackByName = (item: { formControlName: string }): string => item.formControlName;

  public async submit(): Promise<void> {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
    } else {
      const { pronouns, address, addressPostalCode, planId } = this.formGroup.getRawValue();
      this.submit$.emit({ pronouns, address, addressPostalCode, planId });
    }
  }
}
