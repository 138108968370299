import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TextfieldModule } from "src/app/common/components/form/textfield/textfield.module";
import { TableFilterComponent } from "./table-filter.component";

@NgModule({
  declarations: [TableFilterComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, TextfieldModule],
  exports: [TableFilterComponent]
})
export class TableFilterModule {}
