import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { EnergyService } from "../../../core/services/energy.service";
import {
  clearElectricityStationData,
  clearGasStationData,
  clearStationWorkloadData,
  getElectricityStationData,
  getElectricityStationDataSuccess,
  getGasStationData,
  getGasStationDataSuccess,
  getStationWorkloadData,
  getStationWorkloadDataSuccess
} from "../../actions/energy/stations.actions";

@Injectable()
export class StationsEffects {
  getElectricityStationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getElectricityStationData),
      switchMap((action) =>
        this.energyService.getElectricityStationData(action).pipe(
          map((payload) => getElectricityStationDataSuccess({ payload })),
          catchError(() => of(clearElectricityStationData()))
        )
      )
    )
  );

  getGasStationData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGasStationData),
      switchMap((action) =>
        this.energyService.getGasStationData(action).pipe(
          map((payload) => getGasStationDataSuccess({ payload })),
          catchError(() => of(clearGasStationData()))
        )
      )
    )
  );

  getStationWorkloadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStationWorkloadData),
      switchMap((action) =>
        this.energyService.getStationWorkloadData(action).pipe(
          map((payload) => getStationWorkloadDataSuccess({ payload })),
          catchError(() => of(clearStationWorkloadData()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private energyService: EnergyService) {}
}
