import { formatDate } from "@angular/common";
import * as moment from "moment";

export const formatDateDE = (value: string | Date, format: string = "dd.MM.yyyy"): string =>
  formatDate(value, format, "de-DE");

/**
 * @param value ISO format `P1D`
 * @param argWithSuffix
 * @param argThresholds `{ d: 7, w: 4 }`
 */
export const formatDurationWithMomentDE = (
  value: string,
  argWithSuffix: boolean = false,
  argThresholds: moment.argThresholdOpts = { d: 7, w: 4 }
): string => moment.duration(value).locale("de").humanize(argWithSuffix, argThresholds);
