import { Action, createReducer, on } from "@ngrx/store";
import {
  ISelfSufficiencyDegreePerYearDto,
  ISelfSufficiencyDegreeYearDto
} from "../../../../common/dto/energy/self-sufficiency/degree";
import * as DegreeActions from "../../../actions/energy/self-sufficiency/degree.actions";

export interface State {
  selfSufficiencyDegreeYear: ISelfSufficiencyDegreeYearDto;
  selfSufficiencyDegreeAllYears: Array<ISelfSufficiencyDegreePerYearDto>;
}

const initialState: State = {
  selfSufficiencyDegreeYear: {
    selfSufficiencyDegree: undefined
  },
  selfSufficiencyDegreeAllYears: undefined
};

const degreeReducer = createReducer(
  initialState,
  on(DegreeActions.getSelfSufficiencyDegreeData, (state) => ({
    ...state,
    selfSufficiencyDegreeYear: {
      selfSufficiencyDegree: undefined
    }
  })),
  on(DegreeActions.getSelfSufficiencyDegreeDataSuccess, (state, { payload }) => ({
    ...state,
    selfSufficiencyDegreeYear: payload
  })),
  on(DegreeActions.getSelfSufficiencyDegreeAllYearsData, (state) => ({
    ...state,
    selfSufficiencyDegreeAllYears: undefined
  })),
  on(DegreeActions.getSelfSufficiencyDegreeAllYearsDataSuccess, (state, { payload }) => ({
    ...state,
    selfSufficiencyDegreeAllYears: payload
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return degreeReducer(state, action);
}
