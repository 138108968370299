import { Action, createReducer, on } from "@ngrx/store";
import { IRegionsEMobilityDto } from "../../../common/dto/energy/regions-e-mobility";
import * as RegionsEMobilityActions from "../../actions/energy/regions-e-mobility.actions";

export interface State {
  eMobilityAllYears: Array<IRegionsEMobilityDto>;
}

const initialState: State = {
  eMobilityAllYears: undefined
};

const regionsEMobilityReducer = createReducer(
  initialState,
  on(RegionsEMobilityActions.getRegionsEMobilityAllYearsData, (state) => ({
    ...state,
    eMobilityAllYears: undefined
  })),
  on(RegionsEMobilityActions.getRegionsEMobilityAllYearsDataSuccess, (state, { payload }) => ({
    ...state,
    eMobilityAllYears: payload
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return regionsEMobilityReducer(state, action);
}
