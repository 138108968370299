import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Host, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { IButtonToggleItem } from "src/app/common/components/button-toggle/button-toggle.component";
import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";
import { TABLE_PAGING_SERVICE } from "src/app/common/components/table/tokens/table-paging-service";
import { SLOT } from "src/app/common/enums/slot";
import { AppConfig } from "src/app/core/helpers/app.config";
import { FilesService } from "src/app/core/services/files.service";
import { NestedTableComponentStore } from "./component-stores/nested-table-component.store";
import { PaginationService } from "./component-stores/pagination.service";
import { municipalitiesTableConfig as accMunicipalitiesTableConfig } from "./constants/accounting/municipalities-table-config";
import { usersTableConfig as accUsersTableConfig } from "./constants/accounting/users-table-config";
import { municipalitiesTableConfig } from "./constants/incident-management/municipalities-table-config";
import { usersTableConfig } from "./constants/incident-management/users-table-config";
import { Tabs } from "./constants/tabs";
import { municipalitiesTableConfig as typMunicipalitiesTableConfig } from "./constants/typ/municipalities-table-config";
import { usersTableConfig as typUsersTableConfig } from "./constants/typ/users-table-config";
import { NestedTableComponent } from "./nested-table/nested-table.component";
import { SeparatedByTypeComponent } from "./separated-by-type/separated-by-type.component";

const configs: { [slot in SLOT | "street-lighting"]?: { [tab in Tabs]: any } } = {
  [SLOT.OUTAGE_MONITORING]: {
    [Tabs.USERS]: { component: NestedTableComponent, ...usersTableConfig },
    [Tabs.MUNICIPALITIES]: { component: SeparatedByTypeComponent, ...municipalitiesTableConfig }
  },
  [SLOT.THREE_YEAR_PLAN]: {
    [Tabs.USERS]: { component: NestedTableComponent, ...typUsersTableConfig },
    [Tabs.MUNICIPALITIES]: { component: SeparatedByTypeComponent, ...typMunicipalitiesTableConfig }
  },
  [SLOT.ACCOUNTING]: {
    [Tabs.USERS]: { component: NestedTableComponent, ...accUsersTableConfig },
    [Tabs.MUNICIPALITIES]: { component: SeparatedByTypeComponent, ...accMunicipalitiesTableConfig }
  },
  "street-lighting": {
    [Tabs.USERS]: { component: NestedTableComponent, ...accUsersTableConfig },
    [Tabs.MUNICIPALITIES]: { component: SeparatedByTypeComponent, ...accMunicipalitiesTableConfig }
  }
};

@Component({
  selector: "ca-notification-settings",
  templateUrl: "./notification-settings.component.html",
  styleUrls: ["./notification-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TABLE_PAGING_SERVICE,
      useClass: PaginationService,
      deps: [HttpClient]
    },
    NestedTableComponentStore
  ],
  host: {
    class: "ca-notification-settings"
  }
})
export class CANotificationSettingsComponent implements OnInit, OnDestroy {
  @Input() public module: SLOT | "street-lighting";
  public selectedTab = Tabs.MUNICIPALITIES;
  public pagingConfig = { pageSize: 10, type: "full" };
  public config: { columns: Array<ITableColumn>; nestedColumns: Array<ITableColumn>; component: any };
  public toggles: Array<IButtonToggleItem> = [
    { label: "USER_SETTINGS.LIST_MUNICIPALITIES.TITLE", value: Tabs.MUNICIPALITIES },
    { label: "USER_SETTINGS.LIST_USERS.TITLE", value: Tabs.USERS }
  ];
  private destroy$ = new Subject<void>();

  constructor(
    private store: NestedTableComponentStore,
    private fileService: FilesService,
    @Host()
    @Inject(TABLE_PAGING_SERVICE)
    protected readonly pagingService: PaginationService
  ) {}

  public ngOnInit(): void {
    this.config = configs[this.module][this.selectedTab];
    this.pagingService.setModule(this.module);
    this.store.setTabName(this.selectedTab);
    this.pagingService.setTabName(this.selectedTab);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public nameFilterChanged(search: string): void {
    this.pagingService.setFilter(search);
  }

  public setTab(tabName: Tabs): void {
    this.config = configs[this.module][tabName];
    this.store.setTabName(tabName);
    this.pagingService.setTabName(tabName);
    this.pagingService?.resetPagination();
  }

  public downloadXslx(): void {
    this.fileService.postXls(
      `${AppConfig.connection.infrastructure.userSettings}/users/downloadXlsx?module=${this.module}`,
      {}
    );
  }
}
