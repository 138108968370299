export const getFileSize = (number: number | null): string => {
  if (!number) {
    return "-";
  }

  if (number < 1048576) {
    return `${(number / 1024).toFixed(2)} kB`;
  } else if (number >= 1048576) {
    return `${(number / 1048576).toFixed(2)} MB`;
  }
};
