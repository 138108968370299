<mat-select
  class="custom-arrow"
  [placeholder]="placeholder"
  [value]="value"
  (selectionChange)="selectionChange.emit($event.value)"
>
  <mat-option *ngFor="let option of options; trackBy: trackByValue" [value]="option.value">
    {{ option.label | translate }}
  </mat-option>
</mat-select>
