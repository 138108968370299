<tpm-dialog-frame
  *ngrxLet="vm$ as vm"
  [header]="
    'Übersicht über Bestellungen und Kündigungen. Hier können Einträge angepasst und anschließend gespeichert werden.'
      | translate
  "
  [closable]="false"
  (closed)="onClose()"
>
  <div class="campus-one-admin-view-container">
    <div class="description-container">
      <p class="body-2">
        {{ "CAMPUS_ONE_ADMIN_VIEW.DESCRIPTION" | translate }}
      </p>
    </div>
    <tpm-card @inOutAnimation *ngIf="vm.isUpdated === true">
      <div class="user-management-actions-wrapper">
        <button class="delete-button" ene-filled-button (click)="onSubmit()">Speichern</button>
      </div>
    </tpm-card>
    <ngmy-table
      [disableSort]="false"
      [hideFilter]="true"
      [columnsConfig]="columns"
      [loading]="vm.subscriptions.loading"
      [data]="vm.subscriptions.result"
      (tableAction)="onTableAction($event)"
    ></ngmy-table>
  </div>
</tpm-dialog-frame>
