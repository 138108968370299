import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, TrackByFunction } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "../../../../card/card.module";
import { IconModule } from "../../../../icon/icon.module";
import { ListModule } from "../../../../list/list.module";
import { ProgressBarModule } from "../../../../progress-bar/progress-bar.module";
import { DropFileAreaItem } from "../../drop-file-area.component";
import { FileListInputs } from "../../file-list-inputs";

@Component({
  selector: "tpm-drop-area-file-list",
  templateUrl: "file-list.component.html",
  styleUrls: ["file-list.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, TranslateModule, ListModule, IconModule, ProgressBarModule, CardModule]
})
export class DropAreaFileListComponent extends FileListInputs {
  public readonly trackByIndex: TrackByFunction<DropFileAreaItem> = (index: number) => index;
}
