import { createSelector } from "@ngrx/store";
import { ISidenavigationState } from "../reducers/sidenavigation.reducer";
import { RootState } from "../root-reducers";

export const sidenavigation = (state: RootState) => state.sidenavigation;

export const getSidenavigationState = createSelector(sidenavigation, (state) => state);
export const getPanels = createSelector(sidenavigation, (state) => state && state.panels);

/**
 * selects the state of the specified panel
 * @example
 * select(getPanelState, { panelName: "cockpit" })
 */
export const getPanelState = createSelector(
  sidenavigation,
  (state: ISidenavigationState, props: { panelName: string }) => state?.panels[props.panelName]
);

/**
 * selects the "isEnlarged" property of the specified panel
 * @example
 * select(getEnlargeState, { panelName: "cockpit" })
 */
export const getEnlargeState = createSelector(getPanelState, (state) => state?.isEnlarged);

/**
 * selects the "isOpen" property of the specified panel
 * @example
 * select(getEnlargeState, { panelName: "cockpit" })
 */
export const getOpenState = createSelector(getPanelState, (state) => state?.isOpen);
