import { Action, createReducer, on } from "@ngrx/store";
import { GasCockpitValuesAllYearsDto, NetworkDto } from "../../common/dto/network";
import * as NetworkActions from "../actions/network.actions";

export interface State {
  networkIds: Array<NetworkDto>;
  gasCockpitValuesAllYears: GasCockpitValuesAllYearsDto;
}

const initialState: State = {
  networkIds: undefined,
  gasCockpitValuesAllYears: undefined
};

const networkReducer = createReducer(
  initialState,
  on(NetworkActions.getNetworkIds, (state) => ({ ...state, networkIds: undefined })),
  on(NetworkActions.getNetworkIdsSuccess, (state, { payload }) => ({ ...state, networkIds: payload })),
  on(NetworkActions.getGasCockpitValuesForAllYears, (state) => ({ ...state, gasCockpitValuesAllYears: undefined })),
  on(NetworkActions.getGasCockpitValuesForAllYearsSuccess, (state, { payload }) => ({
    ...state,
    gasCockpitValuesAllYears: payload
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return networkReducer(state, action);
}
