<div class="drop-file-area-wrapper">
  <div class="dropzone-wrapper">
    <ng-content></ng-content>
    <tpm-dropzone
      [maxFilenameLength]="maxFilenameLength"
      [multiple]="maxFilesLimit > 1"
      [maxFilesLimit]="maxFilesLimit"
      [maxSizeLimit]="maxSizeLimit"
      [accept]="accept"
      [disabled]="disabled"
      (dropped)="onDrop($event)"
    ></tpm-dropzone>
    <p class="headline-5 dropzone-note">{{ "TYP.FILE_UPLOADER_DIALOG.formContent.dropzoneHeadline" | translate }}</p>
    <p class="body-2 accept">{{ acceptLabel }}</p>
    <p class="body-2 block">
      <span>{{ "TYP.FILE_UPLOADER_DIALOG.formContent.dropzoneDescription" | translate }}</span>
      <a class="secondary link" (click)="openPolicyDialog()">
        {{ "TYP.FILE_UPLOADER_DIALOG.formContent.dropzoneLink" | translate }}
      </a>
    </p>
    <ng-content select="[slot='bottom']"></ng-content>
  </div>
  <div class="file-list-wrapper">
    <p class="headline-4 headline">{{ "Hochgeladene Dateien" | translate }}</p>
    <tpm-drop-area-file-list
      [listItemPrimaryLineAccessor]="listItemPrimaryLineAccessor"
      [listItemSecondaryLineAccessor]="listItemSecondaryLineAccessor"
      [listItemExtraLineAccessor]="listItemExtraLineAccessor"
      [files]="files"
      (fileRemove)="fileRemove.emit($event)"
    ></tpm-drop-area-file-list>
  </div>
</div>
