import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { IFooterItem } from "src/app/common/dto/client-config";

@Component({
  selector: "app-text-dialog",
  templateUrl: "./text.component.html"
})
export class TextDialogComponent {
  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public data: IFooterItem) {}
}
