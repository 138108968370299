<div class="contentful-wrapper-navigation">
  <app-button-toggle
    *ngIf="contentTypeToggles?.length"
    [fullWidth]="isMobileView"
    [size]="isMobileView ? 'small' : 'medium'"
    [active]="contentType"
    [items]="contentTypeToggles"
    (initialized)="onContentTypeChange($event)"
    (buttonChange)="onContentTypeChange($event)"
  ></app-button-toggle>
  <div class="contentful-wrapper-navigation__row">
    <tpm-select
      *ngIf="moduleSelectOptions?.length"
      [class.fill-width]="isMobileView"
      [placeholder]="'CONTENTFUL.none' | translate"
      [options]="moduleSelectOptions"
      (selectionChange)="moduleChange.emit($event)"
    ></tpm-select>
    <ngmy-table-filter *ngIf="!isMobileView" class="filter" (changed)="searchChange.emit($event)"></ngmy-table-filter>
    <button *ngIf="!isMobileView" class="read-all-button" ene-outline-button (click)="markAllRead.emit()">
      {{ "CONTENTFUL.MARK_AS_READ_BTN_LABEL" | translate }}
    </button>
  </div>
</div>
