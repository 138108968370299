import { animate, query, stagger, style, transition, trigger } from "@angular/animations";

/**
 * <div [@inListAnimation]="list.length">
 *  <tpm-tile *ngFor="let item of list;"></tpm-tile>
 * </div>
 */
export const IN_LIST_ANIMATION = trigger("inListAnimation", [
  transition("* <=> *", [
    query(":enter", [style({ opacity: 0 }), stagger("60ms", animate("600ms ease-out", style({ opacity: 1 })))], {
      optional: true
    })
  ])
]);

/**
 * <div [@inOutListAnimation]="list.length">
 *  <tpm-tile *ngFor="let item of list;"></tpm-tile>
 * </div>
 */
export const IN_OUT_LIST_ANIMATION = trigger("inOutListAnimation", [
  transition("* <=> *", [
    query(":enter", [style({ opacity: 0 }), stagger("60ms", animate("600ms ease-out", style({ opacity: 1 })))], {
      optional: true
    }),
    query(":leave", animate("100ms", style({ opacity: 0 })), { optional: true })
  ])
]);
