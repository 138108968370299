<ng-container *ngIf="files?.length > 0; else noList">
  <tpm-list>
    <ng-container *ngFor="let item of files; trackBy: trackByIndex">
      <tpm-list-item [hasMeta]="true" [twoLine]="true">
        <span class="list-item-primary-line">{{ listItemPrimaryLineAccessor(item) }}</span>
        <span class="list-item-secondary-line" slot="secondary">
          {{ listItemSecondaryLineAccessor(item) }}
        </span>
        <tpm-icon slot="meta" icon="gpi-filled-cross" (click)="fileRemove.emit(item)"></tpm-icon>
      </tpm-list-item>
      <p *ngIf="listItemExtraLineAccessor" class="body-2 list-item-extra-line">
        {{ listItemExtraLineAccessor(item) }}
      </p>
      <tpm-progress-bar *ngIf="item.loading" [value]="item.progress"></tpm-progress-bar>
      <div *ngIf="!item.loading && item.error" class="error-message">{{ item.error }}</div>
    </ng-container>
  </tpm-list>
</ng-container>
<ng-template #noList>
  <tpm-card>
    <tpm-icon icon="gpi-document"></tpm-icon>
    <p class="body-3">{{ "TYP.FILE_UPLOADER_DIALOG.listContent.placeholder" | translate }}</p>
  </tpm-card>
</ng-template>
