import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { INotificationSettings } from "../../../../../../../interfaces/dialogs/notification-settings";
import { NEWS_BADGES } from "../../../../constants/news-badges";
import { Square } from "../../../square/square.component";

@Component({
  selector: "app-news-notification-settings-edit-email",
  templateUrl: "./notification-settings-edit-email.component.html",
  styleUrls: ["../../style.scss"]
})
export class NewsNotificationSettingsEditEmailComponent {
  @Input() public formGroup: FormGroup;
  @Input() public indeterminate: INotificationSettings;
  @Input() public notificationSquares: { [key: string]: Square };
  public operationNewsBadge = NEWS_BADGES.operationNews;
  public serviceNewsBadge = NEWS_BADGES.serviceNews;
  public criticalNewsBadge = NEWS_BADGES.criticalNews;
}
