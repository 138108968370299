<div class="mat-dialog-header">
  <div mat-dialog-title>
    <mat-icon fontIcon="ec_info"></mat-icon>
    <h1>{{ data?.title | translate }}</h1>
  </div>
  <button class="big" mat-button mat-dialog-close>
    <mat-icon fontIcon="ec_building_delete_address"></mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="html-translate" [innerHTML]="data?.content | translate: data?.params | trustHtml"></div>
</mat-dialog-content>
