import { SelectionModel } from "@angular/cdk/collections";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {
  MatLegacyCellDef,
  MatLegacyColumnDef,
  MatLegacyFooterCellDef,
  MatLegacyHeaderCellDef
} from "@angular/material/legacy-table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TableComponent } from "../../../container/table.component";
import { TableService } from "../../../services/table.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-multi-selection-column",
  templateUrl: "multi-selection-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectionColumnComponent<T> implements OnInit, OnDestroy {
  @ViewChild(MatLegacyColumnDef, { static: true }) public readonly columnDef!: MatLegacyColumnDef;
  @ViewChild(MatLegacyHeaderCellDef, { static: true }) public readonly headerCellDef!: MatLegacyHeaderCellDef;
  @ViewChild(MatLegacyFooterCellDef, { static: true }) public readonly footerCellDef!: MatLegacyFooterCellDef;
  @ViewChild(MatLegacyCellDef, { static: true }) public readonly cellDef!: MatLegacyCellDef;

  @Input() public sticky: boolean;
  @Input() public disabled: boolean = false;
  @Input() public disableSelectionsAccessor: (row: any) => boolean = () => this.disabled;
  @Input() public selectionsCollection: SelectionModel<T>;

  private selectedData: Array<T> = [];
  private readonly destroy$ = new Subject<void>();

  constructor(@Optional() public readonly table: TableComponent<T>, private readonly tableService: TableService<T>) {}

  public ngOnInit(): void {
    this.tableService.syncColumnDef(this.columnDef, "select");
    this.tableService.addColumnDef(this.table, this.columnDef, this.cellDef, this.headerCellDef, this.footerCellDef);

    this.table.dataSource
      .connect()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.selectedData = this.table.dataSource.filteredData;
        this.selectionsCollection.setSelection(
          ...this.table.dataSource.filteredData.filter((row) => this.selectionsCollection.isSelected(row))
        );
      });

    this.selectionsCollection.changed.pipe(takeUntil(this.destroy$)).subscribe(({ source }) => {
      this.table.tableAction.emit({ type: "select", row: undefined, selected: source.selected });
    });
  }

  public ngOnDestroy(): void {
    this.tableService.removeColumnDef(this.table, this.columnDef);
    this.destroy$.next();
    this.destroy$.complete();
  }

  public isMasterToggleDisabled(): boolean {
    return this.selectedData.every((row) => this.disableSelectionsAccessor(row));
  }

  public masterToggle(): void {
    if (this.isAllSelected()) {
      this.selectionsCollection.clear();
    } else {
      this.selectedData
        .filter((row) => !this.disableSelectionsAccessor(row))
        .forEach((row) => this.selectionsCollection.select(row));
    }
  }

  public isAllSelected(): boolean {
    return (
      this.selectionsCollection.selected.length ===
      this.selectedData.filter((row) => !this.disableSelectionsAccessor(row)).length
    );
  }
}
