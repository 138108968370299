import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { MediaPlayer } from "../../media-player";

/* eslint-disable @angular-eslint/no-host-metadata-property */
@Component({
  selector: "audio-player[title]",
  templateUrl: "./audio-player.component.html",
  styleUrls: ["../../styles/media-player.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "audio-player"
  }
})
export class AudioPlayerComponent extends MediaPlayer {
  @Input() title: string;
}
