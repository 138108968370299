export const SELECT_OPTIONS = [
  {
    label: "Frau",
    value: "she_her_hers"
  },
  {
    label: "Herr",
    value: "he_him_his"
  },
  {
    label: "Divers",
    value: "they_them_theirs"
  }
];

export const TEXT_FIELD_LIST = [
  {
    label: "Vor- und Nachname",
    formControlName: "name"
  },
  {
    label: "Kommune/AGS",
    formControlName: "addressCity"
  },
  {
    label: "Straße, Hausnummer",
    formControlName: "address"
  },
  {
    label: "PLZ, Ort",
    formControlName: "addressPostalCode"
  },

  {
    label: "E-Mailadresse",
    formControlName: "email"
  }
];
