import { PLUGIN } from "src/app/common/enums/plugins";
import { SLOT } from "src/app/common/enums/slot";
import { CrmModule } from "../enums/permissions";

export const MODULES_CRM_IDS_MAP: {
  [slot in Exclude<SLOT | PLUGIN, SLOT.LANDING>]: CrmModule;
} = {
  [SLOT.GRID_DEVELOPMENT]: CrmModule.GridDevelopment,
  [SLOT.OUTAGE_MONITORING]: CrmModule.OutageMonitoring,
  [SLOT.GRID_CONNECTION]: CrmModule.GridConnection,
  [SLOT.THREE_YEAR_PLAN]: CrmModule.ThreeYearPlan,
  [SLOT.ACCOUNTING]: CrmModule.Accounting,
  [PLUGIN.STREET_LIGHTING]: CrmModule.StreetLighting,
  [PLUGIN.SHOPPING_CART]: CrmModule.ShoppingCart,
  [PLUGIN.NOYSEE]: CrmModule.Noysee,
  [PLUGIN.KEM]: CrmModule.KEM,
  [PLUGIN.CO2_BALANCE]: CrmModule.Co2Balance,
  [PLUGIN.DIGINAMIC]: CrmModule.Diginamic,
  [PLUGIN.CAMPUS_ONE]: CrmModule.CampusOne,
  [PLUGIN.QUICK_CHECK]: CrmModule.QuickCheck
};

export const CRM_MODULES_IDS_MAP: {
  [module in CrmModule]?: Exclude<SLOT | PLUGIN, SLOT.LANDING>;
} = {
  [CrmModule.GridDevelopment]: SLOT.GRID_DEVELOPMENT,
  [CrmModule.OutageMonitoring]: SLOT.OUTAGE_MONITORING,
  [CrmModule.GridConnection]: SLOT.GRID_CONNECTION,
  [CrmModule.ThreeYearPlan]: SLOT.THREE_YEAR_PLAN,
  [CrmModule.Accounting]: SLOT.ACCOUNTING,
  [CrmModule.StreetLighting]: PLUGIN.STREET_LIGHTING,
  [CrmModule.ShoppingCart]: PLUGIN.SHOPPING_CART,
  [CrmModule.Noysee]: PLUGIN.NOYSEE,
  [CrmModule.KEM]: PLUGIN.KEM,
  [CrmModule.Co2Balance]: PLUGIN.CO2_BALANCE,
  [CrmModule.Diginamic]: PLUGIN.DIGINAMIC,
  [CrmModule.CampusOne]: PLUGIN.CAMPUS_ONE,
  [CrmModule.QuickCheck]: PLUGIN.QUICK_CHECK
};
