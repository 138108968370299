import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { CardModule } from "src/app/common/components/card/card.module";
import { LightButtonComponent } from "src/app/common/components/light-button/light-button.component";

@Component({
  selector: "links-card",
  templateUrl: "./links-card.component.html",
  styleUrls: ["./../tile/tile.component.scss", "./links-card.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CardModule, LightButtonComponent]
})
export class LinksCardComponent {
  @Output() public readonly newsClicked = new EventEmitter<void>();
  @Output() public readonly subscriptionClicked = new EventEmitter<void>();
}
