export interface IHeatpumpConnectionGroupsDto {
  count: number;
  capacity: number;
  consumption: number;
  co2Saving: number;
}

export class HeatpumpConnectionGroupsDto implements IHeatpumpConnectionGroupsDto {
  public count: number;
  public capacity: number;
  public consumption: number;
  public co2Saving: number;

  constructor(data: IHeatpumpConnectionGroupsDto) {
    Object.assign(this, data);
  }
}
