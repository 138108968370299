import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { Observable } from "rxjs";
import { switchMap, withLatestFrom } from "rxjs/operators";
import { SLOT } from "src/app/common/enums/slot";
import { AppConfig } from "src/app/core/helpers/app.config";
import { Tabs } from "../constants/tabs";

interface IMConfigState {
  nestedTableData: Array<any>;
  tabName: Tabs;
}

@Injectable()
export class NestedTableComponentStore extends ComponentStore<IMConfigState> {
  /** Component Store reducer */
  public readonly setNestedTableData = this.updater((state, { data }: any) => ({
    ...state,
    nestedTableData: data
  }));
  public readonly clearNestedData = this.updater((state) => ({
    ...state,
    nestedTableData: []
  }));
  public readonly setTabName = this.updater((state, tabName: Tabs) => ({
    ...state,
    tabName
  }));

  public readonly fetchNestedTableData = this.effect(
    (props$: Observable<{ id: string; module: SLOT | "street-lighting" }>) =>
      props$.pipe(
        withLatestFrom(this.select((state) => state.tabName)),
        switchMap(([{ id, module }, tabName]) =>
          this.http.get(`${this.urls[tabName]}/${id}`, { params: new HttpParams().set("module", module) }).pipe(
            tapResponse(
              (data: any) =>
                this.setNestedTableData({
                  data
                }),
              () => this.clearNestedData()
            )
          )
        )
      )
  );

  /** Component Store selectors */
  public readonly nestedTableData$ = this.select((state) => state.nestedTableData);
  public readonly tabName$ = this.select((state) => state.tabName);
  private urls = {
    [Tabs.USERS]: `${AppConfig.connection.infrastructure.userSettings}/users/by-user`,
    [Tabs.MUNICIPALITIES]: `${AppConfig.connection.infrastructure.userSettings}/users/by-municipality`
  };

  constructor(private http: HttpClient) {
    super({
      nestedTableData: [],
      tabName: null
    });
  }
}
