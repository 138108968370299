import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GpiIcon } from "@enersis/gp-components/libs/iconfont/dist/gpi-iconfont";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent {
  @Input() public icon: GpiIcon | string;
}
