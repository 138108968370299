import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { EneButtonModule } from "@enersis/ui-button";
import { DialogWrapperComponent } from "./dialog-wrapper.component";

@NgModule({
  declarations: [DialogWrapperComponent],
  imports: [CommonModule, MatLegacyDialogModule, MatIconModule, EneButtonModule],
  exports: [MatLegacyDialogModule, DialogWrapperComponent]
})
export class DialogWrapperModule {}
