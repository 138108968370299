import { TableColumnConfig } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { ModuleInfoColumnComponent } from "./components/module-info-cell/module-info-column.component";
import { MunicipalityInfoColumnComponent } from "./components/municipality-info-cell/municipality-info-column.component";
import { TableRow } from "./modules-tab.interfaces";

const modulePropertyAccessor = (row: TableRow, id: string) => (row[id].active ? "aktiv" : "inaktiv");

export const configurationAreaConcessionsColumns: Array<TableColumnConfig> = [
  {
    id: "name",
    type: "custom",
    key: "Alle Kommunen",
    customComponent: MunicipalityInfoColumnComponent,
    propertyAccessor: (row: TableRow) => `${row.name}${row.ags}`
  },
  {
    id: "grid-development",
    type: "custom",
    key: "NEP",
    customComponent: ModuleInfoColumnComponent,
    propertyAccessor: modulePropertyAccessor
  },
  {
    id: "grid-connections",
    type: "custom",
    key: "EuN",
    customComponent: ModuleInfoColumnComponent,
    propertyAccessor: modulePropertyAccessor
  },
  {
    id: "incident-management",
    type: "custom",
    key: "SM",
    customComponent: ModuleInfoColumnComponent,
    propertyAccessor: modulePropertyAccessor
  },
  {
    id: "three-year-plan",
    type: "custom",
    key: "3JP",
    customComponent: ModuleInfoColumnComponent,
    propertyAccessor: modulePropertyAccessor
  },
  {
    id: "accounting",
    type: "custom",
    key: "AuV",
    customComponent: ModuleInfoColumnComponent,
    propertyAccessor: modulePropertyAccessor
  },
  {
    id: "editAction",
    type: "action",
    actionIcon: "ec_edit",
    key: "",
    // columnStyles: "grey",
    style: {
      width: "40px"
    }
  }
];

export const concessionsModulesCheckboxes: Array<{ id: string; label: string }> = [
  {
    id: "nep",
    label: "NEP"
  },
  {
    id: "eun",
    label: "EuN"
  },
  {
    id: "sm",
    label: "SM"
  },
  {
    id: "tjp",
    label: "3JP"
  },
  {
    id: "auv",
    label: "AuV"
  }
];

export const concessionsStatesCheckboxes: Array<{ id: string; label: string }> = [
  {
    id: "active",
    label: "aktiv"
  },
  {
    id: "inactive",
    label: "inaktiv"
  }
];
