import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormFieldControl } from "../form-control";

export interface SelectItem {
  label: string;
  value: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent extends FormFieldControl {
  @Output() public readonly closed = new EventEmitter<any>();

  @Input() public options: Array<SelectItem>;
  @Input() public helper: string;
  @Input() public validationMessage: string;

  /**
   * Sets the dropdown menu's position to fixed. This is useful when the select
   * is inside of a stacking context e.g. inside of a dialog.
   */
  @Input() public fixedMenuPosition = false;

  public onSelected(event: CustomEvent): void {
    event.stopPropagation();
    this._onChange(this.options[event.detail.index]?.value);
    this.changed.emit(this.options[event.detail.index]?.value);
  }

  public onClosed(event: any /** Event */): void {
    event.stopPropagation();
    this.closed.emit(event.target?.value);
  }

  public trackByValue(_: number, option: SelectItem): string {
    return option.value;
  }
}
