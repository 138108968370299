import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { ButtonToggleModule } from "../../button-toggle/button-toggle.module";
import { DialogWrapperModule } from "../dialog-wrapper/dialog-wrapper.module";
import { ContactDialogComponent } from "./contact.component";

const materialModules = [MatLegacyDialogModule, MatIconModule, MatLegacySelectModule];

@NgModule({
  declarations: [ContactDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PipesModule,
    ButtonToggleModule,
    DialogWrapperModule,
    ...materialModules
  ]
})
export class ContactDialogModule {}
