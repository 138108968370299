import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { JsonsService } from "src/app/core/services/jsons.service";
import { GlossaryTags } from "./regulated-glossary.component";

interface GlossaryItem {
  title: string;
  content: string;
  tags: Array<GlossaryTags>;
}

@Injectable()
export class RegulatedGlossaryDialogService {
  constructor(private readonly jsonsService: JsonsService) {}

  public getGlossaries(): Observable<{ definitions: Array<GlossaryItem>; moduleKnowledge: Array<GlossaryItem> }> {
    return this.jsonsService.getRegulatedGlossaries();
  }
}
