import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import "@enersis/gp-components/gp-icon";
import "@enersis/gp-components/gp-modal";
import { EneButtonModule } from "@enersis/ui-button";
import { DialogFrameComponent } from "./dialog-frame.component";

@NgModule({
  declarations: [DialogFrameComponent],
  imports: [CommonModule, MatLegacyDialogModule, EneButtonModule],
  exports: [DialogFrameComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogFrameModule {}
