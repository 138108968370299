import { formatDate } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, Input, Optional, ViewEncapsulation } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Pure } from "src/app/common/decorators/pure";
import { formatCurrencyDE } from "src/app/core/utils/number/currency";
import { TABLE_COLUMN_CONTEXT, TableColumn } from "../../../classes/table-column";
import { TableColumnConfig, TableColumnContext } from "../../../table.interfaces";
import { TABLE_VALUE_FORMAT } from "../../../table.tokens";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-textual-column",
  templateUrl: "./textual-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextualColumnComponent extends TableColumn {
  @Input() public tooltipDisabled: boolean = false;

  public placeholder = "";

  constructor(
    private readonly translate: TranslateService,
    @Inject(TABLE_VALUE_FORMAT)
    private readonly valueFormat: string,
    @Optional()
    @Inject(TABLE_COLUMN_CONTEXT)
    public readonly context?: TableColumnContext
  ) {
    super(context);
  }

  @Pure
  public hasPlaceholder(columnConfig: TableColumnConfig) {
    return columnConfig?.placeholderAccessor ? columnConfig.placeholderAccessor(this.context.row) : this.placeholder;
  }

  @Pure
  public transform(value: unknown, columnConfig: TableColumnConfig): string {
    let _value = value;

    if (columnConfig.translateAccessor) {
      _value = columnConfig.translateAccessor(this.context.row, this.translate);
    }

    if (this.hasPlaceholder(columnConfig)) {
      this.placeholder = this.translate.instant(columnConfig.placeholderAccessor(this.context.row));
    }

    switch (columnConfig.type) {
      case "date": {
        return _value
          ? formatDate(_value as string | number | Date, columnConfig.dateFormat, "de-ch")
          : this.placeholder;
      }
      case "value": {
        return typeof _value === "number"
          ? formatCurrencyDE(_value as number, "EUR", this.valueFormat)
          : this.placeholder;
      }
      case "list": {
        return (_value as Array<string>)?.length ? (_value as Array<string>).join("\n") : this.placeholder;
      }
      case "text": {
        if (typeof _value === "string") {
          return _value ? this.translate.instant(_value) : this.placeholder;
        } else {
          return _value ? String(_value) : this.placeholder;
        }
      }
      default: {
        return _value as string;
      }
    }
  }
}
