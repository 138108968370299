import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LaunchpadResponse } from "src/app/common/dto/admin-module/launchpad";
import { AdminModulePlanDto } from "src/app/common/dto/admin-module/plan";
import {
  AdminModuleSubscriptionBodyDto,
  AdminModuleSubscriptionDto,
  AdminModuleSubscriptionStatusBodyDto
} from "src/app/common/dto/admin-module/subscription";
import { AppConfig } from "../helpers/app.config";
import { FilesService } from "./files.service";

interface RequestParams {
  pageSize: number;
  parent: string;
  file: Blob;
  moduleSubscriptionName: string;
  fileName: string;
}

@Injectable({ providedIn: "root" })
export class AdminDataService {
  public readonly api = AppConfig.connection.admin;
  public readonly parent = AppConfig.connection.keycloakConfig.config.realm;

  public readonly getApplicationModule = (applicationModule: string): string =>
    `applications/${this.parent}/modules/${applicationModule}`;

  private readonly http = inject(HttpClient);
  private readonly filesService = inject(FilesService);

  public getSubscriptionList(params: Pick<RequestParams, "pageSize">): Observable<Array<AdminModuleSubscriptionDto>> {
    return this.http
      .get<{
        moduleSubscriptions: Array<AdminModuleSubscriptionDto>;
        nextPageToken: string;
      }>(`${this.api.adminModule}/subscription/list`, {
        params: new HttpParams({ fromObject: { ...params, parent: this.parent } })
      })
      .pipe(
        map(({ moduleSubscriptions }) => moduleSubscriptions),
        catchError(() => of([]))
      );
  }

  public getSubscriptionUserList(
    params: Pick<RequestParams, "pageSize">
  ): Observable<Array<AdminModuleSubscriptionDto>> {
    return this.http
      .get<{
        moduleSubscriptions: Array<AdminModuleSubscriptionDto>;
        nextPageToken: string;
      }>(`${this.api.adminModule}/subscription/user/list`, {
        params: new HttpParams({ fromObject: { ...params, parent: this.parent } })
      })
      .pipe(
        map(({ moduleSubscriptions }) => moduleSubscriptions),
        catchError(() => of([]))
      );
  }

  public postSubscription(body: AdminModuleSubscriptionBodyDto): Observable<any> {
    return this.http.post(`${this.api.adminModule}/subscription`, body).pipe(
      map(() => {}),
      catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode)))
    );
  }

  public deleteSubscription(moduleSubscriptionName: string): Observable<any> {
    return this.http
      .delete(`${this.api.adminModule}/subscription`, {
        params: new HttpParams({
          fromObject: {
            moduleSubscriptionName
          }
        })
      })
      .pipe(catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode))));
  }

  public getPlanList(module: string): Observable<Array<AdminModulePlanDto>> {
    return this.http
      .get<{ plans: Array<AdminModulePlanDto> }>(`${this.api.adminModule}/plan/list`, {
        params: new HttpParams({
          fromObject: {
            module
          }
        })
      })
      .pipe(
        map((result) => result.plans),
        catchError(() => of([]))
      );
  }

  public getLaunchpadList(type: string, ags: string): Observable<LaunchpadResponse> {
    return this.http
      .get<LaunchpadResponse>(`${this.api.adminModule}/launchpad`, {
        params: new HttpParams({
          fromObject: {
            type,
            ags
          }
        })
      })
      .pipe(catchError(() => of({ modules: [], outageDashboard: false })));
  }

  public patchSubscriptionStatus(body: Array<AdminModuleSubscriptionStatusBodyDto>): Observable<any> {
    return this.http.patch(`${this.api.adminModule}/subscription/status`, body).pipe(
      map(() => {}),
      catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode)))
    );
  }

  public postInvoiceUpload({
    file,
    moduleSubscriptionName
  }: Pick<RequestParams, "file" | "moduleSubscriptionName">): Observable<{ result: string }> {
    const body = new FormData();
    body.append("file", file);
    body.append("moduleSubscriptionName", moduleSubscriptionName);

    return this.http
      .post<{ result: string }>(`${this.api.adminModule}/invoice/upload`, body)
      .pipe(catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode))));
  }

  public postInvoiceDownload({ fileName }: Pick<RequestParams, "fileName">): void {
    this.filesService.postPdf(`${this.api.adminModule}/invoice/download`, { fileName });
  }

  public deleteInvoice({ fileName }: Pick<RequestParams, "fileName">): Observable<any> {
    return this.http
      .delete(`${this.api.adminModule}/invoice`, { body: { fileName } })
      .pipe(catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode))));
  }
}
