import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { SelectItem } from "src/app/common/components/form/select/select.component";
import { Pure } from "src/app/common/decorators/pure";
import { TableActionColumn } from "../../../classes/table-action-column";
import { SelectTableColumnConfig } from "../../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-select-column",
  templateUrl: "select-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectColumnComponent<T> extends TableActionColumn {
  @Pure
  public hideSelect(columnConfig: SelectTableColumnConfig, row: T): boolean {
    return "hideSelect" in columnConfig
      ? columnConfig.hideSelect
      : "hideSelectAccessor" in columnConfig
      ? columnConfig.hideSelectAccessor(row)
      : false;
  }

  @Pure
  public options(columnConfig: SelectTableColumnConfig, row: T): Array<SelectItem> {
    return "options" in columnConfig
      ? columnConfig.options
      : "optionsAccessor" in columnConfig
      ? columnConfig.optionsAccessor(row)
      : [];
  }

  @Pure
  public value(columnConfig: SelectTableColumnConfig, row: T): any | null {
    return "valueAccessor" in columnConfig ? columnConfig.valueAccessor(row) : row[columnConfig.id] ?? null;
  }

  @Pure
  public label(columnConfig: SelectTableColumnConfig, row: T): string | null {
    return "selectLabel" in columnConfig
      ? columnConfig.selectLabel
      : "selectLabelAccessor" in columnConfig
      ? columnConfig.selectLabelAccessor(row)
      : null;
  }
}
