import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import {
  SelfSufficiencyDegreePerYearDto,
  SelfSufficiencyDegreeYearDto
} from "../../../../common/dto/energy/self-sufficiency/degree";
import { SelfSufficiencyService } from "../../../../core/services/self-sufficiency.service";
import {
  getSelfSufficiencyDegreeAllYearsData,
  getSelfSufficiencyDegreeAllYearsDataSuccess,
  getSelfSufficiencyDegreeData,
  getSelfSufficiencyDegreeDataSuccess
} from "../../../actions/energy/self-sufficiency/degree.actions";

@Injectable()
export class DegreeEffects {
  getDegreeData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelfSufficiencyDegreeData),
      switchMap((action) =>
        this.selfSufficiencyService.getDegreeData(action).pipe(
          map((payload: SelfSufficiencyDegreeYearDto) => getSelfSufficiencyDegreeDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getDegreeAllYearsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelfSufficiencyDegreeAllYearsData),
      switchMap((action) =>
        this.selfSufficiencyService.getDegreeAllYearsData(action).pipe(
          map((payload: Array<SelfSufficiencyDegreePerYearDto>) =>
            getSelfSufficiencyDegreeAllYearsDataSuccess({ payload })
          ),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private selfSufficiencyService: SelfSufficiencyService) {}
}
