<tpm-contentful-wrapper
  *ngIf="vm$ | async as vm"
  [headline]="headline | translate"
  [contentType]="contentType"
  [contentTypeToggles]="contentTypeToggles"
  [hideModuleSelectOptions]="vm.newsType === 'card'"
  (searchChange)="onSearchChange($event)"
  (moduleChange)="onModuleChange($event)"
  (contentTypeChange)="onContentTypeChange($event)"
  (markAllRead)="onMarkAllRead(vm.entries)"
>
  <div class="news-wrapper" [ngSwitch]="vm.newsType" [ngClass]="vm.newsType">
    <ng-container *ngSwitchCase="'list-item'">
      <tpm-contentful-list-item
        *ngFor="let entry of vm.entries; trackBy: trackById"
        [isMobileView]="vm.isMobileView"
        [data]="entry"
        (listItemClick)="onListItemNewsClick($event, entry, vm.contentType)"
      ></tpm-contentful-list-item>
    </ng-container>
    <ng-container *ngSwitchCase="'card'">
      <tpm-contentful-card
        *ngFor="let entry of vm.entries; trackBy: trackById"
        [isMobileView]="vm.isMobileView"
        [buttonLabel]="'CONTENTFUL.NEWS.CARD_BTN_LABEL' | translate"
        [data]="entry"
        (cardClick)="onCardNewsClick(vm.contentType, entry)"
      ></tpm-contentful-card>
    </ng-container>
  </div>
</tpm-contentful-wrapper>
