import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class FullscreenService {
  public isFullscreen$: Observable<boolean>;
  private isFullscreenSubject = new BehaviorSubject(false);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.isFullscreen$ = this.isFullscreenSubject.asObservable();

    this.document.addEventListener("fullscreenchange", () =>
      this.isFullscreenSubject.next(Boolean(this.document.fullscreenElement))
    );
  }

  public openFullscreen(element: HTMLElement | Document = this.document): void {
    if ((element as any).requestFullscreen) {
      (element as any).requestFullscreen();
    } else if ((element as any).webkitRequestFullscreen) {
      (element as any).webkitRequestFullscreen();
    } else if ((element as any).msRequestFullscreen) {
      (element as any).msRequestFullscreen();
    }
  }

  public closeFullscreen(): void {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if ((this.document as any).msExitFullscreen) {
      (this.document as any).msExitFullscreen();
    } else if ((this.document as any).mozCancelFullScreen) {
      (this.document as any).mozCancelFullScreen();
    } else if ((this.document as any).webkitExitFullscreen) {
      (this.document as any).webkitExitFullscreen();
    }
  }

  public toggleFullscreen(element: HTMLElement): void {
    if (!this.document.fullscreenElement) {
      this.openFullscreen(element);
    } else {
      this.closeFullscreen();
    }
  }
}
