import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { timeline } from "src/app/common/constants/module-settings";
import { SLOT } from "src/app/common/enums/slot";
import { IProjectEntity } from "src/app/core/interfaces/project-entity";
import { coordinates } from "./constants";

@Component({
  selector: "app-ca-settings-dialog",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigAreaSettingsDialogComponent implements OnInit {
  public form: FormGroup;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public readonly dialogData: {
      multiSelection: boolean;
      formData: Array<IProjectEntity>;
    },
    private readonly dialogRef: MatLegacyDialogRef<ConfigAreaSettingsDialogComponent>,
    private readonly fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.form = this.createForm();
    this.prefillForm(this.form, this.dialogData.formData[0]);
  }

  public closeDialog(submit: boolean = false): void {
    if (!submit) {
      this.dialogRef.close(false);
    } else if (submit && this.form.valid) {
      const data = this.form.getRawValue();
      data.modules.items = data.modules.items
        .filter((item) => !item.disabled)
        .map((module) => {
          let gridDevelopmentFields = {};
          let gridConnectionFields = {};
          if (module.id === SLOT.GRID_DEVELOPMENT) {
            gridDevelopmentFields = {
              h2Ready: module.gas && module.h2Ready,
              timeline: {
                from: module.from,
                to: module.to,
                default: module.default
              }
            };
          } else if (module.id === SLOT.GRID_CONNECTION && module.active) {
            gridConnectionFields = {
              benchmarking: module.benchmarking
            };
          }

          return {
            moduleId: module.id,
            active: module.active,
            config: {
              division: {
                electricity: module.electricity,
                gas: module.gas
              },
              ...gridDevelopmentFields,
              ...gridConnectionFields
            }
          };
        });
      this.dialogRef.close(data);
    }
  }

  private numberRangeValidator(minValue: number, maxValue: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      //check if the input is a number in the format 7/7.0.../12,0...
      if (Validators.pattern(/^\d+(?:[.,]\d+)?$/)(control) !== null) {
        return { patternError: { value: control.value } };
      }
      let value = control.value;
      if (typeof value === "string") {
        value = value.replace(",", ".");
        value = Number(value);
      }
      if (value < minValue || value > maxValue) {
        return { rangeError: { value: control.value } };
      }
      return null;
    };
  }

  private createForm(): FormGroup {
    const municipality = this.fb.group({
      name: ["", this.dialogData.multiSelection ? null : Validators.required],
      entityId: [{ value: "", disabled: true }],
      ags: [{ value: "", disabled: true }],
      coordinatesLong: ["", this.numberRangeValidator(coordinates.longitude.min, coordinates.longitude.max)],
      coordinatesLat: ["", this.numberRangeValidator(coordinates.latitude.min, coordinates.latitude.max)],
      zoomLevel: [null, this.dialogData.multiSelection ? null : Validators.required],
      coatOfArms: [""],
      newsNotification: [null]
    });
    const contactPerson = this.fb.group({
      role: [{ value: "", disabled: true }, Validators.required],
      salutation: [{ value: "", disabled: true }, Validators.required],
      givenName: ["", Validators.required],
      familyName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      address: ["", Validators.required],
      phoneNumber: ["", Validators.required]
    });
    const commonFields = {
      id: [{ value: "", disabled: true }],
      active: [{ value: false, disabled: false }],
      electricity: [{ value: false, disabled: false }],
      gas: [{ value: false, disabled: false }]
    };
    const gridDevelopmentFields = {
      h2Ready: [{ value: false, disabled: false }],
      from: [{ value: timeline.from, disabled: false }],
      to: [{ value: timeline.to, disabled: false }],
      default: [{ value: timeline.default, disabled: false }]
    };
    const gridConnectionFields = {
      benchmarking: [{ value: false, disabled: false }]
    };
    const modules = this.fb.group({
      concession: [{ value: false, disabled: false }],
      electricity: [{ value: false, disabled: false }],
      gas: [{ value: false, disabled: false }],
      items: this.fb.array(
        this.dialogData.formData[0].moduleComposition.map((module) => {
          let fields = { ...commonFields };

          if (module.id === SLOT.GRID_DEVELOPMENT) {
            fields = { ...fields, ...gridDevelopmentFields };
          } else if (module.id === SLOT.GRID_CONNECTION) {
            fields = { ...fields, ...gridConnectionFields };
          }

          return this.fb.group({ disabled: true, ...fields });
        })
      )
    });

    return this.fb.group({ municipality, contactPerson, modules });
  }

  private prefillForm(form: FormGroup, data: IProjectEntity): void {
    let values = {
      contactPerson: data.contactPerson,
      modules: {
        items: data.moduleComposition.map((module) => ({ id: module.id }))
      }
    } as any;

    if (!this.dialogData.multiSelection) {
      values = {
        ...values,
        municipality: {
          entityId: data.entityId,
          ags: data.entity.ags,
          name: data.entity.name,
          coordinatesLong: `${data.entity.position.longitude}`,
          coordinatesLat: `${data.entity.position.latitude}`,
          zoomLevel: data.entity.level,
          coatOfArms: data.entity.coatOfArms,
          newsNotification: data.features?.newsNotification
        },
        modules: {
          concession: data.config.concession,
          electricity: data.config.division.electricity,
          gas: data.config.division.gas,
          items: data.moduleComposition.map((module) => {
            let fields: any = {
              disabled: false,
              id: module.id,
              active: module.active,
              electricity: module.config.division.electricity,
              gas: module.config.division.gas
            };

            if (module.id === SLOT.GRID_DEVELOPMENT) {
              module.config.timeline = module.config.timeline ?? timeline;
              fields = {
                ...fields,
                h2Ready: module.config.h2Ready,
                from: module.config.timeline.from,
                to: module.config.timeline.to,
                default: module.config.timeline.default
              };
            } else if (module.id === SLOT.GRID_CONNECTION) {
              fields = {
                ...fields,
                benchmarking: module.config.benchmarking
              };
            }

            return fields;
          })
        }
      };
    }

    form.patchValue(values);
  }
}
