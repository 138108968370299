import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Entry } from "contentful";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import { RootState } from "src/app/ngrx/root-reducers";
import {
  selectAvailableContentfulEntries,
  selectContentfulContentType
} from "src/app/ngrx/selectors/contentful.selectors";
import { selectLaunchpadOverlay } from "src/app/ngrx/selectors/overlay.selectors";
import { selectUsersSettingsUsersStorage } from "src/app/ngrx/selectors/users-settings.selectors";
import { createCommunalPlatformNewsEntry, createNetzeBwNewsEntry, createTileOnTileEntry } from "./news.factories";
import { CommunalPlatformNewsEntry, NetzeBwNewsEntry, TileOnTileEntry } from "./news.interfaces";


export const filterRegulatedConditionFn = (type: ContentfulContentType) =>
  type === ContentfulContentType.CPRegulatedModulesNews || type === ContentfulContentType.NBRegulatedModulesNews;

export const filterServicesConditionFn = (type: ContentfulContentType) =>
  type === ContentfulContentType.CPServicesModulesNews || type === ContentfulContentType.NBServicesModulesNews;

export const filterCPConditionFn = (type: ContentfulContentType) =>
  type === ContentfulContentType.CPRegulatedModulesNews || type === ContentfulContentType.CPServicesModulesNews;

export const filterNBConditionFn = (type: ContentfulContentType) =>
  type === ContentfulContentType.NBRegulatedModulesNews || type === ContentfulContentType.NBServicesModulesNews;

export const filterTileOnTileFn = (type: ContentfulContentType) => type === ContentfulContentType.Infokachel;


@Injectable({ providedIn: "root" })
export class NewsService {
  constructor(private readonly store: Store<RootState>) {}

  public readonly sortByDate = (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime();

  public readonly getAssetUrlsMap = (asset: any): Record<string, string> =>
    asset?.reduce((acc, item) => ({ ...acc, [item.sys.id]: item.fields.file.url }), {}) || {};

  public createEntry(
    contentType: ContentfulContentType,
    item: Entry<any>,
    assets: Record<string, string>,
    storage: Record<string, boolean>
  ): CommunalPlatformNewsEntry | NetzeBwNewsEntry | TileOnTileEntry {
    switch (contentType) {
      case ContentfulContentType.CPRegulatedModulesNews:
      case ContentfulContentType.CPServicesModulesNews: {
        return createCommunalPlatformNewsEntry(item as Entry<any>, assets, storage);
      }
      case ContentfulContentType.NBRegulatedModulesNews:
      case ContentfulContentType.NBServicesModulesNews: {
        return createNetzeBwNewsEntry(item as Entry<any>, assets, storage);
      }
      case ContentfulContentType.Infokachel: {
        return createTileOnTileEntry(item as Entry<any>);
      }
    }
  }

  public getNewsEntries(): Observable<Array<CommunalPlatformNewsEntry | NetzeBwNewsEntry>> {
    return combineLatest([
      this.store.pipe(select(selectContentfulContentType)),
      this.store.pipe(select(selectUsersSettingsUsersStorage)),
      this.store.pipe(select(selectAvailableContentfulEntries))
    ]).pipe(
      map(([contentType, storage, { items = [], includes = {} }]) => {
        switch (contentType) {
          case ContentfulContentType.CPRegulatedModulesNews:
          case ContentfulContentType.CPServicesModulesNews: {
            return items
              .map((item) =>
                createCommunalPlatformNewsEntry(item as Entry<any>, this.getAssetUrlsMap(includes.Asset), storage)
              )
              .sort(this.sortByDate);
          }
          case ContentfulContentType.NBRegulatedModulesNews:
          case ContentfulContentType.NBServicesModulesNews: {
            return items
              .map((item) => createNetzeBwNewsEntry(item as Entry<any>, this.getAssetUrlsMap(includes.Asset), storage))
              .sort(this.sortByDate);
          }
          default: {
            return [];
          }
        }
      })
    );
  }



  public getNewsViewModel(): Observable<any> {
    return combineLatest([
      this.store.pipe(select(selectLaunchpadOverlay)),
      this.store.pipe(select(selectAvailableContentfulEntries)),
      this.store.pipe(select(selectUsersSettingsUsersStorage))
    ]).pipe(
      map(([overlay, entries, storage]) => {
        const isRegulatedModuleOverlay = overlay === "module";
        const filterConditionFn = isRegulatedModuleOverlay ? filterRegulatedConditionFn : filterServicesConditionFn;
        const assets = this.getAssetUrlsMap(entries.includes?.Asset);

        const mappedNews =
          entries.items
            ?.sort(this.sortByDate)
            .filter((item) => filterConditionFn(item.sys.contentType.sys.id as ContentfulContentType))
            .map((item) => ({
              contentType: item.sys.contentType.sys.id,
              ...this.createEntry(
                item.sys.contentType.sys.id as ContentfulContentType,
                item,
                assets,
                storage
              )
            })) || [];

        const netzeBWNews = mappedNews.filter((item) => filterNBConditionFn(item.contentType as ContentfulContentType));
        const modulesNews = mappedNews.filter((item) => filterCPConditionFn(item.contentType as ContentfulContentType));
        return {
          netzeBWNews,
          // take 3 netzebw + 3 community items
          carouselNews: [...netzeBWNews.slice(0, 3), ...modulesNews.slice(0, 3)],
          modulesNews:
            entries.items
              ?.filter((item) => filterTileOnTileFn(item.sys.contentType.sys.id as ContentfulContentType))
              .map((item) => createTileOnTileEntry(item as Entry<any>))
              .reduce((acc, item: any) => {
                if (item.moduleName) {
                  return { ...acc, [item.moduleName]: item };
                } else {
                  return acc;
                }
              }, {}) || {}
        };
      })
    );
  }

}
