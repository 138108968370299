import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { SLOT } from "../common/enums/slot";
import { GridDevelopmentState } from "../slots/grid-development/ngrx/grid-development-reducers";
import { TYPState } from "../slots/three-year-plan/ngrx/typ-reducers";
import { adminDataReducer, AdminDataState } from "./reducers/admin-data.reducer";
import * as app from "./reducers/app.reducer";
import * as contentful from "./reducers/contentful.reducer";
import * as energy from "./reducers/energy/energy.reducer";
import * as gasCockpit from "./reducers/gas-cockpit.reducer";
import * as infrastructure from "./reducers/infrstructure/infrastructure.reducer";
import * as map from "./reducers/map.reducer";
import * as network from "./reducers/network.reducer";
import * as regionOverlay from "./reducers/overlay.reducer";
import * as sidenavigation from "./reducers/sidenavigation.reducer";
import * as usersSettings from "./reducers/users-settings.reducer";

export interface RootState {
  app: app.State;
  adminData: AdminDataState;
  contentful: contentful.ContentfulState;
  usersSettings: usersSettings.UsersSettingsState;
  regionOverlay: regionOverlay.OverlayState;
  map: map.State;
  sidenavigation: sidenavigation.ISidenavigationState;
  gasCockpit: gasCockpit.State;
  network: network.State;
  infrastructure: infrastructure.State;
  energy: energy.State;
  router: RouterReducerState;
  /** Optional SLOT specific states interfaces */
  [SLOT.GRID_DEVELOPMENT]?: GridDevelopmentState;
  [SLOT.THREE_YEAR_PLAN]?: TYPState;
}

export const reducers = {
  app: app.reducer,
  adminData: adminDataReducer,
  contentful: contentful.reducer,
  usersSettings: usersSettings.reducer,
  regionOverlay: regionOverlay.reducer,
  map: map.reducer,
  sidenavigation: sidenavigation.reducer,
  gasCockpit: gasCockpit.reducer,
  network: network.reducer,
  infrastructure: infrastructure.reducer,
  energy: energy.reducer,
  router: routerReducer
};
