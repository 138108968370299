<tpm-dialog-frame
  [header]="'Upload Rechnung' | translate"
  [closable]="false"
  [closeButtonLabel]="'Verwerfen' | translate"
  [submitButtonLabel]="'Fertigstellen' | translate"
  (closed)="onClose()"
  (submitted)="onSubmit()"
>
  <tpm-drop-file-area
    *ngrxLet="{ files: files$ } as vm"
    [accept]="accept"
    [acceptLabel]="acceptLabel"
    [listItemExtraLineAccessor]="listItemExtraLineAccessor"
    [disabled]="vm.files?.length >= 1"
    [files]="vm.files"
    (fileRemove)="onRemove($event)"
    (dropped)="onDrop($event)"
  ></tpm-drop-file-area>
</tpm-dialog-frame>
