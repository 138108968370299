import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

export interface GpGlossaries {
  calculationLogic: string;
  datasources: string;
  explanationOfTerms: string;
}

@Injectable({ providedIn: "root" })
export class GpCmsService {
  private readonly api = "https://cms.climateintelligence.com";

  constructor(private readonly http: HttpClient) {}

  public getGlossaries(): Observable<GpGlossaries> {
    return this.http.get<[GpGlossaries]>(`${this.api}/glossaries?_limit=1&_locale=de`).pipe(
      map(([result]) => result),
      catchError(() => of({} as GpGlossaries))
    );
  }
}
