import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  EMobilityConnectionGroupsDto,
  IEMobilityConnectionGroupsDto
} from "src/app/common/dto/energy/connection-groups/e-mobility";
import {
  ElectricityConnectionGroupsDto,
  IElectricityConnectionGroupsDto
} from "src/app/common/dto/energy/connection-groups/electricity";
import { GasConnectionGroupsDto, IGasConnectionGroupsDto } from "src/app/common/dto/energy/connection-groups/gas";
import {
  HeatpumpConnectionGroupsDto,
  IHeatpumpConnectionGroupsDto
} from "src/app/common/dto/energy/connection-groups/heatpump";
import {
  IPhotovoltaicsConnectionGroupsDto,
  PhotovoltaicsConnectionGroupsDto
} from "src/app/common/dto/energy/connection-groups/photovoltaics";
import { HydrogenForecastDto } from "src/app/common/dto/energy/hydrogen-forecast";
import { ElectricityStationDto, IElectricityStationDto } from "src/app/common/dto/energy/station/electricity";
import { GasStationDto, IGasStationDto } from "src/app/common/dto/energy/station/gas";
import { acceptJsonWithoutCache } from "../../common/constants/common";
import {
  CapacityPerYearDto,
  CapacityYearDto,
  ICapacityPerYearDto,
  ICapacityYearDto
} from "../../common/dto/energy/capacity";
import {
  Co2SavingPerYearDto,
  Co2SavingYearDto,
  ICo2SavingPerYearDto,
  ICo2SavingYearDto
} from "../../common/dto/energy/co2-saving";
import { ConsumptionDto, IConsumptionDto } from "../../common/dto/energy/consumption";
import { CumulatedInvestDto, ICumulatedInvestResponse } from "../../common/dto/energy/cumulated-invest";
import { IRegionsEMobilityDto, RegionsEMobilityDto } from "../../common/dto/energy/regions-e-mobility";
import { StationWorkloadDto } from "../../common/dto/energy/station-workload";
import { AppConfig } from "../helpers/app.config";

interface IRequestsParams {
  regionId?: string;
  year?: number;
  years?: Array<number>;
  scenarioId?: string;
  timelineRange?: [number, number];
  connectionGroupIds?: Array<string>;
  stationId?: string;
  year_20?: number;
  year_100?: number;
}

@Injectable({ providedIn: "root" })
export class EnergyService {
  constructor(private http: HttpClient) {}

  public getConsumptionData({ regionId, year, scenarioId }: IRequestsParams): Observable<ConsumptionDto> {
    return this.http
      .get<{ result: IConsumptionDto }>(
        `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/consumption`,
        {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("year", String(year)).set("scenarioId", scenarioId)
        }
      )
      .pipe(
        map(({ result }) => new ConsumptionDto(result)),
        catchError(() => of({} as ConsumptionDto))
      );
  }

  public getCo2SavingData({ regionId, year, scenarioId }: IRequestsParams): Observable<Co2SavingYearDto> {
    return this.http
      .get<{ result: ICo2SavingYearDto }>(
        `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/co2-saving`,
        {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("year", String(year)).set("scenarioId", scenarioId)
        }
      )
      .pipe(
        map(({ result }) => new Co2SavingYearDto(result)),
        catchError(() => of({} as Co2SavingYearDto))
      );
  }

  public getCo2SavingAllYearsData({
    regionId,
    scenarioId,
    timelineRange: [from, to],
    year_20,
    year_100
  }: IRequestsParams): Observable<Array<Co2SavingPerYearDto>> {
    const uri = `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/co2-saving`;
    let params = new HttpParams({ fromObject: { scenarioId } });

    if (year_20 && year_100) {
      params = params.append("year_20", year_20).append("year_100", year_100);
    }

    return this.http
      .get<{ result: Array<ICo2SavingPerYearDto> }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params
      })
      .pipe(
        map(({ result }) => result.filter(({ year }) => year >= from && year <= to)),
        map((result) => result.map((item) => new Co2SavingPerYearDto(item))),
        catchError(() => of([]))
      );
  }

  public getCapacityData({ regionId, year, scenarioId }: IRequestsParams): Observable<CapacityYearDto> {
    return this.http
      .get<{ result: ICapacityYearDto }>(
        `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/capacity`,
        {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("year", String(year)).set("scenarioId", scenarioId)
        }
      )
      .pipe(
        map(({ result }) => new CapacityYearDto(result)),
        catchError(() => of({} as CapacityYearDto))
      );
  }

  public getCapacityAllYearsData({
    regionId,
    scenarioId,
    timelineRange: [from, to]
  }: IRequestsParams): Observable<Array<ICapacityPerYearDto>> {
    const uri = `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/capacity`;

    return this.http
      .get<{ result: Array<ICapacityPerYearDto> }>(uri, {
        params: new HttpParams().set("scenarioId", scenarioId).set("timeRange", `${from},${to}`)
      })
      .pipe(
        map(({ result }) => result.map((item) => new CapacityPerYearDto(item))),
        catchError(() => of([]))
      );
  }

  public getRegionsEMobilityAllYearsData({
    regionId,
    scenarioId,
    timelineRange: [from, to]
  }: IRequestsParams): Observable<Array<RegionsEMobilityDto>> {
    const uri = `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/e-mobility`;

    return this.http
      .get<{ result: Array<IRegionsEMobilityDto> }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: new HttpParams().set("scenarioId", scenarioId)
      })
      .pipe(
        map(({ result }) => result.filter(({ year }) => year >= from && year <= to)),
        map((result) => result.map((item) => new RegionsEMobilityDto(item))),
        catchError(() => of([]))
      );
  }

  public getCumulatedInvestAllYearsData({
    regionId,
    scenarioId
  }: IRequestsParams): Observable<Array<CumulatedInvestDto>> {
    const uri = `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/cumulated-invest`;

    return this.http
      .get<{ result: Array<ICumulatedInvestResponse> }>(uri, {
        params: new HttpParams().set("scenarioId", scenarioId)
      })
      .pipe(
        map((response) => response.result.map((item) => new CumulatedInvestDto(item))),
        catchError(() => of([]))
      );
  }

  public getElectricityConnectionGroupsData(
    params: IRequestsParams
  ): Observable<Array<ElectricityConnectionGroupsDto>> {
    return this.getConnectionsGroupData<IElectricityConnectionGroupsDto>(params, "electricity").pipe(
      map((result) => result.map((item) => new ElectricityConnectionGroupsDto(item)))
    );
  }

  public getGasConnectionGroupsData(params: IRequestsParams): Observable<Array<GasConnectionGroupsDto>> {
    return this.getConnectionsGroupData<IGasConnectionGroupsDto>(params, "gas").pipe(
      map((result) => result.map((item) => new GasConnectionGroupsDto(item)))
    );
  }

  public getEMobilityConnectionGroupsData(params: IRequestsParams): Observable<Array<EMobilityConnectionGroupsDto>> {
    return this.getConnectionsGroupData<IEMobilityConnectionGroupsDto>(params, "e-mobility").pipe(
      map((result) => result.map((item) => new EMobilityConnectionGroupsDto(item)))
    );
  }

  public getPhotovoltaicsConnectionGroupsData(
    params: IRequestsParams
  ): Observable<Array<PhotovoltaicsConnectionGroupsDto>> {
    return this.getConnectionsGroupData<IPhotovoltaicsConnectionGroupsDto>(params, "photovoltaics").pipe(
      map((result) => result.map((item) => new PhotovoltaicsConnectionGroupsDto(item)))
    );
  }

  public getHeatpumpConnectionGroupsData(params: IRequestsParams): Observable<Array<HeatpumpConnectionGroupsDto>> {
    return this.getConnectionsGroupData<IHeatpumpConnectionGroupsDto>(params, "heatpump").pipe(
      map((result) => result.map((item) => new HeatpumpConnectionGroupsDto(item)))
    );
  }

  public getElectricityStationData(params: IRequestsParams): Observable<ElectricityStationDto> {
    return this.getStationData<IElectricityStationDto>(params, "electricity").pipe(
      map((result) => new ElectricityStationDto(result))
    );
  }

  public getGasStationData(params: IRequestsParams): Observable<GasStationDto> {
    return this.getStationData<IGasStationDto>(params, "gas").pipe(map((result) => new GasStationDto(result)));
  }

  public getStationWorkloadData({
    stationId,
    scenarioId,
    timelineRange: [from, to]
  }: IRequestsParams): Observable<Array<StationWorkloadDto>> {
    const uri = `${AppConfig.connection.energy.publicSectorApi}/stations/${stationId}/workload`;

    return this.http
      .get<{ result: Array<StationWorkloadDto> }>(uri, {
        params: new HttpParams({ fromObject: { scenarioId } })
      })
      .pipe(
        map(({ result }) => result.filter(({ year }) => year >= from && year <= to)),
        map((result) => result.map((workload) => new StationWorkloadDto(workload)))
      );
  }

  public getHydrogenForecast({ regionId }: IRequestsParams): Observable<HydrogenForecastDto> {
    return this.http
      .get<{ result: HydrogenForecastDto }>(
        `${AppConfig.connection.energy.publicSectorApi}/regions/${regionId}/hydrogen-forecast`
      )
      .pipe(
        map(({ result }) => result),
        catchError(() => of(null))
      );
  }

  private getConnectionsGroupData<T>(
    { connectionGroupIds, year, scenarioId }: IRequestsParams,
    type: string
  ): Observable<Array<T>> {
    const requests = connectionGroupIds.map((id) =>
      this.http
        .get<{ result: T }>(`${AppConfig.connection.energy.publicSectorApi}/connection-groups/${id}/${type}`, {
          params: new HttpParams().set("year", String(year)).set("scenarioId", scenarioId)
        })
        .pipe(map(({ result }) => result))
    );

    return forkJoin(requests);
  }

  private getStationData<T>({ stationId, year, scenarioId }: IRequestsParams, type: string): Observable<T> {
    const uri = `${AppConfig.connection.energy.publicSectorApi}/stations/${stationId}/${type}`;

    return this.http
      .get<{ result: T }>(uri, {
        params: new HttpParams().set("year", String(year)).set("scenarioId", scenarioId)
      })
      .pipe(map(({ result }) => result));
  }
}
