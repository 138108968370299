/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { map, skip, switchMap, takeUntil } from "rxjs/operators";
import { SLOT } from "src/app/common/enums/slot";
import { Division } from "src/app/core/interfaces/division";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectUserRegions } from "src/app/ngrx/selectors/app.selectors";
import {
  INotification,
  INotificationPatch,
  INotificationSelect
} from "../../../../../../interfaces/dialogs/notification-settings";
import { NotificationSettingsService } from "../../../services/notification-settings.service";
import { Tab } from "../../notification-settings/notification-settings.component";
import {
  INotificationFormModel,
  INotificationSets,
  NotificationSettingsEditComponent
} from "../notification-settings-edit.component";
interface IIndeterminate<T> {
  startEnd?: T;
  update?: T;
  intermediate?: T;
  verifyPhone?: T;
  operationNews?: T;
  serviceNews?: T;
  criticalNews?: T;
  dataStorage?: T;
}

@Component({
  selector: "app-notification-settings-wrapper",
  templateUrl: "./notification-settings-wrapper.component.html",
  styleUrls: ["./notification-settings-wrapper.component.scss"]
})
export class NotificationSettingsWrapperComponent implements OnDestroy, OnInit {
  @ViewChildren(NotificationSettingsEditComponent) forms: QueryList<NotificationSettingsEditComponent>;

  public activeDivision$ = new BehaviorSubject("electricity");
  public divisions$: Observable<Array<{ label: string; value: Division }>>;
  public vm$: Observable<
    Array<{
      required: boolean;
      initialValue: INotificationFormModel;
      selectedRegionIds: Array<string>;
      initialIndeterminateEmail: IIndeterminate<boolean>;
      initialIndeterminateSms: IIndeterminate<boolean>;
      division?: Division;
    }>
  >;
  public initialIndeterminateSms: IIndeterminate<boolean>;
  public initialIndeterminateEmail: IIndeterminate<boolean>;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close = new EventEmitter<void>();

  @Input() public set selectedConfig(value: INotificationSelect) {
    if (!value?.selected?.length) {
      return;
    }

    const oneRegionSelected = value?.selected?.length === 1;
    this.selectedRegionId = value?.selected;
    this.divisions$ = this.store.pipe(
      select(selectUserRegions),
      map((entities) => entities.filter((item) => this.selectedRegionId.includes(item.entityId))),
      map((entities) =>
        entities.map((entity) =>
          entity.moduleComposition.find((moduleComposition) => moduleComposition.id === SLOT.OUTAGE_MONITORING)
        )
      ),
      map((modulesSettings) => {
        const divisions = [];
        const gas = modulesSettings.some((modulesSetting) => modulesSetting.config.division.gas);
        const electricity = modulesSettings.some((modulesSetting) => modulesSetting.config.division.electricity);
        if (electricity) {
          divisions.push({ value: "electricity", label: "APP.ELECTRICITY" });
        }

        if (gas) {
          divisions.push({ value: "gas", label: "APP.GAS" });
        }

        return divisions;
      })
    );

    this.vm$ = this.notificationSettingsService.getData(value.selected).pipe(
      switchMap((selectedItems) => {
        if (this.notificationSettingsService.getTabNameValue() === SLOT.OUTAGE_MONITORING) {
          return this.divisions$.pipe(
            map((divisions) =>
              divisions.flatMap((division) => {
                const verifyPhone = division.value === "gas" ? "gasVerifyPhone" : "electricityVerifyPhone";
                const phone = division.value === "gas" ? "gasPhone" : "electricityPhone";
                const configs = selectedItems.map((item) => ({
                  ...item,
                  phone: item[phone],
                  verifyPhone: item[verifyPhone],
                  ...item[division.value]
                }));
                return this.mapItems(configs, oneRegionSelected, divisions.length === 1, division.value);
              })
            )
          );
        } else {
          return of([this.mapItems(selectedItems, oneRegionSelected, true)]);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @Input() public forMultiRegion: boolean;

  public get invalid(): boolean {
    return this.forms?.toArray().some((form) => form.form.invalid);
  }

  public tabName$: Observable<Tab>;
  private destroy$ = new Subject<void>();
  private selectedRegionId: Array<string>;

  constructor(
    private notificationSettingsService: NotificationSettingsService,
    private store: Store<RootState>,
    private readonly dialogsService: DialogsService
  ) {}

  public onClose(): void {
    this.close.emit();
  }

  public ngOnInit(): void {
    this.tabName$ = this.notificationSettingsService.getTabName();
    this.notificationSettingsService
      .getTabName()
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.onClose();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public changeDivision(event: string) {
    this.activeDivision$.next(event);
  }

  public onSubmit() {
    const divisions = [];
    const data = this.forms.toArray().reduce((acc, form) => {
      if (form.division) {
        divisions.push(form.division);
        const { email, phone, regionIds, verifyPhone, notifyByEmail, notifyBySMS } = this.mapFormValue(form.form);
        return { ...acc, regionIds, [form.division]: { email, phone, notifyByEmail, verifyPhone, notifyBySMS } };
      } else {
        return { ...acc, ...this.mapFormValue(form.form) };
      }
    }, {} as INotificationPatch);

    this.notificationSettingsService.patchNotificationSettings(data, divisions);
  }

  public openPolicy(): void {
    this.dialogsService.openPolicyDialog();
  }

  private mapFormValue(form: FormGroup) {
    const { emailNotificationFormControl, phoneNotificationFormControl, allowNotifyByEmail, allowNotifyBySMS } =
      form.getRawValue();

    const { email, ...notifyByEmail } = emailNotificationFormControl;
    let data: INotificationPatch = { regionIds: this.selectedRegionId, email };
    if (allowNotifyByEmail) {
      data = {
        ...data,
        notifyByEmail
      };
    }

    if (phoneNotificationFormControl && allowNotifyBySMS) {
      const { phone, verifyPhone, ...notifyBySMS } = phoneNotificationFormControl;
      const _notifyBySMS =
        allowNotifyBySMS === false
          ? {
              startEnd: false,
              intermediate: false,
              update: false
            }
          : notifyBySMS;
      return { ...data, phone, verifyPhone, notifyBySMS: _notifyBySMS };
    }

    return data;
  }

  private getConfigSets(items: Array<INotification>): INotificationSets {
    return items.reduce(
      (acc, { email, phone, notifyByEmail, notifyBySMS, verifyPhone }) => {
        acc.email = email;
        acc.phone.add(phone);
        acc.verifyPhone.add(Boolean(verifyPhone));
        acc.notifyByEmail.startEnd.add(Boolean(notifyByEmail?.start));
        acc.notifyByEmail.intermediate.add(Boolean(notifyByEmail?.intermediate));
        acc.notifyByEmail.update.add(Boolean(notifyByEmail?.update));
        acc.notifyByEmail.operationNews.add(Boolean(notifyByEmail?.operationNews));
        acc.notifyByEmail.serviceNews.add(Boolean(notifyByEmail?.serviceNews));
        acc.notifyByEmail.criticalNews.add(Boolean(notifyByEmail?.criticalNews));
        acc.notifyByEmail.dataStorage.add(Boolean(notifyByEmail?.dataStorage));
        acc.notifyBySMS.startEnd.add(Boolean(notifyBySMS?.start));
        acc.notifyBySMS.intermediate.add(Boolean(notifyBySMS?.intermediate));
        acc.notifyBySMS.update.add(Boolean(notifyBySMS?.update));
        return acc;
      },
      {
        email: null,
        phone: new Set<string>(),
        verifyPhone: new Set<boolean>(),
        notifyByEmail: {
          startEnd: new Set<boolean>(),
          intermediate: new Set<boolean>(),
          update: new Set<boolean>(),
          operationNews: new Set<boolean>(),
          serviceNews: new Set<boolean>(),
          criticalNews: new Set<boolean>(),
          dataStorage: new Set<boolean>()
        },
        notifyBySMS: {
          startEnd: new Set<boolean>(),
          intermediate: new Set<boolean>(),
          update: new Set<boolean>()
        }
      }
    );
  }

  private getConfig(
    { notifyByEmail, notifyBySMS, verifyPhone, email, phone }: INotificationSets,
    oneRegionSelected: boolean
  ): INotificationFormModel {
    const phoneArr = [...phone].filter(Boolean);
    const config = {
      email,
      phone: phoneArr.length === 1 ? phoneArr[0] : undefined,
      verifyPhone: !verifyPhone.has(false),
      notifyByEmail: {
        startEnd: !notifyByEmail.startEnd.has(false),
        intermediate: !notifyByEmail.intermediate.has(false),
        update: !notifyByEmail.update.has(false),
        operationNews: !notifyByEmail.operationNews.has(false),
        serviceNews: !notifyByEmail.serviceNews.has(false),
        criticalNews: !notifyByEmail.criticalNews.has(false),
        dataStorage: !notifyByEmail.dataStorage.has(false)
      },
      notifyBySMS: {
        startEnd: !notifyBySMS.startEnd.has(false),
        intermediate: !notifyBySMS.intermediate.has(false),
        update: !notifyBySMS.update.has(false)
      }
    };

    return {
      ...config,
      allowNotifyByEmail:
        oneRegionSelected ||
        config.notifyByEmail.startEnd ||
        this.notificationSettingsService.getTabNameValue() !== SLOT.OUTAGE_MONITORING,
      allowNotifyBySMS: oneRegionSelected || config.verifyPhone
    };
  }

  private getIndeterminateEmail({
    startEnd,
    intermediate,
    update,
    operationNews,
    serviceNews,
    criticalNews,
    dataStorage
  }: IIndeterminate<Set<boolean>>): IIndeterminate<boolean> {
    return {
      startEnd: startEnd.size > 1,
      intermediate: intermediate.size > 1,
      update: update.size > 1,
      operationNews: operationNews.size > 1,
      serviceNews: serviceNews.size > 1,
      criticalNews: criticalNews.size > 1,
      dataStorage: dataStorage.size > 1
    };
  }

  private getIndeterminateSms({
    startEnd,
    update,
    intermediate,
    verifyPhone
  }: IIndeterminate<Set<boolean>>): IIndeterminate<boolean> {
    return {
      verifyPhone: verifyPhone.size > 1,
      startEnd: startEnd.size > 1,
      intermediate: intermediate.size > 1,
      update: update.size > 1
    };
  }

  private mapItems(
    selectedItems: INotification[],
    oneRegionSelected: boolean,
    required?: boolean,
    division?: Division
  ) {
    const configSets = this.getConfigSets(selectedItems);
    const initialValue = this.getConfig(configSets, oneRegionSelected);
    const selectedRegionIds = selectedItems.map((item) => item.regionId);
    const initialIndeterminateEmail = this.getIndeterminateEmail(configSets.notifyByEmail);
    const initialIndeterminateSms = this.getIndeterminateSms({
      ...configSets.notifyBySMS,
      verifyPhone: configSets.verifyPhone
    });
    return {
      required,
      division,
      initialValue,
      selectedRegionIds,
      initialIndeterminateEmail,
      initialIndeterminateSms
    };
  }
}
