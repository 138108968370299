import { LocaleCode } from "../../enums/locale-code";

export interface IRegionContactPerson {
  address: string;
  company: string;
  familyName: string;
  givenName: string;
  mail: string;
  phoneNumber: string;
  role: string;
}

export interface IRegionConfig {
  hasElectricity: boolean;
  hasGas: boolean;
}

export interface IRegionTimeline {
  from: string;
  to: string;
  selected: string;
}

export interface IUserRegion {
  id: string;
  ags: string;
  scenarioID: string;
  name: string;
  level: string;
  latitude: string;
  longitude: string;
  config: IRegionConfig;
}

export interface IUserAppConfig {
  gaProperty: string;
  logo: {
    footer: string;
    main: string;
  };
}

export type Tenant = "netzebw";

export interface IKeycloakUserInfoDto {
  customerSupportId: string;
  email: string;
  familyName: string;
  givenName: string;
  language: LocaleCode;
  name: string;
  preferredUsername: string;
  tenant: Tenant;
  userId: string;
  appConfig: IUserAppConfig;
  regions: Array<IUserRegion>;
  userScope?: string;
  territoryName?: string;
}

// TODO: replace with new structure
export class KeycloakUserInfoDto implements IKeycloakUserInfoDto {
  public customerSupportId: string;
  public email: string;
  public familyName: string;
  public givenName: string;
  public language: LocaleCode;
  public name: string;
  public preferredUsername: string;
  public tenant: Tenant;
  public userId: string;
  public appConfig: IUserAppConfig;
  public regions: Array<IUserRegion>;

  constructor(userInfo: any) {
    this.customerSupportId = userInfo.customerSupportId;
    this.email = userInfo.email;
    this.familyName = userInfo.family_name;
    this.givenName = userInfo.given_name;
    this.language = userInfo.language;
    this.name = userInfo.name;
    this.preferredUsername = userInfo.preferred_username;
    this.tenant = userInfo.tenant;
    this.userId = userInfo.userId;
    this.appConfig = userInfo.app;
    this.regions = userInfo.regions;
  }
}
