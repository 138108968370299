import { Action, createReducer, on } from "@ngrx/store";
import { UsersSettingsUsersStorageItem } from "src/app/core/interfaces/users-settings";
import * as UsersSettingsActions from "../actions/users-settings.actions";

export interface UsersSettingsState {
  usersStorage: Array<UsersSettingsUsersStorageItem>;
}

const initialState: UsersSettingsState = {
  usersStorage: []
};

const usersSettingsReducer = createReducer(
  initialState,
  on(UsersSettingsActions.setUsersSettingsUsersStorage, (state, { result }) => ({ ...state, usersStorage: result }))
);

export function reducer(state: UsersSettingsState | undefined, action: Action): UsersSettingsState {
  return usersSettingsReducer(state, action);
}
