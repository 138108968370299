import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { DropzoneModule } from "../dropzone/dropzone.module";
import { DropAreaFileListComponent } from "./components/file-list/file-list.component";
import { FileListInputs } from "./file-list-inputs";

export interface DropFileAreaItem {
  file: File;
  loading?: boolean;
  progress?: number;
  error?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-drop-file-area",
  templateUrl: "./drop-file-area.component.html",
  styleUrls: ["./drop-file-area.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, TranslateModule, DropzoneModule, DropAreaFileListComponent]
})
export class DropFileAreaComponent extends FileListInputs {
  @Output() public readonly dropped = new EventEmitter<Array<DropFileAreaItem>>();

  @Input({ required: true }) public accept!: string;
  @Input({ required: true }) public acceptLabel!: string;
  @Input() public maxSizeLimit: number = 4;
  @Input() public maxFilesLimit: number = 1;
  @Input() public maxFilenameLength: number = 150;
  @Input() public disabled: boolean = false;

  private readonly dialogsService = inject(DialogsService);

  public onDrop(files: FileList): void {
    this.dropped.emit(Array.from(files).map.call(files, (file) => ({ file })));
  }

  public openPolicyDialog(): void {
    this.dialogsService.openPolicyDialog("dataProtection");
  }
}
