import { NgSwitch, NgSwitchCase } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnDestroy, inject } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { LetDirective } from "@ngrx/component";
import { Observable, Subject, filter, takeUntil } from "rxjs";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import { TabNavBarModule } from "src/app/common/components/tab-nav-bar/tab-nav-bar.module";
import { AdminModuleInvitationModuleDto } from "src/app/common/dto/admin-module/invitation";
import { AdminModuleStatus } from "src/app/common/dto/admin-module/module-status";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { LeaveActionDialogDialogComponent } from "../../components/leave-action-dialog/leave-action-dialog.component";
import { ContractManagementComponent } from "../components/contract-management/contract-management.component";
import { CreateUserDialogComponent } from "../components/create-user-dialog/create-user-dialog.component";
import { TerminateContractAgreementDialogComponent } from "../components/terminate-contract-agreement-dialog/terminate-contract-agreement-dialog.component";
import { UserManagementComponent } from "../components/user-management/user-management.component";
import {
  ProductManagementDialogComponentStore,
  ProductManagementDialogState
} from "../product-management-dialog.component-store";
import { TAB_NAV_ITEMS } from "../product-management-dialog.constants";
import { UserBodyDto } from "../product-management-dialog.interfaces";

@Component({
  selector: "tpm-product-management-dialog",
  templateUrl: "product-management-dialog.component.html",
  styleUrls: ["product-management-dialog.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LetDirective,
    NgSwitch,
    NgSwitchCase,
    DialogFrameModule,
    TabNavBarModule,
    UserManagementComponent,
    ContractManagementComponent
  ],
  providers: [ProductManagementDialogComponentStore]
})
export class ProductManagementDialogComponent implements OnDestroy {
  public readonly dialogRef = inject(MatLegacyDialogRef<ProductManagementDialogComponent>);

  public readonly tabNavItems = TAB_NAV_ITEMS;
  public readonly vm$: Observable<any>;

  private readonly componentStore = inject(ProductManagementDialogComponentStore);
  private readonly dialogsService = inject(DialogsService);

  private readonly destroy$ = new Subject<void>();

  constructor() {
    this.vm$ = this.componentStore.vm$;

    this.componentStore.fetchContractList();
    this.componentStore.fetchInvitationList();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onTabChange(tab: ProductManagementDialogState["tab"]): void {
    this.componentStore.setTab(tab);
  }

  public onAddUser(): void {
    this.dialogsService
      .open(CreateUserDialogComponent, {
        panelClass: "create-user-dialog",
        width: "838px",
        autoFocus: false
      })
      .afterClosed()
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe((users: Array<UserBodyDto>) => {
        this.componentStore.addUsers(users);
      });
  }

  public onUpdateInvitation(
    invitation: AdminModuleInvitationModuleDto & { invitationId: string; status: AdminModuleStatus }
  ): void {
    this.componentStore.updateInvitation(invitation);
  }

  public onSubmit(dialogRef?: MatLegacyDialogRef<ProductManagementDialogComponent>): void {
    this.componentStore.updateInvitationsStatuses(dialogRef);
  }

  public onDeleteUser({ id }: { id: string }): void {
    this.componentStore.deleteUser({ ids: [id] });
  }

  public onTerminateContract(event: any): void {
    this.dialogsService
      .open(TerminateContractAgreementDialogComponent, {
        panelClass: "terminate-contract-agreement-dialog",
        width: "480px"
      })
      .afterClosed()
      .pipe(filter(Boolean), takeUntil(this.destroy$))
      .subscribe(() => {
        this.componentStore.terminateContract({ name: event.name });
      });
  }

  public onDownloadInvoice(fileName: string): void {
    this.componentStore.downloadInvoice(fileName);
  }

  public onClose(): void {
    this.componentStore.isUpdated$.pipe(takeUntil(this.destroy$)).subscribe((isUpdated) => {
      if (isUpdated) {
        this.leaveDialog()
          .pipe()
          .subscribe((action) => {
            if (action === true) {
              this.onSubmit(this.dialogRef);
            } else if (action === false) {
              this.dialogRef.close();
            }
          });
      } else {
        this.dialogRef.close();
      }
    });
  }

  private leaveDialog(): Observable<boolean> {
    return this.dialogsService
      .open(LeaveActionDialogDialogComponent, {
        panelClass: "leave-actions-dialog",
        width: "515px"
      })
      .afterClosed();
  }
}
