<div class="notification-settings__title headline-4">
  <span>{{ "NOTIFICATION_SERVICE.MUNICIPALITIES_OVERVIEW" | translate }}&nbsp;</span>
  <span *ngIf="selectedConfig?.selected.length as count">{{
    "NOTIFICATION_SERVICE.COINT_SELECTED" | translate : { count: count, total: total$ | async }
  }}</span>
</div>
<div class="notification-settings__filters">
  <button
    class="notification-settings__edit-btn"
    mat-flat-button
    color="accent"
    [disabled]="!selectedConfig?.selected.length"
    (click)="showEditForm = true"
  >
    <mat-icon fontIcon="ec_check"></mat-icon>
    <span>{{ "NOTIFICATION_SERVICE.MUNICIPALITIES_SETTINGS" | translate }}</span>
  </button>
  <ngmy-table-filter filterLabel="Kommunenname" (changed)="nameFilterChanged($event)"></ngmy-table-filter>
  <ngmy-table-filter filterLabel="Gemeindeschlüssel" (changed)="agsFilterChanged($event)"></ngmy-table-filter>
</div>
<div class="notification-settings__table-wrapper">
  <app-notification-settings-table
    class="notification-settings__table"
    [ngClass]="{ 'edit-disabled': showEditForm, 'fill-space': !showEditForm }"
    [disableSort]="false"
    [selectDisabled]="false"
    [disableCheckbox]="showEditForm"
    [pagingConfig]="{ pageSize: 20, type: 'full', pageSizeOptions: [20, 50, 100, 1000] }"
    [disableFilter]="true"
    [columnsData]="columns"
    [entrySelector]="entrySelector"
    [sortActive]="sortActive"
    [sortDirection]="sortDirection"
    (tableEvent)="onCellClick($event)"
  ></app-notification-settings-table>
  <app-notification-settings-wrapper
    class="notification-settings__edit"
    *ngIf="showEditForm"
    [selectedConfig]="selectedConfig"
    [forMultiRegion]="true"
    (close)="closeEdit()"
  ></app-notification-settings-wrapper>
</div>
<div class="notification-settings__table-footer" *ngIf="legendSettings$ | async as legendSettings">
  <ng-container *ngIf="legendSettings">
    <ng-container *ngFor="let squares of legendSettings.badges">
      <div class="squares">
        <app-square
          *ngFor="let square of squares | keyvalue"
          [data]="square.value"
          [showDescription]="true"
        ></app-square>
      </div>
    </ng-container>
  </ng-container>
</div>
