import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Asset } from "contentful";
import { Observable, Subject, of } from "rxjs";
import { catchError, startWith, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { MARKETING_CRM_GUID_MAP } from "src/app/common/components/dialogs/lead/constants/marketing-crm-uid-map";
import { PLUGIN } from "src/app/common/enums/plugins";
import { crmFormMarketingTeaserProperty, crmInternalFormId } from "src/app/core/constants/crm-form-marketing-structure";
import { MODULES_CRM_IDS_MAP } from "src/app/core/constants/modules-crm-ids-map";
import { SalesInfoEntry } from "src/app/core/interfaces/contentful";
import { DynamicSnackBar } from "src/app/core/material/snack-bar/snack-bar";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { FilesService } from "src/app/core/services/files.service";
import { MarketingCrmService } from "src/app/core/services/marketing-crm.service";
import { takeLatestFrom } from "src/app/core/utils/ngrx";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectEntity, selectRegionServiceContactPerson, selectUserInfo } from "src/app/ngrx/selectors/app.selectors";
import { LeadService } from "./lead.service";

interface ResolvedSalesInfoEntry {
  moduleName: string;
  headline1: string;
  text1: string;
  headline2: string;
  text2: string;
  action?: string;
  media?: Asset;
}
@Component({
  selector: "app-lead-dialog",
  templateUrl: "./lead.component.html",
  styleUrls: ["./lead.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeadComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("teaserForm", { static: false, read: ElementRef }) public teaserForm: ElementRef;
  public translationParams: { moduleName: string };
  public leadForm: FormGroup;
  public communicationTypes = [
    {
      id: "phone",
      key: "LEAD.USE_PHONE"
    },
    {
      id: "email",
      key: "LEAD.USE_EMAIL"
    }
  ];

  public contactPerson$ = this.store.pipe(select(selectRegionServiceContactPerson));
  public contentfulInfo$ = new Observable<ResolvedSalesInfoEntry>();

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    public readonly data: { slot: PLUGIN; salesInfo: SalesInfoEntry },
    public readonly dialogRef: MatLegacyDialogRef<LeadComponent>,
    private readonly fb: FormBuilder,
    private readonly leadService: LeadService,
    private readonly snackbar: DynamicSnackBar,
    private readonly store: Store<RootState>,
    private readonly dialogsService: DialogsService,
    private readonly filesService: FilesService,
    private readonly translate: TranslateService,
    private readonly analytics: AnalyticsService,
    private readonly marketingService: MarketingCrmService
  ) {}

  public get communicationType() {
    return this.leadForm.get("communicationType");
  }

  private get phone() {
    return this.leadForm.get("phoneNumber");
  }

  private get email() {
    return this.leadForm.get("email");
  }

  private get itsMyPhone() {
    return this.leadForm.get("itsMyPhone");
  }

  public ngOnInit(): void {
    this.translationParams = { moduleName: this.translate.instant(`LAUNCHPAD.PLUGINS.${this.data.slot}.name`) };

    const resolveContentfulType = (entry: SalesInfoEntry): ResolvedSalesInfoEntry => {
      const module = entry.fields as any;
      return {
        ...module,
        moduleName: `LAUNCHPAD.PLUGINS.${this.data.slot}.name`,
        text1: documentToHtmlString(module.text1),
        text2: documentToHtmlString(module.text2),
        action: module.action?.fields?.url || null
      };
    };

    this.contentfulInfo$ = of(resolveContentfulType(this.data.salesInfo));

    this.leadForm = this.fb.group({
      moduleId: MODULES_CRM_IDS_MAP[this.data.slot],
      email: [{ value: takeLatestFrom(this.store, selectUserInfo)?.email, disabled: true }],
      phoneNumber: null,
      itsMyPhone: false,
      text: null,
      communicationType: this.communicationTypes[0].id
    });

    this.communicationType.valueChanges.pipe(startWith(this.communicationType.value)).subscribe((value: string) => {
      if (value === "phone") {
        this.email.clearValidators();
        this.itsMyPhone.addValidators([Validators.requiredTrue]);
        this.phone.addValidators([Validators.required]);
      } else {
        this.email.addValidators([Validators.required]);
        this.itsMyPhone.clearValidators();
        this.phone.clearValidators();
      }

      this.email.updateValueAndValidity();
      this.itsMyPhone.updateValueAndValidity();
      this.phone.updateValueAndValidity();
    });
  }

  public ngAfterViewInit() {
    this.analytics.scanForForms();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public submit(): void {
    this.store
      .pipe(
        select(selectEntity),
        withLatestFrom(this.store.pipe(select(selectUserInfo)), this.contactPerson$),
        take(1),
        tap(([{ entity }, userInfo]) => {
          this.marketingService.submitFormData(crmInternalFormId.marketingTeaser, [
            { internalId: crmFormMarketingTeaserProperty.email, value: this.leadForm.getRawValue().email },
            { internalId: crmFormMarketingTeaserProperty.phoneNumber, value: this.leadForm.getRawValue().phoneNumber },
            { internalId: crmFormMarketingTeaserProperty.ags, value: entity.ags },
            { internalId: crmFormMarketingTeaserProperty.description, value: this.leadForm.getRawValue().text },
            { internalId: crmFormMarketingTeaserProperty.firstName, value: userInfo.given_name },
            { internalId: crmFormMarketingTeaserProperty.lastName, value: userInfo.family_name },
            {
              internalId: crmFormMarketingTeaserProperty.communicationType,
              value: this.leadForm.getRawValue().communicationType
            },
            { internalId: crmFormMarketingTeaserProperty.guid, value: MARKETING_CRM_GUID_MAP[this.data.slot] }
          ]);
        }),
        switchMap(([{ entity }, userInfo, contactPerson]) =>
          this.leadService.submit({
            ...this.leadForm.getRawValue(),
            ags: entity.ags,
            regionName: entity.name,
            contactEmail: contactPerson.email,
            familyName: userInfo.family_name,
            givenName: userInfo.given_name,
            moduleName: this.translate.instant(`LAUNCHPAD.PLUGINS.${this.data.slot}.name`)
          })
        ),
        tap(() => {
          this.snackbar.success("Success", { duration: 2000 });
          this.analytics.trackTeaserFormSubmit(this.data.slot);
        }),
        catchError(() => of(this.snackbar.error("Failed", { duration: 2000 })))
      )
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public downloadFlyer(link: any) {
    this.filesService.getPdf(link);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public openDialog() {
    this.dialogsService.openPolicyDialog("dataProtection");
  }

  public trackEmail(): void {
    this.analytics.trackTeaserEmailClick(this.data.slot);
  }
}
