<tpm-card class="contentful-card" [variant]="'elevation-basic-20'">
  <div class="contentful-card__container" [class.mobile]="isMobileView" (click)="cardClick.emit()">
    <div class="contentful-card__teaser-image">
      <img *ngIf="data.teaserImage" [src]="data.teaserImage" />
    </div>
    <div class="contentful-card__content">
      <div *ngIf="data.date" class="contentful-card__content__header">
        {{ data.date | date : "dd.MM.yyyy" }}
        <tpm-colored-badge
          *ngIf="data.isNew"
          class="new-badge"
          text="NEU"
          [backgroundColor]="'--ene-color-emerald-A500'"
        ></tpm-colored-badge>
        <mat-icon *ngIf="data.checkCritical" class="critical-badge" fontIcon="ec_warning"></mat-icon>
      </div>
      <div class="contentful-card__content-headline headline-2 last-line-3">{{ data.headline }}</div>
      <div class="contentful-card__content-infotext body-1 last-line-4" [innerHTML]="data.teaser"></div>
    </div>
    <div *ngIf="!isMobileView" class="contentful-card__actions">
      <button ene-filled-button size="large">{{ buttonLabel }}</button>
    </div>
  </div>
</tpm-card>
