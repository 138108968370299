import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { acceptJsonWithoutCache } from "../../common/constants/common";
import {
  GasCockpitKpiDto,
  GasNetworkLengthDto,
  IGasCockpitKpiDto,
  IGasNetworkLengthDto
} from "../../common/dto/gas-cockpit";
import { AppConfig } from "../helpers/app.config";

interface RequestParams {
  gasNetworkId: string;
  year?: number;
}

@Injectable({ providedIn: "root" })
export class GasCockpitService {
  constructor(private readonly http: HttpClient) {}

  public getGasNetworksLength({ gasNetworkId }: RequestParams): Observable<GasNetworkLengthDto> {
    return this.http
      .get<{ result: IGasNetworkLengthDto }>(
        `${AppConfig.connection.infrastructure.gridApi}/networks/${gasNetworkId}/segments/length`,
        {
          headers: new HttpHeaders(acceptJsonWithoutCache)
        }
      )
      .pipe(
        map((response) => new GasNetworkLengthDto(response.result)),
        catchError(() => of({} as GasNetworkLengthDto))
      );
  }

  public getGasCockpitKpi({ gasNetworkId, year }: RequestParams): Observable<GasCockpitKpiDto> {
    return this.http
      .get<{ result: IGasCockpitKpiDto }>(`${AppConfig.connection.infrastructure.gridApi}/networks/${gasNetworkId}`, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: new HttpParams().set("year", String(year))
      })
      .pipe(
        map((response) => new GasCockpitKpiDto(response.result)),
        catchError(() => of({} as GasCockpitKpiDto))
      );
  }
}
