import { IClientConfigDto } from "../../dto/client-config";
import { UserInfoDto } from "../../dto/user-info";
import { LocaleCode } from "../../enums/locale-code";

interface IHeaderConfigProps {
  clientConfig: IClientConfigDto;
  userInfo: UserInfoDto;
  regionName: string;
}

export class HeaderConfig {
  public allowedLanguages: Array<string>;
  public language: LocaleCode;
  public regionName: string;
  public email: string;

  private _logo: string;
  private _title: string;

  constructor({ clientConfig, userInfo, regionName }: IHeaderConfigProps) {
    this.allowedLanguages = clientConfig?.allowedLanguages;
    this.language = clientConfig?.language as LocaleCode;
    this.logo = userInfo?.tenant;
    this.title = userInfo?.tenant;
    this.regionName = regionName;
    this.email = userInfo?.email;
  }

  public set logo(value: string) {
    this._logo = value;
  }
  public get logo(): string {
    return `/assets/images/logos/${this._logo}.svg`;
  }

  public set title(value: string) {
    this._title = value;
  }
  public get title(): string {
    return this._title === "enersis" ? "EnergyCity" : "KommunalPlattform";
  }
}
