import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Pure } from "../../decorators/pure";
import { TabNavBarItem } from "./tab-nav-bar.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-tab-nav-bar",
  templateUrl: "./tab-nav-bar.component.html",
  styleUrls: ["./tab-nav-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: "tpm-tab-nav-bar"
  }
})
export class TabNavBarComponent implements AfterViewInit {
  @Output() public readonly initialized = new EventEmitter<string>();
  @Output() public readonly tabChange = new EventEmitter<string>();
  @Output() public readonly tabChildChange = new EventEmitter<string>();

  @Input() public items: Array<TabNavBarItem>;
  @Input() public childActive: string;

  @Input()
  public set active(_active: string) {
    this._active = _active;
  }
  public _active: string;

  @Pure
  public getChildren(active: string): TabNavBarItem["children"] | undefined {
    return this.items?.find(({ name }) => name === active)?.children;
  }

  public readonly trackByName = (item: TabNavBarItem): string => item.name;

  public ngAfterViewInit(): void {
    if (this._active) {
      this.initialized.emit(this._active);
    }
  }
}
