import { Injectable } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { ComponentStore } from "@ngrx/component-store";
import { tap, withLatestFrom } from "rxjs";
import { InvoiceUploadDialogComponent } from "./invoice-upload-dialog.component";

export interface InvoiceUploadDialogState {
  moduleSubscriptionName: string | undefined;
  email: string | undefined;
  file: File | undefined;
}

@Injectable()
export class InvoiceUploadDialogComponentStore extends ComponentStore<InvoiceUploadDialogState> {
  constructor() {
    super({
      moduleSubscriptionName: undefined,
      email: undefined,
      file: undefined
    });
  }

  public readonly files$ = this.select((state) => (state.file ? [state] : []));

  public readonly addFile = this.updater((state, file: File) => ({ ...state, file }));
  public readonly removeFile = this.updater((state) => ({ ...state, file: undefined }));

  public readonly submit = this.effect<MatLegacyDialogRef<InvoiceUploadDialogComponent>>((params$) =>
    params$.pipe(
      withLatestFrom(this.state$),
      tap(([dialogRef, state]) => {
        dialogRef.close({ file: state.file });
      })
    )
  );
}
