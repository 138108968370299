import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { EneButtonModule } from "@enersis/ui-button";
import { LetDirective } from "@ngrx/component";
import { TranslateModule } from "@ngx-translate/core";
import { Observable, filter } from "rxjs";
import { CardModule } from "src/app/common/components/card/card.module";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import { AdminModuleStatus } from "src/app/common/dto/admin-module/module-status";
import { IN_OUT_ANIMATION } from "src/app/core/animations/in-out-animation";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { TableAction } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { TableModule } from "src/app/slots/three-year-plan/common/components/table/table.module";
import { LeaveActionDialogDialogComponent } from "../components/leave-action-dialog/leave-action-dialog.component";
import { CampusOneAdminViewComponentStore } from "./campus-one-admin-view.component-store";
import { CAMPUS_ONE_ADMIN_VIEW_COLUMN_CONFIGS } from "./campus-one-admin-view.constants";
import { CampusOneAdminSubscription } from "./campus-one-admin-view.interfaces";
import { InvoiceUploadDialogComponent } from "./components/invoice-upload-dialog/invoice-upload-dialog.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-campus-one-admin-view",
  templateUrl: "./campus-one-admin-view.component.html",
  styleUrls: ["./campus-one-admin-view.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CampusOneAdminViewComponentStore],
  imports: [NgIf, LetDirective, TranslateModule, DialogFrameModule, EneButtonModule, CardModule, TableModule],
  animations: [IN_OUT_ANIMATION]
})
export class CampusOneAdminViewComponent {
  public readonly dialogRef = inject(MatLegacyDialogRef<CampusOneAdminViewComponent>);

  public readonly columns = CAMPUS_ONE_ADMIN_VIEW_COLUMN_CONFIGS;
  public readonly vm$: Observable<any>;

  private readonly componentStore = inject(CampusOneAdminViewComponentStore);
  private readonly dialogsService = inject(DialogsService);
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    this.vm$ = this.componentStore.vm$;

    this.componentStore.fetchSubscriptions();
  }

  public onSubmit(dialogRef?: MatLegacyDialogRef<CampusOneAdminViewComponent>): void {
    this.componentStore.updateSubscriptionsStatuses(dialogRef);
  }

  public onTableAction(event: TableAction<CampusOneAdminSubscription> & { selected: AdminModuleStatus }): void {
    switch (event.id) {
      case "status": {
        this.componentStore.updateSubscription({
          subscriptionName: event.row.subscriptionName,
          status: event.selected
        });
        break;
      }
      case "upload": {
        this.uploadInvoice(event.row.subscriptionName, event.row.email);
        break;
      }
      case "download": {
        this.componentStore.downloadInvoice(event.row.files[event.row.files.length - 1]);
        break;
      }
      case "delete": {
        this.componentStore.deleteInvoice(event.row.files[event.row.files.length - 1]);
      }
    }
  }

  public onClose(): void {
    this.componentStore.isUpdated$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((isUpdated) => {
      if (isUpdated) {
        this.leaveDialog()
          .pipe()
          .subscribe((action) => {
            if (action === true) {
              this.onSubmit(this.dialogRef);
            } else if (action === false) {
              this.dialogRef.close();
            }
          });
      } else {
        this.dialogRef.close();
      }
    });
  }

  private leaveDialog(): Observable<boolean> {
    return this.dialogsService
      .open(LeaveActionDialogDialogComponent, {
        panelClass: "leave-actions-dialog",
        width: "515px"
      })
      .afterClosed();
  }

  private uploadInvoice(moduleSubscriptionName: string, email: string): void {
    this.dialogsService
      .open(InvoiceUploadDialogComponent, {
        panelClass: "invoice-upload-dialog",
        width: "930px",
        disableClose: true,
        data: {
          moduleSubscriptionName,
          email
        }
      })
      .afterClosed()
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(({ file }) => {
        this.componentStore.addInvoice({ file, moduleSubscriptionName });
      });
  }
}
