import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { ContentfulService } from "src/app/core/services/data-service/contentful.service";
import { fetchContentfulEntries, setContentfulEntries } from "../actions/contentful.actions";

@Injectable()
export class ContentfulEffects {
  fetchContentfulEntries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContentfulEntries),
      switchMap(({ contentType }) =>
        this.contentfulService
          .getEntries(contentType)
          .pipe(map((result) => setContentfulEntries({ contentType, result })))
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly contentfulService: ContentfulService) {}
}
