import { ModuleWithProviders, NgModule } from "@angular/core";
import { AbsPipe } from "./abs.pipe";
import { NumberSignPipe } from "./number-sign.pipe";
import { TrustHtmlPipe } from "./trust-html.pipe";
import { EneUnitPipe } from "./unit.pipe";

const directives = [TrustHtmlPipe, AbsPipe, NumberSignPipe, EneUnitPipe];

@NgModule({
  declarations: [...directives],
  providers: [...directives],
  exports: [...directives]
})
export class PipesModule {
  public static forRoot(): ModuleWithProviders<PipesModule> {
    return {
      ngModule: PipesModule,
      providers: [...directives]
    };
  }
}
