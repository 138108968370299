import { ObserversModule } from "@angular/cdk/observers";
import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "src/app/common/components/card/card.module";
import { IconModule } from "src/app/common/components/icon/icon.module";
import { LaunchpadTileConfig } from "../../container/launchpad.interfaces";

@Component({
  selector: "tpm-tile",
  templateUrl: "./tile.component.html",
  styleUrls: ["./tile.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, TranslateModule, ObserversModule, CardModule, EneButtonModule, IconModule]
})
export class TileComponent {
  @Output() public readonly tileClicked = new EventEmitter<string>();
  @Output() public readonly leadClicked = new EventEmitter<string>();
  @Output() public readonly productManagementClicked = new EventEmitter<string>();
  @Output() public readonly storyTellerClicked = new EventEmitter<string>();

  @Input() public props: LaunchpadTileConfig | undefined;
  @Input() public isMobile: boolean | undefined;
}
