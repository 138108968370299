import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output } from "@angular/core";
import { Pure } from "src/app/common/decorators/pure";
import { ITableColumn } from "../../../interfaces/table-column";
import { TABLE_CUSTOM_TEMPLATE_CONTEXT } from "../../../tokens/table-custom-template-context";

@Component({
  selector: "app-table-cell",
  templateUrl: "table-cell.component.html",
  styleUrls: ["table-cell.component.scss", "../../../styles/cells.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellComponent<T extends object> implements OnInit {
  @Input() public column: ITableColumn;
  @Input() public row: T;
  @Input() public loading = false;

  @Output() public readonly cellClicked = new EventEmitter<T>();
  public componentInjector: Injector;

  constructor(private readonly injector: Injector) {}

  public ngOnInit(): void {
    if (this.column.type === "custom") {
      this.componentInjector = this.createInjector({ id: this.column.id, row: this.row });
    }
  }

  @Pure
  public isButtonDisabled(column: ITableColumn, row: T): boolean {
    return !column.buttonDisabledAccessor || column.buttonDisabledAccessor(row);
  }

  private createInjector(context: { id: string; row: T }): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: TABLE_CUSTOM_TEMPLATE_CONTEXT,
          useValue: context
        }
      ]
    });
  }
}
