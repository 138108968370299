export interface IStationWorkloadDto {
  year: number;
  value: number;
}

export class StationWorkloadDto implements IStationWorkloadDto {
  public year: number;
  public value: number;

  constructor(data: IStationWorkloadDto) {
    Object.assign(this, data);
  }
}
