import { Injectable } from "@angular/core";
import { SLOT } from "src/app/common/enums/slot";
import { Router } from "@angular/router";
import { RoutingQueryHandlerService } from "../routing-query-handler-service/routing-query-handler.service";
import { combineLatestWith, filter, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { selectRegionId } from "src/app/ngrx/selectors/app.selectors";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/ngrx/root-reducers";
import { RoutingQueryParams } from "../routing-query-handler-service/routing-query-handler.types";

@Injectable({ providedIn: "root" })
export class RegionSwitcherService {
  private readonly STAY_ON_SLOTS = [
    SLOT.LANDING,
    /**
     * Explore compatibility for different modules
     */
    // SLOT.ACCOUNTING,
    // SLOT.GRID_CONNECTION,
    // SLOT.GRID_DEVELOPMENT,
    // SLOT.THREE_YEAR_PLAN
    SLOT.OUTAGE_MONITORING
  ];

  private readonly regionId$ = this.store.select(selectRegionId);

  constructor(
    private readonly router: Router,
    private readonly store: Store<RootState>,
    private readonly routingQueryHandlerService: RoutingQueryHandlerService
  ) {}

  /**
   * Observes when the regionId is sync with parameter from url
   * So it means we can rely on it when applying refresh logic
   * @returns {Observable<string>} - is observable regionId
   */
  get regionSwitched$(): Observable<string> {
    return this.routingQueryHandlerService.currentRouteChain$.pipe(
      distinctUntilChanged((prev, curr) => prev[1] === curr[1]),
      combineLatestWith(this.regionId$),
      filter(([currentRouteChain, regionId]) => regionId === currentRouteChain[1] && Boolean(currentRouteChain[2])),
      map(([, regionId]) => regionId)
    );
  }

  /**
   * Common switch function to make switching regions onto landing page
   * @param entityId - id of the region entity
   */
  public switchRegionOntoLanding(entityId: string): void {
    this.router.navigate(["regions", entityId, SLOT.LANDING], {
      queryParams: { overlay: this.routingQueryHandlerService.routingQueryParams.overlay || "module" }
    });
  }

  /**
   * Common switch function to make switching regions
   * @param entityId - id of the region entity
   * @param params {RoutingQueryParams} - params object to be provided for redirection purposes
   */
  public switchRegion(entityId: string, params?: Partial<RoutingQueryParams>): void {
    const currentSlot = this.routingQueryHandlerService.currentRouteChainSnapshot[2] as SLOT;

    if (this.STAY_ON_SLOTS.includes(currentSlot)) {
      this.router.navigate(["regions", entityId, currentSlot], {
        queryParams: params || this.routingQueryHandlerService.routingQueryParams
      });
    } else {
      this.switchRegionOntoLanding(entityId);
    }
  }
}
