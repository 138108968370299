import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { IButtonToggleItem } from "src/app/common/components/button-toggle/button-toggle.component";
import { SelectOptionItem } from "src/app/common/components/select/select.interface";
import { SLOT } from "src/app/common/enums/slot";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import { TableFilterComponent } from "src/app/slots/three-year-plan/common/components/table/components/table-filter/table-filter.component";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-contentful-wrapper-navigation",
  templateUrl: "./contentful-wrapper-navigation.component.html",
  styleUrls: ["./contentful-wrapper-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentfulWrapperNavigationComponent implements OnDestroy {
  @ViewChild("filterRef") public readonly filterRef!: TableFilterComponent;

  @Output() public readonly searchChange = new EventEmitter<string>();
  @Output() public readonly moduleChange = new EventEmitter<SLOT | undefined>();
  @Output() public readonly contentTypeChange = new EventEmitter<ContentfulContentType>();
  @Output() public readonly markAllRead = new EventEmitter<string>();

  @Input() public isMobileView: boolean;
  @Input() public moduleSelectOptions: Array<SelectOptionItem>;
  @Input() public contentType: ContentfulContentType;
  @Input() public contentTypeToggles: Array<IButtonToggleItem>;

  private readonly destroy$ = new Subject<void>();

  public onContentTypeChange(contentType: ContentfulContentType): void {
    this.filterRef?.reset();
    this.contentTypeChange.emit(contentType);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
