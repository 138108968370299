import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map } from "rxjs/operators";
import { EMPTY } from "rxjs";
import {
  getGasCockpitData,
  getGasCockpitKpi, getGasCockpitKpiSuccess,
  getGasNetworksLength,
  getGasNetworksLengthSuccess
} from "../actions/gas-cockpit.actions";
import { GasCockpitService } from "../../core/services/gas-cockpit.service";
import {
  GasCockpitKpiDto,
  GasNetworkLengthDto
} from "../../common/dto/gas-cockpit";

@Injectable()
export class GasCockpitEffects {
  getGasCockpitData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGasCockpitData),
      exhaustMap(action => [
          getGasCockpitKpi(action),
          getGasNetworksLength(action)
        ]
      )
    )
  );

  getGasCockpitKpi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGasCockpitKpi),
      exhaustMap(action =>
        this.gasCockpitService.getGasCockpitKpi(action)
          .pipe(
            map((payload: GasCockpitKpiDto) => getGasCockpitKpiSuccess({ payload })),
            catchError(() => EMPTY)
          )
      )
    )
  );

  getGasNetworkLength$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGasNetworksLength),
      exhaustMap(action =>
        this.gasCockpitService.getGasNetworksLength(action)
          .pipe(
            map((payload: GasNetworkLengthDto) => getGasNetworksLengthSuccess({ payload })),
            catchError(() => EMPTY)
          )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private gasCockpitService: GasCockpitService
  ) {}
}
