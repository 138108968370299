import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store, select } from "@ngrx/store";
import { EMPTY, of } from "rxjs";
import { catchError, exhaustMap, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { DynamicSnackBar } from "src/app/core/material/snack-bar/snack-bar";
import { AuthService } from "src/app/core/services/auth.service";
import { CommunicationService } from "src/app/core/services/communication.service";
import {
  fetchClientConfig,
  fetchClientConfigSuccess,
  fetchKeycloakUserInfo,
  fetchKeycloakUserInfoSuccess,
  fetchUserInfo,
  fetchUserInfoError,
  fetchUserInfoSuccess,
  initApp,
  logout,
  postContactData,
  setPolicy,
  updatePolicy
} from "../actions/app.actions";
import { RootState } from "../root-reducers";
import { selectKeycloakUserInfo, selectUserRegion } from "../selectors/app.selectors";

@Injectable()
export class AppEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initApp),
      switchMap(() => [fetchKeycloakUserInfo(), fetchClientConfig()])
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => this.authService.logout())
      ),
    { dispatch: false }
  );

  fetchKeycloakUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchKeycloakUserInfo),
      switchMap(() =>
        this.authService.getKeycloakUserInfo().pipe(
          map((payload) => fetchKeycloakUserInfoSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  fetchUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserInfo),
      switchMap(() =>
        this.authService.getUserInfo().pipe(
          map((payload) => fetchUserInfoSuccess({ payload })),
          catchError(() => of(fetchUserInfoError()))
        )
      )
    )
  );

  clientConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchClientConfig),
      switchMap(() =>
        this.authService.getClientConfig().pipe(
          map((payload) => fetchClientConfigSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  postContactData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postContactData),
        withLatestFrom(this.store.pipe(select(selectKeycloakUserInfo)), this.store.pipe(select(selectUserRegion))),
        exhaustMap(([action, userInfo, { name }]) =>
          this.communicationService
            .postContactData({
              ...action.form,
              ...userInfo,
              municipality: name,
              tenantName: action.requestType
            })
            .pipe(catchError(() => EMPTY))
        )
      ),
    { dispatch: false }
  );

  updatePolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updatePolicy),
        tap(() => this.snackbar?.success("SNACKBAR.SAVE")),
        tap(({ policy }) => {
          this.store.dispatch(setPolicy({ policy }));
        })
      ),
    { dispatch: false }
  );

  setPolicy$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPolicy),
        tap(({ policy }) => {
          this.authService.setPolicyCookies(policy);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly store: Store<RootState>,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly communicationService: CommunicationService,
    private readonly snackbar: DynamicSnackBar
  ) {}
}
