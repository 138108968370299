import { OverlayConfig } from "@angular/cdk/overlay";
import { ComponentType } from "@angular/cdk/portal";
import { MatLegacyDialogConfig } from "@angular/material/legacy-dialog";
import { ContactDialogComponent } from "../components/dialogs/contact/contact.component";
import { NotificationSettingsComponent } from "../components/dialogs/notification-settings/components/notification-settings/notification-settings.component";
import { RegionsSwitcherComponent } from "../components/dialogs/regions-switcher/regions-switcher.component";
import { TextDialogComponent } from "../components/dialogs/text/text.component";
import { Dialog } from "../enums/dialogs";

interface IDialogConfigWithComponent<T extends OverlayConfig = OverlayConfig> {
  component: ComponentType<any>;
  config?: T;
}

type MatDialogs = Dialog.TEXT | Dialog.CONTACT | Dialog.REGIONS_SWITCHER | Dialog.NOTIFICATION_SETTINGS;

export const matDialogsConfigs: { [key in MatDialogs]: IDialogConfigWithComponent<MatLegacyDialogConfig> } = {
  [Dialog.TEXT]: {
    component: TextDialogComponent,
    config: {
      autoFocus: false,
      width: "400px"
    }
  },
  [Dialog.CONTACT]: {
    component: ContactDialogComponent,
    config: {
      autoFocus: false,
      width: "500px"
    }
  },
  [Dialog.REGIONS_SWITCHER]: {
    component: RegionsSwitcherComponent,
    config: {
      autoFocus: true,
      width: "35rem",
      panelClass: "regions-switcher-dialog"
    }
  },
  [Dialog.NOTIFICATION_SETTINGS]: {
    component: NotificationSettingsComponent,
    config: {
      autoFocus: false,
      panelClass: "notification-settings-dialog",
      width: "600px"
    }
  }
};
