import { environment } from "src/environments/environment";
import { EnvironmentType } from "../enums/environmentType";

/**
 * Checks on which environment the application is running and returns
 * the custom dimension id of "User Type" based on that.
 * On PROD it is 2, while on DEV and RC it's 1
 */
export const AnalyticsUserDimensions = {
  userType: environment.type === EnvironmentType.PROD ? 2 : 1
};
