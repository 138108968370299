import { TableColumnConfig } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { STATUS_COLORS } from "../../product-management-dialog.constants";

export const COLUMNS_CONFIG: Array<TableColumnConfig> = [
  {
    id: "fullName",
    key: "Vertragspartner",
    type: "text"
  },
  {
    id: "email",
    key: "E-Mail",
    type: "text"
  },
  {
    id: "address",
    key: "Straße, Hausnummer",
    type: "text"
  },
  {
    id: "addressPostalCode",
    key: "PLZ, Ort",
    type: "text"
  },
  {
    id: "status",
    key: "Vertragsstatus",
    type: "text",
    translateAccessor: (row) => `CAMPUS_ONE_ADMIN_VIEW.STATUS.${row.status}`,
    styleAccessor: (row) => ({ color: STATUS_COLORS[row.status] ?? null })
  },
  {
    id: "contractTerm",
    key: "Vertragslaufzeit",
    type: "text"
  },
  {
    id: "download",
    key: "Rechnungen",
    type: "action",
    actionLabel: "PDF Download",
    buttonDisabledAccessor: (row) => !row.files?.length
  }
];
