import { Injectable } from "@angular/core";
import { UnleashClient } from "unleash-proxy-client";
import { AppConfig } from "../helpers/app.config";

@Injectable({ providedIn: "root" })
export class FeatureFlagService {
  readonly unleash: UnleashClient;

  constructor() {
    if (AppConfig.connection.unleash) {
      this.unleash = new UnleashClient({
        disableRefresh: true,
        url: AppConfig.connection.unleash.url,
        clientKey: AppConfig.connection.unleash.clientKey,
        appName: AppConfig.connection.unleash.appName,
        environment: AppConfig.connection.unleash.environment
      });
    }
  }

  setUserId(email: string): void {
    this.unleash?.setContextField("userId", email);
  }

  start(): void {
    this.unleash?.start();
  }

  stop(): void {
    this.unleash?.stop();
  }

  isEnabled(feature: string): boolean {
    return this.unleash?.isEnabled(feature);
  }
}
