import { BuildingsConnectionGroupType } from "../../constants/map/connection-group";
import { BuildingsConnectionGroupEntryDto } from "./buildings-connection-group-entry";

export interface BuildingsConnectionGroup {
  entries: Array<BuildingsConnectionGroupEntryDto>;
  type: BuildingsConnectionGroupType;
  isActive: boolean;
}

export class BuildingsConnectionGroupDto {
  public electricity: BuildingsConnectionGroup;
  public gas: BuildingsConnectionGroup;

  constructor(elEntries: Array<BuildingsConnectionGroupEntryDto>, gasEntries: Array<BuildingsConnectionGroupEntryDto>) {
    const isGasActive = Boolean(gasEntries.length && !elEntries.length);
    this.electricity = {
      entries: elEntries,
      type: BuildingsConnectionGroupType.electricityConnectionGroup,
      isActive: !isGasActive
    };
    this.gas = {
      entries: gasEntries,
      type: BuildingsConnectionGroupType.gasConnectionGroup,
      isActive: isGasActive
    };
  }
}
