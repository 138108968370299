export interface ISelfSufficiencyProductionDto {
  timestampsUnix: Array<number>;
  production: Array<number>;
}

export class SelfSufficiencyProductionDto implements ISelfSufficiencyProductionDto {
  public timestampsUnix: Array<number>;
  public production: Array<number>;

  constructor(data: ISelfSufficiencyProductionDto) {
    this.timestampsUnix = data.timestampsUnix;
    this.production = data.production;
  }
}
