import { Pipe, PipeTransform } from "@angular/core";
import { secondsToFormattedMinutes } from "src/app/common/utils/seconds-to-formatted-minutes";

@Pipe({ name: "formatTime" })
export class FormatTimePipe implements PipeTransform {
  public transform(currentTime: number = 0, durationTime: number = NaN): string {
    const _currentTime = secondsToFormattedMinutes(currentTime);
    const _durationTime = secondsToFormattedMinutes(durationTime);

    return `${_currentTime} / ${_durationTime}`;
  }
}
