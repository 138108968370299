import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GraphicType } from "@enersis/gp-components/gp-list";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: ["./list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    "[class.two-line]": "twoLine"
  }
})
export class ListItemComponent {
  @Input() public hasMeta: boolean = false;
  @Input() public nonInteractive: boolean = true;
  @Input() public twoLine: boolean = false;
  @Input() public graphic: GraphicType = null;
  @Input() public selected: boolean = false;
}
