<ng-container *ngIf="context">
  <p class="contact">{{ context.row[context.id] }}</p>
  <ng-container [ngSwitch]="notificationSettings.module">
    <ng-container *ngSwitchCase="'incident-management'">
      <ng-container *ngTemplateOutlet="badgesElectricity; context: { $implicit: notificationSettings.electricity }"></ng-container>
      <ng-container *ngTemplateOutlet="badgesGas; context: { $implicit: notificationSettings.gas }"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="badgesElectricity; context: { $implicit: notificationSettings }"></ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #badgesElectricity let-settings>
  <div class="notification-settings">
    <app-square *ngIf="settings.start" [data]="notificationSquares.electricity.start" [showTooltip]="true"></app-square>
    <app-square
      *ngIf="settings.intermediate"
      [data]="notificationSquares.electricity.intermediate"
      [showTooltip]="true"
    ></app-square>
    <app-square *ngIf="settings.update" [data]="notificationSquares.electricity.update" [showTooltip]="true"></app-square>
    <app-square *ngIf="settings.end" [data]="notificationSquares.electricity.end" [showTooltip]="true"></app-square>
  </div>
</ng-template>
<ng-template #badgesGas let-settings>
  <div class="notification-settings">
    <app-square *ngIf="settings.start" [data]="notificationSquares.gas.start" [showTooltip]="true"></app-square>
    <app-square
      *ngIf="settings.intermediate"
      [data]="notificationSquares.gas.intermediate"
      [showTooltip]="true"
    ></app-square>
    <app-square *ngIf="settings.update" [data]="notificationSquares.gas.update" [showTooltip]="true"></app-square>
  </div>
</ng-template>
