import { DatePipe, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { CardModule } from "src/app/common/components/card/card.module";
import { ColoredBadgeModule } from "src/app/common/components/colored-badge/colored-badge.module";
import { CommunalPlatformNewsEntry, NetzeBwNewsEntry } from "../../../../news/news.interfaces";

@Component({
  selector: "tpm-news-banner",
  templateUrl: "news-banner.component.html",
  styleUrls: ["news-banner.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, DatePipe, MatIconModule, CardModule, ColoredBadgeModule]
})
export class NewsBannerComponent {
  @Input() public isMobile!: boolean;
  @Input() public props!: NetzeBwNewsEntry | CommunalPlatformNewsEntry;
  @Input() public showBadge!: boolean;
}
