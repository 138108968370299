import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { SLOT } from "src/app/common/enums/slot";
import { RootState } from "src/app/ngrx/root-reducers";
import { hasRegionPermissions } from "src/app/ngrx/selectors/permissions.selectors";

@Injectable({ providedIn: "root" })
export class CanActivateAccountingChangelogGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly store: Store<RootState>) {}

  public canActivate(): Observable<UrlTree | boolean> {
    return this.store.pipe(
      select(hasRegionPermissions, { permissions: "module-00008.changelog.read" }),
      map((_hasPermissions) => (_hasPermissions ? true : this.router.parseUrl(SLOT.LANDING))),
      take(1)
    );
  }
}
