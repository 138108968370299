export interface IPhotovoltaicsConnectionGroupsDto {
  capacity: number;
  generationAmount: number;
  co2Saving: number;
}

export class PhotovoltaicsConnectionGroupsDto implements IPhotovoltaicsConnectionGroupsDto {
  public capacity: number;
  public generationAmount: number;
  public co2Saving: number;

  constructor(data: IPhotovoltaicsConnectionGroupsDto) {
    Object.assign(this, data);
  }
}
