import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { EneLayoutService } from "@enersis/ui-helper";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Subscription } from "rxjs";
import { tap } from "rxjs/operators";
import { matDialogsConfigs } from "src/app/common/constants/dialogs-configs";
import { Dialog } from "src/app/common/enums/dialogs";
import { RoutingQueryHandlerService } from "src/app/core/services/routing-query-handler-service/routing-query-handler.service";
import { closeAllDialogs, openDialog } from "../actions/dialogs.actions";

@Injectable()
export class DialogsEffects {
  openDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openDialog),
        tap(({ dialogType, data }) => this.openMatDialog(dialogType, data))
      ),
    { dispatch: false }
  );

  closeAllDialogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeAllDialogs),
        tap(() => {
          this.matDialog.closeAll();
        })
      ),
    { dispatch: false }
  );

  private dialogLayoutSubscription: Subscription;

  constructor(
    private readonly actions$: Actions,
    private readonly matDialog: MatLegacyDialog,
    private readonly layoutService: EneLayoutService,
    private readonly routingQueryHandlerService: RoutingQueryHandlerService
  ) {}

  private openMatDialog(dialogType: Dialog, data?: any): void {
    if (!matDialogsConfigs[dialogType]) {
      this.routingQueryHandlerService.resetQueryStateFor(["dialogType", "dialogTab"]);
      return;
    }

    const { component, config } = matDialogsConfigs[dialogType];
    config.width = config.width || "500px";

    const dialogRef = this.matDialog.open(component, {
      ...config,
      data: data ? { ...data, disableClose: config.disableClose } : { disableClose: config.disableClose }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.routingQueryHandlerService.resetQueryStateFor(["dialogType", "dialogTab"]);
    });

    this.dialogLayoutSubscription?.unsubscribe();
    this.dialogLayoutSubscription = this.layoutService.isMobileScreen$.subscribe((isMobile) => {
      dialogRef.updateSize(isMobile ? "100%" : config.width, isMobile ? "100%" : config.height ?? null);
    });
  }
}
