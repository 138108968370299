import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyTabsModule } from "@angular/material/legacy-tabs";
import { EneTabsModule } from "@enersis/ui-tabs";
import { TranslateModule } from "@ngx-translate/core";
import { TabNavBarComponent } from "./tab-nav-bar.component";

@NgModule({
  declarations: [TabNavBarComponent],
  imports: [CommonModule, TranslateModule, EneTabsModule, MatLegacyTabsModule],
  exports: [TabNavBarComponent]
})
export class TabNavBarModule {}
