import { Action, createReducer, on } from "@ngrx/store";
import { LaunchpadOverlay, RegionOverlay } from "src/app/core/interfaces/overlay";
import * as RegionOverlayActions from "../actions/overlay.actions";

export interface OverlayState {
  regionOverlay: {
    previous: RegionOverlay;
    current: RegionOverlay;
  };
  launchpadOverlay: LaunchpadOverlay;
}

const initialState: OverlayState = {
  regionOverlay: {
    previous: null,
    current: null
  },
  launchpadOverlay: "module"
};

const setRegionOverlay = (state: OverlayState, current: RegionOverlay) => ({
  previous: state.regionOverlay.current,
  current
});

const regionOverlayReducer = createReducer(
  initialState,
  on(RegionOverlayActions.openLaunchpad, (state) => ({
    ...state,
    regionOverlay: setRegionOverlay(state, "launchpad")
  })),
  on(RegionOverlayActions.closeLaunchpad, (state) => ({
    ...state,
    regionOverlay: setRegionOverlay(state, null)
  })),
  on(RegionOverlayActions.openNews, (state) => ({ ...state, regionOverlay: setRegionOverlay(state, "news") })),
  on(RegionOverlayActions.closeNews, (state) => ({
    ...state,
    regionOverlay: setRegionOverlay(state, state.regionOverlay.previous)
  })),
  on(RegionOverlayActions.openWebinars, (state) => ({ ...state, regionOverlay: setRegionOverlay(state, "webinars") })),
  on(RegionOverlayActions.closeWebinars, (state) => ({
    ...state,
    regionOverlay: setRegionOverlay(state, state.regionOverlay.previous)
  })),
  on(RegionOverlayActions.changeRegionOverlay, (state, { overlay }) => ({
    ...state,
    regionOverlay: setRegionOverlay(state, overlay)
  })),
  on(RegionOverlayActions.changeLaunchpadOverlay, (state, { overlay }) => ({
    ...state,
    launchpadOverlay: overlay
  }))
);

export function reducer(state: OverlayState | undefined, action: Action): OverlayState {
  return regionOverlayReducer(state, action);
}
