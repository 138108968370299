import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import {
  MatLegacyCellDef,
  MatLegacyColumnDef,
  MatLegacyFooterCellDef,
  MatLegacyHeaderCellDef
} from "@angular/material/legacy-table";
import { Pure } from "src/app/common/decorators/pure";
import { TableComponent } from "../../container/table.component";
import { TableService } from "../../services/table.service";
import { TABLE_COLUMNS } from "../../table.constants";
import { TableColumnConfig, TableColumnType } from "../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-column-composer",
  templateUrl: "./column-composer.component.html",
  styleUrls: ["../../styles/column.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnComposerComponent<T extends Record<string, any>> implements OnInit, OnDestroy {
  @ViewChild(MatLegacyColumnDef, { static: true }) public readonly columnDef!: MatLegacyColumnDef;
  @ViewChild(MatLegacyHeaderCellDef, { static: true }) public readonly headerCellDef!: MatLegacyHeaderCellDef;
  @ViewChild(MatLegacyFooterCellDef, { static: true }) public readonly footerCellDef!: MatLegacyFooterCellDef;
  @ViewChild(MatLegacyCellDef, { static: true }) public readonly cellDef!: MatLegacyCellDef;

  @Input() public sortDisabled: boolean = true;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("hideHeader") public headerHidden: boolean = false;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("hideFooter") public footerHidden: boolean = true;
  @Input() public loading: boolean;

  @Input()
  public set columnConfig(columnConfig: TableColumnConfig) {
    this._columnConfig = columnConfig;
    this.tableService.syncColumnDef(this.columnDef, columnConfig.id);
  }
  public _columnConfig!: TableColumnConfig;

  constructor(@Optional() public readonly table: TableComponent<T>, private readonly tableService: TableService<T>) {}

  @Pure
  public getColumnClasses(
    { type, classes = [], classesAccessor = () => [] }: TableColumnConfig,
    row?: T
  ): Array<string> {
    return Array.prototype.concat(`${type}-column`, classes, classesAccessor(row));
  }

  @Pure
  public getStyleAccessor(
    { style = {}, styleAccessor = () => ({}) }: TableColumnConfig,
    row: T
  ): { [klass: string]: any } {
    return {
      ...style,
      ...styleAccessor(row)
    };
  }

  @Pure
  public getOmittedStyles({ style = {} }: TableColumnConfig): Record<string, any> {
    return Object.keys(style)
      .filter((prop) => prop !== "color")
      .reduce((accStyle, prop) => ({ ...accStyle, [prop]: style[prop] }), {});
  }

  @Pure
  public getFooterColumn(
    columnConfig: TableColumnConfig,
    list: Array<T>
  ): { columnConfig: Partial<TableColumnConfig>; row: Record<string, string | number> } {
    switch (columnConfig.footer?.type) {
      case "text": {
        return {
          columnConfig: { id: columnConfig.id, type: "text" },
          row: { [columnConfig.id]: columnConfig.footer.key }
        };
      }
      case "value": {
        return {
          columnConfig: { id: columnConfig.id, type: "value" },
          row: {
            [columnConfig.id]: list.reduce((acc, item) => acc + ((item[columnConfig.id] as number) ?? 0), 0)
          }
        };
      }
    }
  }

  public ngOnInit(): void {
    this.tableService.syncColumnDef(this.columnDef, this._columnConfig.id);
    this.tableService.addColumnDef(this.table, this.columnDef, this.cellDef, this.headerCellDef, this.footerCellDef);
  }

  public ngOnDestroy(): void {
    this.tableService.removeColumnDef(this.table, this.columnDef);
  }

  public isSortDisabled(globalSortDisabled: boolean, sortDisabled: boolean, type: TableColumnType): boolean {
    return globalSortDisabled || sortDisabled === true || type === TABLE_COLUMNS.Action || type === TABLE_COLUMNS.Icon;
  }
}
