<mat-accordion class="custom spaced">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>
          {{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.AREA_INFORMATION.TITLE" | translate }}
        </span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <ng-container *ngTemplateOutlet="areaInformation"></ng-container>
    </ng-template>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.COA.TITLE" | translate }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <mat-divider big></mat-divider>
      <form name="municipalSettingsForm" enctype="multipart/form-data" class="content">
        <img *ngIf="coatOfArms" [src]="coatOfArms" class="preview-image" />
        <p class="quality-warning">
          {{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.COA.QUALITY_WARNING" | translate }}
        </p>
        <div class="upload-zone" (dropFile)="uploadHandler($event)" dragAndDrop>
          <input
            type="file"
            (change)="uploadHandler($event.target.files)"
            accept="image/png, image/jpeg, image/svg+xml"
          />
          <mat-icon fontIcon="ec_upload"></mat-icon>
          <p class="hint">{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.COA.DRAG_AND_DROP" | translate }}</p>
          <button color="accent" mat-stroked-button>
            {{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.COA.CHOOSE_FILE" | translate }}
          </button>
        </div>
        <div class="details" *ngIf="coatOfArmsFile">
          <span>Uploaded: {{ coatOfArmsFile.name }} ({{ formatToKb(coatOfArmsFile.size) }})</span>
          <div class="delete-file" (click)="deleteFile()">
            {{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.COA.DELETE" | translate }}
          </div>
        </div>
      </form>
    </ng-template>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span>{{
          "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.AREA_INFORMATION.GENERAL_SETTING" | translate
        }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="generalSettings"></ng-container>
  </mat-expansion-panel>
</mat-accordion>

<ng-template #areaInformation>
  <mat-divider big></mat-divider>
  <div class="content">
    <form class="contact-form" [formGroup]="form">
      <mat-form-field class="input">
        <mat-label>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.NAME" | translate}}</mat-label>
        <input matInput formControlName="name" />
        <mat-icon matSuffix>mode_edit</mat-icon>
      </mat-form-field>
      <mat-form-field class="input">
        <mat-label>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.REGION_ID" | translate}}</mat-label>
        <input matInput formControlName="entityId" />
      </mat-form-field>
      <mat-form-field class="input">
        <mat-label>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.AGS" | translate}}</mat-label>
        <input matInput formControlName="ags" />
      </mat-form-field>
      <mat-form-field class="input">
        <mat-label>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.LONGITUDE" | translate}}</mat-label>
        <input matInput formControlName="coordinatesLong" />
        <mat-icon matSuffix>mode_edit</mat-icon>
      </mat-form-field>
      <mat-error>
        <span *ngIf="form.get('coordinatesLong').hasError('patternError') || form.get('coordinatesLong').hasError('rangeError')" >
          {{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.LONG_ERROR" | translate : { minLong: coordinatesLongitude.min, maxLong: coordinatesLongitude.max} }}
        </span>
      </mat-error>
      <mat-form-field class="input">
        <mat-label>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.LATITUDE" | translate}}</mat-label>
        <input matInput formControlName="coordinatesLat" />
        <mat-icon matSuffix>mode_edit</mat-icon>
      </mat-form-field>
      <mat-error>
        <span *ngIf="form.get('coordinatesLat').hasError('patternError') || form.get('coordinatesLat').hasError('rangeError')" >
          {{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.LAT_ERROR" | translate : { minLat: coordinatesLatitude.min, maxLat: coordinatesLatitude.max} }}
        </span>
      </mat-error>
      <mat-form-field class="input">
        <mat-label>{{ "CONFIGURATION_AREA.DIALOGS.SETTINGS.TABS.COMMUNE.AREA_INFORMATION.ZOOM_LEVEL" | translate}}</mat-label>
        <mat-select formControlName="zoomLevel">
          <mat-option *ngFor="let level of zoomLevelOptions" [value]="level">{{ level }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</ng-template>

<ng-template #generalSettings>
  <mat-divider big></mat-divider>
  <div class="content">
    <form class="contact-form" [formGroup]="form">
      <mat-checkbox formControlName="newsNotification">{{
        "CONFIGURATION_AREA.DIALOGS.SETTINGS.EXPANSION_PANELS.AREA_INFORMATION.NEWS_NOTIFICATION" | translate
      }}</mat-checkbox>
    </form>
  </div>
</ng-template>
