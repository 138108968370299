import { AfterViewInit, Directive, ElementRef, HostListener } from "@angular/core";
import { KeyCode } from "../../enums/key-code/key-code";
import { AnalyticsService } from "../../../core/services/analytics/analytics.service";

@Directive({ selector: "[scanMediaAnalytics]", standalone: true })
export class MediaAnalyticsDirective implements AfterViewInit {
  constructor(private readonly element: ElementRef, private readonly analyticsService: AnalyticsService) {
    if (!["VIDEO", "AUDIO"].includes(this.element.nativeElement.nodeName)) {
      console.warn("[MediaAnalyticsDirective]: Wrong element. <audio> or <video> tag required");
    }
  }
  public ngAfterViewInit(): void {
    this.analyticsService.scanForMedia();
  }
}
