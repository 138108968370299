import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { getFileSize } from "src/app/common/utils/file-size";
import { DropFileAreaItem } from "./drop-file-area.component";

@Directive()
export class FileListInputs {
  @Output() public readonly fileRemove = new EventEmitter<DropFileAreaItem>();

  @Input() public listItemPrimaryLineAccessor: (item: DropFileAreaItem) => string = (item: DropFileAreaItem) =>
    item.file.name;
  @Input() public listItemSecondaryLineAccessor: (item: DropFileAreaItem) => string = (item: DropFileAreaItem) =>
    getFileSize(item.file.size);
  @Input() public listItemExtraLineAccessor: (item: DropFileAreaItem) => string | undefined;

  @Input() public files: Array<DropFileAreaItem> | undefined;
}
