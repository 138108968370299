import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Colors } from "src/styles/themes/colors";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-colored-badge",
  templateUrl: "./colored-badge.component.html",
  styleUrls: ["./colored-badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColoredBadgeComponent {
  @Input() public maxWidth: string | null = null;
  @Input() public text: string;
  @Input() public icon: string;

  @Input()
  public set iconColor(_iconColor: string) {
    if (_iconColor) {
      this._iconColor = _iconColor.includes("-color-") ? `var(${_iconColor})` : _iconColor;
    }
  }
  public _iconColor: string = null;

  @Input()
  public set backgroundColor(_backgroundColor: string) {
    if (_backgroundColor) {
      this._backgroundColor = _backgroundColor.includes("-color-") ? `var(${_backgroundColor})` : _backgroundColor;
    }
  }
  public _backgroundColor: string = Colors.Grey_200;

  @Input()
  public set color(_color: string) {
    if (_color) {
      this._color = _color.includes("-color-") ? `var(${_color})` : _color;
    }

    if (!this._iconColor) {
      this._iconColor = this._color;
    }
  }
  public _color: string = Colors.White;
}
