import { CrmFormConfig } from "../interfaces/crm-form-marketing";

export enum crmInternalFormId {
  marketingTeaser = "marketingTeaser"
}

export enum crmFormMarketingTeaserProperty {
  email = "email",
  ags = "ags",
  phoneNumber = "phoneNumber",
  communicationType = "communicationType",
  firstName = "firstName",
  lastName = "lastName",
  description = "description",
  guid = "guid"
}

const crmFormStructureMarketingTeaser: CrmFormConfig = {
  internalFormId: crmInternalFormId.marketingTeaser,
  entries: {
    [crmFormMarketingTeaserProperty.email]: { type: "text", index: 0 },
    [crmFormMarketingTeaserProperty.phoneNumber]: { type: "text", index: 1 },
    [crmFormMarketingTeaserProperty.ags]: { type: "text", index: 2 },
    [crmFormMarketingTeaserProperty.description]: { type: "textarea", index: 3 },
    [crmFormMarketingTeaserProperty.firstName]: { type: "text", index: 4 },
    [crmFormMarketingTeaserProperty.lastName]: { type: "text", index: 5 },
    [crmFormMarketingTeaserProperty.communicationType]: {
      type: "radio",
      index: 6,
      options: ["any", "email", "phone", "fax", "post"]
    },
    [crmFormMarketingTeaserProperty.guid]: { type: "text", index: 7 }
  }
};
/**
 * Define which form property is at which position.
 */
export const crmFormStructures: Array<CrmFormConfig> = [crmFormStructureMarketingTeaser];
