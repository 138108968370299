import { Action, createReducer, on } from "@ngrx/store";
import * as ConsumptionActions from "../../../actions/energy/self-sufficiency/consumption.actions";

export interface State {
  timestampsUnix: Array<number>;
  consumption: Array<number>;
}

const initialState: State = {
  timestampsUnix: undefined,
  consumption: undefined
};

const consumptionReducer = createReducer(
  initialState,
  on(ConsumptionActions.getSelfSufficiencyConsumptionData, (state) => ({
    ...state,
    timestampsUnix: undefined,
    consumption: undefined
  })),
  on(ConsumptionActions.getSelfSufficiencyConsumptionDataSuccess, (state, { payload }) => ({ ...state, ...payload }))
);

export function reducer(state: State | undefined, action: Action): State {
  return consumptionReducer(state, action);
}
