export const NEWS_BADGES = {
  operationNews: {
    id: "operationNews",
    type: "operationNews",
    label: "NOTIFICATION_SERVICE.EDIT.NEWS.QUARTAL_OPERATION",
    key: "NOTIFICATION_SERVICE.EDIT.NEWS.OPERATION_NEWS_LEGEND",
    class: "primary"
  },
  serviceNews: {
    id: "serviceNews",
    type: "serviceNews",
    label: "NOTIFICATION_SERVICE.EDIT.NEWS.QUARTAL_SERVICE",
    key: "NOTIFICATION_SERVICE.EDIT.NEWS.SERVICE_NEWS_LEGEND",
    class: "secondary"
  },
  criticalNews: {
    id: "criticalNews",
    type: "criticalNews",
    label: "NOTIFICATION_SERVICE.EDIT.NEWS.CRITICAL",
    key: "NOTIFICATION_SERVICE.EDIT.NEWS.CRITICAL_NEWS_LEGEND",
    class: "critical"
  }
};
