import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";

@Component({
  selector: "tpm-marketplace-popup-dialog",
  templateUrl: "marketplace-popup-dialog.component.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, DialogFrameModule]
})
export class MarketplacePopupDialogComponent {
  public readonly dialogRef = inject(MatLegacyDialogRef<MarketplacePopupDialogComponent>);
}
