import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  Optional,
  SimpleChanges,
  ViewEncapsulation
} from "@angular/core";
import { KPI_ITEM_MAP } from "../../constants/kpi-map";
import { IKpiBadge } from "../../interfaces/kpi-badge";
import { IKpiItem, IKpiItemMap } from "../../interfaces/kpi-item";

type StringOrNumber = string | number;

/* eslint-disable @angular-eslint/no-host-metadata-property */
@Component({
  selector: "kpi-item",
  templateUrl: "./kpi-item.component.html",
  styleUrls: ["./kpi-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "kpi-item",
    "[class.loading]": "loading",
    "[class]": "_kpiClass"
  }
})
export class KpiItemComponent implements OnChanges {
  @Input() public loading: boolean = false;
  @Input() public badge: IKpiBadge;
  @Input() public crest: string;
  @Input() public name: string;
  @Input() public value: StringOrNumber;
  @Input() public title: string;

  public id: string | number;
  public _iconColor: string;
  public _config: IKpiItem;
  public _kpiClass: string;

  constructor(
    @Optional() @Attribute("light") private light: any,
    @Inject(KPI_ITEM_MAP) private kpiItemMap: IKpiItemMap,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.name) {
      this._kpiClass = `kpi-item-${this.name}`;

      this.updateConfig();
      this.updateIconColor();
    }

    if (changes.title) {
      this.updateConfig();
    }
  }

  public detectChanges(): void {
    this.cdr.detectChanges();
  }

  private updateIconColor(): void {
    if (coerceBooleanProperty(this.light)) {
      this._iconColor = "var(--ene-color-grey-500)";
    } else {
      this._iconColor = `var(${this._config.iconColor || "--ene-color-grey-700"})`;
    }
  }

  private updateConfig(): void {
    this._config = this.kpiItemMap[this.name] || this.kpiItemMap.placeholder;
    this._config.title = this.title || this._config.title;
    this.id = this._config.id;
  }
}
