import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { SelfSufficiencyService } from "../../../../core/services/self-sufficiency.service";
import {
  getSelfSufficiencySummaryData,
  getSelfSufficiencySummaryDataSuccess
} from "../../../actions/energy/self-sufficiency/summary.actions";
import { SelfSufficiencySummaryDto } from "../../../../common/dto/energy/self-sufficiency/summary";

@Injectable()
export class SummaryEffects {
  getSummaryData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelfSufficiencySummaryData),
      switchMap(action =>
        this.selfSufficiencyService.getSummaryData(action)
          .pipe(
            map((payload: SelfSufficiencySummaryDto) => getSelfSufficiencySummaryDataSuccess({ payload })),
            catchError(() => EMPTY)
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private selfSufficiencyService: SelfSufficiencyService
  ) { }
}
