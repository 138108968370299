import { DestroyRef, inject, Injectable } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatestWith,
  distinctUntilKeyChanged,
  filter,
  map,
  Observable,
  skip,
  Subject,
  take,
  takeUntil
} from "rxjs";

import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Dialog } from "src/app/common/enums/dialogs";
import { LaunchpadOverlay } from "src/app/core/interfaces/overlay";
import { openDialog } from "src/app/ngrx/actions/dialogs.actions";
import { changeLaunchpadOverlay, closeLaunchpad, openLaunchpad } from "src/app/ngrx/actions/overlay.actions";
import { IPanel } from "src/app/ngrx/reducers/sidenavigation.reducer";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectLaunchpadOverlay } from "src/app/ngrx/selectors/overlay.selectors";
import { getPanels } from "src/app/ngrx/selectors/sidenavigation.selectors";
import { RoutingQueryParams, RoutingQueryState } from "./routing-query-handler.types";

@Injectable({
  providedIn: "root"
})
export class RoutingQueryHandlerService {
  private readonly panelState$: Observable<Record<string, IPanel>>;
  private readonly dialogType$: Observable<{ dialogType: Dialog; data?: Partial<any> }>;
  private readonly currentOverlay$: Observable<LaunchpadOverlay>;
  private readonly openOverlay$: Observable<string>;
  private readonly closeOverlay$: Observable<string>;
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly _currentRouteChain$ = new BehaviorSubject<string[]>([]);
  private readonly _viewReady$ = new Subject<boolean>();

  constructor(
    private readonly store: Store<RootState>,
    private readonly router: Router,
    private readonly actions$: Actions,
    private readonly route: ActivatedRoute
  ) {
    this.panelState$ = this.store.select(getPanels);
    this.currentOverlay$ = this.store.select(selectLaunchpadOverlay);

    this.dialogType$ = this.actions$.pipe(ofType(openDialog));
    this.openOverlay$ = this.actions$.pipe(ofType(openLaunchpad));
    this.closeOverlay$ = this.actions$.pipe(ofType(closeLaunchpad));

    this.feedUpQueryStateWithPanels();
    this.feedUpQueryStateWithDialogs();
    this.subscribeRouteParamsChanges();
    this.feedUpQueryStateWithOverlays();
  }

  /**
   * Returns current route chain e.g. ["regions", "id", "child"]
   * @returns {Observable<string[]>}
   */
  public get currentRouteChain$(): Observable<string[]> {
    return this._currentRouteChain$.asObservable();
  }

  /**
   * Returns current state of readiness
   * @returns {Observable<boolean>}
   */
  public get viewReady$(): Observable<boolean> {
    return this._viewReady$.asObservable();
  }

  /**
   * Returns current route chain snapshot e.g. ["regions", "id", "child"]
   * @returns {string[]}
   */
  public get currentRouteChainSnapshot(): string[] {
    return this._currentRouteChain$.getValue();
  }

  /**
   * Partial params of the query handlers
   * @returns {Record<string, string>} includes all necessary information about currently active panels
   * in query params format
   */
  public get routingQueryParams(): RoutingQueryParams {
    const { panelName, dialogType, dialogTab, overlay } = this.routingQueryState;
    return {
      panelName,
      dialogType,
      dialogTab,
      overlay
    };
  }

  /**
   * Indicates if an active panel in the query
   * @returns {boolean}
   */
  public get hasActivePanel(): boolean {
    return Boolean(this.routingQueryState.panelName);
  }

  /**
   * Partial state of the query handlers
   * @returns {RoutingQueryState} includes all necessary information about currently active panels
   */
  public get routingQueryState(): RoutingQueryState {
    const { panelName, dialogType, dialogTab, overlay } = this.route.snapshot.queryParams;
    return {
      panelName,
      panelState: { isOpen: true, isCollapsed: false, isEnlarged: false },
      dialogType,
      dialogTab,
      overlay
    };
  }

  /**
   * Public method to indicate when component's view is ready
   */
  public notifyViewReady(): void {
    this._viewReady$.next(true);
  }

  /**
   * This method remove query parameters with provided names from the url
   * @param params {(keyof RoutingQueryParams)[]} - an array of query param names
   */
  public resetQueryStateFor(params: (keyof RoutingQueryParams)[]): void {
    const queryParams = this.routingQueryParams;
    let shouldReset = false;
    for (const key of params) {
      if (queryParams[key]) {
        delete queryParams[key];
        shouldReset = true;
      }
    }
    /**
     * Reset only if at least parameter was met in queryParamsMap
     */
    if (shouldReset) {
      this.router.navigate([], { queryParams });
    }
  }

  /**
   * This method subscribes on panels changes and modifies the query to keep
   * the current state of active tab there
   */
  private feedUpQueryStateWithPanels(): void {
    this.panelState$.pipe(takeUntilDestroyed(this.destroyRef$)).subscribe((panels) => {
      const panelsEntries = Object.entries(panels).filter(([panelName]) => panelName !== "timeline");

      for (const [panelName, panelState] of panelsEntries) {
        if (panelState.isOpen) {
          this.router.navigate([], {
            queryParams: { panelName },
            queryParamsHandling: "merge"
          });
          return;
        }
      }

      /**
       * Reset the query state as nothing is selected (return operator hasn't been met)
       */
      this.resetQueryStateFor(["panelName"]);
    });
  }

  /**
    This method subscribe dialogs changes that allows to change query params accordingly
   */
  private feedUpQueryStateWithDialogs(): void {
    this.dialogType$
      .pipe(
        filter(({ data }) => !(data?.tab && !this._currentRouteChain$.getValue().includes(data.tab))),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe(({ dialogType, data }) => {
        this.router.navigate([], {
          queryParams: { dialogType, dialogTab: data?.tab },
          queryParamsHandling: "merge"
        });
      });

    this.route.queryParams
      .pipe(
        filter((params: RoutingQueryParams) => Boolean(params.dialogType)),
        map((params) => ({
          dialogType: params.dialogType,
          data: { tab: params.dialogTab }
        })),
        take(1),
        takeUntil(this.dialogType$)
      )
      .subscribe((dialogProps) => this.store.dispatch(openDialog(dialogProps)));
  }

  /**
   This method subscribe overlay launchpad changes that allows to change query params accordingly
   */
  private feedUpQueryStateWithOverlays(): void {
    this.currentOverlay$
      .pipe(
        combineLatestWith(this.openOverlay$),
        filter(([overlay]) => Boolean(overlay) && this.routingQueryParams.overlay !== overlay),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe(([overlay]) => {
        this.router.navigate([], {
          queryParams: { overlay },
          queryParamsHandling: "merge"
        });
      });

    this.currentOverlay$
      .pipe(
        combineLatestWith(this.closeOverlay$),
        filter(([currentOverlay]) => Boolean(currentOverlay) && currentOverlay === this.routingQueryParams.overlay),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe(() => this.resetQueryStateFor(["overlay"]));

    this.route.queryParams
      .pipe(
        distinctUntilKeyChanged("overlay"),
        combineLatestWith(this.viewReady$),
        filter(([params]) => Boolean(params.overlay)),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe(([{ overlay }]) => {
        this.store.dispatch(changeLaunchpadOverlay({ overlay }));
        this.store.dispatch(openLaunchpad());
      });

    this.route.queryParams
      .pipe(
        distinctUntilKeyChanged("overlay"),
        filter((params) => !Boolean(params.overlay)),
        skip(1),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe(() => this.store.dispatch(closeLaunchpad()));
  }

  /**
   * This method subscribes the service on route parameters changes
   */
  private subscribeRouteParamsChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe((event: NavigationEnd) => {
        this._currentRouteChain$.next(
          event.url
            .split("?")[0]
            .split("/")
            .filter((path) => Boolean(path))
        );
      });
  }
}
