<tpm-card class="tile-card" [variant]="'elevation-basic-20'">
  <div class="tile-wrapper">
    <div class="tile-body">
      <light-button (click)="newsClicked.emit(); $event.stopPropagation()" label="LAUNCHPAD.DIALOGS.news.TITLE">
      </light-button>
      <light-button (click)="subscriptionClicked.emit(); $event.stopPropagation()" label="HEADER.USER.NOTIFICATIONS">
      </light-button>
    </div>
  </div>
</tpm-card>
