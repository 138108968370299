import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ContentfulCardModule } from "../components/contentful-card/contentful-card.module";
import { ContentfulWrapperModule } from "../components/contentful-wrapper/contentful-wrapper.module";
import { WebinarDialogModule } from "./components/webinar-dialog/webinar-dialog.module";
import { WebinarsComponent } from "./container/webinars.component";

@NgModule({
  declarations: [WebinarsComponent],
  imports: [CommonModule, TranslateModule, ContentfulWrapperModule, ContentfulCardModule, WebinarDialogModule]
})
export class WebinarsModule {}
