<ng-container matColumnDef [sticky]="_columnConfig.sticky" [stickyEnd]="_columnConfig.stickyEnd">
  <ng-container *ngIf="!headerHidden">
    <th
      *matHeaderCellDef
      mat-header-cell
      mat-sort-header
      [disabled]="isSortDisabled(sortDisabled, _columnConfig.sortDisabled, _columnConfig.type)"
      [ngClass]="getColumnClasses(_columnConfig)"
      [ngStyle]="getOmittedStyles(_columnConfig)"
    >
      <span
        tooltipIfTruncated
        [matTooltip]="_columnConfig.key | translate"
        [matTooltipClass]="'table-tooltip'"
        [innerHTML]="_columnConfig.key | translate"
      ></span>
    </th>
  </ng-container>
  <td
    *matCellDef="let row; let index = index"
    mat-cell
    [ngClass]="getColumnClasses(_columnConfig, row)"
    [ngStyle]="getStyleAccessor(_columnConfig, row)"
  >
    <ng-container *ngIf="!loading; else skeleton">
      <ngmy-column-resolver [index]="index" [columnConfig]="_columnConfig" [row]="row"></ngmy-column-resolver>
    </ng-container>
    <ng-template #skeleton>
      <app-skeleton-loader-box height="16px"></app-skeleton-loader-box>
    </ng-template>
  </td>
  <td
    *matFooterCellDef
    mat-footer-cell
    [ngClass]="getColumnClasses(_columnConfig)"
    [ngStyle]="getOmittedStyles(_columnConfig)"
  >
    <ngmy-textual-column
      *ngIf="!footerHidden && getFooterColumn(_columnConfig, table.dataSource.filteredData) as footer"
      [columnConfig]="footer.columnConfig"
      [row]="footer.row"
    ></ngmy-textual-column>
  </td>
</ng-container>
