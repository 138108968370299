import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { MatLegacyCellDef, MatLegacyColumnDef } from "@angular/material/legacy-table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Pure } from "src/app/common/decorators/pure";
import { EXPAND_ANIMATION } from "src/app/core/animations/expand-animation";
import { TableComponent } from "../../container/table.component";
import { TableService } from "../../services/table.service";
import { TableMultiRowColumnConfig } from "../../table.interfaces";

const COLUMN_NAME = "multi-row";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-column-multi-row",
  templateUrl: "./column-multi-row.component.html",
  styleUrls: ["./column-multi-row.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [EXPAND_ANIMATION]
})
export class ColumnMultiRowComponent<T extends Record<string, any>> implements OnInit, OnDestroy {
  @ViewChild(MatLegacyColumnDef, { static: true }) public columnDef!: MatLegacyColumnDef;
  @ViewChild(MatLegacyCellDef, { static: true }) public cellDef!: MatLegacyCellDef;

  @Input()
  public set config(_config: TableMultiRowColumnConfig | undefined) {
    this._config = _config;
    this.tableService.expand$.next({ expanded: _config.expanded, row: null });
  }
  public _config: TableMultiRowColumnConfig | undefined;

  public readonly expandedRows = new Map<T, boolean>([]);
  public colspan: number;

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly tableService: TableService<T>, @Optional() private readonly table?: TableComponent<T>) {
    this.tableService.expand$.pipe(takeUntil(this.destroy$)).subscribe(({ expanded, row }) => {
      this.expandedRows.set(row, expanded);
    });
  }

  @Pure
  public hasPlaceholder(config: TableMultiRowColumnConfig) {
    return "rowAccessor" in config || "rowComponent" in config;
  }

  @Pure
  public rowAccessor(row: TableMultiRowColumnConfig) {
    return this.config.rowAccessor(row);
  }

  public ngOnInit(): void {
    this.tableService.syncColumnDef(this.columnDef, COLUMN_NAME);
    this.tableService.addColumnDef(this.table, this.columnDef, this.cellDef);

    this.colspan = this.table.attrColspan;
  }

  public ngOnDestroy(): void {
    this.tableService.removeColumnDef(this.table, this.columnDef);
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onClick(row: T): void {
    this.expandedRows.set(row, !this.expandedRows.get(row));
  }
}
