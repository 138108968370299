import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { SquareComponent } from "./square.component";

@NgModule({
  declarations: [SquareComponent],
  imports: [CommonModule, MatLegacyTooltipModule, TranslateModule],
  exports: [SquareComponent]
})
export class SquareModule {}
