import { RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { slotsNamesMap } from "src/app/common/constants/slots-names-map";
import { SLOT } from "src/app/common/enums/slot";
import { RouterStateUrl } from "src/app/common/interfaces/router-state-url";
import { RootState } from "../root-reducers";

export const selectRouter = createFeatureSelector<RootState, RouterReducerState<RouterStateUrl>>("router");
export const selectRouterState = createSelector(selectRouter, (state) => state?.state);

export const selectCurrentSlot = createSelector(selectRouterState, (state) => state?.slot);
export const selectCurrentSlotName = createSelector(selectCurrentSlot, (slot) => slot && slotsNamesMap[slot]);
export const selectBOPBySlotActivity = createSelector(
  selectCurrentSlot,
  (slot) =>
    (slot === SLOT.GRID_DEVELOPMENT ||
      slot === SLOT.GRID_CONNECTION ||
      slot === SLOT.OUTAGE_MONITORING ||
      slot === SLOT.THREE_YEAR_PLAN ||
      slot === SLOT.ACCOUNTING)
);
