import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";
import { NotificationSettingsTYPCellComponent } from "../components/notification-settings-typ-cell/notification-settings-typ-cell.component";

export const notificationSettingsTYPColumns: Array<ITableColumn> = [
  {
    id: "municipality",
    key: "NOTIFICATION_SERVICE.TABLE_COLUMNS.ALL_MUNICIPALITIES",
    type: "text"
  },
  {
    id: "email",
    key: "NOTIFICATION_SERVICE.TABLE_COLUMNS.EMAIL",
    type: "custom",
    customTemplate: NotificationSettingsTYPCellComponent,
    propertyAccessor: (data, id) => data[id]
  },
  {
    id: "edit",
    key: "",
    type: "action",
    actionIcon: "ec_edit",
    columnStyles: "grey",
    style: {
      minWidth: "3rem",
      maxWidth: "3rem"
    },
    stickyEnd: true
  }
];
