<table mat-table matSort [dataSource]="dataSource">
  <ng-container matColumnDef="{{ column.id }}" *ngFor="let column of columnsToDisplay">
    <th mat-sort-header mat-header-cell *matHeaderCellDef [ngStyle]="column.style">{{ column.key | translate }}</th>
    <td mat-cell *matCellDef="let row" [ngStyle]="column.style">
      <ng-container [ngSwitch]="column.type">
        <ng-container *ngSwitchCase="'text'">
          {{ row[column.id] }}
        </ng-container>
        <ng-container *ngSwitchCase="'notification-settings'">
          <div class="notification-settings">
            <app-square *ngFor="let badge of row.electricityBadges[column.id]" [data]="badge" [showTooltip]="true"></app-square>
          </div>
          <div class="notification-settings" *ngIf="row.gasBadges">
            <app-square *ngFor="let badge of row.gasBadges[column.id]" [data]="badge" [showTooltip]="true"></app-square>
          </div>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>
