import { createAction, props } from "@ngrx/store";
import { Co2SavingPerYearDto, Co2SavingYearDto } from "../../../common/dto/energy/co2-saving";

export const getCo2SavingData = createAction(
  "[CO2 Saving] Get co2 saving data",
  props<{
    regionId: string;
    scenarioId: string;
    year: number;
  }>()
);

export const getCo2SavingDataSuccess = createAction(
  "[CO2 Saving] Get co2 saving data success",
  props<{ payload: Co2SavingYearDto }>()
);

export const getCo2SavingAllYearsData = createAction(
  "[CO2 Saving] Get co2 saving all years data",
  props<{
    regionId: string;
    scenarioId: string;
    timelineRange: [number, number];
    year_20?: number;
    year_100?: number;
  }>()
);

export const getCo2SavingAllYearsDataSuccess = createAction(
  "[CO2 Saving] Get co2 saving data all years success",
  props<{ payload: Array<Co2SavingPerYearDto> }>()
);
