import { TabNavBarItem } from "src/app/common/components/tab-nav-bar/tab-nav-bar.interfaces";
import { AdminModuleStatus } from "src/app/common/dto/admin-module/module-status";
import {
  SelectTableColumnConfig,
  TableColumnConfig
} from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { Colors } from "src/styles/themes/colors";
import { SELECT_COLORS } from "../constants/colors-maps.constants";

export const STATUS_COLORS: { [key in AdminModuleStatus]: string | null } = {
  PENDING: Colors.Error_300,
  ACTIVE: Colors.Success_500,
  PROLONGED: Colors.Success_500,
  CANCELED: Colors.Error_300,
  TERMINATED: Colors.Error_300
};

export const TAB_NAV_ITEMS: Array<TabNavBarItem> = [
  {
    label: "Nutzerverwaltung",
    name: "userManagement"
  },
  {
    label: "Vertragsverwaltung",
    name: "contractManagement"
  }
];

export const createColumnsConfig = (contractList: Array<{ id: string; key: string }>): Array<TableColumnConfig> => [
  {
    id: "name",
    key: "Nutzer",
    type: "text",
    style: {
      width: "200px"
    }
  },
  ...contractList.map(({ id, key }) => createContractColumnConfig(id, key)),
  {
    id: "email",
    key: "E-Mail",
    type: "text",
    style: {
      width: "200px"
    }
  },
  {
    id: "functionalRole",
    key: "Funktion",
    type: "text",
    style: {
      width: "200px"
    }
  },
  {
    id: "menuAction",
    key: "",
    type: "menu",
    sortDisabled: true,
    stickyEnd: true,
    style: {
      backgroundColor: Colors.White
    },
    options: [
      {
        value: "deleteUser",
        label: "Nutzer aus Liste löschen"
      }
    ]
  }
];

export const createContractColumnConfig = (id: string, key: string): SelectTableColumnConfig => ({
  id,
  key,
  type: "select",
  sortDisabled: true,
  options: [
    {
      value: "ACTIVE",
      label: "aktiv"
    },
    {
      value: "CANCELED",
      label: "inaktiv"
    }
  ],
  style: {
    width: "220px"
  },
  styleAccessor: (row) => ({
    color: SELECT_COLORS[row[id]?.status] ?? Colors.Grey_500,
    "--ene-color-grey-700": SELECT_COLORS[row[id]?.status] ?? Colors.Grey_500
  }),
  valueAccessor: (row: any) => row[id]?.status ?? "CANCELED",
  hideSelectAccessor: (row: any) => row[id]?.status === "PENDING",
  selectLabelAccessor: (row: any) => (row[id]?.status === "PENDING" ? "in Bearbeitung" : null)
});
