<app-button-toggle
  *ngIf="(tabName$ | async) === 'incident-management' && (divisions$ | async)?.length > 1"
  class="toggle-division"
  fullWidth
  mobileView
  size="small"
  [active]="activeDivision$ | async"
  [items]="divisions$ | async"
  (buttonChange)="changeDivision($event)"
></app-button-toggle>
<div class="settings">
  <div class="settings-header">
    <h3>{{ "NOTIFICATION_SERVICE.EDIT.TITLE" | translate }}</h3>
    <mat-icon (click)="onClose()" fontIcon="ec_building_delete_address" *ngIf="forMultiRegion"></mat-icon>
  </div>
  <app-notification-settings-edit
    *ngFor="let vm of vm$ | async"
    class="notification-settings__edit"
    [hidden]="vm.division && vm.division !== (activeDivision$ | async)"
    [vm]="vm"
    #form
  ></app-notification-settings-edit>
  <ng-container *ngIf="forMultiRegion">
    <div class="settings-form__info">
      {{ "NOTIFICATION_SERVICE.FOOTER_INFO" | translate }}
      <span class="link" (click)="openPolicy()">{{ "NOTIFICATION_SERVICE.OPEN_DATA_PROTECTION" | translate }}</span>
    </div>
    <div class="settings-form__footer">
      <button
        class="settings-form__footer-submit"
        mat-flat-button
        color="accent"
        (click)="onSubmit()"
        [disabled]="invalid"
      >
        {{ "NOTIFICATION_SERVICE.SAVE" | translate }}
      </button>
    </div>
  </ng-container>
</div>
