import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyListModule } from "@angular/material/legacy-list";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { ConfigAreaConfirmDialogComponent } from "./confirm.component";

const materialModules = [
  MatLegacyDialogModule,
  MatLegacyButtonModule,
  MatIconModule,
  MatLegacyCheckboxModule,
  MatLegacyInputModule,
  MatLegacyFormFieldModule,
  MatDividerModule,
  MatLegacyListModule
];

@NgModule({
  declarations: [ConfigAreaConfirmDialogComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, PipesModule, ...materialModules]
})
export class ConfigAreaConfirmDialogModule {}
