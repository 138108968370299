import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ColoredBadgeComponent } from "./colored-badge.component";

@NgModule({
  imports: [CommonModule],
  declarations: [ColoredBadgeComponent],
  exports: [ColoredBadgeComponent]
})
export class ColoredBadgeModule {}
