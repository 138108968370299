import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CrmModule } from "../enums/permissions";
import { AppConfig } from "../helpers/app.config";
import { UsersSettingsUsersStorageItem } from "../interfaces/users-settings";

@Injectable({ providedIn: "root" })
export class UserSettingsService {
  private readonly api = AppConfig.connection.infrastructure.userSettings;

  constructor(private readonly http: HttpClient) {}

  public getUsersStorage(): Observable<Array<UsersSettingsUsersStorageItem>> {
    return this.http.get<{ result: Array<UsersSettingsUsersStorageItem> }>(`${this.api}/users/storage`).pipe(
      map(({ result }) => result.map((item) => ({ ...item, value: JSON.parse(item.value as unknown as string) }))),
      catchError(() => of([]))
    );
  }

  public updateUsersStorage(items: Array<UsersSettingsUsersStorageItem>): Observable<any> {
    return this.http
      .put(
        `${this.api}/users/storage`,
        items.map(({ key, value }) => ({ key, value: JSON.stringify(value) }))
      )
      .pipe(catchError(() => of({})));
  }

  public getServiceModule(
    crmId: CrmModule,
    ags: string
  ): Observable<{
    regionExists: boolean;
    signedToken?: string;
  }> {
    return this.http
      .get<{ regionExists: boolean; signedToken: string }>(`${this.api}/plugin/${crmId}/${ags}`)
      .pipe(catchError(() => of({ regionExists: false })));
  }
}
