export interface ISelfSufficiencySummaryDto {
  production: number;
  consumption: number;
  gridFeed: number;
  ownUse: number;
  restNeed: number;
  selfSufficiencyDegree: number;
  selfSupplyDegree: number;
}

export class SelfSufficiencySummaryDto implements ISelfSufficiencySummaryDto {
  public production: number;
  public consumption: number;
  public gridFeed: number;
  public ownUse: number;
  public restNeed: number;
  public selfSufficiencyDegree: number;
  public selfSupplyDegree: number;

  constructor(data: ISelfSufficiencySummaryDto) {
    this.production = data.production;
    this.consumption = data.consumption;
    this.gridFeed = data.gridFeed;
    this.ownUse = data.ownUse;
    this.restNeed = data.restNeed;
    this.selfSufficiencyDegree = data.selfSufficiencyDegree;
    this.selfSupplyDegree = data.selfSupplyDegree;
  }
}
