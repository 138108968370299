import { Attribute, Component, ContentChildren, Input, OnInit, QueryList, ViewEncapsulation } from "@angular/core";
import { Alignment } from "../../enums/alignment";
import { KpiItemComponent } from "../kpi-item/kpi-item.component";

/* eslint-disable @angular-eslint/no-host-metadata-property */
@Component({
  selector: "kpi-group",
  templateUrl: "./kpi-group.component.html",
  styleUrls: ["./kpi-group.component.scss"],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "kpi-group",
    "[style.grid-template-columns]": "gridTemplateColumns"
  }
})
export class KpiGroupComponent implements OnInit {
  @Input()
  public get crest(): string {
    return this._crest;
  }
  public set crest(value: string) {
    if (value) {
      this.items.forEach((item) => {
        item.crest = value;
        item.detectChanges();
      });
    }
  }

  public gridTemplateColumns = "";

  @ContentChildren(KpiItemComponent, { descendants: true })
  public items!: QueryList<KpiItemComponent>;

  private _crest: string;

  constructor(@Attribute("alignment") public alignment: Alignment, @Attribute("columns") public columns: number) {}

  public ngOnInit(): void {
    if (this.alignment === Alignment.GRID) {
      this.setColumnsCount(this.columns);
    }
  }

  private setColumnsCount(value: number = 1): void {
    this.gridTemplateColumns = `repeat(${value}, 1fr)`;
  }
}
