export interface ISelfSufficiencyDegreeYearDto {
  selfSufficiencyDegree?: number;
}

export interface ISelfSufficiencyDegreePerYearDto extends ISelfSufficiencyDegreeYearDto {
  year: number;
}

export class SelfSufficiencyDegreeYearDto implements ISelfSufficiencyDegreeYearDto {
  public selfSufficiencyDegree: number;

  constructor(data: ISelfSufficiencyDegreeYearDto) {
    this.selfSufficiencyDegree = data.selfSufficiencyDegree;
  }
}

export class SelfSufficiencyDegreePerYearDto implements ISelfSufficiencyDegreePerYearDto {
  public selfSufficiencyDegree: number;
  public year: number;

  constructor(data: ISelfSufficiencyDegreePerYearDto) {
    this.selfSufficiencyDegree = data.selfSufficiencyDegree || null;
    this.year = data.year;
  }
}
