<tpm-contentful-wrapper
  *ngIf="vm$ | async as vm"
  [headline]="vm.headline | translate"
  (searchChange)="onSearchChange($event)"
  (moduleChange)="onModuleChange($event)"
  (markAllRead)="onMarkAllRead(vm.entries)"
>
  <div class="webinars-wrapper">
    <tpm-contentful-card
      *ngFor="let entry of vm.entries; trackBy: trackById"
      [isMobileView]="vm.isMobileView"
      [buttonLabel]="
        (entry.mediaType === 'pdf'
          ? 'CONTENTFUL.WEBINARS.CARD_BTN_LABEL_DOCUMENT'
          : 'CONTENTFUL.WEBINARS.CARD_BTN_LABEL_VIDEO'
        ) | translate
      "
      [data]="entry"
      (cardClick)="onCardClick(entry)"
    ></tpm-contentful-card>
  </div>
</tpm-contentful-wrapper>
