<ng-container *ngIf="!loading; else loadingRef">
  <ene-icon *ngIf="_config?.icon" [icon]="_config.icon" [style.color]="_iconColor"></ene-icon>
  <div class="kpi-item-content">
    <label
      class="kpi-item-label"
      tooltipIfTruncated
      [innerHTML]="_config?.title | translate"
      [matTooltipClass]="'dark-tooltip'"
      [matTooltip]="_config?.title | translate"
    ></label>
    <div class="kpi-item-inputs">
      <kpi-box [name]="name" [value]="value"></kpi-box>
      <div class="kpi-item-achieves">
        <img *ngIf="crest" class="kpi-item-crest" alt="Crest" [src]="crest" />
      </div>
    </div>
  </div>
  <kpi-badge *ngIf="badge" [badge]="badge"></kpi-badge>
</ng-container>
<ng-template #loadingRef>
  <app-skeleton-loader-box height="25px"></app-skeleton-loader-box>
  <app-skeleton-loader-box height="36px"></app-skeleton-loader-box>
</ng-template>
