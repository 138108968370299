<app-dialog-wrapper [title]="'REGIONS_SWITCHER.TITLE' | translate">
  <ngmy-table
    small
    class="regions-switcher-table"
    filterLabel="REGIONS_SWITCHER.FILTER_NAME"
    [columnsConfig]="columnsConfig"
    [pagingConfig]="{ pageSize: 10 }"
    [data]="vm$ | async"
    (tableAction)="onTableAction($event.row)"
  ></ngmy-table>
  <div actions>
    <span>{{ "REGIONS_SWITCHER.FOOTER.TEXT" | translate }}</span>
    <span class="link" (click)="openContactModal()">
      {{ "REGIONS_SWITCHER.FOOTER.LINK" | translate }}
    </span>
  </div>
</app-dialog-wrapper>
