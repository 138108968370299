import { Injectable } from "@angular/core";
import { crmFormStructures, crmInternalFormId } from "../constants/crm-form-marketing-structure";
import { AppConfig } from "../helpers/app.config";

const scripts = [
  "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007",
  "https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007"
];

/**
 * Will interacts with the dynamics 365 crm.
 * Dynamics365 scripts will check by 'data-website-id' and 'data-hostname'
 * if this page is allowed and set on their system.
 * If so it will fill the hidden form blocks by the specifics 'data-form-block-id'.
 *
 */
@Injectable({ providedIn: "root" })
export class MarketingCrmService {
  private containerEl: HTMLDivElement;

  constructor() {}
  public attachFormTracker(): void {
    if (!AppConfig.connection.marketingCrm) {
      return;
    }

    this.activate();
  }

  /**
   * Will fill the form of the formBlockId and send the data to the crm.
   */
  public submitFormData(
    internalFormId: crmInternalFormId,
    data: Array<{ internalId: string; value: string | number }>
  ): void {
    const formStructure = crmFormStructures.find((form) => form.internalFormId === internalFormId);
    if (!formStructure) {
      return console.error(`cant find crm form structure config of ${internalFormId}`);
    }

    const formBlockId = this.getFormBlockId(internalFormId);
    const formBlockEl: HTMLDivElement = document.querySelector(`[data-form-block-id="${formBlockId}"]`);
    if (!formBlockEl) {
      return console.error(`cant find crm html-form element of ${internalFormId}`);
    }

    const formEntryElements = formBlockEl.querySelectorAll("[data-editorblocktype]");

    // set values of input fields
    data.forEach((dataEntry) => {
      const formStructureEntry = formStructure.entries[dataEntry.internalId];
      if (!formStructureEntry) {
        return;
      }

      const formEntryElement = formEntryElements.item(formStructureEntry.index);
      if (!formEntryElement) {
        return console.error(`cant find crm form-element ${dataEntry.internalId}`);
      }

      switch (formStructureEntry.type) {
        case "text": {
          formEntryElement.querySelector("input").value = (dataEntry.value ?? "").toString();
          break;
        }
        case "textarea": {
          formEntryElement.querySelector("textarea").value = (dataEntry.value ?? "").toString();
          break;
        }
        case "radio": {
          const optionIndex = formStructureEntry.options.indexOf(dataEntry.value as string);
          if (optionIndex < 0) {
            return;
          }
          const optionEntryElements = formEntryElement.querySelectorAll("input");
          optionEntryElements.forEach((element, index) => (element.checked = index === optionIndex));
          break;
        }
      }
    });

    // send by submit
    const submitButton = formBlockEl.querySelector("button[type=submit]") as HTMLButtonElement;
    if (!submitButton) {
      return console.error(`cant find crm submit-button of ${internalFormId}`);
    }
    submitButton.click();

    // reload form by clicking the feedback form button
    const feedbackInterval = setInterval(() => {
      const feedbackButton = formBlockEl.querySelector("button.onFormSubmittedFeedbackButton") as HTMLButtonElement;
      if (feedbackButton) {
        clearInterval(feedbackInterval);
        feedbackButton.click();
      }
    }, 200);
    setTimeout(() => clearInterval(feedbackInterval), 5000);
  }

  private activate() {
    if (this.containerEl) {
      return;
    }

    //create container
    this.containerEl = document.createElement("div");
    this.containerEl.id = "marketing-crm-container";
    this.containerEl.style.display = "none";
    document.body.appendChild(this.containerEl);

    // create crm config block
    const divEl: HTMLDivElement = document.createElement("div");
    divEl.classList.add("d365-mkt-config");
    divEl.setAttribute("data-website-id", AppConfig.connection.marketingCrm.websiteId);
    divEl.setAttribute("data-hostname", AppConfig.connection.marketingCrm.hostname);
    this.containerEl.appendChild(divEl);

    // create hidden form blocks
    crmFormStructures.forEach(({ internalFormId }) => {
      const formBlockId = this.getFormBlockId(internalFormId);
      const divFormEl: HTMLDivElement = document.createElement("div");
      divFormEl.setAttribute("data-form-block-id", formBlockId);
      this.containerEl.appendChild(divFormEl);
    });

    // add scripts
    scripts.forEach((script) => {
      const scriptEl: HTMLScriptElement = document.createElement("script");
      scriptEl.src = script;
      this.containerEl.appendChild(scriptEl);
    });

    // disable non-essential Dynamics 365 Marketing cookies
    window["d365mktConfigureTracking"] = () => ({ Anonymize: true });
  }

  private getFormBlockId(internalFormId: crmInternalFormId): string {
    return AppConfig.connection.marketingCrm.formIds[internalFormId];
  }
}
