<table-filter
  *ngIf="!filterDisabled"
  [filterLabel]="filterLabel | translate"
  (filterChanged)="handleFilterEvent($event)"
></table-filter>
<ng-template #loadingTpl>
  <app-skeleton-loader-box height="25px"></app-skeleton-loader-box>
</ng-template>
<div class="table-wrapper" [class.scrollable-x]="scrollableX" [class.scrollable-y]="scrollableY">
  <mat-table
    matSort
    [matSortDisabled]="_sortDisabled"
    [matSortDirection]="sortDirection"
    [matSortActive]="sortActive"
    [dataSource]="dataSource"
  >
    <!-- Checkboxes / Select (optional column) -->
    <ng-container matColumnDef="select">
      <mat-header-cell class="select-cell" *matHeaderCellDef>
        <mat-checkbox
          [disableRipple]="true"
          [disabled]="disableCheckbox"
          [checked]="selections.hasValue() && isAllSelected()"
          [indeterminate]="selections.hasValue() && !isAllSelected()"
          (change)="masterToggle()"
        ></mat-checkbox>
      </mat-header-cell>
      <mat-cell class="select-cell" *matCellDef="let row">
        <mat-checkbox
          *ngIf="(loading$ | async) === false; else loadingTpl"
          [disableRipple]="true"
          [disabled]="disableCheckbox"
          [checked]="selections.isSelected(row.regionId)"
          (click)="$event.stopPropagation()"
          (change)="selections.toggle(row.regionId)"
        ></mat-checkbox>
      </mat-cell>
    </ng-container>
    <!-- List of columns configs -->
    <ng-container
      *ngFor="let column of _columnsConfigs; trackBy: trackById"
      [matColumnDef]="column.id"
      [sticky]="column.sticky"
      [stickyEnd]="column.stickyEnd"
    >
      <!-- Header cells (optional) -->
      <ng-container *ngIf="!headerHidden">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          [disabled]="isSortDisabled(_sortDisabled, column.type)"
          [ngClass]="columnsStyles[column.id]"
          [ngStyle]="column.style"
        >
          <span
            *ngIf="(loading$ | async) === false; else loadingTpl"
            class="textual-cell"
            tooltipIfTruncated
            [matTooltipClass]="'light-tooltip'"
            [matTooltip]="column.key | translate"
            [innerHTML]="column.key | translate | trustHtml"
          ></span>
        </mat-header-cell>
      </ng-container>
      <mat-cell *matCellDef="let row" [ngClass]="columnsStyles[column.id]" [ngStyle]="column.style">
        <app-table-cell
          *ngIf="isComponentTemplate(column.type); else transformedTemplate"
          [ngClass]="columnsStyles[column.id]"
          [column]="column"
          [row]="row"
          [loading]="loading$ | async"
          (cellClicked)="cellClicked($event)"
        ></app-table-cell>
        <ng-template #transformedTemplate>
          <span
            *ngIf="(loading$ | async) === false; else loadingTpl"
            class="textual-cell"
            tooltipIfTruncated
            [matTooltipClass]="'light-tooltip'"
            [matTooltip]="row[column.id] | translate"
          >
            {{ row[column.id] | tableTemplateTransform: column }}
          </span>
        </ng-template>
      </mat-cell>
      <!-- Footer cells (optional) -->
      <mat-footer-cell
        *matFooterCellDef
        [ngClass]="columnsStyles[column.id]"
        [ngStyle]="column.style"
        [ngSwitch]="column.footer?.type"
      >
        <ng-container *ngSwitchCase="'text'">
          <span class="textual-cell">
            {{ column.footer.key | translate }}
          </span>
        </ng-container>
        <ng-container *ngSwitchCase="'value'">
          <span class="textual-cell">
            {{ getSum(dataSource.filteredData, column.id) | tableTemplateTransform: column }}
          </span>
        </ng-container>
      </mat-footer-cell>
    </ng-container>
    <ng-container *ngIf="!headerHidden">
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    </ng-container>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.highlighted]="highlightedRowKeys && hasHighlightedRows(row)"
      [class.selected]="
        _entrySelector?.propertyName && row[_entrySelector.propertyName] === _entrySelector.propertyValue
      "
    ></mat-row>

    <mat-footer-row [class.empty-footer]="footerHidden" *matFooterRowDef="footerColumns"></mat-footer-row>
  </mat-table>
</div>
<app-table-paginator *ngIf="_pagingConfig" [config]="_pagingConfig"></app-table-paginator>
