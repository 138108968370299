import { createAction, props } from "@ngrx/store";
import { SelfSufficiencyProductionDto } from "../../../../common/dto/energy/self-sufficiency/production";

export const getSelfSufficiencyProductionData = createAction(
  "[SelfSufficiency Production] Get production data",
  props<{
    filters: Array<string>;
    scenarioId: string;
    regionId: string;
    fromUTC: number;
    toUTC: number;
  }>()
);

export const getSelfSufficiencyProductionDataSuccess = createAction(
  "[SelfSufficiency Production] Get production success",
  props<{
    payload: SelfSufficiencyProductionDto;
  }>()
);
