/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @angular-eslint/component-selector */
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Host,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  TrackByFunction,
  ViewChild,
  forwardRef
} from "@angular/core";
import { MatLegacyTable } from "@angular/material/legacy-table";
import { MatSort } from "@angular/material/sort";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subscription, asyncScheduler, combineLatest } from "rxjs";
import { map, observeOn, tap, withLatestFrom } from "rxjs/operators";
import { PaginatorComponent, PaginatorDefaultOptions } from "src/app/common/components/paginator/paginator.component";
import { Pure } from "src/app/common/decorators/pure";
import { TableDataSource } from "../classes/table-data-source";
import { TablePagingService } from "../classes/table-paging-service";
import { TableAction, TableColumnConfig, TableLoadingConfig, TableMultiRowColumnConfig } from "../table.interfaces";
import { TABLE_LOADING_CONFIG, TABLE_PAGING_SERVICE } from "../table.tokens";

@Component({
  selector: "ngmy-table",
  templateUrl: "./table.component.html",
  styleUrls: ["../styles/table.scss", "../styles/theme.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: "ngmy-table"
  }
})
export class TableComponent<T extends Record<string, any>> implements AfterViewInit, OnDestroy {
  @ViewChild(MatLegacyTable, { static: true }) public readonly matTable!: MatLegacyTable<T>;
  @ViewChild(MatSort, { static: true }) public readonly sortRef!: MatSort;

  @ViewChild(forwardRef(() => PaginatorComponent))
  public set paginator(cmp: PaginatorComponent) {
    this._paginator = cmp;
    this.paginatorRefChange.next(!!cmp);
  }
  public get paginator(): PaginatorComponent | undefined {
    return this._paginator;
  }
  private _paginator: PaginatorComponent | undefined;

  @Input() public multiRowConfig: TableMultiRowColumnConfig | undefined;

  @Input("hideHeader") public headerHidden: boolean = false;
  @Input("hideFilter") public filterHidden: boolean = false;

  @Input() public noDataLabel: string = "APP.NO_DATA";

  @Input("filterLabel") public filterLabel: string = "TOOLTIP.table.filterPlaceholder";
  @Input()
  public set filterValue(value: string) {
    this._filterValue = value;
    this.onFilterChange(value);
  }
  private _filterValue: string = "";

  @Input() public fixedLayout: boolean = false;
  @Input() public scrollableX: boolean = false;
  @Input() public scrollableY: boolean = false;

  @Input() public rowClass: string = "base-row";

  @Input() public highlightRowAccessor: (row: T, name?: string) => boolean = () => false;
  @Input() public highlightRowClass: (row: T, name?: string) => string = () => "highlighted-new";

  @Input() public sortActive: string = "";
  @Input() public sortDirection: "desc" | "asc" = "desc";
  @Input("disableSort")
  public set sortDisabled(disabled: boolean) {
    this._sortDisabled = disabled;
    this.sortRefChange.next(disabled);
  }
  public _sortDisabled: boolean = true;

  @Input()
  public set pagingConfig(config: PaginatorDefaultOptions) {
    this._pagingConfig = config;
  }
  public _pagingConfig: PaginatorDefaultOptions | undefined;

  @Input()
  public set columnsConfig(config: Array<TableColumnConfig>) {
    this._columnsConfig = config;

    this.columnsToDisplay = config.map(({ id }) => id);
    this.footerHidden = !config.find(({ footer }) => !!footer);
    this.dataSource.columnsConfig = config;

    if (this._selections) {
      this.columnsToDisplay = ["select", ...this.columnsToDisplay];
    }
  }
  public _columnsConfig: Array<TableColumnConfig> = [];

  @Input()
  public set loading(loading: boolean) {
    this.loadingChange.next({ ...this._loadingConfig, loading });
    this._loading = loading;
  }
  public _loading: boolean;

  @Input()
  public set height(_height: number) {
    this._height = _height;
  }
  public _height: number | null = null;

  @Input()
  public set data(data: Array<T> | undefined) {
    this.dataChange.next(data);
    this._data = data;
  }
  public _data: Array<T> | undefined;

  @Input()
  public set selections(_selections: boolean) {
    this._selections = coerceBooleanProperty(_selections);
  }
  public _selections: boolean = false;

  @Input()
  public set selectionDisabled(_selectionDisabled: boolean) {
    this._selectionDisabled = coerceBooleanProperty(_selectionDisabled);
  }
  public _selectionDisabled: boolean = false;

  @Input() public disableSelectionsAccessor: (row: any) => boolean = () => this._selectionDisabled;

  @Input()
  public set selected(value: number | string | undefined) {
    this.selectedChange.next(value);
  }

  @Input()
  public readonly selectionsCollection: SelectionModel<T> = new SelectionModel<T>(true, []);

  @Output() public readonly tableAction = new EventEmitter<TableAction<T>>();

  public footerHidden: boolean = true;
  public columnsToDisplay: Array<string> = [];
  public dataSource: TableDataSource<T>;

  public get attrColspan(): number {
    return this.columnsToDisplay.length || 1;
  }

  private readonly paginatorRefChange = new BehaviorSubject<boolean>(false);
  private readonly sortRefChange = new BehaviorSubject<boolean>(false);
  private readonly dataChange = new BehaviorSubject<Array<T> | undefined>([]);
  private readonly loadingChange = new BehaviorSubject<TableLoadingConfig | undefined>(this._loadingConfig);
  private readonly selectedChange = new BehaviorSubject<number | string | undefined>(undefined);

  private raceConditionSubscription!: Subscription;

  constructor(
    @Optional()
    @Host()
    @Inject(TABLE_PAGING_SERVICE)
    private readonly pagingService: TablePagingService<T>,
    @Inject(TABLE_LOADING_CONFIG)
    private readonly _loadingConfig: TableLoadingConfig,
    private readonly translate: TranslateService
  ) {
    this.dataSource = new TableDataSource<T>([], this.translate);
  }

  @Pure
  public isSticky(columnsConfig: Array<TableColumnConfig>): boolean {
    return !!columnsConfig.find(({ sticky, stickyEnd }) => sticky || stickyEnd);
  }

  @Pure
  public getHighlightClass(row: T, name?: string): string {
    if (this.isHighlighted(row, name)) {
      return this.highlightRowClass(row, name);
    }
    return "";
  }

  public readonly trackById: TrackByFunction<TableColumnConfig> = (_: number, item: TableColumnConfig): string =>
    item.id;

  public ngAfterViewInit(): void {
    this.raceConditionSubscription = combineLatest([
      this.getDataSource(),
      this.selectedChange,
      this.getSorting(),
      this.getPagining()
    ])
      .pipe(observeOn(asyncScheduler))
      .subscribe(([data, selected]) => {
        this.dataSource.data = data || [];
        this.dataSource.filter = this._loading ? "" : this._filterValue;
        this.dataSource.setSelected(selected);

        if (this.dataSource.selected !== undefined) {
          this.tableAction.emit({ id: selected, type: "click", row: this.dataSource.selected });
        }
      });
  }

  public ngOnDestroy(): void {
    this.raceConditionSubscription?.unsubscribe();
  }

  public onFilterChange(filter: string): void {
    if (this.pagingService) {
      this.pagingService.setFilter(filter);
    } else {
      this.dataSource.filter = filter;
    }
  }

  protected getSorting(): Observable<boolean> {
    return this.sortRefChange.pipe(
      tap((disabled) => {
        if (this.pagingService) {
          this.pagingService.setSortRef(this.sortRef);
        } else if (!disabled) {
          this.dataSource.sort = this.sortRef;
        }
      })
    );
  }

  protected getPagining(): Observable<boolean> {
    return this.paginatorRefChange.asObservable().pipe(
      tap((show) => {
        if (show) {
          if (this.pagingService) {
            this.pagingService.setPagingRef(this.paginator);
          } else {
            this.dataSource.paginator = this.paginator;
          }
        }
      })
    );
  }

  private getPagingData(): Observable<Array<T>> {
    return this.pagingService.loadData().pipe(
      tap(({ total }) => {
        if (this.paginator) {
          this.paginator.length = total;
        }
      }),
      map(({ data }) => data)
    );
  }

  private getDataSource(): Observable<any> {
    const dataSource = this.pagingService ? this.getPagingData() : this.dataChange;

    return dataSource.pipe(
      withLatestFrom(this.loadingChange.asObservable()),
      map(([data, { loading, size }]) => (loading ? Array(size).fill({}) : data))
    );
  }

  private isHighlighted(row: T, name?: string): boolean {
    return !this.loading && this.highlightRowAccessor(row, name);
  }
}
