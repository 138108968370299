import { createAction, props } from "@ngrx/store";
import { SelfSufficiencySummaryDto } from "../../../../common/dto/energy/self-sufficiency/summary";

export const getSelfSufficiencySummaryData = createAction(
  "[SelfSufficiency Summary] Get summary data",
  props<{
    regionId: string;
    scenarioId: string;
    fromUTC: number;
    toUTC: number;
    filters: Array<string>;
  }>()
);

export const getSelfSufficiencySummaryDataSuccess = createAction(
  "[SelfSufficiency Summary] Get summary data success",
  props<{
    payload: SelfSufficiencySummaryDto;
  }>()
);
