<div *ngIf="vm$ | async as vm" class="contentful-overlay">
  <div class="contentful-overlay__headline headline-1">{{ headline }}</div>
  <tpm-contentful-wrapper-navigation
    [isMobileView]="vm.isMobileView"
    [contentType]="contentType"
    [contentTypeToggles]="contentTypeToggles"
    [moduleSelectOptions]="vm.moduleSelectOptions"
    (searchChange)="searchChange.emit($event)"
    (moduleChange)="moduleChange.emit($event)"
    (contentTypeChange)="contentTypeChange.emit($event)"
    (markAllRead)="markAllRead.emit()"
  ></tpm-contentful-wrapper-navigation>
  <div class="contentful-overlay__content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
