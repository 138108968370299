import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewChild
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { GpTextFieldElement } from "@enersis/gp-components/gp-textfield";
import { FormFieldControl } from "../form-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-textfield",
  templateUrl: "./textfield.component.html",
  styleUrls: ["./textfield.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextfieldComponent extends FormFieldControl {
  @ViewChild("textfield", { static: true })
  public readonly textfieldRef!: ElementRef<GpTextFieldElement>;

  @Input() public icon: string = "";
  @Input() public iconTrailing: string = "";
  @Input() public placeholder: string = "";
  @Input() public helper: string = "";
  @Input() public maxLength: number = null;
  @Input() public appearance = "default";

  @Input()
  public set value(_value: string) {
    this._value = _value;
    // issue with updating value with reactive form with regular binding
    this.textfieldRef.nativeElement.value = _value;
  }

  constructor(
    @Self()
    @Optional()
    protected readonly ngControl: NgControl,
    protected readonly cdr: ChangeDetectorRef,
    @Attribute("outlined")
    @Optional()
    public readonly outlined: boolean,
    @Attribute("small")
    @Optional()
    public readonly small: boolean,
    @Attribute("medium")
    @Optional()
    public readonly medium: boolean,
    @Attribute("large")
    @Optional()
    public readonly large: boolean,
    @Attribute("xlarge")
    @Optional()
    public readonly xlarge: boolean
  ) {
    super(ngControl, cdr);

    this.outlined = coerceBooleanProperty(this.outlined);
    this.small = coerceBooleanProperty(this.small);
    this.medium = coerceBooleanProperty(this.medium);
    this.xlarge = coerceBooleanProperty(this.xlarge);
    this.large = coerceBooleanProperty(this.large) || (!this.small && !this.large && !this.medium && !this.xlarge);
  }

  public onInputChange(event: InputEvent): void {
    event.stopPropagation();

    this.changed.emit((event.target as any).value);
    this._onChange((event.target as any).value);
  }

  public onKeyDown(event: any): boolean {
    return this.maxLength !== null ? (event.target.value || "").length <= this.maxLength : true;
  }
}
