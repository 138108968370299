export interface ISelfSufficiencyConsumptionDto {
  timestampsUnix: Array<number>;
  consumption: Array<number>;
}

export class SelfSufficiencyConsumptionDto implements ISelfSufficiencyConsumptionDto {
  public timestampsUnix: Array<number>;
  public consumption: Array<number>;

  constructor(data: ISelfSufficiencyConsumptionDto) {
    this.timestampsUnix = data.timestampsUnix;
    this.consumption = data.consumption;
  }
}
