import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Colors } from "src/styles/themes/colors";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-coin",
  templateUrl: "./coin.component.html",
  styleUrls: ["./coin.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoinComponent {
  @Input() public text: string;

  @Input()
  public set backgroundColor(_backgroundColor: string) {
    if (_backgroundColor) {
      this._backgroundColor = _backgroundColor.includes("-color-") ? `var(${_backgroundColor})` : _backgroundColor;
    }
  }
  public _backgroundColor: string = "#84C041";

  @Input()
  public set color(_color: string) {
    if (_color) {
      this._color = _color.includes("-color-") ? `var(${_color})` : _color;
    }
  }
  public _color: string = Colors.White;
}
