export interface ICo2SavingGas {
  co2Saving: number;
  co2SavingPotentialThroughCompaction: number;
}

export interface ICo2SavingElectricity {
  co2Saving: number;
}

export interface ICo2SavingYearDto {
  electricity: ICo2SavingElectricity;
  gas: ICo2SavingGas;
}

export interface ICo2SavingPerYearDto extends ICo2SavingYearDto {
  year: number;
}

export class Co2SavingYearDto implements ICo2SavingYearDto {
  public electricity: ICo2SavingElectricity;
  public gas: ICo2SavingGas;

  constructor(data: ICo2SavingYearDto) {
    this.electricity = data.electricity;
    this.gas = data.gas;
  }
}

export class Co2SavingPerYearDto implements ICo2SavingPerYearDto {
  public year: number;
  public electricity: ICo2SavingElectricity;
  public gas: ICo2SavingGas;

  constructor(data: ICo2SavingPerYearDto) {
    this.year = data.year;
    this.electricity = data.electricity;
    this.gas = data.gas;
  }
}
