import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { UserSettingsService } from "src/app/core/services/users-settings.service";
import {
  fetchUsersSettingsUsersStorage,
  setUsersSettingsUsersStorage,
  updateUsersSettingsUsersStorage
} from "../actions/users-settings.actions";

@Injectable()
export class UsersSettingsEffects {
  fetchUsersSettingsUsersStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUsersSettingsUsersStorage),
      switchMap(() =>
        this.userSettingsService.getUsersStorage().pipe(map((result) => setUsersSettingsUsersStorage({ result })))
      )
    )
  );

  updateUsersSettingsUsersStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUsersSettingsUsersStorage),
      switchMap(({ items }) =>
        this.userSettingsService.updateUsersStorage(items).pipe(map(() => fetchUsersSettingsUsersStorage()))
      )
    )
  );

  constructor(private readonly actions$: Actions, private readonly userSettingsService: UserSettingsService) {}
}
