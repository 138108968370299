import { InjectionToken } from "@angular/core";
import { IKpiItemMap } from "../interfaces/kpi-item";

export const KPI_ITEM_MAP = new InjectionToken<IKpiItemMap>("KPI_ITEM_MAP");

export const kpiItemMap: IKpiItemMap = {
  placeholder: {
    id: "placeholder",
    icon: "ec_forbidden",
    title: "TDI.NOT_AVAILABLE.LONG"
  }
};
