import { MatLegacyPaginatorIntl } from "@angular/material/legacy-paginator";
import { TranslateService } from "@ngx-translate/core";

export class PaginatorIntl extends MatLegacyPaginatorIntl {
  readonly pageSizeAllOption: string;
  readonly ofLabel: string;

  constructor(private readonly translate: TranslateService) {
    super();

    this.pageSizeAllOption = this.translate.instant("APP.PAGE_SIZE_ALL_OPTION");
    this.ofLabel = this.translate.instant("APP.OF");

    this.itemsPerPageLabel = this.translate.instant("APP.ITEMS_PER_PAGE");
    this.firstPageLabel = this.translate.instant("APP.FIRST_PAGE");
    this.previousPageLabel = this.translate.instant("APP.PREV_PAGE");
    this.nextPageLabel = this.translate.instant("APP.NEXT_PAGE");
    this.lastPageLabel = this.translate.instant("APP.LAST_PAGE");
  }

  public getRangeLabel = (page: number, pageSize: number, length: number) =>
    `${page * pageSize + 1}\xa0-\xa0${Math.min((page + 1) * pageSize, length)}\xa0${this.ofLabel}\xa0${length}`;
}
