<tpm-card
  class="tile-card"
  [variant]="'elevation-basic-20'"
  (click)="props.active && tileClicked.emit(props.name); $event.stopPropagation()"
>
  <div class="tile-wrapper" [class.title-wrapper--disabled]="props.active === false">
    <div *ngIf="isMobile === false" class="tile-top">
      <div class="module-icon">
        <img class="image" [src]="props.icon" alt="Icon" />
        <tpm-icon *ngIf="props.active === false" class="lock-icon" [icon]="'gpi-filled-lock'"></tpm-icon>
      </div>
      <div class="logo">
        <img [src]="props.logo" alt="Logo" />
      </div>
    </div>
    <div class="tile-body">
      <p class="headline-2 title">{{ props.title }}</p>
      <div #ref>
        <ng-content></ng-content>
      </div>
      <div *ngIf="!ref.children.length" class="info-container">
        <div class="description-container">
          <p class="body-1 description">{{ props.description }}</p>
          <button
            *ngIf="props.overlay === 'regulated' && props.showStoryTellerButton === true"
            class="story-teller-button"
            ene-button
            [disabled]="props.active === false"
            [icon]="'ec_speechbubble-1'"
            [size]="'xlarge'"
            (click)="props.active && storyTellerClicked.emit(props.name); $event.stopPropagation()"
          ></button>
        </div>
        <button
          *ngIf="props.showLeadButton === true"
          class="lead-button"
          ene-filled-button
          (click)="leadClicked.emit(props.name); $event.stopPropagation()"
        >
          {{ "APP.INFORM_NOW" | translate }}
        </button>
        <button
          *ngIf="props.showProductManagementButton === true"
          class="product-management-button"
          ene-outline-button
          (click)="productManagementClicked.emit(props.name); $event.stopPropagation()"
        >
          {{ "NUTZER VERWALTEN" | translate }}
        </button>
        <div *ngIf="props.showInProgress" class="body-1 in-progress-status">In Kürze verfügbar</div>
      </div>
    </div>
  </div>
</tpm-card>
