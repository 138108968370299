export const createDivisionTemplateString = ({
  hasElectricity,
  hasGas
}: {
  hasElectricity: boolean;
  hasGas: boolean;
}): string => {
  switch (true) {
    case Boolean(hasElectricity && hasGas): {
      return "electricity_gas";
    }
    case Boolean(hasElectricity): {
      return "electricity";
    }
    case Boolean(hasGas): {
      return "gas";
    }
  }
};
