import { AsyncPipe, NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TrackByFunction } from "@angular/core";
import { IN_OUT_LIST_ANIMATION } from "src/app/core/animations/list-animation";
import { LaunchpadNewsCardProps, LaunchpadTileConfig } from "../../container/launchpad.interfaces";
import { LinksCardComponent } from "../links-card/links-card.component";
import { NewsCardComponent } from "../news-card/news-card.component";
import { TileComponent } from "../tile/tile.component";

@Component({
  selector: "tpm-tiles-grid",
  templateUrl: "./tiles-grid.component.html",
  styleUrls: ["./tiles-grid.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgFor, NgIf, TileComponent, NewsCardComponent, LinksCardComponent],
  animations: [IN_OUT_LIST_ANIMATION]
})
export class TilesGridComponent {
  @Output() public readonly newsCardClicked = new EventEmitter<LaunchpadTileConfig>();
  @Output() public readonly tileClicked = new EventEmitter<LaunchpadTileConfig>();
  @Output() public readonly leadClicked = new EventEmitter<LaunchpadTileConfig>();
  @Output() public readonly productManagementClicked = new EventEmitter<LaunchpadTileConfig>();
  @Output() public readonly storyTellerClicked = new EventEmitter<LaunchpadTileConfig>();
  @Output() public readonly newsClicked = new EventEmitter<void>();
  @Output() public readonly subscriptionClicked = new EventEmitter<void>();
  
  @Input() public isMobile: boolean | undefined;
  @Input() public list: Array<LaunchpadTileConfig> | undefined;
  @Input() public news: Record<string | `module-${number}`, LaunchpadNewsCardProps> | undefined;

  public readonly trackByName: TrackByFunction<LaunchpadTileConfig> = (_, tile) => tile.name;
}
