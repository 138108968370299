import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormFieldControl } from "../form-field-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-textarea-field",
  templateUrl: "./textarea-field.component.html",
  styleUrls: ["./textarea-field.component.scss", "../form-field.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaFieldComponent extends FormFieldControl {
  @Input() public maxlength: number = 255;
  @Input() public minRows: number = 3;
  @Input() public maxRows: number = 3;
  @Input() public placeholder: string | null = null;
  @Input() public name: string | null = null;
}
