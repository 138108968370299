import { Component, inject } from "@angular/core";
import { RouterModule } from "@angular/router";
import "@enersis/gp-components/gp-icon";
import { IconModule } from "src/app/common/components/icon/icon.module";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { DualRolesService, PNG_URL, PortalChoice } from "src/app/core/services/dual-roles.service";

@Component({
  selector: "app-dual-roles-portal",
  templateUrl: "./dual-roles-portal.component.html",
  styleUrls: ["./dual-roles-portal.component.scss"],
  imports: [RouterModule, IconModule],
  standalone: true
})
export class DualRolesPortalComponent {
  public PNG_URL = PNG_URL;
  private readonly analytics: AnalyticsService = inject(AnalyticsService);
  private readonly dualRolesService: DualRolesService = inject(DualRolesService);

  rememberChoice(choice: PortalChoice): void {
    this.analytics.trackEvent({
      category: "Pachtnetzgesellschaften",
      action: "Auswahldialog Doppelrolle",
      name: choice === "png" ? "Pachtnetz-Verwaltung" : "Kommunen-Verwaltung"
    });
    this.dualRolesService.setPortalChoiceCookie(choice);
  }
}
