import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { combineLatest } from "rxjs";
import { startWith } from "rxjs/operators";

export function disabledIfNotTrue(formControlName: string, listenStatus: boolean = false): ValidatorFn {
  let subscribtion;
  return (control: AbstractControl): ValidationErrors | null => {
    if (subscribtion) {
      return null;
    }

    let _control = control;
    let formControl: AbstractControl;

    while (_control) {
      formControl = _control.parent?.get(formControlName);
      if (formControl) {
        break;
      }
      _control = _control.parent;
    }

    if (!formControl) {
      return null;
    }

    const observables = [formControl.valueChanges];
    if (listenStatus) {
      observables.push(formControl.statusChanges);
    }

    subscribtion = combineLatest(observables)
      .pipe(startWith(formControl.value))
      .subscribe(() => {
        if ((!formControl.value || formControl.invalid) && control.enabled) {
          control.disable({ emitEvent: false });
          control.patchValue(null);
        } else if (formControl.value && !formControl.invalid && control.disabled) {
          control.enable({ emitEvent: false });
        }
      });

    return null;
  };
}
