import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { acceptJsonWithoutCache } from "../../common/constants/common";
import {
  ISelfSufficiencyConsumptionDto,
  SelfSufficiencyConsumptionDto
} from "../../common/dto/energy/self-sufficiency/consumption";
import {
  ISelfSufficiencyDegreeYearDto,
  SelfSufficiencyDegreePerYearDto,
  SelfSufficiencyDegreeYearDto
} from "../../common/dto/energy/self-sufficiency/degree";
import {
  ISelfSufficiencyProductionDto,
  SelfSufficiencyProductionDto
} from "../../common/dto/energy/self-sufficiency/production";
import {
  ISelfSufficiencySummaryDto,
  SelfSufficiencySummaryDto
} from "../../common/dto/energy/self-sufficiency/summary";
import { AppConfig } from "../helpers/app.config";

interface IRequestsParams {
  regionId?: string;
  year?: number;
  scenarioId?: string;
  fromUTC?: number;
  toUTC?: number;
  filters?: Array<string>;
  timelineRange?: [number, number];
}

@Injectable({ providedIn: "root" })
export class SelfSufficiencyService {
  protected readonly publicSectorApi = AppConfig.connection.energy.publicSectorApi;

  constructor(private readonly http: HttpClient) {}

  public getDegreeData({ regionId, year, scenarioId }: IRequestsParams): Observable<SelfSufficiencyDegreeYearDto> {
    return this.http
      .get<{ result: ISelfSufficiencyDegreeYearDto }>(
        `${this.publicSectorApi}/regions/${regionId}/electricity/self-sufficiency/summary`,
        {
          headers: new HttpHeaders(acceptJsonWithoutCache),
          params: new HttpParams().set("year", String(year)).set("scenarioId", scenarioId)
        }
      )
      .pipe(
        map(({ result }) => new SelfSufficiencyDegreeYearDto(result)),
        catchError(() => of({} as SelfSufficiencyDegreeYearDto))
      );
  }

  public getDegreeAllYearsData({
    regionId,
    timelineRange: [from, to],
    scenarioId
  }: IRequestsParams): Observable<Array<ISelfSufficiencyDegreeYearDto>> {
    const uri = `${this.publicSectorApi}/regions/${regionId}/electricity/self-sufficiency/summary`;

    return this.http
      .get<{ result: Array<SelfSufficiencyDegreePerYearDto> }>(uri, {
        params: new HttpParams().set("scenarioId", scenarioId).set("timeRange", `${from},${to}`)
      })
      .pipe(
        map(({ result }) => result.map((item) => new SelfSufficiencyDegreePerYearDto(item))),
        catchError(() => of([]))
      );
  }

  public getConsumptionData({
    regionId,
    scenarioId,
    fromUTC,
    toUTC
  }: IRequestsParams): Observable<SelfSufficiencyConsumptionDto> {
    const uri = `${this.publicSectorApi}/regions/${regionId}/electricity/self-sufficiency/consumption/series`;

    return this.http
      .get<{ result: ISelfSufficiencyConsumptionDto }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: new HttpParams()
          .set("scenarioId", scenarioId)
          .set("fromUTC", String(fromUTC))
          .set("toUTC", String(toUTC))
      })
      .pipe(
        map((response) => new SelfSufficiencyConsumptionDto(response.result)),
        catchError(() => of({} as SelfSufficiencyConsumptionDto))
      );
  }

  public getProductionData({ regionId, ...params }: IRequestsParams): Observable<SelfSufficiencyProductionDto> {
    const uri = `${this.publicSectorApi}/regions/${regionId}/electricity/self-sufficiency/production/series`;

    return this.http
      .get<{ result: ISelfSufficiencyProductionDto }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: this.createParamsAndAppendFilters(params)
      })
      .pipe(
        map((response) => new SelfSufficiencyProductionDto(response.result)),
        catchError(() => of({} as SelfSufficiencyProductionDto))
      );
  }

  public getSummaryData({ regionId, ...params }: IRequestsParams): Observable<SelfSufficiencySummaryDto> {
    const uri = `${this.publicSectorApi}/regions/${regionId}/electricity/self-sufficiency/summary`;

    return this.http
      .get<{ result: ISelfSufficiencySummaryDto }>(uri, {
        headers: new HttpHeaders(acceptJsonWithoutCache),
        params: this.createParamsAndAppendFilters(params)
      })
      .pipe(
        map((response) => new SelfSufficiencySummaryDto(response.result)),
        catchError(() => of({} as SelfSufficiencySummaryDto))
      );
  }

  private createParamsAndAppendFilters({ scenarioId, fromUTC, toUTC, filters }: IRequestsParams): HttpParams {
    let params = new HttpParams()
      .set("scenarioId", scenarioId)
      .set("fromUTC", String(fromUTC))
      .set("toUTC", String(toUTC));

    if (filters?.length) {
      filters.forEach((filter) => (params = params.append("filter", filter)));
    }

    return params;
  }
}
