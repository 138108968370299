<div class="column-wrapper">
  <button
    ene-button
    [icon]="$any(columnConfig).icon ?? 'ec_mplayer_dots-vertical'"
    [matMenuTriggerFor]="columnMenu"
    [disabled]="disabled($any(columnConfig), row)"
  ></button>
</div>
<mat-menu #columnMenu="matMenu" xPosition="before">
  <ng-container *ngFor="let item of options($any(columnConfig), row); trackBy: trackByValue">
    <button
      mat-menu-item
      [disabled]="item.disabled"
      (click)="onTableAction({ id: item.value, type: 'click', row: context.row })"
    >
      <span>{{ item.label | translate }}</span>
    </button>
  </ng-container>
</mat-menu>
