import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, of } from "rxjs";
import { catchError, map, switchMap, take } from "rxjs/operators";
import { initUserRegion, setEntity } from "src/app/ngrx/actions/app.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectEntities, selectEntity } from "src/app/ngrx/selectors/app.selectors";
import { hasPermissions } from "src/app/ngrx/selectors/permissions.selectors";

@Injectable({ providedIn: "root" })
export class CanActivateAppGuard implements CanActivate {
  constructor(private readonly store: Store<RootState>, private readonly router: Router) {}

  public canActivate(): Observable<UrlTree | boolean> {
    return combineLatest([this.store.pipe(select(selectEntities)), this.store.pipe(select(selectEntity))]).pipe(
      switchMap(([entities, entity]) => {
        const foundEntity = entity || entities[0];
        const regionId = foundEntity.id;

        // check if entity data wasn't settled, set from params or first
        if (!entity) {
          this.store.dispatch(setEntity({ entity: foundEntity }));
          this.store.dispatch(initUserRegion({ entityId: foundEntity.id }));
        }

        const queryParams = this.router.getCurrentNavigation().finalUrl.queryParams;

        return this.store.pipe(
          select(hasPermissions, { permissions: "module-00008.changelog.read", regionId }),
          map((_hasPermissions) =>
            _hasPermissions && !(queryParams.news_area || queryParams.dialog)
              ? this.router.parseUrl("admin-portal")
              : this.router.createUrlTree(["regions", regionId], {
                  queryParams
                })
          )
        );
      }),
      catchError(() => of(false)),
      take(1)
    );
  }
}
