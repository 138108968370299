import { coerceCssPixelValue } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-skeleton-loader-box",
  templateUrl: "skeleton-loader-box.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SkeletonLoaderBoxComponent {
  @Input()
  public set width(value: string | number) {
    this._width = coerceCssPixelValue(value);
  }
  public _width: string | number = "100%";

  @Input()
  public set height(value: string | number) {
    this._height = coerceCssPixelValue(value);
  }
  public _height: string | number = "1em";
}
