import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AssetFile } from "contentful";
import { MediaAnalyticsDirective } from "../../directives/media-analytics/media-analytics.directive";

@Component({
  selector: "app-contentful-media",
  templateUrl: "./contentful-media.component.html",
  styles: [
    `
      :host {
        display: block;
      }
      img,
      video {
        max-width: 100%;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MediaAnalyticsDirective]
})
/**
 * A component that displays a video or an image, depending on the content_type of the AssetFile
 */
export class ContentfulMediaComponent {
  @Input() public asset: AssetFile;

  public get hasAsset() {
    return this.asset?.contentType && this.asset?.url;
  }
  public get isVideo() {
    return this.asset?.contentType.startsWith("video");
  }
  public get isImage() {
    return this.asset?.contentType.startsWith("image");
  }
}
