import { ChangeDetectionStrategy, Component, TrackByFunction, ViewEncapsulation } from "@angular/core";
import { Pure } from "src/app/common/decorators/pure";
import { TableActionColumn } from "../../../classes/table-action-column";
import { MenuTableColumnConfig } from "../../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-menu-column",
  templateUrl: "menu-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuColumnComponent<T> extends TableActionColumn {
  public readonly trackByValue: TrackByFunction<MenuTableColumnConfig["options"][0]> = (_: number, item): string =>
    item.value;

  @Pure
  public disabled(columnConfig: MenuTableColumnConfig, row: T): boolean {
    return "disabledAccessor" in columnConfig
      ? columnConfig.disabledAccessor(row)
      : this.options(columnConfig, row).length === 0;
  }

  @Pure
  public options(columnConfig: MenuTableColumnConfig, row: T): MenuTableColumnConfig["options"] {
    return "options" in columnConfig
      ? columnConfig.options
      : "optionsAccessor" in columnConfig
      ? columnConfig.optionsAccessor(row)
      : [];
  }
}
