import { PLUGIN } from "../enums/plugins";
import { SLOT } from "../enums/slot";

export type OnlyModules = Exclude<SLOT, SLOT.LANDING>;

export const SLOT_ICONS_MAP: { [slot in OnlyModules | string]: string } = {
  // regulated modules
  [SLOT.GRID_DEVELOPMENT]: "ec_module_griddevelopm",
  [SLOT.OUTAGE_MONITORING]: "ec_sm",
  [SLOT.GRID_CONNECTION]: "ec_eun",
  [SLOT.THREE_YEAR_PLAN]: "ec_3jp",
  [SLOT.ACCOUNTING]: "ec_auv",
  // services modules
  [PLUGIN.STREET_LIGHTING]: "ec_streetlight_outage",
  [PLUGIN.SHOPPING_CART]: "ec_streetlight_cart",
  [PLUGIN.NOYSEE]: "ec_noysee",
  [PLUGIN.KEM]: "ec_kem",
  [PLUGIN.CO2_BALANCE]: "ec_co2",
  [PLUGIN.DIGINAMIC]: "ec_diginamic",
  [PLUGIN.QUICK_CHECK]: "ec_diginamic"
};
