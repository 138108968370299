export const badges = [
  {
    id: "intermediate",
    class: "primary",
    type: "intermediate",
    label: "APP.NETZEBW",
    key: "NOTIFICATION_SERVICE.NETZEBW_DESCRIPTION"
  },
  {
    class: "secondary",
    id: "start",
    type: "start",
    label: "APP.MUNICIPALITY",
    key: "NOTIFICATION_SERVICE.MUNICIPALITY_DESCRIPTION"
  }
];
