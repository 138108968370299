import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SkeletonLoaderBoxComponent } from "./skeleton-loader-box/skeleton-loader-box.component";
import { SkeletonLoaderTableComponent } from "./skeleton-loader-table/skeleton-loader-table.component";

@NgModule({
  declarations: [SkeletonLoaderBoxComponent, SkeletonLoaderTableComponent],
  imports: [CommonModule],
  exports: [SkeletonLoaderBoxComponent, SkeletonLoaderTableComponent]
})
export class SkeletonLoaderModule {}
