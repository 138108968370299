import { TextFieldModule } from "@angular/cdk/text-field";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { TextareaFieldComponent } from "./textarea-field.component";

@NgModule({
  declarations: [TextareaFieldComponent],
  imports: [ReactiveFormsModule, MatLegacyFormFieldModule, TextFieldModule, MatLegacyInputModule],
  exports: [TextareaFieldComponent]
})
export class TextareaFieldModule {}
