import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { TableColumn, TABLE_COLUMN_CONTEXT } from "../../../classes/table-column";
import { CombinedTableColumnConfig, TableColumnContext } from "../../../table.interfaces";
import { TABLE_COMBINED_VALUES_COLUMNS_COMPONENTS } from "./combined-values-column.constants";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-combined-values-column",
  templateUrl: "./combined-values-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CombinedValuesColumnComponent extends TableColumn implements OnInit, OnDestroy {
  @ViewChild("outlet", { static: true, read: ViewContainerRef })
  public readonly outlet!: ViewContainerRef;

  constructor(
    private readonly cfr: ComponentFactoryResolver,
    private readonly injector: Injector,
    @Optional()
    @Inject(TABLE_COLUMN_CONTEXT)
    readonly context?: TableColumnContext
  ) {
    super(context);
  }

  public ngOnInit(): void {
    if ("combinedValues" in this.columnConfig) {
      this.loadComponent(this.index, this.columnConfig, this.row);
    }
  }

  public ngOnDestroy(): void {
    this.outlet.clear();
  }

  private loadComponent(index: number, columnConfig: CombinedTableColumnConfig, row: Record<string, any>): void {
    this.outlet.clear();

    for (let _index = columnConfig.combinedValues.length - 1; _index >= 0; _index--) {
      const { id, type, tooltip, actionIcon } = columnConfig.combinedValues[_index];
      const component = TABLE_COMBINED_VALUES_COLUMNS_COMPONENTS.get(type);

      if (component) {
        this.outlet.createComponent(
          this.cfr.resolveComponentFactory(component),
          0,
          Injector.create({
            parent: this.injector,
            providers: [
              {
                provide: TABLE_COLUMN_CONTEXT,
                useValue: {
                  index,
                  columnConfig: {
                    ...this.columnConfig,
                    tooltip,
                    actionIcon,
                    type,
                    id
                  },
                  row
                }
              }
            ]
          })
        );
      }
    }
  }
}
