import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonToggleModule } from "src/app/common/components/button-toggle/button-toggle.module";
import { SelectModule } from "src/app/common/components/select/select.module";
import { TableFilterModule } from "src/app/slots/three-year-plan/common/components/table/components/table-filter/table-filter.module";
import { ContentfulWrapperNavigationComponent } from "./components/contentful-wrapper-navigation/contentful-wrapper-navigation.component";
import { ContentfulWrapperComponent } from "./container/contentful-wrapper.component";

@NgModule({
  declarations: [ContentfulWrapperComponent, ContentfulWrapperNavigationComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    EneButtonModule,
    SelectModule,
    ButtonToggleModule,
    TableFilterModule
  ],
  exports: [ContentfulWrapperComponent]
})
export class ContentfulWrapperModule {}
