import { NgFor } from "@angular/common";
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TrackByFunction
} from "@angular/core";
import "@enersis/gp-components/gp-tabs";
import { TranslateModule } from "@ngx-translate/core";

export interface TabConfig<T extends string = string> {
  name: T;
  label: string;
  disabled?: boolean;
}

@Component({
  selector: "tpm-tabs",
  templateUrl: "tabs.component.html",
  styleUrls: ["tabs.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TabsComponent {
  @Output() readonly activeTabChange = new EventEmitter<string>();

  @Input({ required: true }) tabs: Array<TabConfig>;
  @Input() active: string;
  @Input() child: boolean = false;

  readonly trackByFn: TrackByFunction<TabConfig> = (_, tab) => tab.name;

  onActiveTabChange(event: CustomEvent<{ index: number; label: string }>): void {
    event.stopPropagation();

    const tab = this.tabs.find((_, index) => index === event.detail.index);

    this.activeTabChange.emit(tab.name);
  }
}
