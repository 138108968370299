import { Injectable, inject } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { Store, select } from "@ngrx/store";
import { switchMap, tap, withLatestFrom } from "rxjs";
import { AdminModuleInvitationDto, AdminModuleInvitationModuleDto } from "src/app/common/dto/admin-module/invitation";
import { AdminModuleStatus } from "src/app/common/dto/admin-module/module-status";
import { AdminModuleSubscriptionDto } from "src/app/common/dto/admin-module/subscription";
import { LoadingResponse } from "src/app/common/interfaces/loading-response";
import { DynamicSnackBar } from "src/app/core/material/snack-bar/snack-bar";
import { AdminDataService } from "src/app/core/services/admin-data.service";
import { CampusOneDataService } from "src/app/core/services/marketplace/campus-one-data.service";
import { formatDateDE, formatDurationWithMomentDE } from "src/app/core/utils/number/date";
import { selectRegionAgs } from "src/app/ngrx/selectors/app.selectors";
import { ProductManagementDialogComponent } from "./container/product-management-dialog.component";
import { createColumnsConfig } from "./product-management-dialog.constants";
import { UserBodyDto } from "./product-management-dialog.interfaces";

export interface ProductManagementDialogState {
  tab: "userManagement" | "contractManagement";
  invitations: LoadingResponse<Array<AdminModuleInvitationDto>>;
  contracts: LoadingResponse<Array<AdminModuleSubscriptionDto>>;
  updatedInvitations: Array<AdminModuleInvitationModuleDto & { invitationId }>;
}

@Injectable()
export class ProductManagementDialogComponentStore extends ComponentStore<ProductManagementDialogState> {
  public readonly store = inject(Store);
  public readonly adminDataService = inject(AdminDataService);
  public readonly campusOneDataService = inject(CampusOneDataService);
  public readonly snackbar = inject(DynamicSnackBar);

  constructor() {
    super({
      tab: "userManagement",
      contracts: {
        result: [],
        loading: false
      },
      invitations: {
        result: [],
        loading: false
      },
      updatedInvitations: []
    });
  }

  public readonly tab$ = this.select((state) => state.tab);
  public readonly invitations$ = this.select((state) => state.invitations);
  public readonly updatedInvitations$ = this.select((state) => state.updatedInvitations);
  public readonly isUpdated$ = this.select(this.updatedInvitations$, (invitations) => invitations.length >= 1);
  public readonly contracts$ = this.select((state) => ({
    result: state.contracts.result.map((item) => this.createContractItem(item)),
    loading: state.contracts.loading
  }));
  public readonly mergedInvitations$ = this.select(
    this.invitations$,
    this.contracts$,
    this.updatedInvitations$,
    (invitations, contracts, updatedInvitations) => ({
      result: this.mergeInvitationsResult(invitations, contracts, updatedInvitations),
      loading: invitations.loading && contracts.loading
    }),
    { debounce: true }
  );
  public readonly columnsConfig$ = this.select(this.contracts$, (contractList) =>
    createColumnsConfig(contractList.result.map((contract) => ({ id: contract.name, key: contract.displayName })))
  );

  public readonly vm$ = this.select(
    {
      tab: this.tab$,
      isUpdated: this.isUpdated$,
      updatedInvitations: this.updatedInvitations$,
      columnsConfig: this.columnsConfig$,
      invitations: this.mergedInvitations$,
      contracts: this.contracts$
    },
    { debounce: true }
  );

  public readonly setTab = this.updater((state, tab: ProductManagementDialogState["tab"]) => ({
    ...state,
    tab
  }));

  public readonly updateInvitation = this.updater(
    (state, props: AdminModuleInvitationModuleDto & { invitationId: string; status: AdminModuleStatus }) => {
      const invitationIndex = state.updatedInvitations.findIndex(
        ({ invitationId }) => invitationId === props.invitationId
      );

      if (invitationIndex !== -1) {
        state.updatedInvitations.splice(invitationIndex, 1);
      }

      return { ...state, updatedInvitations: [...state.updatedInvitations, props] };
    }
  );

  public readonly fetchContractList = this.effect((trigger$) =>
    trigger$.pipe(
      tap(() => {
        this.patchState({ contracts: { result: [], loading: true } });
      }),
      switchMap(() =>
        this.adminDataService.getSubscriptionUserList({ pageSize: 100 }).pipe(
          tapResponse(
            (result) => {
              this.patchState({ contracts: { result, loading: false } });
            },
            () => {
              this.patchState({ contracts: { result: [], loading: false } });
            }
          )
        )
      )
    )
  );

  public readonly downloadInvoice = (fileName: string) => this.adminDataService.postInvoiceDownload({ fileName });

  public readonly terminateContract = this.effect<{ name: string }>((params$) =>
    params$.pipe(
      switchMap(({ name }) =>
        this.adminDataService.deleteSubscription(name).pipe(
          tapResponse(
            () => {
              this.fetchContractList();
              this.snackbar.success("Vertrag gekündigt", undefined, undefined, false);
            },
            () => {
              this.snackbar.error(
                "Vertrag konnte aufgrund eines internen Fehlers nicht gekündigt werden",
                undefined,
                undefined,
                false
              );
            }
          )
        )
      )
    )
  );

  /**
   * @param isLoading is for silent table update
   */
  public readonly fetchInvitationList = this.effect<boolean | void>((params$) =>
    params$.pipe(
      tap((isLoading) => {
        if (isLoading !== false) {
          this.patchState({ invitations: { result: [], loading: true }, updatedInvitations: [] });
        }
      }),
      withLatestFrom(this.store.pipe(select(selectRegionAgs))),
      switchMap(() =>
        this.campusOneDataService.getInvitationList({ pageSize: 9999 }).pipe(
          tapResponse(
            (result) => {
              this.patchState({ invitations: { result, loading: false }, updatedInvitations: [] });
            },
            () => {
              this.patchState({ invitations: { result: [], loading: false } });
            }
          )
        )
      )
    )
  );

  public readonly addUsers = this.effect<Array<UserBodyDto>>((params$) =>
    params$.pipe(
      withLatestFrom(this.store.pipe(select(selectRegionAgs))),
      switchMap(([users, ags]) =>
        this.campusOneDataService.postInvitation(users.map((user) => ({ ...user, ags }))).pipe(
          tapResponse(
            () => {
              this.fetchInvitationList(false);
            },
            () => {
              this.snackbar.error(
                "Nutzer konnte aufgrund eines internen Fehlers nicht hinzugefügt werden",
                undefined,
                undefined,
                false
              );
            }
          )
        )
      )
    )
  );

  public readonly deleteUser = this.effect<{ ids: Array<string> }>((params$) =>
    params$.pipe(
      switchMap(({ ids }) =>
        this.campusOneDataService.deleteInvitation({ ids }).pipe(
          tapResponse(
            () => {
              this.fetchInvitationList(false);
            },
            () => {
              this.snackbar.error(
                "Nutzer konnte aufgrund eines internen Fehlers nicht entfernt werden.",
                undefined,
                undefined,
                false
              );
            }
          )
        )
      )
    )
  );

  public readonly updateInvitationsStatuses = this.effect<
    MatLegacyDialogRef<ProductManagementDialogComponent> | undefined
  >((params$) =>
    params$.pipe(
      withLatestFrom(this.updatedInvitations$, this.store.pipe(select(selectRegionAgs))),
      switchMap(([dialogRef, updatedInvitations, ags]) =>
        this.campusOneDataService
          .postInvitationModuleStatus(
            updatedInvitations.map((invitation) => ({
              invitationId: invitation.invitationId,
              name: invitation.name,
              moduleSubscriptionId: invitation.moduleSubscriptionName,
              status: invitation.status === "ACTIVE" ? true : false,
              ags
            }))
          )
          .pipe(
            tapResponse(
              () => {
                this.snackbar.success("Status erfolgreich geändert", undefined, undefined, false);
                dialogRef?.close();
                this.fetchInvitationList(false);
              },
              () => {
                this.snackbar.error(
                  "Status konnte aufgrund eines internen Fehlers nicht geändert werden",
                  undefined,
                  undefined,
                  false
                );
                dialogRef?.close();
                this.fetchInvitationList(false);
              }
            )
          )
      )
    )
  );

  private readonly createContractItem = (contract: AdminModuleSubscriptionDto) => ({
    displayName: contract.displayName,
    status: contract.status?.code,
    contractNumber: contract.clientSubscriptionContract.details.contractNumber,
    fullName: `${contract.clientSubscriptionContract.offeree.givenName} ${contract.clientSubscriptionContract.offeree.familyName}`,
    email: contract.clientSubscriptionContract.offeree.email,
    address: `${contract.clientSubscriptionContract.offeree.addressStreet}${
      contract.clientSubscriptionContract.offeree.addressHouseNumber
        ? `, ${contract.clientSubscriptionContract.offeree.addressHouseNumber}`
        : ""
    }`,
    addressPostalCode: contract.clientSubscriptionContract.offeree.addressPostalCode,
    date: contract.clientSubscriptionContract.details.contractEndDate,
    contractTerm: `${formatDateDE(contract.clientSubscriptionContract.details.contractStartDate)} - ${formatDateDE(
      contract.clientSubscriptionContract.details.contractEndDate
    )}`,
    billNumber: contract.clientSubscriptionContract.details.billNumber,
    name: contract.name,
    applicationModule: contract.applicationModule,
    contactDataUrl: "mailto:info@wtt-campusone.com",
    termsAndConditionsDataUrl: contract.clientSubscriptionContract.details.termsAndConditionsDataUrl,
    privacyPolicyDataUrl: contract.clientSubscriptionContract.details.privacyPolicyDataUrl,
    footer: contract.plan
      ? `<b>Mindestvertragslaufzeit</b>:&nbsp;${formatDurationWithMomentDE(
          contract.plan.duration
        )}&nbsp;<b>Kündigungsfrist:</b>&nbsp;${formatDurationWithMomentDE(contract.plan.noticePeriod)}`
      : "",
    files: contract.files
  });

  private mergeInvitationsResult(invitations, contracts, updatedInvitations) {
    return invitations.result.map(({ invitationModules, ...props }) => ({
      ...props,
      ...contracts.result.reduce(
        (acc, contract) => ({
          ...acc,
          [contract.name]:
            // take info from update list
            updatedInvitations.find(({ invitationId }) => invitationId === props.id) ??
              // take info from invitations list itself
              invitationModules.find(({ moduleSubscriptionName }) => moduleSubscriptionName === contract.name) ?? {
                // take info from contract list like placeholder
                name: contract.applicationModule,
                moduleSubscriptionName: contract.name
              }
        }),
        {}
      )
    }));
  }
}
