import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

interface IFileOptions {
  type?: string;
  name?: string;
  params?: HttpParams;
}

@Injectable({ providedIn: "root" })
export class FilesService {
  readonly applicationType = {
    Pdf: "application/pdf",
    Xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    Csv: "csv",
    Zip: "application/zip"
  };

  constructor(private readonly http: HttpClient, @Inject(DOCUMENT) private readonly document: Document) {}

  public downloadFile(uri: string, name: string): void {
    this.getDocument(uri, { name });
  }

  public downloadBase64FromSource(source: Observable<any>, options?: IFileOptions, pathArray?: Array<string>): void {
    source.pipe(take(1)).subscribe((data) => {
      if (data) {
        this.downloadBase64Document(data, options, pathArray);
      }
    });
  }

  public getPdf(uri: string, options?: IFileOptions, pathArray?: Array<string>): void {
    this.getDocument(uri, this.getOptions(this.applicationType.Pdf, options), pathArray);
  }

  public getXlsx(uri: string, options?: IFileOptions, pathArray?: Array<string>): void {
    this.getDocument(uri, this.getOptions(this.applicationType.Xlsx, options), pathArray);
  }

  public postPdf(uri: string, body: Record<string, any>, options?: IFileOptions): void {
    this.postOctetDocument(uri, body, this.getOptions(this.applicationType.Pdf, options));
  }

  public postCsv(uri: string, body: Record<string, any>, options?: IFileOptions): void {
    this.postDocument(uri, body, this.getOptions(this.applicationType.Csv, options));
  }

  public postXls(uri: string, body: Record<string, any>, options?: IFileOptions): void {
    this.postOctetDocument(uri, body, this.getOptions(this.applicationType.Xlsx, options));
  }

  public postArchive(uri: string, body: Record<string, any>): void {
    this.postOctetDocument(uri, body, this.getOptions(this.applicationType.Zip));
  }

  private getDocument(uri: string, options?: IFileOptions, pathArray?: Array<string>): void {
    this.http
      .get(uri, { responseType: "blob", params: options?.params, observe: "response" })
      .pipe(take(1))
      .subscribe(({ body, headers }) => this.downloadDocument(body, headers, options, pathArray));
  }

  private postDocument(uri: string, data: Record<string, any>, options?: IFileOptions): void {
    this.http
      .post(uri, data, { responseType: "text", params: options.params, observe: "response" })
      .pipe(take(1))
      .subscribe(({ body, headers }) => this.downloadDocument(body, headers, options));
  }

  private postOctetDocument(uri: string, data: Record<string, any>, options?: IFileOptions): void {
    this.http
      .post(uri, data, { responseType: "blob", params: options.params, observe: "response" })
      .pipe(take(1))
      .subscribe(({ body, headers }) => this.downloadOctetStream(body, headers));
  }

  private downloadDocument(
    data: Blob | string,
    headers: HttpHeaders = new HttpHeaders(),
    options?: IFileOptions,
    pathArray?: Array<string>
  ): void {
    const linkEl = this.document.createElement("a");
    const blob = new Blob([data], { type: options?.type });
    linkEl.href = window.URL.createObjectURL(blob);
    linkEl.download =
      options?.name ||
      decodeURI(
        headers
          ?.get("content-disposition")
          ?.replace(/"/g, "")
          ?.replace("UTF-8''", "")
          .match(/[^=]*$/)[0]
      ) ||
      "";

    linkEl.click();
    this.trackDownload(pathArray);
    setTimeout(() => window.URL.revokeObjectURL(linkEl.href));
  }

  private downloadOctetStream(blob: Blob, headers: HttpHeaders = new HttpHeaders(), pathArray?: Array<string>): void {
    const linkEl = this.document.createElement("a");
    linkEl.href = window.URL.createObjectURL(blob);
    linkEl.download = headers
      .get("content-disposition")
      ?.replace(/"/g, "")
      .match(/[^=]*$/)[0];

    linkEl.click();
    this.trackDownload(pathArray);
    setTimeout(() => window.URL.revokeObjectURL(linkEl.href));
  }

  private downloadBase64Document(data: string, options?: IFileOptions, pathArray?: Array<string>): void {
    const linkEl = this.document.createElement("a");
    linkEl.href = `data:${options?.type};base64,${data}`;
    linkEl.download = options?.name || "";

    linkEl.click();
    this.trackDownload(pathArray);
    setTimeout(() => window.URL.revokeObjectURL(linkEl.href));
  }

  private getOptions(type: string, options?: IFileOptions): IFileOptions {
    return { type, ...(options || {}) };
  }

  private trackDownload(pathArray?: Array<string>): void {
    if (pathArray) {
      // track downloads with additional path (e.g. active panel/tab...)
      (window as any).trackDownload(pathArray.join("/"));
    }
  }
}
