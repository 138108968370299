import { ChangeDetectionStrategy, Component, Inject, Optional } from "@angular/core";
import { EneLayoutService } from "@enersis/ui-helper";
import { Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, merge, Observable } from "rxjs";
import { map, scan } from "rxjs/operators";
import { IButtonToggleItem } from "src/app/common/components/button-toggle/button-toggle.component";
import { SLOT } from "src/app/common/enums/slot";
import { ContentfulActionField, ContentfulContentType } from "src/app/core/interfaces/contentful";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { OVERLAY_DATA, OverlayService } from "src/app/core/services/overlay.service";
import { fetchContentfulEntries } from "src/app/ngrx/actions/contentful.actions";
import { updateUsersSettingsUsersStorage } from "src/app/ngrx/actions/users-settings.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { CardNewsDetailsComponent } from "../components/card-news-details/card-news-details.component";
import { NewsActionsManagerService } from "../news-actions-manager.service";
import { CommunalPlatformNewsEntry, NetzeBwNewsEntry, NewsType } from "../news.interfaces";
import { NewsService } from "../news.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-news",
  templateUrl: "./news.component.html",
  styleUrls: ["./news.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsComponent {
  public readonly contentTypeToggles: Array<IButtonToggleItem>;
  public readonly contentType$ = new BehaviorSubject<ContentfulContentType>(undefined);
  public readonly search$ = new BehaviorSubject<string>("");
  public readonly module$ = new BehaviorSubject<SLOT | undefined>(undefined);

  public readonly vm$ = merge(
    this.layoutService.isMobileScreen$.pipe(map((isMobileView) => ({ isMobileView }))),
    this.contentType$.pipe(map((contentType) => ({ ...this.getNewsType(contentType), contentType }))),
    this.getNews()
  ).pipe(scan((vm, values) => ({ ...vm, ...values }), {}));
  public readonly hideModuleSelectOptions$ = this.contentType$
    .asObservable()
    .pipe(
      map(
        (contentType) =>
          contentType === ContentfulContentType.NBRegulatedModulesNews ||
          contentType === ContentfulContentType.NBServicesModulesNews
      )
    );

  public readonly contentType: ContentfulContentType;

  constructor(
    @Optional()
    @Inject(OVERLAY_DATA)
    private readonly overlayData: { contentTypes: Array<ContentfulContentType>; contentType: ContentfulContentType },
    private readonly newsService: NewsService,
    private readonly layoutService: EneLayoutService,
    private readonly overlayService: OverlayService,
    private readonly newsActionsManagerService: NewsActionsManagerService,
    private readonly store: Store<RootState>,
    private readonly analytics: AnalyticsService
  ) {
    if (this.overlayData?.contentTypes) {
      this.contentTypeToggles = this.overlayData.contentTypes.map((value) => ({
        label: `CONTENTFUL.NEWS.TOGGLES.${value}`,
        value
      }));

      this.contentType = this.overlayData?.contentType || (this.contentTypeToggles[0].value as ContentfulContentType);
    }
  }

  public readonly trackById = (_: number, entry: CommunalPlatformNewsEntry | NetzeBwNewsEntry): string => entry.id;

  public get headline(): string {
    switch (this.contentType) {
      case ContentfulContentType.NBRegulatedModulesNews:
      case ContentfulContentType.CPRegulatedModulesNews:
      case ContentfulContentType.RegulatedModulesWebinars: {
        return "CONTENTFUL.NEWS.REGULATED_TITLE";
      }
      case ContentfulContentType.NBServicesModulesNews:
      case ContentfulContentType.ServicesModulesWebinars:
      case ContentfulContentType.CPServicesModulesNews: {
        return "CONTENTFUL.NEWS.MARKETPLACE_TITLE";
      }
      default:
        return "CONTENTFUL.NEWS.TITLE";
    }
  }

  public onSearchChange(search: string): void {
    this.search$.next(search);
  }

  public onModuleChange(module: SLOT): void {
    this.module$.next(module);
  }

  public onContentTypeChange(contentType: ContentfulContentType): void {
    if (this.contentType$.value) {
      this.analytics.trackNewsTypeChange(contentType);
    }

    this.contentType$.next(contentType);
    this.store.dispatch(fetchContentfulEntries({ contentType }));
  }

  public onMarkAllRead(news: Array<CommunalPlatformNewsEntry | NetzeBwNewsEntry>): void {
    const items = news.filter(({ isNew }) => isNew).map(({ id }) => ({ key: id, value: true }));

    if (items.length) {
      this.store.dispatch(updateUsersSettingsUsersStorage({ items }));
    }

    this.analytics.trackMarkAllReadClick();
  }

  public onCardNewsClick(
    contentType: ContentfulContentType,
    selected: CommunalPlatformNewsEntry | NetzeBwNewsEntry
  ): void {
    this.overlayService.openInGlobal(CardNewsDetailsComponent, { data: { selected } });
    this.analytics.trackNewsItemClick(contentType, selected?.headline);
  }

  public onListItemNewsClick(
    action: ContentfulActionField | void,
    selected: CommunalPlatformNewsEntry,
    contentType: ContentfulContentType
  ): void {
    if (selected.isNew) {
      this.store.dispatch(updateUsersSettingsUsersStorage({ items: [{ key: selected.id, value: true }] }));
    }

    if (action) {
      this.newsActionsManagerService.dispatch(action.action, action.url, selected);
    }

    this.analytics.trackNewsItemClick(contentType, selected?.headline);
  }

  private getNews(): Observable<{ entries: Array<CommunalPlatformNewsEntry | NetzeBwNewsEntry> }> {
    return combineLatest([this.newsService.getNewsEntries(), this.search$, this.module$]).pipe(
      map(([_entries, search, module]) => ({
        entries: _entries
          // filter by search
          .filter(({ headline }) => headline?.toLowerCase().includes(search))
          // filter by module
          .filter((entry) => ("moduleName" in entry ? entry.moduleName?.includes(module ?? "") : true))
      }))
    );
  }

  private getNewsType(contentType: ContentfulContentType): { newsType: NewsType } {
    switch (contentType) {
      case ContentfulContentType.CPRegulatedModulesNews:
      case ContentfulContentType.CPServicesModulesNews: {
        return { newsType: "list-item" };
      }
      case ContentfulContentType.NBRegulatedModulesNews:
      case ContentfulContentType.NBServicesModulesNews: {
        return { newsType: "card" };
      }
      default: {
        return { newsType: "list-item" };
      }
    }
  }
}
