import { createAction, props } from "@ngrx/store";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import { LaunchpadOverlay, RegionOverlay } from "src/app/core/interfaces/overlay";

export const openLaunchpad = createAction("[Region Overlay] Open Launchpad overlay");
export const closeLaunchpad = createAction("[Region Overlay] Close Launchpad overlay");

export const openNews = createAction(
  "[Region Overlay] Open News overlay",
  (properties: { contentType?: ContentfulContentType } = {}) => properties
);
export const closeNews = createAction("[Region Overlay] Close News overlay");

export const openWebinars = createAction("[Region Overlay] Open Webinars overlay");
export const closeWebinars = createAction("[Region Overlay] Close Webinars overlay");

export const changeRegionOverlay = createAction(
  "[Region Overlay] Change Region overlay",
  props<{ overlay: RegionOverlay }>()
);

export const changeLaunchpadOverlay = createAction(
  "[Region Overlay] Change Launchpad overlay",
  props<{ overlay: LaunchpadOverlay }>()
);
