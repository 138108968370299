import { EventsMap } from "redux-beacon";
import { trackEvent } from "src/app/ngrx/actions/analytics.actions";
import { createEventDefinition, trackRouterChange } from "./utils";
import { ROUTER_NAVIGATED } from "@ngrx/router-store";

/*
 Here we map event definitions to Action types. Basically, we are
 exporting an object that tells Redux Beacon what events to fire when
 a given Action is dispatched.
*/
export const eventsMap: EventsMap = {
  [trackEvent.type]: createEventDefinition,
  [ROUTER_NAVIGATED]: trackRouterChange
};
