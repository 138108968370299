import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  OnInit,
  Optional
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { Sort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { TabComponent } from "@enersis/ui-tabs";
import { select, Store } from "@ngrx/store";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ITableColumn } from "src/app/common/components/table/interfaces/table-column";
import { INotification } from "src/app/common/interfaces/dialogs/notification-settings";
import { CrmModule } from "src/app/core/enums/permissions";
import { DynamicSnackBar } from "src/app/core/material/snack-bar/snack-bar";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectRegionEnabledModules, selectUserRegions } from "src/app/ngrx/selectors/app.selectors";
import { hasNotificationSettingsPermissions } from "src/app/ngrx/selectors/permissions.selectors";
import { notificationSettingsAuvColumns } from "../../constants/notification-settings-auv.columns";
import { notificationSettingsStreetLightingColumns } from "../../constants/notification-settings-lighting.columns";
import { notificationSettingsNewsColumns } from "../../constants/notification-settings-news.columns";
import { notificationSettingsTYPColumns } from "../../constants/notification-settings-typ.columns";
import { notificationSettingsColumns } from "../../constants/notification-settings.columns";
import { NotificationSettingsService } from "../../services/notification-settings.service";

export type Tab = "incident-management" | "three-year-plan" | "accounting" | "street-lighting" | "news";

interface ITabConfig {
  name: Tab;
  label: string;
  disabled?: boolean;
}

interface ITabItem {
  name: string;
  title: string;
}

@Component({
  selector: "app-notification-settings-dialog",
  templateUrl: "./notification-settings.component.html",
  styleUrls: ["./notification-settings.component.scss"],
  providers: [
    {
      provide: NotificationSettingsService,
      deps: [DynamicSnackBar, HttpClient, Store]
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsComponent implements OnInit {
  public tabs: ReadonlyArray<ITabConfig> = [
    { name: "incident-management", label: "NOTIFICATION_SERVICE.OUTAGE" },
    { name: "three-year-plan", label: "NOTIFICATION_SERVICE.THREE_YEARS_PLAN" },
    { name: "accounting", label: "NOTIFICATION_SERVICE.AUV" },
    { name: "street-lighting", label: "NOTIFICATION_SERVICE.STREET_LIGHTNING" },
    { name: "news", label: "NOTIFICATION_SERVICE.EDIT.NEWS.NEWS_TITLE" }
  ];

  public activeTabName: Tab;
  public selectedRegions: Array<INotification> = [];
  public showEditForm: boolean;
  public multiRegion: boolean;
  public readonly columnsConfigs: { [key in Tab]: Array<ITableColumn> } = {
    "incident-management": notificationSettingsColumns,
    "three-year-plan": notificationSettingsTYPColumns,
    accounting: notificationSettingsAuvColumns,
    "street-lighting": notificationSettingsStreetLightingColumns,
    news: notificationSettingsNewsColumns
  };
  public sort: Sort = {
    active: "municipality",
    direction: "asc"
  };

  private readonly destroyRef$ = inject(DestroyRef);

  constructor(
    protected readonly notificationSettingsService: NotificationSettingsService,
    private readonly store: Store<RootState>,
    private readonly analytics: AnalyticsService,
    private readonly dialogRef: MatLegacyDialogRef<NotificationSettingsComponent>,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    @Optional()
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private readonly data: { tab: Tab }
  ) {}

  public ngOnInit(): void {
    this.notificationSettingsService.setDefaultSort(this.sort);

    if (this.data?.tab) {
      this.setTab(this.data.tab);
    } else {
      this.initializeTabWithPermissions();
    }

    this.store
      .pipe(
        select(selectUserRegions),
        map((regions) => regions.length > 1),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe((multiRegion) => {
        this.multiRegion = multiRegion;
        this.dialogRef.updateSize(multiRegion ? "70rem" : "600px");
        this.cdr.detectChanges();
      });
  }

  public trackByName(_: number, { name }: ITabItem): string {
    return name;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public onPanelTabChange({ tabName, tabTitle }: TabComponent): void {
    this.setTab(tabName as Tab);
    this.analytics.trackNotificationTabClick(tabTitle);
  }

  private setTab(tab: Tab): void {
    this.activeTabName = tab;
    this.notificationSettingsService.setTabName(tab);
    this.router.navigate([], { queryParams: { dialogTab: tab }, queryParamsHandling: "merge" });
  }

  private initializeTabWithPermissions(): void {
    combineLatest([
      this.store.pipe(
        select(selectRegionEnabledModules),
        map((activeModules) => [...activeModules, "street-lighting", "news"])
      ),
      this.store.pipe(select(hasNotificationSettingsPermissions, [CrmModule.OutageMonitoring])),
      this.store.pipe(select(hasNotificationSettingsPermissions, [CrmModule.ThreeYearPlan])),
      this.store.pipe(select(hasNotificationSettingsPermissions, [CrmModule.Accounting])),
      this.store.pipe(select(hasNotificationSettingsPermissions, [CrmModule.StreetLighting]))
    ])
      .pipe(
        map(
          ([
            activeModules,
            hasOMPermissions,
            hasTYPPermissions,
            hasAccountingPermissions,
            hasStreetLightingPermissions
          ]) => ({
            activeModules,
            "incident-management": hasOMPermissions,
            "three-year-plan": hasTYPPermissions,
            accounting: hasAccountingPermissions,
            "street-lighting": hasStreetLightingPermissions,
            news: true
          })
        ),
        takeUntilDestroyed(this.destroyRef$)
      )
      .subscribe((props) => {
        this.tabs = this.tabs.map((tab) => ({
          ...tab,
          disabled: !(props.activeModules.includes(tab.name) && props[tab.name])
        }));

        this.setTab(this.tabs.find((obj) => !obj.disabled).name);
        this.cdr.detectChanges();
      });
  }
}
