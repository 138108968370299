import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ContactPayload, ContactPayloadData } from "src/app/common/models/contact-paylod";
import { AppConfig } from "../helpers/app.config";

@Injectable({
  providedIn: "root"
})
export class CommunicationService {
  constructor(private http: HttpClient) {}

  public postContactData(data: ContactPayloadData): Observable<void> {
    const url = `${AppConfig.connection.communication.email}/public/${data.topic}`;
    const body = new ContactPayload(data).getBody();
    return this.http.post<void>(url, body);
  }
}
