import { createAction, props } from "@ngrx/store";
import { RegionsPerYearDto, RegionsYearDto } from "../../../common/dto/infrastructure/regions";

export const getRegionsData = createAction(
  "[Infrastructure Regions] Get regions data",
  props<{
    regionId: string;
    year: number;
  }>()
);

export const getRegionsDataSuccess = createAction(
  "[Infrastructure Regions] Get regions data success",
  props<{
    payload: RegionsYearDto;
  }>()
);

export const getRegionsAllYearsData = createAction(
  "[Infrastructure Regions] Get regions all years data",
  props<{
    regionId: string;
    timelineRange: [number, number];
  }>()
);

export const getRegionsAllYearsDataSuccess = createAction(
  "[Infrastructure Regions] Get regions all years data success",
  props<{
    payload: Array<RegionsPerYearDto>;
  }>()
);
