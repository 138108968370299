import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Store, select } from "@ngrx/store";
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription, combineLatest, of } from "rxjs";
import { catchError, filter, map, startWith, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { TablePagingService } from "src/app/common/components/table/classes/table-paging-service";
import { ITablePagingMetadata } from "src/app/common/components/table/interfaces/table-paging-metadata";
import { CrmModule } from "src/app/core/enums/permissions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectUserInfo } from "src/app/ngrx/selectors/app.selectors";
import { AppConfig } from "../../../../../core/helpers/app.config";
import { DynamicSnackBar } from "../../../../../core/material/snack-bar/snack-bar";
import {
  INotification,
  INotificationConfig,
  INotificationPatch
} from "../../../../interfaces/dialogs/notification-settings";
import { Tab } from "../components/notification-settings/notification-settings.component";
import { EventsMap, ModulesMap } from "../constants/events-map";

@Injectable()
export class NotificationSettingsService extends TablePagingService<object> {
  public loading$ = new BehaviorSubject<boolean>(true);
  private data$ = new BehaviorSubject<Array<INotification>>([]);
  private defaultSort: Sort;
  private tabName = new BehaviorSubject(null);
  private agsFilter$ = new Subject();
  private nameFilter$ = new Subject();
  private renewData$ = new Subject<void>();
  private total$ = new ReplaySubject<number>(1);
  private configUrl = `${AppConfig.connection.infrastructure.userSettings}/module/config`;
  private url = `${AppConfig.connection.infrastructure.userSettings}/users`;
  private subscribtionsUrl = `${AppConfig.connection.infrastructure.userSettings}/subscriptions`;
  private configs$ = new BehaviorSubject<{ start: boolean; intermediate: boolean }>(null);
  public get configs() {
    return this.configs$.asObservable();
  }

  constructor(private readonly snackbar: DynamicSnackBar, private http: HttpClient, private store: Store<RootState>) {
    super();
  }

  public setTabName(tabName: Tab): void {
    this.tabName.next(tabName);
    this.sort?.sortChange.emit(this.defaultSort);
    this.resetPagination();
    this.agsFilter$.next("");
    this.nameFilter$.next("");
    if (tabName === "three-year-plan") {
      this.updateConfigs();
    }
  }

  public getTabName(): Observable<Tab> {
    return this.tabName.asObservable();
  }

  public getTabNameValue(): Tab {
    return this.tabName.value;
  }

  public setAgsFilter(ags: string): void {
    this.resetPagination();
    this.agsFilter$.next(ags);
  }

  public setNameFilter(name: string): void {
    this.resetPagination();
    this.nameFilter$.next(name);
  }

  public getFilter(): Observable<any> {
    return combineLatest([this.agsFilter$.pipe(startWith("")), this.nameFilter$.pipe(startWith(""))]).pipe(
      map(([ags, name]) => ({ ags, name }))
    );
  }

  public patchNotificationSettings(data: INotificationPatch, divisions: Array<string>): Subscription {
    const body = EventsMap.mapData(data, this.tabName.value, divisions);
    return this.http.post<void>(this.subscribtionsUrl, body).subscribe(
      () => {
        this.snackbar.open("USER_SETTINGS.EDIT.SAVE_SUCCESS", undefined, undefined, "success", false);
        this.renewData$.next();
      },
      () => this.snackbar.open("USER_SETTINGS.EDIT.SAVE_ERROR", undefined, undefined, "error", false)
    );
  }

  public getData(regionIds: Array<string>): Observable<Array<INotification>> {
    return this.data$.pipe(
      filter((data) => Boolean(data.length)),
      map((items) => items.filter((item) => regionIds.includes(item.regionId)))
    );
  }

  public loadData(): Observable<ITablePagingMetadata<INotification>> {
    return combineLatest([
      this.getPaging(),
      this.getSort(),
      this.getFilter(),
      this.renewData$.pipe(startWith([]))
    ]).pipe(
      switchMap(([paging, sorting, search]) => {
        this.loading$.next(true);
        let paramsObject: any = {};

        if (paging) {
          paramsObject = { ...paramsObject, limit: paging.pageSize, offset: paging.pageIndex * paging.pageSize };
        }

        if (sorting) {
          paramsObject = { ...paramsObject, sortColumn: sorting.active, sortDirection: sorting.direction };
        }

        if (search.ags) {
          paramsObject = { ...paramsObject, filterByAgs: search.ags };
        }

        if (search.name) {
          paramsObject = { ...paramsObject, filterByName: search.name };
        }
        return this.fetchData(paramsObject);
      })
    );
  }

  public fetchData(
    paramsObject: { [param: string]: string | Array<string> } = {}
  ): Observable<ITablePagingMetadata<INotification>> {
    return this.http
      .get<{ result: ITablePagingMetadata<INotification> }>(`${this.subscribtionsUrl}`, {
        params: new HttpParams({
          fromObject: { topic: ModulesMap[this.tabName.value], ...paramsObject }
        })
      })
      .pipe(
        withLatestFrom(this.store.pipe(select(selectUserInfo))),
        map(([{ result }, { email }]) => ({
          ...result,
          data: result.data.map((item: any) => ({
            email,
            regionId: item.regionId,
            module: this.tabName.value,
            municipality: item.municipality,
            ...EventsMap.mapEvents(item, this.tabName.value)
          }))
        })),
        tap(({ total, data }) => {
          this.data$.next(data);
          this.total$.next(total);
          this.loading$.next(false);
        }),
        catchError((e) => {
          console.error(e);
          return of({ total: 0, data: [] });
        })
      );
  }

  public setDefaultSort(sort: Sort): void {
    this.defaultSort = sort;
  }

  public getTotal(): Observable<number> {
    return this.total$.asObservable();
  }

  private updateConfigs() {
    this.http
      .get<{ result: INotificationConfig[] }>(`${this.configUrl}`, {
        params: new HttpParams().set("topic", CrmModule.ThreeYearPlan)
      })
      .pipe(withLatestFrom(this.store.pipe(select(selectUserInfo))))
      .subscribe(([{ result }, { userScope }]) => {
        const events = EventsMap.getEvent(this.tabName.value);
        const startEndConfig = result.find((item) => item.event === events.startEnd);
        const intermediateConfig = result.find((item) => item.event === events.intermediate);
        const start = startEndConfig && (!startEndConfig.userScope || startEndConfig.userScope?.includes(userScope));
        const intermediate =
          intermediateConfig && (!intermediateConfig.userScope || intermediateConfig.userScope?.includes(userScope));

        this.configs$.next({ start, intermediate });
      });
  }
}
