import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import "@enersis/gp-components/gp-icon";
import { IconComponent } from "./icon.component";

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule],
  exports: [IconComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class IconModule {}
