import { ChangeDetectionStrategy, Component, Input, ViewChild } from "@angular/core";
import { MatLegacyPaginator, MatLegacyPaginatorIntl } from "@angular/material/legacy-paginator";
import { TranslateService } from "@ngx-translate/core";
import { PaginatorIntl } from "../../../paginator/paginator-intl";
import { ITablePaging } from "../../interfaces/table-paging";

@Component({
  selector: "app-table-paginator",
  templateUrl: "./table-paginator.component.html",
  styleUrls: ["./table-paginator.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatLegacyPaginatorIntl,
      useClass: PaginatorIntl,
      deps: [TranslateService]
    }
  ]
})
export class TablePaginatorComponent {
  @ViewChild(MatLegacyPaginator, { static: true }) public readonly matPaginator: MatLegacyPaginator;

  @Input()
  public set config(_config: ITablePaging) {
    this._config = _config;
    this.type = _config.type || "compact";
  }
  public get config(): ITablePaging {
    return this._config;
  }
  public _config: ITablePaging;

  public type: "compact" | "full";
}
