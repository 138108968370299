import { FEATURES } from "src/app/common/enums/features";
import { SLOT } from "src/app/common/enums/slot";
import { INotificationPatch } from "src/app/common/interfaces/dialogs/notification-settings";
import { CrmModule } from "src/app/core/enums/permissions";

export const ModulesMap = {
  [SLOT.OUTAGE_MONITORING]: CrmModule.OutageMonitoring,
  [SLOT.ACCOUNTING]: CrmModule.Accounting,
  [SLOT.THREE_YEAR_PLAN]: CrmModule.ThreeYearPlan,
  "street-lighting": CrmModule.StreetLighting,
  news: FEATURES.NEWS
};

export class EventsMap {
  private static eventsMap = {
    "incident-management": {
      electricity: {
        startEnd: "electricityOutageStartEnd",
        intermediate: "electricityOutageIntermediate",
        update: "electricityOutageUpdate"
      },
      gas: {
        startEnd: "gasOutageStartEnd",
        intermediate: "gasOutageIntermediate",
        update: "gasOutageUpdate"
      }
    },
    accounting: {
      startEnd: "accountingInstallationUpdateNetworkProvider",
      update: "accountingInstallationUpdateMunicipality",
      intermediate: "accountingDocumentUpdate"
    },
    "three-year-plan": {
      startEnd: "municipalMeasureUpdate",
      intermediate: "networkproviderMeasureUpdate",
      dataStorage: "dataStorage"
    },
    "street-lighting": {
      startEnd: "newIncidentInstantNotification",
      intermediate: "newIncidentDailyNotification"
    },
    news: {
      operationNews: "operationNews",
      serviceNews: "serviceNews",
      criticalNews: "criticalNews"
    }
  };

  public static getEvent(moduleName: string, division: string = "electricity") {
    if (moduleName === "incident-management") {
      return EventsMap.eventsMap[moduleName][division];
    }
    return EventsMap.eventsMap[moduleName];
  }

  public static mapEvents(item: any, moduleName: string) {
    if (moduleName === "incident-management") {
      const gasEvents = this.mapEvent(item, this.getEvent(moduleName, "gas"));
      const electricityEvents = this.mapEvent(item, this.getEvent(moduleName, "electricity"));
      return {
        gas: gasEvents,
        gasVerifyPhone: item.gasVerifyPhone,
        electricityVerifyPhone: item.electricityVerifyPhone,
        electricity: electricityEvents,
        gasPhone: item.gasPhone,
        electricityPhone: item.electricityPhone
      };
    } else {
      return { phone: item.phone, verifyPhone: item.verifyPhone, ...this.mapEvent(item, this.getEvent(moduleName)) };
    }
  }

  public static mapData(data: INotificationPatch, moduleName: string, divisions: Array<string>) {
    if (moduleName === "incident-management") {
      return divisions.flatMap((key) => this.reverseMapEvent({ ...data, ...data[key] }, moduleName, key));
    } else {
      return this.reverseMapEvent(data, moduleName);
    }
  }

  private static mapEvent(item: any, events: any) {
    return {
      notifyByEmail: {
        start: Boolean(item.emailEvents?.includes(events.startEnd)),
        end: Boolean(item.emailEvents?.includes(events.startEnd)),
        intermediate: events.intermediate ? Boolean(item.emailEvents?.includes(events.intermediate)) : undefined,
        update: events.update ? Boolean(item.emailEvents?.includes(events.update)) : undefined,
        operationNews: Boolean(item.emailEvents?.includes("operationNews")),
        serviceNews: Boolean(item.emailEvents?.includes("serviceNews")),
        criticalNews: Boolean(item.emailEvents?.includes("criticalNews")),
        dataStorage: Boolean(item.emailEvents?.includes("dataStorage"))
      },
      notifyBySMS: {
        start: Boolean(item.phoneEvents?.includes(events.startEnd)),
        end: Boolean(item.phoneEvents?.includes(events.startEnd)),
        intermediate: events.intermediate ? Boolean(item.phoneEvents?.includes(events.intermediate)) : undefined,
        update: events.update ? Boolean(item.phoneEvents?.includes(events.update)) : undefined
      }
    };
  }

  private static reverseMapEvent(data: INotificationPatch, moduleName: string, division?: string) {
    const topic = ModulesMap[moduleName];

    return data.regionIds.flatMap((item) =>
      Object.entries(EventsMap.getEvent(moduleName, division)).flatMap(([key, event]) => {
        const result = [];
        if (data.notifyByEmail) {
          result.push({
            id: item,
            type: "region",
            channel: "email",
            topic,
            phone: null,
            event,
            subscribe: Boolean(data.notifyByEmail[key])
          });
        }

        if (data.notifyBySMS) {
          result.push({
            id: item,
            type: "region",
            channel: "phone",
            phone: data.phone || data[division].phone,
            topic,
            event,
            subscribe: Boolean(data.notifyBySMS[key])
          });
        }
        return result;
      })
    );
  }
}
