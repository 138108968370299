import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from "@angular/core";
import { TABLE_MULTI_ROW_CONTEXT } from "../../../classes/table-multi-row";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-multi-row-resolver",
  template: "<ng-container #outlet></ng-container>",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiRowResolverComponent<T extends Record<string, any>> implements OnInit, OnDestroy {
  @Input() public component!: Type<any>;
  @Input() public dataAccessor!: (row: T) => any;
  @Input() public row!: T;

  @ViewChild("outlet", { static: true, read: ViewContainerRef })
  public readonly outlet!: ViewContainerRef;

  constructor(private readonly cfr: ComponentFactoryResolver, private readonly injector: Injector) {}

  public ngOnInit(): void {
    this.loadComponent(this.component, this.dataAccessor, this.row);
  }

  public ngOnDestroy(): void {
    this.outlet.clear();
  }

  private loadComponent(component: Type<any>, dataAccessor: (row: T) => any, row: T): void {
    const componentFactory = this.cfr.resolveComponentFactory(component);

    this.outlet.clear();
    this.outlet.createComponent(
      componentFactory,
      0,
      Injector.create({
        parent: this.injector,
        providers: [
          {
            provide: TABLE_MULTI_ROW_CONTEXT,
            useValue: { data: dataAccessor(row) }
          }
        ]
      })
    );
  }
}
