import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";

@Component({
  selector: "tpm-leave-action-dialog",
  templateUrl: "leave-action-dialog.component.html",
  styleUrls: ["leave-action-dialog.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, DialogFrameModule, EneButtonModule]
})
export class LeaveActionDialogDialogComponent {
  public readonly dialogRef = inject(MatLegacyDialogRef<LeaveActionDialogDialogComponent>);
}
