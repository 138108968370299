import { IKeycloakUserInfoDto } from "../dto/keycloak/keycloak-user-info";
import { IContactEmailBody, IContactForm } from "../interfaces/dialogs/contact";

export type ContactPayloadData = IContactForm & IKeycloakUserInfoDto & { municipality: string; tenantName: string };

export class ContactPayload {
  private tenantName: string;
  private customerSupportId: string;
  private municipality: string;
  private givenName: string;
  private familyName: string;
  private userEmail: string;
  private topic: string;
  private text: string;
  private occurred?: string;

  constructor(data: ContactPayloadData) {
    this.customerSupportId = data.customerSupportId || "";
    this.municipality = data.municipality;
    this.givenName = data.givenName;
    this.familyName = data.familyName;
    this.userEmail = data.email;
    this.topic = data.topic;
    this.text = data.text;
    this.tenantName = data.tenantName;
    this.occurred = data.occurred;
  }

  public getBody(): IContactEmailBody {
    return {
      tenantName: this.tenantName,
      customerSupportId: this.customerSupportId,
      municipality: this.municipality,
      userName: this.userName,
      userEmail: this.userEmail,
      url: this.url,
      topic: this.topic,
      text: this.text,
      type: this.topic,
      occured: this.occurred
    };
  }

  public get url(): string {
    return document.location.hostname;
  }

  public get userName(): string {
    return `${this.givenName || ""} ${this.familyName || ""}`;
  }
}
