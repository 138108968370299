import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ITableEntrySelector } from "src/app/common/components/table/interfaces/table-entry-selector";
import { INotificationSelect } from "../../../../../interfaces/dialogs/notification-settings";
import { ITableColumn } from "../../../../table/interfaces/table-column";
import { TABLE_PAGING_SERVICE } from "../../../../table/tokens/table-paging-service";
import { accountingBadges } from "../../constants/accounting-badges";
import { NEWS_BADGES } from "../../constants/news-badges";
import { NotificationSquares } from "../../constants/notification-type";
import { NotificationSettingsService } from "../../services/notification-settings.service";
import { Tab } from "../notification-settings/notification-settings.component";

@Component({
  selector: "app-multi-region",
  templateUrl: "./multi-region.component.html",
  styleUrls: ["./multi-region.component.scss"],
  viewProviders: [
    {
      provide: TABLE_PAGING_SERVICE,
      useExisting: NotificationSettingsService
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiRegionComponent implements OnInit {
  @Input() public columns: Array<ITableColumn>;
  @Input() public sortActive: string;
  @Input() public sortDirection: string;

  public selectedConfig: INotificationSelect;
  public showEditForm: boolean;
  public entrySelector: ITableEntrySelector;
  public legendSettings$: Observable<{ tabName: Tab }>;
  public total$: Observable<number>;
  constructor(protected readonly pagingService: NotificationSettingsService) {}

  ngOnInit(): void {
    this.total$ = this.pagingService.getTotal();
    this.legendSettings$ = this.pagingService.getTabName().pipe(
      map((tabName) => {
        switch (tabName) {
          case "incident-management":
            return { tabName, badges: Object.values(NotificationSquares) };
          case "three-year-plan":
            return {
              tabName,
              badges: [
                {
                  primary: {
                    type: "primary",
                    label: "APP.NETZEBW",
                    key: "NOTIFICATION_SERVICE.NETZEBW_DESCRIPTION"
                  },
                  secondary: {
                    type: "secondary",
                    label: "APP.MUNICIPALITY",
                    key: "NOTIFICATION_SERVICE.MUNICIPALITY_DESCRIPTION"
                  },
                  documents: {
                    id: "documents",
                    type: "documents",
                    label: "NOTIFICATION_SERVICE.DOCUMENTS",
                    key: "NOTIFICATION_SERVICE.DOCUMENT_DESCRIPTION",
                    class: "documents"
                  }
                }
              ]
            };
          case "accounting":
            return {
              tabName,
              badges: [accountingBadges]
            };
          case "news":
            return {
              tabName,
              badges: [NEWS_BADGES]
            };
        }
      })
    );
  }

  public onCellClick(event: INotificationSelect & { type: string }): void {
    switch (event.type) {
      case "selectOne":
        this.selectedConfig = event;
        this.entrySelector = { propertyName: "regionId", propertyValue: event.selected[0] };
        this.showEditForm = true;
        break;
      case "select":
        this.selectedConfig = event;
        this.entrySelector = null;
        break;
    }
  }

  public agsFilterChanged(ags: string): void {
    this.pagingService.setAgsFilter(ags);
  }

  public nameFilterChanged(name: string): void {
    this.pagingService.setNameFilter(name);
  }

  public closeEdit(): void {
    this.showEditForm = false;
    this.entrySelector = null;
  }
}
