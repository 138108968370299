<gp-formfield [label]="label" [class.disabled]="_disabled">
  <gp-radio
    global="true"
    [value]="_value"
    [disabled]="_disabled"
    [required]="required"
    [checked]="_checked"
    (change)="onChange($event)"
  ></gp-radio>
</gp-formfield>
