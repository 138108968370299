export interface IAttribute {
  value: string;
  name: string;
  year: number;
}

export interface IGasCockpitKpiDto {
  name: string;
  type: string;
  attributes: Array<IAttribute>;
}

export interface IGasNetworkLengthDto {
  totalLength: number;
  supplySegmentLength: number;
  h2SuitableSegments: number;
  h2UnsuitableSegments: number;
  h2SuitableSegmentFraction: number;
  h2UnsuitableSegmentFraction: number;
}

export class GasCockpitKpiDto implements IGasCockpitKpiDto {
  public name: string;
  public type: string;
  public attributes: Array<IAttribute>;

  constructor(data: IGasCockpitKpiDto) {
    this.name = data.name;
    this.type = data.type;
    this.attributes = data.attributes;
  }
}

export class GasNetworkLengthDto implements IGasNetworkLengthDto {
  public totalLength: number;
  public supplySegmentLength: number;
  public h2SuitableSegments: number;
  public h2UnsuitableSegments: number;
  public h2SuitableSegmentFraction: number;
  public h2UnsuitableSegmentFraction: number;

  constructor(data: IGasNetworkLengthDto) {
    Object.assign(this, data);
  }
}
