<mat-form-field [appearance]="appearance">
  <mat-label>{{ label }}</mat-label>
  <textarea
    matInput
    [formControl]="control"
    [maxlength]="maxlength"
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="minRows"
    [cdkAutosizeMaxRows]="maxRows"
    [required]="required"
    [placeholder]="placeholder"
    [name]="name"
  ></textarea>
</mat-form-field>
