import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { Pure } from "src/app/common/decorators/pure";
import { TableColumn } from "../../../classes/table-column";
import { BadgeTableColumnConfig } from "../../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-badge-column",
  templateUrl: "./badge-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BadgeColumnComponent<T> extends TableColumn {
  @Pure
  public getColor({ color = null, colorAccessor = () => null }: BadgeTableColumnConfig, row?: T): string | null {
    return color ?? colorAccessor(row);
  }

  @Pure
  public itemAccessor(columnConfig: BadgeTableColumnConfig, row: T): Array<Record<string, any>> {
    return "itemAccessor" in columnConfig ? columnConfig.itemAccessor(row) : [row];
  }
}
