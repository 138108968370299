import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-ca-confirm-dialog",
  templateUrl: "./confirm.component.html",
  styleUrls: ["./confirm.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigAreaConfirmDialogComponent implements OnInit {
  public confirmForm: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatLegacyDialogRef<ConfigAreaConfirmDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.confirmForm = this.fb.group({
      moduleConfigurationCorrect: [null, Validators.requiredTrue],
      coaCorrect: [null, Validators.requiredTrue],
      municipalityNamesCorrect: [null, Validators.requiredTrue]
    });
  }

  public closeDialog(submit: boolean = false): void {
    this.dialogRef.close(submit);
  }
}
