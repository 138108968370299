import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EneIconModule } from "@enersis/ui-icon";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "light-button",
  templateUrl: "./light-button.component.html",
  standalone: true,
  styleUrls: ["./light-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, EneIconModule]
})
export class LightButtonComponent {
  @Input() label: string;
}
