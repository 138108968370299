import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyTableModule } from "@angular/material/legacy-table";
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip";
import { MatSortModule } from "@angular/material/sort";
import { EneButtonModule } from "@enersis/ui-button";
import { EneIconModule } from "@enersis/ui-icon";
import { EneTabsModule } from "@enersis/ui-tabs";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonToggleModule } from "src/app/common/components/button-toggle/button-toggle.module";
import { SquareModule } from "src/app/common/components/dialogs/notification-settings/components/square/square.module";
import { TableModule } from "src/app/common/components/table/table.module";
import { TableFilterModule } from "src/app/slots/three-year-plan/common/components/table/components/table-filter/table-filter.module";
import { TableModule as NgmyTableModule } from "src/app/slots/three-year-plan/common/components/table/table.module";
import { ConfigurationAreaDownloadsComponent } from "./downloads/configuration-area-downloads.component";
import { ModuleInfoColumnComponent } from "./modules-tab/components/module-info-cell/module-info-column.component";
import { MunicipalityInfoColumnComponent } from "./modules-tab/components/municipality-info-cell/municipality-info-column.component";
import { CAModulesTabComponent } from "./modules-tab/container/modules-tab.component";
import { RegionCellComponent } from "./notification-settings/cells/region-cell.component";
import { NestedTableComponent } from "./notification-settings/nested-table/nested-table.component";
import { CANotificationSettingsComponent } from "./notification-settings/notification-settings.component";
import { SeparatedByTypeComponent } from "./notification-settings/separated-by-type/separated-by-type.component";
import { TableExpandableRowsComponent } from "./notification-settings/table/table-expandable-rows.component";

const components = [
  CAModulesTabComponent,
  CANotificationSettingsComponent,
  ConfigurationAreaDownloadsComponent,
  NestedTableComponent,
  SeparatedByTypeComponent,
  TableExpandableRowsComponent
];

@NgModule({
  declarations: [ModuleInfoColumnComponent, MunicipalityInfoColumnComponent, RegionCellComponent, ...components],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EneTabsModule,
    EneButtonModule,
    EneIconModule,
    NgmyTableModule,
    MatLegacyCheckboxModule,
    MatButtonToggleModule,
    MatExpansionModule,
    TableModule,
    MatLegacyTooltipModule,
    SquareModule,
    MatLegacyTableModule,
    MatLegacyPaginatorModule,
    MatSortModule,
    TableFilterModule,
    ButtonToggleModule
  ],
  exports: [...components]
})
export class ConfigurationAreaContentModule {}
