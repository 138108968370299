import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { INotificationSettings } from "../../../../../../../interfaces/dialogs/notification-settings";

@Component({
  selector: "app-typ-notification-settings-edit-email",
  templateUrl: "./notification-settings-edit-email.component.html",
  styleUrls: ["../../style.scss"]
})
export class TYPNotificationSettingsEditEmailComponent {
  @Input() public formGroup: FormGroup;
  @Input() public indeterminate: INotificationSettings;
  public netzebwBadge = { id: "start", label: "APP.NETZEBW", class: "primary" };
  public municipalBadge = { id: "intermediate", label: "APP.MUNICIPALITY", class: "secondary" };
  public documentsBadge = { id: "documents", label: "NOTIFICATION_SERVICE.DOCUMENTS", class: "documents" };
}
