<div *ngIf="showPaginationAbove" class="tpm-paginator-above-outer-container">
  <ng-content select="[above]"></ng-content>
  <ng-container *ngTemplateOutlet="pagination"></ng-container>
</div>
<ng-content></ng-content>
<div *ngIf="showPaginationBelow" class="tpm-paginator-below-outer-container">
  <ng-content select="[below]"></ng-content>
  <ng-container *ngTemplateOutlet="pagination"></ng-container>
</div>

<ng-template #pagination>
  <div class="tpm-paginator-container">
    <div class="tpm-paginator-page-size" *ngIf="_displayedPageSizeOptions.length > 1">
      <div class="tpm-paginator-page-size-label">
        {{ _intl.itemsPerPageLabel }}
      </div>
      <mat-select
        class="tpm-paginator-page-size-select"
        [value]="pageSize"
        [disabled]="disabled"
        [panelClass]="selectConfig.panelClass || ''"
        [disableOptionCentering]="selectConfig.disableOptionCentering"
        [aria-label]="_intl.itemsPerPageLabel"
        (selectionChange)="_changePageSize($event.value)"
      >
        <mat-option *ngFor="let pageSizeOption of _displayedPageSizeOptions" [value]="pageSizeOption">
          {{ pageSizeOption === 999999999 ? _intl.pageSizeAllOption : pageSizeOption }}
        </mat-option>
      </mat-select>
    </div>
    <div class="tpm-paginator-range-actions">
      <div class="tpm-paginator-range-label">
        {{ _intl.getRangeLabel(pageIndex, pageSize, length) }}
      </div>
      <button
        mat-icon-button
        type="button"
        class="tpm-paginator-navigation-first"
        (click)="firstPage()"
        [attr.aria-label]="_intl.firstPageLabel"
        [matTooltip]="_intl.firstPageLabel"
        [matTooltipDisabled]="_previousButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_previousButtonsDisabled()"
        *ngIf="showFirstLastButtons"
      >
        <svg class="tpm-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z" />
        </svg>
      </button>
      <button
        mat-icon-button
        type="button"
        class="tpm-paginator-navigation-previous"
        (click)="previousPage()"
        [attr.aria-label]="_intl.previousPageLabel"
        [matTooltip]="_intl.previousPageLabel"
        [matTooltipDisabled]="_previousButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_previousButtonsDisabled()"
      >
        <svg class="tpm-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      </button>
      <button
        mat-icon-button
        type="button"
        class="tpm-paginator-navigation-next"
        (click)="nextPage()"
        [attr.aria-label]="_intl.nextPageLabel"
        [matTooltip]="_intl.nextPageLabel"
        [matTooltipDisabled]="_nextButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_nextButtonsDisabled()"
      >
        <svg class="tpm-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
        </svg>
      </button>
      <button
        mat-icon-button
        type="button"
        class="tpm-paginator-navigation-last"
        (click)="lastPage()"
        [attr.aria-label]="_intl.lastPageLabel"
        [matTooltip]="_intl.lastPageLabel"
        [matTooltipDisabled]="_nextButtonsDisabled()"
        [matTooltipPosition]="'above'"
        [disabled]="_nextButtonsDisabled()"
        *ngIf="showFirstLastButtons"
      >
        <svg class="tpm-paginator-icon" viewBox="0 0 24 24" focusable="false">
          <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z" />
        </svg>
      </button>
    </div>
  </div>
</ng-template>
