import { Directionality } from "@angular/cdk/bidi";
import {
  Overlay,
  OverlayKeyboardDispatcher,
  OverlayOutsideClickDispatcher,
  OverlayPositionBuilder,
  ScrollStrategyOptions
} from "@angular/cdk/overlay";
import { DOCUMENT, Location } from "@angular/common";
import { ComponentFactoryResolver, Inject, Injectable, Injector, NgZone } from "@angular/core";
import { DynamicOverlayContainer } from "./dynamic-overlay-container";

@Injectable({ providedIn: "root" })
export class DynamicOverlay extends Overlay {
  private readonly dynamicOverlayContainer: DynamicOverlayContainer;

  constructor(
    scrollStrategies: ScrollStrategyOptions,
    overlayContainer: DynamicOverlayContainer,
    componentFactoryResolver: ComponentFactoryResolver,
    positionBuilder: OverlayPositionBuilder,
    keyboardDispatcher: OverlayKeyboardDispatcher,
    injector: Injector,
    ngZone: NgZone,
    @Inject(DOCUMENT) document: Document,
    directionality: Directionality,
    location: Location,
    outsideClickDispatcher: OverlayOutsideClickDispatcher
  ) {
    super(
      scrollStrategies,
      overlayContainer,
      componentFactoryResolver,
      positionBuilder,
      keyboardDispatcher,
      injector,
      ngZone,
      document,
      directionality,
      location,
      outsideClickDispatcher
    );

    this.dynamicOverlayContainer = overlayContainer;
  }

  public setMainOverlay(isMain: boolean): void {
    this.dynamicOverlayContainer.setMainOverlay(isMain);
  }

  public clearOverlayContainer(): void {
    this.dynamicOverlayContainer.clearContainerElement();
  }
}
