import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { CardModule } from "src/app/common/components/card/card.module";
import { ContentfulMediaComponent } from "src/app/common/components/contentful-media/contentful-media.component";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { OrderFormEntry } from "src/app/core/interfaces/contentful";
import { OrderFormComponent } from "../components/order-form/order-form.component";
import { CampusOneOrderModuleComponentStore, FormSubmitBody } from "./order-module-dialog.component-store";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-order-module-dialog",
  templateUrl: "./order-module-dialog.component.html",
  styleUrls: ["./order-module-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DialogFrameModule,
    CardModule,
    PipesModule,
    OrderFormComponent,
    ContentfulMediaComponent
  ],
  providers: [CampusOneOrderModuleComponentStore]
})
export class OrderModuleDialogComponent {
  public readonly orderFormContent$ = this.componentStore.orderFormContent$;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public readonly matDialogData: { moduleId: string; orderForm: OrderFormEntry },
    public readonly dialogRef: MatLegacyDialogRef<OrderModuleDialogComponent>,
    private readonly componentStore: CampusOneOrderModuleComponentStore
  ) {
    this.componentStore.setOrderForm({ orderForm: this.matDialogData.orderForm });
  }

  public onSubmit(body: Omit<FormSubmitBody, "moduleId">): void {
    this.componentStore.submit({ body: { ...body, moduleId: this.matDialogData.moduleId }, dialogRef: this.dialogRef });
  }
}
