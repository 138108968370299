import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { distinctUntilChanged, filter, skip, startWith, takeUntil } from "rxjs/operators";
import { PhoneNumberInputComponent } from "src/app/common/components/form/phone-number-input/phone-number-input.component";
import { Division } from "src/app/core/interfaces/division";
import { Square } from "../../../square/square.component";

@Component({
  selector: "app-im-notification-settings-edit-phone",
  templateUrl: "./notification-settings-edit-phone.component.html",
  styleUrls: ["../../style.scss"]
})
export class IMNotificationSettingsEditPhoneComponent implements OnDestroy, OnInit {
  @Input() public formGroup: FormGroup;
  @Input() public indeterminate: any;
  @Input() public division: Division;
  @Input() public notificationSquares: { [key: string]: Square };
  @ViewChild("phoneInput") public readonly phoneInput: PhoneNumberInputComponent;

  public checkboxWarning: AbstractControl;

  public get allowNotifyBySMSControl(): AbstractControl | null {
    return this.formGroup.get("allowNotifyBySMS");
  }

  public get startEndControl(): AbstractControl | null {
    return this.formGroup.get("startEnd");
  }
  public get verifyPhoneControl(): AbstractControl | null {
    return this.formGroup.get("verifyPhone");
  }
  public get phoneControl(): AbstractControl | null {
    return this.formGroup.get("phone");
  }
  private readonly destroy$ = new Subject<void>();

  public validateCheckboxes(control?: AbstractControl): void {
    if (!this.allowNotifyBySMSControl?.value) {
      return;
    }

    if (!control || control.value) {
      this.phoneInput.control.setErrors(null);
      this.checkboxWarning = undefined;
      return;
    }

    this.checkboxWarning = control;

    if (!this.phoneInput.control.value) {
      this.phoneInput.control.setErrors({ emptyPhone: true });
      this.phoneInput.control.markAsTouched();
      this.phoneInput.detectChanges();
    }
  }

  public ngOnInit(): void {
    this.updatePhoneCheckboxes();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updatePhoneCheckboxes(): void {
    this.verifyPhoneControl.valueChanges.pipe(filter(Boolean), takeUntil(this.destroy$)).subscribe((value) => {
      if (!this.phoneControl.value && value) {
        this.phoneInput.control.setErrors({ verifyPhone: true });
        this.phoneInput.control.markAsTouched();
        this.phoneInput.detectChanges();
      }
    });

    this.phoneControl.valueChanges
      .pipe(startWith(this.phoneControl.value), distinctUntilChanged(), skip(1), takeUntil(this.destroy$))
      .subscribe(() => {
        this.verifyPhoneControl.patchValue(false);
      });
  }
}
