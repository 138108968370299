export interface IGasStationDto {
  workload: number;
  peakHourlyCapacity: number;
  cumulatedInvestment: number;
}

export class GasStationDto implements IGasStationDto {
  public workload: number;
  public peakHourlyCapacity: number;
  public cumulatedInvestment: number;

  constructor(data: IGasStationDto) {
    Object.assign(this, data);
  }
}
