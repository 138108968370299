import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { RegionsEMobilityDto } from "../../../common/dto/energy/regions-e-mobility";
import { EnergyService } from "../../../core/services/energy.service";
import {
  getRegionsEMobilityAllYearsData,
  getRegionsEMobilityAllYearsDataSuccess
} from "../../actions/energy/regions-e-mobility.actions";

@Injectable()
export class RegionEMobilityEffects {
  getRegionsEMobilityAllYearsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRegionsEMobilityAllYearsData),
      switchMap((action) =>
        this.energyService.getRegionsEMobilityAllYearsData(action).pipe(
          map((payload: Array<RegionsEMobilityDto>) => getRegionsEMobilityAllYearsDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private energyService: EnergyService) {}
}
