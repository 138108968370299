import { Action, createReducer, on } from "@ngrx/store";
import { ICo2SavingPerYearDto, ICo2SavingYearDto } from "../../../common/dto/energy/co2-saving";
import * as Co2SavingActions from "../../actions/energy/co2-saving.actions";

export interface State {
  co2SavingYear: ICo2SavingYearDto;
  co2SavingAllYears: Array<ICo2SavingPerYearDto>;
}

const initialState: State = {
  co2SavingYear: {
    electricity: undefined,
    gas: undefined
  },
  co2SavingAllYears: undefined
};

const co2SavingReducer = createReducer(
  initialState,
  on(Co2SavingActions.getCo2SavingData, (state) => ({
    ...state,
    co2SavingYear: {
      electricity: undefined,
      gas: undefined
    }
  })),
  on(Co2SavingActions.getCo2SavingDataSuccess, (state, { payload }) => ({ ...state, co2SavingYear: payload })),
  on(Co2SavingActions.getCo2SavingAllYearsData, (state) => ({
    ...state,
    co2SavingAllYears: undefined
  })),
  on(Co2SavingActions.getCo2SavingAllYearsDataSuccess, (state, { payload }) => ({
    ...state,
    co2SavingAllYears: payload
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return co2SavingReducer(state, action);
}
