import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, ViewEncapsulation } from "@angular/core";
import { KPI_ITEM_MAP } from "../../constants/kpi-map";
import { IKpiItem, IKpiItemMap } from "../../interfaces/kpi-item";

export type ValueType = "number" | "string" | "undefined";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "kpi-box",
  templateUrl: "./kpi-box.component.html",
  styleUrls: ["./kpi-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: "kpi-box"
  }
})
export class KpiBoxComponent {
  @Input()
  public set name(_name: string) {
    this._name = _name;
    this.config = this.kpiItemMap[this._name] || this.kpiItemMap.placeholder;
  }

  @Input()
  public set value(_value: string | number) {
    this._value = _value ?? undefined;
    this.valueType = typeof this._value as ValueType;
  }

  public _name: string;
  public _value: string | number;

  public valueType: ValueType;
  public config: IKpiItem;

  constructor(
    @Inject(KPI_ITEM_MAP) private readonly kpiItemMap: IKpiItemMap,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public detectChanges(): void {
    this.cdr.detectChanges();
  }
}
