import { Action, createReducer, on } from "@ngrx/store";
import * as ConsumptionActions from "../../actions/energy/consumption.actions";

export interface State {
  electricity: number;
  gas: number;
}

const initialState: State = {
  electricity: undefined,
  gas: undefined
};

const consumptionReducer = createReducer(
  initialState,
  on(ConsumptionActions.getConsumptionData, (state) => ({ ...state, electricity: undefined, gas: undefined })),
  on(ConsumptionActions.getConsumptionDataSuccess, (state, { payload }) => ({ ...state, ...payload }))
);

export function reducer(state: State | undefined, action: Action): State {
  return consumptionReducer(state, action);
}
