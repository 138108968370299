import { Action, createReducer, on } from "@ngrx/store";
import * as BuildingActions from "../../actions/infrastructure/building.actions";

export interface State {
  sumPVPotential: number;
  region: {
    countBuildings: number;
    sumPVPotential: number;
    sumPVSuitability: number;
    countBuildingCompaction: number;
    loading: boolean;
  };
  buildings: {
    result: any;
    loading: boolean;
  };
}

const initialState: State = {
  sumPVPotential: undefined,
  region: {
    countBuildingCompaction: undefined,
    countBuildings: undefined,
    sumPVPotential: undefined,
    sumPVSuitability: undefined,
    loading: true
  },
  buildings: {
    result: undefined,
    loading: true
  }
};

const buildingReducer = createReducer(
  initialState,
  on(BuildingActions.getBuildingByRegionIdData, (state) => ({
    ...state,
    region: {
      countBuildingCompaction: undefined,
      countBuildings: undefined,
      sumPVPotential: undefined,
      sumPVSuitability: undefined,
      loading: true
    }
  })),
  on(BuildingActions.getBuildingByRegionIdDataSuccess, (state, { payload }) => ({
    ...state,
    region: {
      ...payload,
      loading: false
    }
  })),
  on(BuildingActions.getBuildingsData, (state) => ({
    ...state,
    buildings: {
      result: undefined,
      loading: true
    }
  })),
  on(BuildingActions.getBuildingsDataSuccess, (state, { payload }) => ({
    ...state,
    buildings: {
      result: payload,
      loading: false
    }
  })),
  on(BuildingActions.clearBuildingsData, (state) => ({
    ...state,
    buildings: {
      result: undefined,
      loading: false
    }
  }))
);

export function reducer(state: State | undefined, action: Action): State {
  return buildingReducer(state, action);
}
