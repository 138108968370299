<div *ngIf="vm$ | async as vm" class="ca-main">
  <ng-container *ngTemplateOutlet="multiButtonTemplate"></ng-container>
  <h2>{{ "Kommunenauswahl" | translate }}</h2>
  <ngmy-table
    class="modules-table"
    selections
    [scrollableY]="true"
    [disableSort]="false"
    [sortActive]="'name'"
    [sortDirection]="'asc'"
    [pagingConfig]="pagingConfig"
    [columnsConfig]="vm.columns"
    [loading]="false"
    [data]="vm.list"
    (tableAction)="onTableEvent($event)"
  ></ngmy-table>
  <ng-container *ngTemplateOutlet="multiButtonTemplate"></ng-container>
</div>
<ng-template #multiButtonTemplate>
  <button
    ene-filled-button
    size="large"
    icon="ec_select-all"
    [disabled]="!selected.length"
    (click)="openDialog(selected, selected.length > 1)"
  >
    {{ "EINSTELLUNGEN FÜR GEWÄHLTE KOMMUNEN" | translate }}
  </button>
</ng-template>
