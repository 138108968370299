import { createAction, props } from "@ngrx/store";
import { UsersSettingsUsersStorageItem } from "src/app/core/interfaces/users-settings";

export const fetchUsersSettingsUsersStorage = createAction("[Users Settings] Fetch users storage");
export const setUsersSettingsUsersStorage = createAction(
  "[Users Settings] Set users storage",
  props<{ result: Array<UsersSettingsUsersStorageItem> }>()
);
export const updateUsersSettingsUsersStorage = createAction(
  "[Users Settings] Update users storage item",
  props<{ items: Array<UsersSettingsUsersStorageItem> }>()
);
