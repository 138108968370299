import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { TableActionColumn } from "../../../classes/table-action-column";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-action-icon-column",
  templateUrl: "./action-icon-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionIconColumnComponent extends TableActionColumn {}
