import { createAction, props } from "@ngrx/store";
import { IPanel } from "../reducers/sidenavigation.reducer";

export const registerPanels = createAction(
  "[Sidenav] register new Panels",
  props<{
    panels: Array<{
      name: string;
      initialState: IPanel;
    }>;
  }>()
);

export const changePanelState = createAction(
  "[Sidenav] Change Panel State",
  props<{
    panelName: string;
    stateChange: Partial<IPanel>;
  }>()
);

export const toggleEnlarge = createAction("[Sidenav] Toggle panel enlarge", props<{ panelName: string }>());

export const toggleVisible = createAction("[Sidenav] Toggle visible", props<{ panelName: string }>());

export const openPanel = createAction("[Sidenav] Open panel", props<{ panelName: string }>());

export const openSinglePanel = createAction(
  "[Sidenav] Open panel or panel group at a time. Close all others except the timeline",
  props<{ panelName: string; panelGroup?: Array<string>; toggleOff?: boolean }>()
);

export const closePanel = createAction("[Sidenav] Close panel", props<{ panelName: string }>());
export const closeAllPanels = createAction("[Sidenav] Close all panels");
