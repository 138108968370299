import { Overlay, OverlayContainer } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MAT_EXPANSION_PANEL_DEFAULT_OPTIONS } from "@angular/material/expansion";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MAT_LEGACY_DATE_FORMATS, MAT_LEGACY_DATE_LOCALE } from "@angular/material/legacy-core";
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS } from "@angular/material/legacy-dialog";
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS, MatLegacySnackBar } from "@angular/material/legacy-snack-bar";
import { EneIconModule } from "@enersis/ui-icon";
import { TranslateModule } from "@ngx-translate/core";
import { LocaleCode } from "src/app/common/enums/locale-code";
import { datepickerDefaultFormats } from "./datepicker-formats/datepicker-formats";
import { dialogDefaultOptions } from "./dialog/dialog-options";
import { DynamicOverlay } from "./dynamic-overlay/dynamic-overlay";
import { DynamicOverlayContainer } from "./dynamic-overlay/dynamic-overlay-container";
import { expansionPanelDefaultOptions } from "./expansion-panel/expansion-panel";
import { DynamicSnackBar } from "./snack-bar/snack-bar";
import { snackBarDefaultOptions } from "./snack-bar/snack-bar-options";
import { SnackBarComponent } from "./snack-bar/snack-bar.component";

@NgModule({
  declarations: [SnackBarComponent],
  imports: [CommonModule, MatLegacyButtonModule, EneIconModule, TranslateModule],
  providers: [
    { provide: MAT_LEGACY_DIALOG_DEFAULT_OPTIONS, useValue: dialogDefaultOptions },
    { provide: MAT_EXPANSION_PANEL_DEFAULT_OPTIONS, useValue: expansionPanelDefaultOptions },
    { provide: MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS, useValue: snackBarDefaultOptions },
    { provide: MAT_LEGACY_DATE_FORMATS, useValue: datepickerDefaultFormats },
    { provide: MAT_LEGACY_DATE_LOCALE, useValue: LocaleCode.de_DE },
    { provide: MatLegacySnackBar, useExisting: DynamicSnackBar },
    { provide: Overlay, useExisting: DynamicOverlay },
    { provide: OverlayContainer, useExisting: DynamicOverlayContainer }
  ]
})
export class AppMaterialModule {}
