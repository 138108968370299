export interface IEMobilityConnectionGroupsDto {
  count: number;
  capacity: number;
  consumption: number;
  co2Saving: number;
}

export class EMobilityConnectionGroupsDto implements IEMobilityConnectionGroupsDto {
  public count: number;
  public capacity: number;
  public consumption: number;
  public co2Saving: number;

  constructor(data: IEMobilityConnectionGroupsDto) {
    Object.assign(this, data);
  }
}
