import { Directive, HostListener, Input, OnInit } from "@angular/core";
import { MatLegacyTooltip } from "@angular/material/legacy-tooltip";

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: "[matTooltip][tooltipIfTruncated]" })
export class TooltipIfTruncatedDirective implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("tooltipIfTruncatedDisabled") public disabled: boolean = false;

  constructor(private readonly matTooltip: MatLegacyTooltip) {}

  @HostListener("mouseover", ["$event.target"])
  public onMouseover(element: HTMLElement): void {
    this.matTooltip.disabled = this.disabled || !(element?.offsetWidth < element?.scrollWidth);
  }

  public ngOnInit(): void {
    this.matTooltip.disabled = this.disabled;
  }
}
