import { createAction, props } from "@ngrx/store";
import { SelfSufficiencyConsumptionDto } from "../../../../common/dto/energy/self-sufficiency/consumption";

export const getSelfSufficiencyConsumptionData = createAction(
  "[SelfSufficiency Consumption] Get consumption data",
  props<{
    regionId: string;
    scenarioId: string;
    fromUTC: number;
    toUTC: number;
  }>()
);

export const getSelfSufficiencyConsumptionDataSuccess = createAction(
  "[SelfSufficiency Consumption] Get consumption success",
  props<{
    payload: SelfSufficiencyConsumptionDto;
  }>()
);
