import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { TranslateModule } from "@ngx-translate/core";
import { PhoneNumberInputComponent } from "./phone-number-input.component";

@NgModule({
  declarations: [PhoneNumberInputComponent],
  imports: [CommonModule, ReactiveFormsModule, MatLegacyInputModule, MatLegacyFormFieldModule, TranslateModule],
  exports: [PhoneNumberInputComponent]
})
export class PhoneNumberInputModule {}
