import { ChangeDetectionStrategy, Component, Inject, Optional } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { WebinarsEntry } from "../../webinars.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-webinar-dialog",
  templateUrl: "./webinar-dialog.component.html",
  styleUrls: ["./webinar-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebinarDialogComponent {
  constructor(@Optional() @Inject(MAT_LEGACY_DIALOG_DATA) public readonly data: WebinarsEntry) {}
}
