import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { MediaPlayerModule } from "src/app/common/components/media-player/media-player.module";
import { WebinarDialogComponent } from "./webinar-dialog.component";

@NgModule({
  declarations: [WebinarDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MediaPlayerModule,
    MatLegacyDialogModule,
    MatIconModule,
    MatLegacyButtonModule
  ]
})
export class WebinarDialogModule {}
