import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { catchError, exhaustMap, map, switchMap } from "rxjs/operators";
import { BuildingDto } from "../../../common/dto/infrastructure/building";
import { InfrastructureService } from "../../../core/services/infrastructure.service";
import {
  clearBuildingsData,
  getBuildingByRegionIdData,
  getBuildingByRegionIdDataSuccess,
  getBuildingsData,
  getBuildingsDataSuccess
} from "../../actions/infrastructure/building.actions";

@Injectable()
export class BuildingEffects {
  getBuilding$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuildingByRegionIdData),
      exhaustMap((action) =>
        this.infrastructureService.getBuildingByRegionIdData(action).pipe(
          map((payload: BuildingDto) => getBuildingByRegionIdDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getBuildingsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBuildingsData),
      switchMap((action) =>
        this.infrastructureService.getBuildingsData(action).pipe(
          map((payload) => getBuildingsDataSuccess({ payload })),
          catchError(() => of(clearBuildingsData()))
        )
      )
    )
  );

  constructor(private actions$: Actions, private infrastructureService: InfrastructureService) {}
}
