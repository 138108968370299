import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormFieldControl } from "../form-field-control";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-input-field",
  templateUrl: "./input-field.component.html",
  styleUrls: ["./input-field.component.scss", "../form-field.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFieldComponent extends FormFieldControl {
  @Input() public type: "text" | "number" = "text";
  @Input() public maxlength: number | null = null;
  @Input() public placeholder: string | null = null;
  @Input() public name: string | null = null;

  public onKeyDown(event: any): boolean {
    return this.maxlength !== null ? (event.target.value || "").length <= this.maxlength : true;
  }
}
