import { Type } from "@angular/core";
import { ActionColumnComponent } from "./components/columns/action-column/action-column.component";
import { BadgeColumnComponent } from "./components/columns/badge-column/badge-column.component";
import { CheckboxColumnComponent } from "./components/columns/checkbox-column/checkbox-column.component";
import { CombinedValuesColumnComponent } from "./components/columns/combined-values-column/combined-values-column.component";
import { IconColumnComponent } from "./components/columns/icon-column/icon-column.component";
import { ImageColumnComponent } from "./components/columns/image-column/image-column.component";
import { LinkColumnComponent } from "./components/columns/link-column/link-column.component";
import { MenuColumnComponent } from "./components/columns/menu-column/menu-column.component";
import { MultiRowExpandColumnComponent } from "./components/columns/multi-row-expand-column/multi-row-expand-column.component";
import { SelectColumnComponent } from "./components/columns/select-column/select-column.component";
import { StateColumnComponent } from "./components/columns/state-column/state-column.component";
import { TextualColumnComponent } from "./components/columns/textual-column/textual-column.component";
import { TableColumnType } from "./table.interfaces";

export const TABLE_COLUMNS = {
  Empty: "empty",
  Text: "text",
  Value: "value",
  List: "list",
  Date: "date",
  Action: "action",
  Icon: "icon",
  State: "state",
  Image: "image",
  Badge: "badge",
  Button: "button",
  NotificationSettings: "notification-settings",
  CombinedValues: "combined-values",
  Custom: "custom",
  Link: "link",
  Email: "email",
  Checkbox: "checkbox",
  Select: "select",
  Menu: "menu",
  ActionIcon: "action-icon",
  MultiRowExpand: "multi-row-expand"
} as const;

export const TABLE_COLUMNS_COMPONENTS = new Map<TableColumnType, Type<any>>([
  ["multi-row-expand", MultiRowExpandColumnComponent],
  ["action", ActionColumnComponent],
  ["button", ActionColumnComponent],
  ["icon", IconColumnComponent],
  ["state", StateColumnComponent],
  ["image", ImageColumnComponent],
  ["badge", BadgeColumnComponent],
  ["text", TextualColumnComponent],
  ["value", TextualColumnComponent],
  ["list", TextualColumnComponent],
  ["date", TextualColumnComponent],
  ["text", TextualColumnComponent],
  ["combined-values", CombinedValuesColumnComponent],
  ["link", LinkColumnComponent],
  ["email", LinkColumnComponent],
  ["checkbox", CheckboxColumnComponent],
  ["select", SelectColumnComponent],
  ["menu", MenuColumnComponent]
]);
