export const Colors = {
  Primary_700: "#061671",
  Primary_600: "#000099",
  Primary_500: "#374a9a",
  Primary_400: "#848fcf",
  Primary_300: "#8aaddc",
  Primary_200: "#b5def2",
  Primary_100: "#c4d6ed",
  Primary_50: "#d8e3f3",

  Secondary_700: "#8a4500",
  Secondary_600: "#bd5e00",
  Secondary_500: "#ee7700",
  Secondary_400: "#ff9900",
  Secondary_300: "#ffad33",
  Secondary_200: "#ffc266",
  Secondary_100: "#ffd699",
  Secondary_50: "#ffebcc",

  Grey_800: "#212121",
  Grey_700: "#3b3b3b",
  Grey_600: "#545454",
  Grey_500: "#828282",
  Grey_400: "#969696",
  Grey_300: "#b1b1b1",
  Grey_200: "#cccccc",
  Grey_100: "#e3e3e3",
  Grey_50: "#f2f2f2",

  White: "#ffffff",
  Black: "#000000",

  Error_500: "#862525",
  Error_300: "#b50015",
  Error_100: "#e2001a",
  Error_50: "#ee6676",

  Success_500: "#045343",
  Success_300: "#06856c",
  Success_100: "#08b593",
  Success_50: "#76d8b3",

  Yellow_700: "#958300",
  Yellow_600: "#b29d00",
  Yellow_500: "#ccb300",
  Yellow_400: "#e5ca00",
  Yellow_300: "#ffe000",
  Yellow_200: "#ffe93f",
  Yellow_100: "#fff27e",
  Yellow_50: "#fff6b3",

  Sand_700: "#6f5000",
  Sand_600: "#a57409",
  Sand_500: "#d5960b",
  Sand_400: "#f3b120",
  Sand_300: "#f6c251",
  Sand_200: "#f8d381",
  Sand_100: "#fbe4b1",
  Sand_50: "#fdf1d8",

  Green_700: "#34430a",
  Green_600: "#4f660f",
  Green_500: "#719315",
  Green_400: "#94c11c",
  Green_300: "#abdf20",
  Green_200: "#c1e75a",
  Green_100: "#d1ed87",
  Green_50: "#e4f4b8",

  Emerald_700: "#0f4324",
  Emerald_600: "#176738",
  Emerald_500: "#168144",
  Emerald_400: "#149a4f",
  Emerald_300: "#1ac766",
  Emerald_200: "#2fe47f",
  Emerald_100: "#78edab",
  Emerald_50: "#aef4cd",

  Dashboard_Resolved: "#6DAB2F",

  Teal_900: "#114f52",
  Teal_700: "#228A97",
  Teal_600: "#005b66",
  Teal_500: "#007585",
  Teal_400: "#4fbbcc",
  Teal_300: "#37afbf",
  Teal_200: "#80d1dc",
  Teal_100: "#c9f3f8",
  Teal_50: "#e4f9fb",

  Light_blue_700: "#114864",
  Light_blue_600: "#196790",
  Light_blue_500: "#2086bb",
  Light_blue_400: "#33a2db",
  Light_blue_300: "#5eb6e3",
  Light_blue_200: "#8acaeb",
  Light_blue_100: "#b5def2",
  Light_blue_50: "#e1f1fa",

  Blue_700: "#030c3f",
  Blue_600: "#06166f",
  Blue_500: "#081fa0",
  Blue_400: "#0b29d0",
  Blue_300: "#1f72e8",
  Blue_200: "#33bbff",
  Blue_100: "#70cfff",
  Blue_50: "#ade4ff",

  Purple_700: "#3d0a38",
  Purple_600: "#4a1d51",
  Purple_500: "#572f6a",
  Purple_400: "#744b89",
  Purple_300: "#9066a7",
  Purple_200: "#b194c1",
  Purple_100: "#d3c2dc",
  Purple_50: "#e8dfec",

  Pink_700: "#7f106f",
  Pink_600: "#b5179f",
  Pink_500: "#da1bbe",
  Pink_400: "#e741cf",
  Pink_300: "#ed6edb",
  Pink_200: "#f29be6",
  Pink_100: "#f8c7f1",
  Pink_50: "#fee8fb",

  Crimson_700: "#431414",
  Crimson_600: "#571a1a",
  Crimson_500: "#6a2020",
  Crimson_400: "#932c2c",
  Crimson_300: "#b83737",
  Crimson_200: "#cc5656",
  Crimson_100: "#d98282",
  Crimson_50: "#e7b1b1",

  Coral_700: "#491704",
  Coral_600: "#772709",
  Coral_500: "#ab380d",
  Coral_400: "#e64b11",
  Coral_300: "#f06a38",
  Coral_200: "#f48d67",
  Coral_100: "#f48d67",
  Coral_50: "#fbd4c6",

  Brown_700: "#402f21",
  Brown_600: "#624732",
  Brown_500: "#825f43",
  Brown_400: "#a57855",
  Brown_300: "#b89375",
  Brown_200: "#cbb09a",
  Brown_100: "#dccabc",
  Brown_50: "#eee5dd"
};
