import { IGpViewerFacade, IGpViewerState } from "@enersis/gp-components/gp-map2-viewer";
import { Action, createReducer, on } from "@ngrx/store";
import { BuildingsConnectionGroupDto } from "src/app/common/dto/map/buildings-connection-group";
import { IMapFeature } from "src/app/common/interfaces/map/map-feature";
import * as MapActions from "../actions/map.actions";

export interface State {
  selectedFeature: IMapFeature;
  buildingsConnectionGroup: BuildingsConnectionGroupDto;
  isLegendKeepClosed: boolean;
  mapState: {
    state: IGpViewerState;
    viewer: IGpViewerFacade;
  };
}

const initialState: State = {
  selectedFeature: null,
  buildingsConnectionGroup: null,
  isLegendKeepClosed: false,
  mapState: null
};

function changeActiveConnectionGroup(state: BuildingsConnectionGroupDto, id: string): BuildingsConnectionGroupDto {
  return Object.keys(state).reduce(
    (acc, key) => ({ ...acc, [key]: { ...state[key], isActive: state[key].type === id } }),
    {}
  ) as BuildingsConnectionGroupDto;
}

const mapReducer = createReducer(
  initialState,
  on(MapActions.selectMapFeature, (state, { feature }) => ({ ...state, selectedFeature: feature })),
  on(MapActions.clearMapFeature, (state) => ({ ...state, selectedFeature: null })),
  on(MapActions.getBuildingsConnectionGroupSuccess, (state, { payload }) => ({
    ...state,
    buildingsConnectionGroup: payload
  })),
  on(MapActions.changeActiveConnectionGroup, (state, { id }) => ({
    ...state,
    buildingsConnectionGroup: changeActiveConnectionGroup(state.buildingsConnectionGroup, id)
  })),
  on(MapActions.clearBuildingsConnectionGroup, (state) => ({ ...state, buildingsConnectionGroup: null })),
  on(MapActions.keepLegendClosed, (state, { isClosed: isClosed }) => ({ ...state, isLegendKeepClosed: isClosed }))
);

export function reducer(state: State | undefined, action: Action): State {
  return mapReducer(state, action);
}
