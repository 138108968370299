<div class="header">
  <img src="/assets/images/logos/tenant-0001.svg" alt="Netze BW Logo" />
</div>
<div class="container">
  <div class="portal-card">
    <div class="card-header">
      <h2 class="font-primary">Willkommen zurück</h2>
    </div>
    <div class="portal-body">
      <div class="intro-text">
        <p>
          Ihrem Account ist eine Rolle in einer Kommune sowie in einer Pachtnetzgesellschaft zugeordnet. Bitte wählen
          Sie aus, welchen Bereich der KommunalPlattform Sie jetzt öffnen möchten:
        </p>
      </div>
      <div class="cards-container">
        <div class="card">
          <div class="card-body">
            <span class="icon-wrapper">
              <img src="/assets/images/logos/kompacht-icon.svg" />
            </span>
            <h2>KomPacht</h2>
            <p>
              Dieser Bereich ist für Geschäftsführer*innen der Pachtnetzgesellschaften zugänglich. Hier gelangen Sie zur
              Dokumentenverwaltung Ihrer Pachtnetzgesellschaft(en).
            </p>
          </div>
          <a class="button" data-cy="dual-role-choose-png" [href]="PNG_URL" (click)="rememberChoice('png')">
            Auswählen <tpm-icon slot="meta" icon="gpi-chevron-right"></tpm-icon>
          </a>
        </div>
        <div class="card">
          <div class="card-body">
            <span class="icon-wrapper">
              <img src="/assets/images/logos/kommunalplatform-icon.svg" />
            </span>
            <h2>KommunalPlattform</h2>
            <p>
              Dieser Bereich ist für Nutzer*innen aus Konzessions- oder Pachtkommunen zugänglich. Hier gelangen Sie zu
              allen Modulen des Netzbetriebs und des Marketplace auf der KommunalPlattform.
            </p>
          </div>
          <a class="button" data-cy="dual-role-choose-kp" routerLink="/" (click)="rememberChoice('kp')">
            Auswählen <tpm-icon slot="meta" icon="gpi-chevron-right"></tpm-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
