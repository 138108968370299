import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { TranslateModule } from "@ngx-translate/core";
import { SelectComponent } from "./select.component";

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, TranslateModule, MatLegacySelectModule],
  exports: [SelectComponent]
})
export class SelectModule {}
