import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { TranslateModule } from "@ngx-translate/core";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";

@Component({
  selector: "tpm-terminate-contract-agreement-dialog",
  templateUrl: "terminate-contract-agreement-dialog.component.html",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, DialogFrameModule]
})
export class TerminateContractAgreementDialogComponent {
  private readonly dialogRef = inject(MatLegacyDialogRef<TerminateContractAgreementDialogComponent>);

  public close(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    this.dialogRef.close(true);
  }
}
