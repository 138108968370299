<tpm-dialog-frame
  *ngIf="orderFormContent$ | async as orderFormContent"
  [header]="orderFormContent.moduleName | translate"
>
  <div class="campus-one-module-order-container">
    <tpm-card class="campus-one-module-order-container__form-card">
      <app-order-form (submit$)="onSubmit($event)"></app-order-form>
    </tpm-card>
    <div class="campus-one-module-order-container__description">
      <img class="logo" [src]="orderFormContent.logo.url" [alt]="orderFormContent.logo.fileName" />
      <p class="body-1">{{ orderFormContent.productHeadline }}</p>
      <div
        class="campus-one-module-order-container__description__paragraphs html-translate"
        [innerHTML]="orderFormContent.productDescription | trustHtml"
      ></div>
      <app-contentful-media
        *ngFor="let media of orderFormContent.productMedia"
        [asset]="media"
        class="media"
      ></app-contentful-media>
    </div>
  </div>
</tpm-dialog-frame>
