import { ChangeDetectionStrategy, Component, Inject, Optional } from "@angular/core";
import { TABLE_CUSTOM_TEMPLATE_CONTEXT } from "src/app/common/components/table/tokens/table-custom-template-context";
import { Pure } from "src/app/common/decorators/pure";
import { INotificationSettings } from "../../../../../interfaces/dialogs/notification-settings";
import { NotificationSquares } from "../../constants/notification-type";

@Component({
  selector: "app-notification-settings-cell",
  templateUrl: "./notification-settings-cell.component.html",
  styleUrls: ["./notification-settings-cell.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationSettingsCellComponent {
  public readonly hasChanged: boolean;
  public readonly notificationSquares = NotificationSquares;

  @Pure
  public get notificationSettings(): INotificationSettings & {
    module: string;
    gas: INotificationSettings;
    electricity: INotificationSettings;
  } {
    const notifyByKey = this.context.id === "phone" ? "notifyBySMS" : "notifyByEmail";
    if (this.context.row.module === "incident-management") {
      this.context.row.phone = [this.context.row.gasPhone, this.context.row.electricityPhone].filter(
        (item, idx, arr) => arr.indexOf(item) === idx
      ).join(", ");
      return {
        module: this.context.row.module,
        gas: this.context.row.gas[notifyByKey],
        electricity: this.context.row.electricity[notifyByKey]
      };
    } else {
      return { ...this.context.row[notifyByKey] };
    }
  }

  constructor(
    @Optional()
    @Inject(TABLE_CUSTOM_TEMPLATE_CONTEXT)
    public readonly context: { id: string; row: any; phone?: string; email?: string }
  ) {}
}
