import { Injectable } from "@angular/core";
import {
  ContentfulClientApi,
  ContentType,
  createClient as createContentfulClient,
  EntryCollection,
  EntrySkeletonType
} from "contentful";
import { from, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfig } from "../../helpers/app.config";

@Injectable({ providedIn: "root" })
export class ContentfulService {
  private readonly contentfulClient: ContentfulClientApi<any>;

  constructor() {
    this.contentfulClient = this.createContentfulClient();
  }

  public createContentfulClient(): ContentfulClientApi<any> {
    return createContentfulClient({
      space: AppConfig.connection.contentful.space,
      accessToken: AppConfig.connection.contentful.accessToken
    });
  }

  public getContentTypes(): Observable<Array<ContentType>> {
    return from(this.contentfulClient.getContentTypes({})).pipe(
      map(({ items }) => items),
      catchError(() => of([]))
    );
  }

  public getEntries(content_type?: string): Observable<EntryCollection<EntrySkeletonType<any>>> {
    return from(this.contentfulClient.getEntries<EntrySkeletonType<any>>({ content_type })).pipe(
      catchError(() => of({} as EntryCollection<EntrySkeletonType<any>>))
    );
  }
}
