import { SLOT } from "../enums/slot";

export const slotsNamesMap: { [key in SLOT]: string } = {
  [SLOT.LANDING]: "",
  [SLOT.GRID_DEVELOPMENT]: "LAUNCHPAD.MODULES.grid-development.name",
  [SLOT.GRID_CONNECTION]: "LAUNCHPAD.MODULES.grid-connections.name",
  [SLOT.THREE_YEAR_PLAN]: "LAUNCHPAD.MODULES.three-year-plan.name",
  [SLOT.OUTAGE_MONITORING]: "LAUNCHPAD.MODULES.incident-management.name",
  [SLOT.ACCOUNTING]: "LAUNCHPAD.MODULES.accounting.name"
};
