import { Action, combineReducers } from "@ngrx/store";
import * as building from "./building.reducer";
import * as regions from "./regions.reducer";

export interface State {
  building: building.State;
  regions: regions.State;
}

const infrastructureReducer = combineReducers({
  building: building.reducer,
  regions: regions.reducer
});

export function reducer(state: State | undefined, action: Action): State {
  return infrastructureReducer(state, action);
}
