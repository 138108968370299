import { TableColumnConfig } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";

export const regionsSwitcherTableColumnsConfig: Array<TableColumnConfig> = [
  {
    id: "coatOfArms",
    type: "image",
    key: "REGIONS_SWITCHER.TABLE_COLUMNS.COAT_OF_ARMS",
    classes: "center",
    style: {
      width: "50px"
    },
    // ags link contains regionId, remove it from filter prediction.
    propertyAccessor: () => ""
  },
  {
    id: "municipality",
    type: "text",
    key: "REGIONS_SWITCHER.TABLE_COLUMNS.MUNICIPALITY"
  },
  {
    id: "municipalityKey",
    type: "text",
    key: "REGIONS_SWITCHER.TABLE_COLUMNS.MUNICIPALITY_KEY"
  },
  {
    id: "regionSwitch",
    type: "action",
    actionIcon: "ec_controls_collapsed_dropdown",
    key: "",
    style: {
      width: "50px"
    }
  }
];
