import { Action, createReducer, on } from "@ngrx/store";
import * as SidenavActions from "../actions/sidenavigation.actions";

export interface ISidenavigationState {
  panels: Record<string, IPanel>;
}

export interface IPanel {
  isOpen: boolean;
  isCollapsed: boolean;
  isEnlarged: boolean;
}

const initialState: () => ISidenavigationState = () => ({
  panels: {}
});

const updatePanelState = (
  state: ISidenavigationState,
  panelName: string,
  stateChange: Partial<IPanel>
): ISidenavigationState => ({
  ...state,
  panels: {
    ...state.panels,
    [panelName]: {
      ...state.panels[panelName],
      ...stateChange
    }
  }
});

function closeAllPanelsWithoutTimeline(
  { panels }: ISidenavigationState,
  panelName?: string,
  panelGroup?: Array<string>
): ISidenavigationState {
  if (panelName === "timeline") {
    return { panels };
  }

  const _panelState = { isOpen: false, isCollapsed: false, isEnlarged: false };
  const _panels = { ...panels };
  Object.keys(_panels)
    .filter((key) => key !== "timeline" && !(panelGroup?.includes(panelName) && panelGroup?.includes(key)))
    .forEach((key) => (_panels[key] = _panelState));
  return { panels: _panels };
}

const sidenavigationReducer = createReducer(
  initialState(),
  on(SidenavActions.registerPanels, (state, { panels }) => ({
    ...state,
    panels: panels.reduce((p, newPanel) => ((p[newPanel.name] = newPanel.initialState), p), {})
  })),
  on(SidenavActions.changePanelState, (state, action) => updatePanelState(state, action.panelName, action.stateChange)),
  on(SidenavActions.toggleVisible, (state, action) =>
    updatePanelState(state, action.panelName, { isOpen: !state.panels[action.panelName].isOpen })
  ),
  on(SidenavActions.toggleEnlarge, (state, action) =>
    updatePanelState(state, action.panelName, { isEnlarged: !state.panels[action.panelName].isEnlarged })
  ),
  on(SidenavActions.openPanel, (state, action) => updatePanelState(state, action.panelName, { isOpen: true })),
  on(SidenavActions.openSinglePanel, (state, { panelName, panelGroup, toggleOff }) => {
    const newPanelsState = closeAllPanelsWithoutTimeline(state, panelName, panelGroup);
    return updatePanelState(newPanelsState, panelName, {
      isOpen: panelGroup && !toggleOff ? !state.panels[panelName].isOpen : true
    });
  }),
  on(SidenavActions.closePanel, (state, action) => updatePanelState(state, action.panelName, { isOpen: false })),
  on(SidenavActions.closeAllPanels, (state) => closeAllPanelsWithoutTimeline(state))
);

export function reducer(state: ISidenavigationState | undefined, action: Action): ISidenavigationState {
  return sidenavigationReducer(state, action);
}
