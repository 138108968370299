const classes: ReadonlyArray<string> = [
  "general_features",
  "facades",
  "roof_pitches",
  "basement",
  "heat_cooling",
  "renovation",
  "windows",
  "various"
] as const;

type ClassesNames = typeof classes[number];

export interface IDetailInformationClass {
  label: string;
  key: string;
  unit: string;
  data_type: string;
  data_precision: number;
  data_scale: number;
}

export interface IFooterItem {
  id: string;
  dialogId: string;
  label: string;
  title?: string;
  customContent?: string;
  content?: string;
  sizing?: string;
  params?: object;
}

export interface IClientConfigDto {
  introductionModal: boolean;
  theme: any;
  toolbox: any;
  module: Array<any>;
  footer: ReadonlyArray<IFooterItem>;
  allowedLanguages: Array<string>;
  language: string;
  help: { glossary: any };
  environments: {
    scenarioId: string;
    detailInformationClasses: Array<{ [name in ClassesNames]: Array<IDetailInformationClass> }>;
  };
  contactsInfo: any;
  useNewContactForm: boolean;
}

export class ClientConfigDto implements IClientConfigDto {
  public introductionModal: boolean;
  public theme: any;
  public toolbox: any;
  public module: Array<any>;
  public footer: ReadonlyArray<IFooterItem>;
  public allowedLanguages: Array<string>;
  public language: string;
  public help: any;
  public environments: any;
  public contactsInfo: any;
  public useNewContactForm: boolean;

  constructor(response: any) {
    this.introductionModal = response.introductionModal;
    this.theme = response.theme;
    this.toolbox = response.toolbox;
    this.module = response.module;
    this.footer = response.footer;
    this.allowedLanguages = response.allowedLanguages;
    this.language = response.language;
    this.help = response.help;
    this.environments = response.environments;
    this.contactsInfo = response.contactsInfo;
    this.useNewContactForm = response.useNewContactForm;
  }
}
