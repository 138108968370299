import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { NetzeBwNewsEntry } from "../../news/news.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-contentful-card",
  templateUrl: "./contentful-card.component.html",
  styleUrls: ["./contentful-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentfulCardComponent {
  @Input() public isMobileView: boolean;
  @Input() public buttonLabel: string;
  @Input() public data: NetzeBwNewsEntry;

  @Output() public readonly cardClick = new EventEmitter<void>();
}
