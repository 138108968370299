import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ContentfulCardModule } from "../components/contentful-card/contentful-card.module";
import { ContentfulListItemModule } from "../components/contentful-list-item/contentful-list-item.module";
import { ContentfulWrapperModule } from "../components/contentful-wrapper/contentful-wrapper.module";
import { NewsComponent } from "./container/news.component";

@NgModule({
  declarations: [NewsComponent],
  imports: [CommonModule, TranslateModule, ContentfulWrapperModule, ContentfulListItemModule, ContentfulCardModule]
})
export class NewsModule {}
