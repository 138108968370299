import { Action, createReducer, on } from "@ngrx/store";
import { EntryCollection } from "contentful";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import * as ContentfulActions from "../actions/contentful.actions";

export interface ContentfulState {
  contentType: ContentfulContentType | undefined;
  entries: EntryCollection<any>;
}

const initialState: ContentfulState = {
  contentType: undefined,
  entries: {} as EntryCollection<any>
};

const contentfulReducer = createReducer(
  initialState,
  on(ContentfulActions.fetchContentfulEntries, (state) => ({
    ...state,
    contentType: undefined,
    entries: {} as EntryCollection<any>
  })),
  on(ContentfulActions.setContentfulEntries, (state, { contentType, result }) => ({
    ...state,
    contentType,
    entries: result
  }))
);

export function reducer(state: ContentfulState | undefined, action: Action): ContentfulState {
  return contentfulReducer(state, action);
}
