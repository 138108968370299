import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import "@enersis/gp-components/gp-card";
import { CardComponent } from "./card.component";

@NgModule({
  declarations: [CardComponent],
  imports: [CommonModule],
  exports: [CardComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardModule {}
