import { Pipe, PipeTransform } from "@angular/core";
import { formatNumberWithPrefix } from "../utils/format-number-with-prefix";

@Pipe({
  name: "numberSign"
})
export class NumberSignPipe implements PipeTransform {
  public transform(value: number | string): string {
    return formatNumberWithPrefix(Number(value));
  }
}
