import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Directive,
  EventEmitter,
  Host,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  ViewChild
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { asyncScheduler, BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { debounceTime, map, observeOn, switchMap, tap } from "rxjs/operators";
import { CustomTableDataSource } from "../components/table/classes/custom-table-data-source";
import { TablePagingService } from "../components/table/classes/table-paging-service";
import { TablePaginatorComponent } from "../components/table/components/table-paginator/table-paginator.component";
import { TABLE_COMPONENT_TAMPLATES, TABLE_TEMPLATES } from "../components/table/constants/table-templates";
import { ITableColumn } from "../components/table/interfaces/table-column";
import { ITableEntrySelector } from "../components/table/interfaces/table-entry-selector";
import { ITableEvent } from "../components/table/interfaces/table-event";
import { ITablePaging } from "../components/table/interfaces/table-paging";
import { TABLE_PAGING_SERVICE } from "../components/table/tokens/table-paging-service";
import { TableTemplate } from "../components/table/types/table-template";
import { Pure } from "../decorators/pure";

@Directive()
/* eslint-disable @angular-eslint/no-input-rename */
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractTableComponent<T extends object> implements OnDestroy, AfterViewInit {
  /** @description Activate pagination with provided config. */
  @Input()
  public get pagingConfig(): ITablePaging {
    return this._pagingConfig;
  }
  public set pagingConfig(value: ITablePaging) {
    this._pagingConfig = value;
    this.paginatorRefSubject.next(Boolean(value));
  }
  public _pagingConfig: ITablePaging;

  /**
   * @description Activate sort by flag that was provided in setter.
   * Default state = true
   */
  @Input("disableSort")
  public get sortDisabled(): boolean {
    return this._sortDisabled;
  }
  public set sortDisabled(value: boolean) {
    this._sortDisabled = value;
    this.sortRefSubject.next(value);
  }
  public _sortDisabled: boolean = true;

  @Input() public sortActive: string;
  @Input() public sortDirection: string = "desc";

  /** @description Activate sort by flag that was provided in setter. */
  @Input()
  public get data(): Array<T> | undefined {
    return this._data;
  }
  public set data(_data: Array<T> | undefined) {
    this._data = _data;
    this.dataSubject.next(_data);
  }
  public _data: Array<T> = [];

  /** @description Complex configuration for table columns with ITableColumn interface. */
  @Input("columnsData")
  public get columnsConfigs(): Array<ITableColumn> {
    return this._columnsConfigs;
  }
  public set columnsConfigs(columns: Array<ITableColumn>) {
    this._columnsConfigs = columns;
    this.dataSource.columnsConfigs = columns;
    this.displayedColumns = columns.map(({ id }) => id);
    this.footerColumns = this.displayedColumns;
    this.footerHidden = columns.every(({ footer }) => !footer);

    if (this.selectDisabled === false) {
      this.displayedColumns = ["select", ...this.displayedColumns];
    }

    this.columnsStyles = columns.reduce(
      (stylesCollection, { id, type, columnStyles: styles = [] }) => ({
        ...stylesCollection,
        [id]: [].concat(`${type}-cell`, styles)
      }),
      {} as Record<keyof T, Array<string>>
    );
  }
  public _columnsConfigs: Array<ITableColumn>;

  @Input()
  public get selectDisabled(): boolean {
    return this._selectDisabled;
  }
  public set selectDisabled(value: boolean) {
    this._selectDisabled = value;

    this.selectionsSubscription?.unsubscribe();

    if (!value) {
      this.selections = new SelectionModel<T>(true, []);
      this.selectionsSubscription = this.selections.changed
        .pipe(debounceTime(100))
        .subscribe(({ source }) => this.tableEvent.emit({ id: "", type: "select", selected: source.selected }));
    }
  }
  public _selectDisabled: boolean = true;

  @Input()
  public get entrySelector(): ITableEntrySelector {
    return this._entrySelector;
  }
  public set entrySelector(entry: ITableEntrySelector) {
    this._entrySelector = entry;

    // TODO(bobkov): make better, found bug with it.
    if (entry && this.data && this.pagingConfig) {
      const { propertyName: name, propertyValue: value } = this.entrySelector;
      let index = this.dataSource.data.findIndex((item) => item[name] === value);

      if (index === -1) {
        index = 0;
      }

      this.paginatorRef.matPaginator.pageIndex = Math.floor(index / this.pagingConfig.pageSize);
    }
  }
  public _entrySelector: ITableEntrySelector | null = null;

  @Input("disableFilter") public filterDisabled: boolean = false;
  @Input("filterLabelKey") public filterLabel: string = "TOOLTIP.table.filterPlaceholder";
  @Input("hideHeader") public headerHidden: boolean = false;
  @Input() public scrollableX = false;
  @Input() public scrollableY = false;
  @Input() public highlightedRowKeys: Array<string>;
  @Input() public disableCheckbox: boolean;

  @Output() public tableEvent = new EventEmitter<ITableEvent<T>>();

  @ViewChild(MatSort, { static: true }) public sortRef: MatSort | null = null;
  @ViewChild(TablePaginatorComponent) public paginatorRef: TablePaginatorComponent | null = null;

  public footerHidden: boolean = true;
  public displayedColumns: Array<string> = [];
  public footerColumns: Array<string> = [];
  public columnsStyles: Record<keyof T, Array<string>> | null = null;
  public dataSource = new CustomTableDataSource<T>([]);
  public selections: SelectionModel<T>;

  protected raceConditionSubscription: Subscription;
  protected selectionsSubscription: Subscription;
  private componentTemplates = TABLE_COMPONENT_TAMPLATES;
  private paginatorRefSubject = new BehaviorSubject<boolean>(false);
  private sortRefSubject = new BehaviorSubject<boolean>(false);
  private dataSubject = new BehaviorSubject<Array<T>>([]);

  constructor(
    @Optional() @Host() @Inject(TABLE_PAGING_SERVICE) protected readonly pagingService: TablePagingService<T>
  ) {}

  public ngAfterViewInit(): void {
    this.raceConditionSubscription = combineLatest([this.getPagining(), this.getSorting()])
      .pipe(
        observeOn(asyncScheduler),
        switchMap(() => (this.pagingService ? this.getPagingData() : this.dataSubject))
      )
      .subscribe((data) => {
        this.selections?.clear();
        this.dataSource.data = data || [];
      });
  }

  public ngOnDestroy(): void {
    this.selectionsSubscription?.unsubscribe();
    this.raceConditionSubscription?.unsubscribe();
  }

  public trackById(_: number, { id }: { id: string }): string {
    return id;
  }

  public masterToggle(): void {
    this.isAllSelected() ? this.selections.clear() : this.dataSource.data.forEach((row) => this.selections.select(row));
  }

  public isAllSelected(): boolean {
    return this.selections.selected.length === this.dataSource.data.length;
  }

  public handleFilterEvent(filter: string): void {
    if (this.pagingService) {
      this.pagingService.setFilter(filter);
    } else {
      this.dataSource.filter = filter;
    }
  }

  @Pure
  public isFooterDisabled(columnsConfigs: Array<ITableColumn> = []): boolean {
    return columnsConfigs.every(({ footer }) => !footer);
  }

  @Pure
  public isSortDisabled(disabled: boolean, type: TableTemplate): boolean {
    return disabled || type === TABLE_TEMPLATES.Action || type === TABLE_TEMPLATES.Icon;
  }

  @Pure
  public isComponentTemplate(type: TableTemplate): boolean {
    return this.componentTemplates.includes(type);
  }

  @Pure
  public getSum(data: Array<T> = [], id: string): number {
    return data.reduce((acc, item) => acc + item[id], 0);
  }

  @Pure
  public hasHighlightedRows(rowData: T): boolean {
    return this.highlightedRowKeys.every((value) => rowData[value]);
  }

  protected getPagining(): Observable<boolean> {
    return this.paginatorRefSubject.asObservable().pipe(
      tap((paging) => {
        if (this.pagingService) {
          this.pagingService.setPaging(this.paginatorRef.matPaginator);
        } else if (paging) {
          this.dataSource.paginator = this.paginatorRef.matPaginator;
        }
      })
    );
  }

  protected getSorting(): Observable<boolean> {
    return this.sortRefSubject.pipe(
      tap((sortDisabled) => {
        if (this.pagingService) {
          this.pagingService.setSort(this.sortRef);
        } else if (!sortDisabled) {
          this.dataSource.sort = this.sortRef;
        }
      })
    );
  }

  protected getPagingData(): Observable<Array<T>> {
    return this.pagingService.loadData().pipe(
      tap(({ total }) => (this.paginatorRef.matPaginator.length = total)),
      map(({ data }) => data)
    );
  }
}
