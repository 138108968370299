<gp-select
  [outlined]="appearance === 'outline'"
  [label]="label"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [required]="required"
  [value]="_value"
  [helper]="helper"
  [validationMessage]="validationMessage"
  [fixedMenuPosition]="fixedMenuPosition"
  [validityTransform]="validityTransform"
  (selected)="onSelected($event)"
  (closed)="onClosed($event)"
  (blur)="_onTouched($event)"
>
  <gp-list-item
    *ngFor="let option of options; trackBy: trackByValue"
    [value]="option.value"
    [selected]="_value === option.value"
  >
    {{ option.label }}
  </gp-list-item>
</gp-select>
