import { ChangeDetectionStrategy, Component, Inject, inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { LetDirective } from "@ngrx/component";
import { TranslateModule } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { DialogFrameModule } from "src/app/common/components/dialog-frame/dialog-frame.module";
import {
  DropFileAreaComponent,
  DropFileAreaItem
} from "src/app/common/components/form/drop-file-area/drop-file-area.component";
import { MimeType } from "src/app/common/enums/mime-type";
import { InvoiceUploadDialogComponentStore, InvoiceUploadDialogState } from "./invoice-upload-dialog.component-store";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-invoice-upload-dialog",
  templateUrl: "./invoice-upload-dialog.component.html",
  styleUrls: ["./invoice-upload-dialog.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LetDirective, TranslateModule, DialogFrameModule, DropFileAreaComponent],
  providers: [InvoiceUploadDialogComponentStore]
})
export class InvoiceUploadDialogComponent {
  public readonly accept = MimeType.Pdf;
  public readonly acceptLabel = "*.pdf 4 MB max, 20 Dateien max";
  public readonly listItemExtraLineAccessor = (item: InvoiceUploadDialogState) => item.email;
  public readonly files$: Observable<Array<InvoiceUploadDialogState>>;

  private readonly dialogRef = inject(MatLegacyDialogRef<InvoiceUploadDialogComponent>);
  private readonly componentStore = inject(InvoiceUploadDialogComponentStore);

  constructor(@Inject(MAT_LEGACY_DIALOG_DATA) public readonly data: { moduleSubscriptionName: string; email: string }) {
    this.files$ = this.componentStore.files$;

    this.componentStore.patchState({
      moduleSubscriptionName: this.data.moduleSubscriptionName,
      email: this.data.email
    });
  }

  public onDrop(files: Array<DropFileAreaItem>): void {
    this.componentStore.patchState({ file: files[0].file });
  }

  public onRemove(): void {
    this.componentStore.removeFile();
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSubmit(): void {
    this.componentStore.submit(this.dialogRef);
  }
}
