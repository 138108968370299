import { SLOT_ICONS_MAP } from "src/app/common/constants/slots-icons-map";
import { NetzeBwNewsEntry, TileOnTileEntry } from "./news.interfaces";

export const createCommunalPlatformNewsEntry = (
  entry: any,
  assets: Record<string, string>,
  storage: Record<string, boolean>
): any => ({
  ...entry.fields,
  id: entry.sys.id,
  icon: SLOT_ICONS_MAP[entry.fields.moduleName] || "ec_info",
  image: assets[entry.fields.modulicon?.sys.id],
  isNew: !storage[entry.sys.id],
  actions: entry.fields.actions?.map(({ fields }) => ({ ...fields }))
});

export const createNetzeBwNewsEntry = (
  entry: any,
  assets: Record<string, string>,
  storage: Record<string, boolean>
): NetzeBwNewsEntry => ({
  ...entry.fields,
  id: entry.sys.id,
  teaserImage: assets[entry.fields.teaserImage?.sys.id],
  isNew: !storage[entry.sys.id],
  actions: entry.fields.actions?.map(({ fields }) => ({ ...fields }))
});

export const createTileOnTileEntry = (entry: any): TileOnTileEntry => ({
  ...entry.fields,
  id: entry.sys.id,
  actions: entry.fields.actions?.map(({ fields }) => ({ ...fields }))
});
