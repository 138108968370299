import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { InputFieldComponent } from "./input-field.component";

@NgModule({
  declarations: [InputFieldComponent],
  imports: [ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule],
  exports: [InputFieldComponent]
})
export class InputFieldModule {}
