<p class="description body-2">
  {{
    "Kündigen und verwalten Sie Ihre Verträge zu den Modulen. Die Änderungen werden nach spätestens 48 Stunden wirksam."
      | translate
  }}
</p>
<div class="contract-management-tables-wrapper" [@inListAnimation]="contracts.result.length">
  <ng-container *ngFor="let tableData of contracts.result; let first = first">
    <mat-divider *ngIf="first" big></mat-divider>
    <div class="top-wrapper">
      <p class="display-name headline-4">{{ tableData.displayName }}</p>
      <a
        *ngFor="let link of linkList"
        class="link"
        target="_blank"
        [disabled]="tableData[link.prop]"
        [href]="tableData[link.prop]"
      >
        {{ link.label | translate }}
      </a>
    </div>
    <ngmy-table
      class="contract-management-table"
      bordered
      [hideFilter]="true"
      [columnsConfig]="columnsConfig"
      [loading]="contracts.loading"
      [data]="[tableData]"
      (tableAction)="onTableAction($event)"
    ></ngmy-table>
    <div class="contract-footer">
      <p class="contract-info body-2" [innerHTML]="tableData.footer"></p>
      <button
        class="terminate-contract-button"
        ene-filled-button
        [disabled]="tableData.status === 'CANCELED'"
        [size]="'large'"
        (click)="terminateContract.emit(tableData)"
      >
        {{ "vertrag kündigen" | translate }}
      </button>
    </div>
    <mat-divider big></mat-divider>
  </ng-container>
</div>
