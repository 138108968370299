import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { of } from "rxjs";
import { delay, filter, switchMap, take, tap } from "rxjs/operators";
import { Dialog } from "src/app/common/enums/dialogs";
import { ContentfulActionType } from "src/app/core/interfaces/contentful";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { openDialog } from "src/app/ngrx/actions/dialogs.actions";
import { closeLaunchpad, closeNews, openNews, openWebinars } from "src/app/ngrx/actions/overlay.actions";
import { navigateToSlot } from "src/app/ngrx/actions/router.actions";
import { openSinglePanel } from "src/app/ngrx/actions/sidenavigation.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { getPanels } from "src/app/ngrx/selectors/sidenavigation.selectors";
import { CommunalPlatformNewsEntry, TileOnTileEntry } from "./news.interfaces";

type PanelActions = Exclude<
  ContentfulActionType,
  | ContentfulActionType.NOTIFICATION_SETTINGS_DIALOG
  | ContentfulActionType.WEBINAR_DIALOG_REGULATED
  | ContentfulActionType.WEBINAR_DIALOG_SERVICES
  | ContentfulActionType.EXTERNAL_URL
  | ContentfulActionType.GLOSSARY_DIALOG
  | ContentfulActionType.NEWS_OVERLAY
  | ContentfulActionType.CONTACT_DIALOG
  | ContentfulActionType.CAMPUS_ONE_ORDER
>;

@Injectable({ providedIn: "root" })
export class NewsActionsManagerService {
  // TODO: refactor it with `title` of side panel and similar `name` of the panels
  public readonly actionsPanels: Record<PanelActions, Array<string>> = {
    ARCHIVE_PANEL: ["archivePanel", "archive"],
    ENERGY_MONITOR_PANEL: ["energyMonitor", "monitor", "monitor_3yp", "outagesMonitor"],
    INSTALLATION_OVERVIEW_PANEL: ["installationOverview", "overview"],
    MAP_LAYERS_PANEL: ["mapLayers"],
    OBJECT_SELECTION_PANEL: ["objectSelection"],
    COCKPIT_PANEL: ["cockpit"],
    BENCHMARKING_PANEL: ["municipalityBenchmarking"],
    MEASURES_PANEL: ["measures"],
    MEASURES_LIST_PANEL: ["measuresListPanel"],
    CRISIS_MANAGEMENT_PANEL: ["crisisManagement"],
    DATA_STORAGE_PANEL: ["dataStorage"]
  };

  public readonly actionsDialogs = {
    GLOSSARY_DIALOG: "GLOSSARY",
    NOTIFICATION_SETTINGS_DIALOG: "NOTIFICATION_SETTINGS",
    CONTACT_DIALOG: "CONTACT"
  };

  public readonly actionsOverlays = {
    WEBINAR_DIALOG_REGULATED: "WEBINAR_DIALOG_REGULATED",
    WEBINAR_DIALOG_SERVICES: "WEBINAR_DIALOG_SERVICES"
  };

  constructor(private readonly store: Store<RootState>, private readonly analytics: AnalyticsService) {}

  public dispatch(
    action: ContentfulActionType,
    url?: string,
    entry?: CommunalPlatformNewsEntry | TileOnTileEntry
  ): void {
    switch (action) {
      case ContentfulActionType.EXTERNAL_URL: {
        window.open(url, "_blank");
        break;
      }
      case ContentfulActionType.NEWS_OVERLAY: {
        this.store.dispatch(openNews());
        break;
      }
      case ContentfulActionType.GLOSSARY_DIALOG:
      case ContentfulActionType.NOTIFICATION_SETTINGS_DIALOG:
      case ContentfulActionType.CONTACT_DIALOG: {
        this.store.dispatch(openDialog({ dialogType: Dialog[this.actionsDialogs[action]] }));
        break;
      }
      case ContentfulActionType.WEBINAR_DIALOG_REGULATED:
      case ContentfulActionType.WEBINAR_DIALOG_SERVICES: {
        this.store.dispatch(closeNews());
        this.store.dispatch(openWebinars());
        break;
      }
      case ContentfulActionType.ARCHIVE_PANEL:
      case ContentfulActionType.ENERGY_MONITOR_PANEL:
      case ContentfulActionType.INSTALLATION_OVERVIEW_PANEL:
      case ContentfulActionType.MAP_LAYERS_PANEL:
      case ContentfulActionType.OBJECT_SELECTION_PANEL:
      case ContentfulActionType.COCKPIT_PANEL:
      case ContentfulActionType.CRISIS_MANAGEMENT_PANEL:
      case ContentfulActionType.DATA_STORAGE_PANEL:
      case ContentfulActionType.BENCHMARKING_PANEL: {
        if (!entry) {
          throw new Error("entry is required for this action");
        }
        // close all
        of(null)
          .pipe(
            tap(() => {
              this.store.dispatch(closeNews());
              this.store.dispatch(closeLaunchpad());
              this.store.dispatch(navigateToSlot({ slot: entry.moduleName }));
            }),
            delay(500),
            switchMap(() => this.store.pipe(select(getPanels))),
            filter((panels) => Boolean(Object.keys(panels).length)),
            take(1)
          )
          .subscribe((panels) => {
            const exactPanelName = Object.keys(panels).find((panel) => this.actionsPanels[action].includes(panel));
            this.store.dispatch(openSinglePanel({ panelName: exactPanelName }));
          });

        break;
      }
    }

    if (entry && "headline" in entry) {
      this.analytics.trackNewsActionClick(entry.moduleName, entry.headline || action);
    }
  }
}
