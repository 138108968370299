import { createAction, props } from "@ngrx/store";
import { BuildingsConnectionGroupDto } from "src/app/common/dto/map/buildings-connection-group";
import { IMapFeature } from "src/app/common/interfaces/map/map-feature";

export const selectMapFeature = createAction(
  "[Map] Select feature on map click event",
  props<{ feature: IMapFeature | null }>()
);

export const clearMapFeature = createAction("[Map] Clear selected map feature");

export const getBuildingsConnectionGroup = createAction(
  "[Map / Connection Group] Get buildings connection group",
  props<{ id: string }>()
);

export const getBuildingsConnectionGroupSuccess = createAction(
  "[Map / Connection Group] Get buildings connection group successfully",
  props<{ payload: BuildingsConnectionGroupDto }>()
);

export const clearBuildingsConnectionGroup = createAction("[Map / Connection Group] Clear buildings connection group");

export const changeActiveConnectionGroup = createAction(
  "[Map / Connection Group] Change active connection group by id",
  props<{ id: string }>()
);

export const keepLegendClosed = createAction("[Map] Change legend opend state", props<{ isClosed: boolean }>());
