import { Component, Input } from "@angular/core";

@Component({
  selector: "app-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"]
})
export class BadgeComponent {
  @Input() public badge: { id: string; label: string; class: string };
  @Input() public tooltipLabel: string;
}
