import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable, catchError, map, of, throwError } from "rxjs";
import { AdminModuleInvitationDto } from "src/app/common/dto/admin-module/invitation";
import { AppConfig } from "../../helpers/app.config";
import { MarketplaceBannerEntry, OrderFormEntry, SalesInfoEntry } from "../../interfaces/contentful";

interface RequestParams {
  pageSize: number;
  name: string;
  moduleSubscriptionId: string;
  email: string;
  ags: string;
  functionalRole: string;
  invitationId: string;
  status: boolean;
  ids: Array<string>;
}

type AdvertisingReturnMap = {
  marketplaceBanner: MarketplaceBannerEntry;
  orderForm: OrderFormEntry;
  salesInfo: SalesInfoEntry;
};

@Injectable({ providedIn: "root" })
export class CampusOneDataService {
  private readonly api = AppConfig.connection.marketplaceModule.campusOne;
  private readonly http = inject(HttpClient);

  public postInvitation(
    body: Array<Pick<RequestParams, "name" | "email" | "functionalRole" | "ags">>
  ): Observable<void | Object> {
    return this.http
      .post(`${this.api}/invitation`, body)
      .pipe(catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode))));
  }

  public deleteInvitation(body: Pick<RequestParams, "ids">): Observable<void | Object> {
    return this.http
      .delete(`${this.api}/invitation`, { body })
      .pipe(catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode))));
  }

  public postInvitationModuleStatus(
    body: Array<Pick<RequestParams, "name" | "moduleSubscriptionId" | "invitationId" | "ags" | "status">>
  ): Observable<void | Object> {
    return this.http
      .post(`${this.api}/invitation/module-status`, body)
      .pipe(catchError(({ error }) => throwError(() => new Error(error.message || error.statusCode))));
  }

  public getInvitationList(fromObject: Pick<RequestParams, "pageSize">): Observable<Array<AdminModuleInvitationDto>> {
    return this.http
      .get<{ invitations: Array<any> }>(`${this.api}/invitation/list`, {
        params: new HttpParams({ fromObject })
      })
      .pipe(
        map(({ invitations }) => invitations),
        catchError(() => of([]))
      );
  }

  /**
   * Will return content from contentful, which is filtered by backend of user
   */
  public getAdvertisingContent<T extends "marketplaceBanner" | "orderForm" | "salesInfo">(
    contentType: T,
    regionId: string
  ): Observable<Array<AdvertisingReturnMap[T]>> {
    return this.http
      .get<{ items: Array<AdvertisingReturnMap[T]> }>(`${this.api}/advertising/${contentType}?regionId=${regionId}`)
      .pipe(map(({ items }) => items));
  }
}
