import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY } from "rxjs";
import { catchError, exhaustMap, map, switchMap } from "rxjs/operators";
import { RegionsPerYearDto, RegionsYearDto } from "../../../common/dto/infrastructure/regions";
import { InfrastructureService } from "../../../core/services/infrastructure.service";
import {
  getRegionsAllYearsData,
  getRegionsAllYearsDataSuccess,
  getRegionsData,
  getRegionsDataSuccess
} from "../../actions/infrastructure/regions.actions";

@Injectable()
export class RegionsEffects {
  getRegions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRegionsData),
      exhaustMap((action) =>
        this.infrastructureService.getRegionsData(action).pipe(
          map((payload: RegionsYearDto) => getRegionsDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getRegionsAllYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRegionsAllYearsData),
      switchMap((action) =>
        this.infrastructureService.getRegionsAllYearsData(action).pipe(
          map((payload: Array<RegionsPerYearDto>) => getRegionsAllYearsDataSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private infrastructureService: InfrastructureService) {}
}
