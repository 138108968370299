import { NgFor, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { EneButtonModule } from "@enersis/ui-button";
import { TranslateModule } from "@ngx-translate/core";
import { IN_LIST_ANIMATION } from "src/app/core/animations/list-animation";
import { AdminDataService } from "src/app/core/services/admin-data.service";
import { TableAction } from "src/app/slots/three-year-plan/common/components/table/table.interfaces";
import { TableModule } from "src/app/slots/three-year-plan/common/components/table/table.module";
import { ProductManagementDialogState } from "../../product-management-dialog.component-store";
import { COLUMNS_CONFIG } from "./contract-management.contants";

@Component({
  selector: "tpm-contract-management",
  templateUrl: "contract-management.component.html",
  styleUrls: ["contract-management.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgIf, MatDividerModule, TranslateModule, TableModule, EneButtonModule],
  animations: [IN_LIST_ANIMATION]
})
export class ContractManagementComponent {
  @Output() public readonly terminateContract = new EventEmitter<any>();
  @Output() public readonly downloadInvoice = new EventEmitter<string>();

  @Input({ required: true }) public contracts: ProductManagementDialogState["contracts"];

  private readonly adminService = inject(AdminDataService);

  public readonly columnsConfig = COLUMNS_CONFIG;
  public readonly linkList: ReadonlyArray<{
    prop: "contactDataUrl" | "termsAndConditionsDataUrl" | "privacyPolicyDataUrl";
    label: string;
  }> = [
    {
      prop: "contactDataUrl",
      label: "Kontakt"
    },
    {
      prop: "termsAndConditionsDataUrl",
      label: "AGB"
    },
    {
      prop: "privacyPolicyDataUrl",
      label: "Datenschutzerklärung"
    }
  ];

  public onTableAction(event: TableAction<any>): void {
    switch (event.id) {
      case "download": {
        this.downloadInvoice.emit(event.row.files[event.row.files.length - 1]);
        break;
      }
    }
  }
}
