import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { Title } from "@angular/platform-browser";
import { EneIconService } from "@enersis/ui-icon";
import { Store, select } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { map, switchMap, take, withLatestFrom } from "rxjs/operators";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { AuthService } from "src/app/core/services/auth.service";
import { FeatureFlagService } from "src/app/core/services/feature-flag.service";
import { MarketingCrmService } from "src/app/core/services/marketing-crm.service";
import { setPolicy } from "src/app/ngrx/actions/app.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectRegionAgs, selectRegionName } from "src/app/ngrx/selectors/app.selectors";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private store: Store<RootState>,
    private title: Title,
    private translate: TranslateService,
    private readonly iconService: EneIconService,
    private readonly renderer: Renderer2,
    private readonly marketingService: MarketingCrmService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly analyticsService: AnalyticsService,
    private readonly featureFlagService: FeatureFlagService
  ) {}

  public ngOnInit(): void {
    this.featureFlagService.setUserId(this.authService.getParsedToken()?.email);
    this.featureFlagService.start();
    this.iconService.initIconFont("energycity", this.renderer);
    this.matIconRegistry.setDefaultFontSetClass("material-icons", "energycity-icons");
    this.updateLang();

    this.store
      .pipe(
        select(selectRegionName),
        withLatestFrom(this.store.pipe(select(selectRegionAgs))),
        map(([regionName, regionAgs]) => this.getBrowserTabTitle(regionName, regionAgs))
      )
      .subscribe((title) => {
        this.title.setTitle(title);
      });

    this.store.dispatch(setPolicy({ policy: this.authService.getPolicyCookies() }));
    this.marketingService.attachFormTracker();

    if (this.authService.getPolicyCookies().cookiesDetails?.statistic) {
      this.store
        .pipe(
          select(selectRegionName),
          switchMap(() => this.authService.getUserInfo())
        )
        .pipe(take(1))
        .subscribe((userInfo) => {
          this.analyticsService.activate(userInfo);
        });
    }

    this.translate.onLangChange.subscribe(() => {
      this.updateLang();
    });
  }

  public ngOnDestroy(): void {
    this.featureFlagService.stop();
  }

  private getBrowserTabTitle(regionName: string, regionAgs: string): string {
    const communalPlatform = "Kommunalplattform NetzeBW";

    return regionName && regionAgs ? `${regionName} | ${communalPlatform} | ${regionAgs}` : communalPlatform;
  }

  private updateLang(): void {
    // Get current language without the country
    this.document.documentElement.lang = this.translate.currentLang.substring(0, 2);
  }
}
