import { IAttribute } from "./gas-cockpit";

export interface INetworkType {
  networkTypeId: string;
  key: string;
  description: string;
}

export interface INetworkDto {
  networkId: string;
  name: string;
  regionId: number;
  type: INetworkType;
}

export interface IGasCockpitValuesAllYearsDto {
  name: string;
  type: string;
  attributes: Array<IAttribute>;
}

export class GasCockpitValuesAllYearsDto implements IGasCockpitValuesAllYearsDto {
  public type: string;
  public name: string;
  public attributes: Array<IAttribute>;

  constructor(data: IGasCockpitValuesAllYearsDto) {
    this.type = data.type;
    this.name = data.name;
    this.attributes = data.attributes;
  }
}

export class NetworkDto implements INetworkDto {
  public networkId: string;
  public name: string;
  public regionId: number;
  public type: INetworkType;

  constructor(data: INetworkDto) {
    this.networkId = data.networkId;
    this.name = data.name;
    this.regionId = data.regionId;
    this.type = data.type;
  }
}
