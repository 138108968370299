import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { TableModule } from "src/app/slots/three-year-plan/common/components/table/table.module";
import { DialogWrapperModule } from "../dialog-wrapper/dialog-wrapper.module";
import { RegionsSwitcherComponent } from "./regions-switcher.component";

@NgModule({
  declarations: [RegionsSwitcherComponent],
  imports: [CommonModule, TranslateModule, DialogWrapperModule, TableModule]
})
export class RegionsSwitcherDialogModule {}
