import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import "@enersis/gp-components/gp-list";
import { ListItemComponent } from "./components/list-item/list-item.component";
import { ListComponent } from "./components/list/list.component";

@NgModule({
  declarations: [ListComponent, ListItemComponent],
  imports: [CommonModule],
  exports: [ListComponent, ListItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ListModule {}
