import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfig } from "../helpers/app.config";
import { IProjectEntity, MinMaxYears, ModuleConfigLogsItem } from "../interfaces/project-entity";

interface RequestParams {
  active?: boolean;
  filter?: boolean;
  module?: string;
  name?: string;
  limit?: number;
  offset?: number;
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
  ags?: string;
  completed?: boolean;
}

export type LogType = "logs" | "reports";

@Injectable({ providedIn: "root" })
export class ProjectConfigurationService {
  public readonly api = AppConfig.connection.infrastructure.moduleConfig;

  constructor(private readonly http: HttpClient) {}

  public getLogsOrReports(url: string, logType: LogType): Observable<Array<ModuleConfigLogsItem>> {
    return this.http
      .get<{ logFiles: Array<ModuleConfigLogsItem> }>(`${this.api}/${logType}/${url}`, {
        params: new HttpParams({
          fromObject: {
            limit: 100,
            offset: 0,
            sortColumn: "timestamp",
            sortDirection: "desc"
          }
        })
      })
      .pipe(
        map(({ logFiles }) => logFiles),
        catchError(() => of([]))
      );
  }

  public getEntities(requestParams?: RequestParams): Observable<Array<IProjectEntity>> {
    return this.http
      .get<Array<IProjectEntity>>(`${this.api}/entity`, {
        params: this.getHttpParams(requestParams, ["filter", "active", "module", "limit", "offset"])
      })
      .pipe(
        map((entities) => entities.sort((a, b) => a.entity.name.localeCompare(b.entity.name))),
        catchError(() => of([]))
      );
  }

  public getEntity(name: string, requestParams?: RequestParams): Observable<IProjectEntity> {
    return this.http
      .get<{ result: IProjectEntity }>(`${this.api}/entity/${name}`, {
        params: this.getHttpParams(requestParams, ["active", "limit", "offset"])
      })
      .pipe(map(({ result }) => result));
  }

  public getYears(): Observable<MinMaxYears> {
    return this.http
      .get<{ result: MinMaxYears }>(`${this.api}/modules/min-max-years`)
      .pipe(map(({ result }) => result));
  }

  public updateEntities({ entities, formData }: any): Observable<void> {
    const _body = {
      entities,
      modules: formData.modules.items
    };

    return this.http.patch<void>(`${this.api}/entity`, _body);
  }

  public updateEntity(entityId: string, body: any): Observable<void> {
    const _body = {
      entity: {
        name: body.municipality.name,
        zoomLevel: body.municipality.zoomLevel,
        position: {
          longitude: Number(body.municipality.coordinatesLong.replace(",", ".")),
          latitude: Number(body.municipality.coordinatesLat.replace(",", "."))
        }
      },
      contactPerson: body.contactPerson,
      modules: body.modules.items,
      plugins: [],
      features: {
        newsNotification: body.municipality.newsNotification ?? false
      }
    };

    return this.http.patch<void>(`${this.api}/entity/${entityId}`, _body);
  }

  public updateEntityEmblem(entityId: string, body: FormData): Observable<void> {
    return this.http.post<void>(`${this.api}/entity/${entityId}/emblem`, body);
  }

  public getCrisisInfo({ ags }: Pick<RequestParams, "ags">): Observable<boolean> {
    return this.http
      .get<{ result: Record<string, boolean> }>(`${this.api}/crisisInfo`, {
        params: new HttpParams({ fromObject: { ags } })
      })
      .pipe(
        map(({ result }) => result[ags]),
        catchError(() => of(false))
      );
  }

  public updateCrisisInfo({ ags, completed }: Pick<RequestParams, "ags" | "completed">): Observable<void> {
    return this.http.put<void>(`${this.api}/crisisInfo`, { ags, completed });
  }

  private getHttpParams(requestParams: RequestParams = {}, list: Array<string>): HttpParams {
    const fromObject = list.reduce(
      (httpParams, param) => (param in requestParams ? { ...httpParams, [param]: requestParams[param] } : httpParams),
      {}
    );

    return new HttpParams({ fromObject });
  }
}
