<ng-container *ngIf="initialized">
  <div class="notification-settings">
    <app-notification-settings-wrapper
      class="notification-settings__edit"
      [selectedConfig]="selectedConfig"
      (close)="closeEdit()"
      #notificationWrapper
    ></app-notification-settings-wrapper>
  </div>
  <mat-divider></mat-divider>
  <div class="notification-settings__footer">
    <div class="notification-settings__footer-info">
      {{ "NOTIFICATION_SERVICE.FOOTER_INFO" | translate }}
      <span class="link" (click)="openPolicy()">{{ "NOTIFICATION_SERVICE.OPEN_DATA_PROTECTION" | translate }}</span>
    </div>
    <div class="notification-settings__footer-buttons">
      <button mat-flat-button color="accent" (click)="notificationWrapper.onSubmit()" [disabled]="notificationWrapper?.invalid">
        {{ "NOTIFICATION_SERVICE.SAVE" | translate }}
      </button>
    </div>
  </div>
</ng-container>
