import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, switchMap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { SelfSufficiencyService } from "../../../../core/services/self-sufficiency.service";
import {
  getSelfSufficiencyProductionData,
  getSelfSufficiencyProductionDataSuccess
} from "../../../actions/energy/self-sufficiency/production.actions";
import { SelfSufficiencyProductionDto } from "../../../../common/dto/energy/self-sufficiency/production";

@Injectable()
export class SelfSufficiencyProductionEffects {
  getProductionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelfSufficiencyProductionData),
      switchMap(action =>
        this.selfSufficiencyService.getProductionData(action)
          .pipe(
            map((payload: SelfSufficiencyProductionDto) => getSelfSufficiencyProductionDataSuccess({ payload })),
            catchError(() => EMPTY)
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private selfSufficiencyService: SelfSufficiencyService
  ) { }
}
