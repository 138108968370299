import { Category, Unit } from "../interfaces/unit-format/unit";

export const units: Record<Category, Array<Unit>> = {
  decimal: [
    { category: "decimal", base: "", symbol: "", scale: 1 },
    { category: "decimal", base: "k", symbol: "k", scale: 1000 },
    { category: "decimal", base: "M", symbol: "M", scale: 1000000 },
    { category: "decimal", base: "G", symbol: "G", scale: 1000000000 },
    { category: "decimal", base: "T", symbol: "T", scale: 1000000000000 },
  ],
  time: [
    { category: "time", base: "seconds", symbol: "s", scale: 1 },
    { category: "time", base: "minutes", symbol: "m", scale: 60 },
    { category: "time", base: "hours", symbol: "h", scale: 3600 },
    { category: "time", base: "days", symbol: "d", scale: 86400 },
  ],
  length: [
    { category: "length", base: "m", symbol: "m", scale: 1 },
    { category: "length", base: "km", symbol: "km", scale: 1000 },
  ],
  area: [
    { category: "area", base: "m2", symbol: "m²", scale: 1 },
    { category: "area", base: "ha", symbol: "ha", scale: 10000 },
    { category: "area", base: "km2", symbol: "km²", scale: 1000000 },
  ],
  volume: [
    { category: "volume", base: "liters", symbol: "l", scale: 1 },
    { category: "volume", base: "m3", symbol: "m³", scale: 1000 },
  ],
  weight: [
    { category: "weight", base: "g", symbol: "g", scale: 1 },
    { category: "weight", base: "kg", symbol: "kg", scale: 1000 },
    { category: "weight", base: "t", symbol: "t", scale: 1000000 },
    { category: "weight", base: "Mt", symbol: "Mt", scale: 1000000000000 },
    { category: "weight", base: "Gt", symbol: "Gt", scale: 1000000000000000 },
  ],
  percentage: [
    {
      category: "percentage",
      base: "%",
      symbol: "%",
      scale: 1,
    },
    {
      category: "percentage",
      base: "decimal",
      symbol: "",
      scale: 100,
    },
  ],
  currency: [{ category: "currency", base: "EUR", symbol: "€", scale: 1 }]
};
