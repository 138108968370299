<div class="tpm-tab-nav-bar-wrapper">
  <nav mat-tab-nav-bar [disablePagination]="true" [disableRipple]="true">
    <a
      mat-tab-link
      *ngFor="let item of items; trackBy: trackByName"
      [active]="item.name === _active"
      [disabled]="item.disabled === true"
      (click)="_active = item.name; tabChange.emit(item.name)"
    >
      {{ item.label | translate }}
    </a>
  </nav>
  <tpm-tab-nav-bar
    *ngIf="getChildren(_active) as children"
    class="children"
    [items]="children"
    [active]="childActive"
    (tabChange)="tabChildChange.emit($event)"
  ></tpm-tab-nav-bar>
</div>
