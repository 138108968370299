import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { Pure } from "src/app/common/decorators/pure";
import { TableColumn } from "../../../classes/table-column";
import { StateTableColumnConfig } from "../../../table.interfaces";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "ngmy-state-column",
  templateUrl: "./state-column.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StateColumnComponent<T> extends TableColumn {
  @Pure
  public getColor({ color = null, colorAccessor = () => null }: StateTableColumnConfig, row?: T): string | null {
    return color ?? colorAccessor(row);
  }
}
