import { Action, combineReducers } from "@ngrx/store";
import * as degree from "./degree.reducer";
import * as consumption from "./consumption.reducer";
import * as production from "./production.reducer";
import * as summary from "./summary.reducer";
import * as timeRange from "./time-range.reducer";

export interface State {
  degree: degree.State;
  consumption: consumption.State;
  production: production.State;
  summary: summary.State;
  timeRange: timeRange.State;
}

const selfSufficiencyReducer = combineReducers({
  degree: degree.reducer,
  consumption: consumption.reducer,
  production: production.reducer,
  summary: summary.reducer,
  timeRange: timeRange.reducer
});

export function reducer(state: State | undefined, action: Action): State {
  return selfSufficiencyReducer(state, action);
}
