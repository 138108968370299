import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-dialog-wrapper",
  templateUrl: "./dialog-wrapper.component.html",
  styleUrls: ["./dialog-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogWrapperComponent {
  @Input() public hideHeader: boolean = false;

  @Input() public titleIcon: string;
  @Input() public title: string;

  @Input() public closeButtonLabel: string;
  @Input() public submitButtonLabel: string;

  @Input() public hasActions: boolean = true;
  @Input() public submitDisabled: boolean = false;

  @Output() public readonly closed = new EventEmitter<void>();
  @Output() public readonly submitted = new EventEmitter<void>();
}
