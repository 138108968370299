import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { EMPTY } from "rxjs";
import { catchError, exhaustMap, filter, map, switchMap } from "rxjs/operators";
import { GasCockpitValuesAllYearsDto, NetworkDto } from "../../common/dto/network";
import { NetworkService } from "../../core/services/network.service";
import {
  getGasCockpitValuesForAllYears,
  getGasCockpitValuesForAllYearsSuccess,
  getNetworkIds,
  getNetworkIdsSuccess
} from "../actions/network.actions";
import { RootState } from "../root-reducers";
import { selectRegionId } from "../selectors/app.selectors";

@Injectable()
export class NetworkEffects {
  getNetworkIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNetworkIds),
      switchMap(() => this.store.pipe(select(selectRegionId))),
      filter(Boolean),
      exhaustMap((regionId: string) =>
        this.networkService.getNetworkIds(regionId).pipe(
          map((payload: Array<NetworkDto>) => getNetworkIdsSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getGasCockpitValuesForAllYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGasCockpitValuesForAllYears),
      exhaustMap((action) =>
        this.networkService.getGasCockpitValuesAllYears(action).pipe(
          map((payload: GasCockpitValuesAllYearsDto) => getGasCockpitValuesForAllYearsSuccess({ payload })),
          catchError(() => EMPTY)
        )
      )
    )
  );

  constructor(private actions$: Actions, private networkService: NetworkService, private store: Store<RootState>) {}
}
