import { formatDate } from "@angular/common";
import { Inject, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { formatCurrencyDE } from "src/app/core/utils/number/currency";
import { ITableColumn } from "../interfaces/table-column";
import { TABLE_VALUE_FORMAT } from "../tokens/table-value-format";

@Pipe({ name: "tableTemplateTransform" })
export class TableTemplateTransformPipe implements PipeTransform {
  private readonly placeholder = "-";

  constructor(private readonly translate: TranslateService, @Inject(TABLE_VALUE_FORMAT) private valueFormat: string) {}

  public transform(value: unknown, { type, dateFormat }: ITableColumn): string {
    switch (type) {
      case "date": {
        return value ? formatDate(value as string | number | Date, dateFormat, "de-ch") : this.placeholder;
      }
      case "value": {
        return value ? formatCurrencyDE(value as number, "EUR", this.valueFormat) : this.placeholder;
      }
      case "list": {
        return value ? (value as Array<string>).join("\n") : this.placeholder;
      }
      case "text": {
        if (typeof value === "string") {
          return value ? this.translate.instant(value) : this.placeholder;
        } else {
          return value ? String(value) : this.placeholder;
        }
      }
      default: {
        return value as string;
      }
    }
  }
}
