export interface ILngLatPoint {
  lng: number;
  lat: number;
}

export interface IRegionsYearDto {
  ags: string;
  area: number;
  name: string;
  rs: string;
  population: string;
  bbox: {
    sw: ILngLatPoint;
    ne: ILngLatPoint;
  };
  loading?: boolean;
}

export interface IPvPotentialItem {
  countBuildings?: number;
  sumPVPotential?: number;
  sumPVSuitability?: number;
}

export interface IRegionsPerYearDto extends IPvPotentialItem {
  year: number;
}

export class RegionsYearDto implements IRegionsYearDto {
  public ags: string;
  public area: number;
  public name: string;
  public rs: string;
  public population: string;
  public bbox: {
    sw: ILngLatPoint;
    ne: ILngLatPoint;
  };

  constructor(data: IRegionsYearDto) {
    this.ags = data.ags;
    this.area = data.area;
    this.name = data.name;
    this.rs = data.rs;
    this.population = data.population;
    this.bbox = data.bbox;
  }
}

export class RegionsPerYearDto implements IRegionsPerYearDto {
  public countBuildings: number;
  public sumPVPotential: number;
  public sumPVSuitability: number;
  public year: number;

  constructor(data: IRegionsPerYearDto) {
    this.countBuildings = data.countBuildings || null;
    this.sumPVPotential = data.sumPVPotential || null;
    this.sumPVSuitability = data.sumPVSuitability || null;
    this.year = data.year;
  }
}
