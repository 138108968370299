import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TrackByFunction
} from "@angular/core";
import { Size } from "@enersis/gp-components/gp-toggle-button";
import { EneLayoutService } from "@enersis/ui-helper";
import { Observable, of } from "rxjs";

export interface IButtonToggleItem<T = string | number> {
  label: string;
  value: T;
  disabled?: boolean;
  preventedClick?: boolean;
}

@Component({
  selector: "app-button-toggle",
  templateUrl: "./button-toggle.component.html",
  styleUrls: ["./button-toggle.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonToggleComponent implements OnInit {
  @Output() public readonly initialized = new EventEmitter<IButtonToggleItem["value"]>();
  @Output() public readonly buttonChange = new EventEmitter<IButtonToggleItem["value"]>();
  @Output() public readonly buttonDisabledClick = new EventEmitter<IButtonToggleItem["value"]>();
  @Output() public readonly buttonPreventedClick = new EventEmitter<IButtonToggleItem["value"]>();

  @Input() public active: IButtonToggleItem["value"];
  @Input() public items: Array<IButtonToggleItem>;
  @Input() public dark: boolean;

  @Input()
  public set fullWidth(_fullWidth: boolean | string | undefined) {
    this._fullWidth = coerceBooleanProperty(_fullWidth);
  }
  public _fullWidth: boolean;

  @Input()
  public set mobileView(_mobileView: boolean | string | undefined) {
    this._mobileView = coerceBooleanProperty(_mobileView);
    this.isMobileScreen$ = coerceBooleanProperty(this._mobileView) ? this.layoutService.isMobileScreen$ : of(false);
  }
  public _mobileView: boolean;

  @Input()
  public set size(_size: Size | undefined) {
    this._size = _size ?? "medium";
  }
  public _size: Size | undefined;

  public isMobileScreen$: Observable<boolean>;

  constructor(private readonly layoutService: EneLayoutService) {}

  public ngOnInit(): void {
    this.initialized.emit(this.active);
  }

  public readonly trackByValue: TrackByFunction<IButtonToggleItem> = (_, item) => item.value;

  public onClick(item: IButtonToggleItem): void {
    if (item.disabled === true) {
      this.buttonDisabledClick.emit(item.value);
      return;
    }

    if (item.preventedClick === true) {
      this.buttonPreventedClick.emit(item.value);
      return;
    }

    this.buttonChange.emit(item.value);
  }
}
