// Sources:
//
// eslint-disable-next-line max-len
// https://web.archive.org/web/20160215155254/http://blogs.msdn.com:80/b/vsofficedeveloper/archive/2008/05/08/office-2007-open-xml-mime-types.aspx
// https://learn.microsoft.com/en-us/previous-versions/office/office-2007-resource-kit/ee309278%28v=office.12%29
//

export enum MimeType {
  Jpg = "image/jpeg",
  Png = "image/png",
  Tiff = "image/tiff",
  Heif = "image/heif",
  Raw = "image/x-dcraw",
  Dng = "image/x-adobe-dng",
  Pdf = "application/pdf",
  // *.doc *.dot
  Word = "application/msword",
  // *.docx
  Word_X = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // *.xls
  Excel = "application/vnd.ms-excel",
  // *.xlsx
  Excel_X = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  Txt = "text/plain",
  Odt = "application/vnd.oasis.opendocument.text",
  All = ""
}
